import styled from 'styled-components'
import colors from '../../../assets/colors'

const ComponentStyled = styled.div`
  && {
    .earned-income {
      text-align: center;
    }

    .section-title {
      font-size: 14px;
      font-weight: 600;
      line-height: 16px;
      letter-spacing: 1.08px;
      text-transform: uppercase;
      margin-bottom: 30px;
      text-align: left;

      &.monthly-section {
        margin-bottom: 20px;
      }
    }

    .section-wrapper {
      margin-bottom: 60px;

      .send-link {
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-left: 25px;

        p {
          font-size: 16px;
          line-height: 16px;
          color: ${colors.darkGrey8};
          margin-bottom: 0;
        }
      }
    }
  }
`

export default ComponentStyled
