import styled from 'styled-components'
import colors from '../../../assets/colors'

const CircleHeaderStyled = styled.div`
  && {
    position: relative;
    text-align: center;
    padding-top: 70px;
    padding-bottom: 50px;

    svg,
    img {
      position: relative;
      z-index: 2;
    }

    ::before {
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      clip-path: ellipse(52% 90% at 50% 3%);
      background-color: ${colors.yellow};
    }
  }
`

export default CircleHeaderStyled
