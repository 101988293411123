import React from 'react'
import StepsStyled from './styled'
import { Step } from '../../atoms'
import PropTypes from 'prop-types'

const Steps = ({ activeStep1, activeStep2, activeStep3 }) => {
  return (
    <StepsStyled>
      <Step active={activeStep1} number="1" label="Teléfono" />
      <Step active={activeStep2} number="2" label="Contraseña" />
      <Step active={activeStep3} number="3" label="Datos" />
    </StepsStyled>
  )
}

Steps.propTypes = {
  activeStep1: PropTypes.bool,
  activeStep2: PropTypes.bool,
  activeStep3: PropTypes.bool
}

export default Steps
