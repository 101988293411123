import { connect } from 'react-redux'
import compose from 'recompose/compose'
import View from './view'

const mapStateToProps = state => {
  return {
    poll: state.themes.poll
  }
}

const mapDispatchToProps = dispatch => {
  return {}
}

const enhance = compose(connect(mapStateToProps, mapDispatchToProps))

export default enhance(View)
