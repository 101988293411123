import { connect } from 'react-redux'
import compose from 'recompose/compose'
import View from './view'
import { withRouter } from 'react-router'
import { usersOperations } from '../../../redux/users'

const mapStateToProps = state => {
  return {
    isFetchingUsers: state.users.organizationUsersLoading || state.users.themeParticipantsLoading || state.users.themeAdminsLoading,
    fetchParticipantsIsFetching: state.users.themeParticipantsLoading,
    organization: state.organizations.item,
    theme: state.themes.item,
    themes: state.themes.list,
    usersList: state.users.organizationUsersList,
    total: state.users.organizationUsersTotal,
    pageInfo: state.users.organizationUsersPageInfo
  }
}

const mapDispatchToProps = dispatch => {
  return {
    fetchOrganizationUsers: searchText => dispatch(usersOperations.initOrganizationUsersList(searchText)),
    initParticipantsList: () => dispatch(usersOperations.initThemeParticipantsList()),
    initAdminsList: () => dispatch(usersOperations.initThemeAdminsList())
  }
}

const enhance = compose(connect(mapStateToProps, mapDispatchToProps))

export default withRouter(enhance(View))
