import * as types from './types'
import { LOG_OUT } from '../auth/types'

const initialState = {
  userInfo: null,
  isFetching: false
}

const reducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case types.ME_UPDATE_INFO:
      return {
        ...state,
        userInfo: action.value
      }

    case types.ME_SET_FETCHING:
      return {
        ...state,
        isFetching: action.value
      }

    case LOG_OUT:
      return initialState

    default:
      return state
  }
}

export default reducer
