import React from 'react'
import SideBarUpdateUserStyled from './styled'
import { UpdateUserForm } from '../../molecules'

const SideBarUpdateUser = () => {
  return (
    <SideBarUpdateUserStyled>
      <UpdateUserForm />
    </SideBarUpdateUserStyled>
  )
}

export default SideBarUpdateUser
