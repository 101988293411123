import * as types from './types'

export const setFetching = value => {
  return {
    type: types.ME_SET_FETCHING,
    value
  }
}

export const updateInfo = value => {
  return {
    type: types.ME_UPDATE_INFO,
    value
  }
}
