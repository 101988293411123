import React, { Component } from 'react'
import { ComponentStyled } from './styled'
import { Button, Avatar, SearchField } from '../../atoms'
import i18n from '../../../assets/i18n'
import { getBlackListUsers } from '../../../utils'
import PropTypes from 'prop-types'
import { Row, Col } from 'react-bootstrap'
import { toast } from 'react-toastify'
import _ from 'lodash'
import { ReactComponent as Spinner } from '../../../assets/images/yellow-spinner.svg'

class EditThemeParticipants extends Component {
  constructor(props) {
    super(props)
    this.state = {
      themesToAdd: _.filter(props.themes || [], v => v.id !== props.theme?.id),
      participantsToAdd: [],
      participantsAdded: [],
      adminsAdded: []
    }
  }

  async componentDidMount() {
    await this.loadThemeAdmins()
    await this.loadThemeParticipants()
    await this.loadOrganizationUsers()
  }

  loadThemeAdmins = async () => {
    const adminsAdded = await this.props.initAdminsList()
    this.setState({ adminsAdded })
  }

  loadThemeParticipants = async () => {
    const participantsAdded = await this.props.initParticipantsList()
    this.setState({ participantsAdded })
  }

  loadOrganizationUsers = async (searchText = '') => {
    let participantsToAdd = await this.props.fetchOrganizationUsers(searchText)
    participantsToAdd = _.differenceWith(participantsToAdd, this.state.participantsAdded, _.isEqual)
    this.setState({ participantsToAdd })
  }

  filterThemes = async (searchText = '') => {
    const { themes, theme } = this.props
    let themesToAdd = _.filter(themes || [], v => v.id !== theme?.id)
    if (searchText.length > 0) {
      themesToAdd = themesToAdd.filter(item => {
        return item?.name.toLowerCase().includes(searchText.toLowerCase())
      })
    }
    this.setState({ themesToAdd })
  }

  onSearch = _.debounce((e, searchText) => {
    this.loadOrganizationUsers(searchText)
    this.filterThemes(searchText)
  }, 1000)

  onCheckAll = () => {
    const { isFetchingUsers } = this.props
    const { participantsToAdd, participantsAdded, adminsAdded } = this.state

    if (!isFetchingUsers) {
      const { blackList } = getBlackListUsers(participantsToAdd, adminsAdded)

      if (blackList.length > 0) {
        toast(i18n.alreadyUsersAdminMessage, { type: toast.TYPE.ERROR })
      } else {
        this.setState({ participantsAdded: _.concat(participantsToAdd, participantsAdded), participantsToAdd: [] })
      }
    }
  }

  onThemeTapped = async itemToAdd => {
    const { participantsAdded, adminsAdded, participantsToAdd } = this.state
    const { fetchNonPersistentThemeParticipantsList } = this.props
    const participants = await fetchNonPersistentThemeParticipantsList(itemToAdd?.id)

    const { blackList } = getBlackListUsers(participants, adminsAdded)

    if (blackList.length > 0) {
      toast(i18n.alreadyUsersAdminMessage, { type: toast.TYPE.ERROR })
    } else {
      const newList = _.uniqBy([...participantsAdded, ...participants], 'id')
      const formatParticipantsToAdd = _.differenceWith(participantsToAdd, newList, _.isEqual)

      this.setState({ participantsAdded: newList, participantsToAdd: formatParticipantsToAdd })
    }
  }

  onClickParticipantToAdd = itemToAdd => {
    const { participantsToAdd, adminsAdded } = this.state
    const { isFetchingUsers } = this.props

    if (!isFetchingUsers) {
      const isInBlackList = _.findIndex(adminsAdded, { id: itemToAdd.id })

      if (isInBlackList > -1) {
        toast(i18n.alreadyAdminMessage, { type: toast.TYPE.ERROR })
      } else {
        _.pull(participantsToAdd, itemToAdd)
        this.setState({ participantsToAdd })
        this.setState(previousState => ({
          participantsAdded: _.uniqBy([...previousState.participantsAdded, itemToAdd], 'id')
        }))
      }
    }
  }

  onClickParticipantRemove = itemToRemove => {
    const { participantsAdded } = this.state
    const { isFetchingUsers } = this.props

    if (!isFetchingUsers) {
      _.pull(participantsAdded, itemToRemove)
      this.setState({ participantsAdded })
      this.setState(previousState => ({
        participantsToAdd: _.uniqBy([...previousState.participantsToAdd, itemToRemove], 'id')
      }))
    }
  }

  render() {
    const { participantsToAdd, participantsAdded, themesToAdd } = this.state
    const { isFetchingUsers, handleOnSubmit } = this.props

    const themesToAddFormat = _.map(themesToAdd, (item, key) => {
      const name = _.get(item, 'name', '')

      return (
        <div key={key} className="theme-list-wrapper" onClick={() => this.onThemeTapped(item)}>
          <p className="theme-name">#{name}</p>
        </div>
      )
    })

    const participantsToAddFormat = _.map(participantsToAdd, item => {
      const clients = _.get(this.props.organization, 'clients.edges', '')
      const itemId = _.get(item, '_id', '')
      const directName = _.get(item, 'fullname', '')
      const directImage = _.get(item, 'image._id', '')
      const subInfo = _.get(item, 'phone', '')
      const isAdmin = _.find(clients, client => client?.node?._id === itemId)
      if (isAdmin) return null
      return (
        <div key={itemId} className="participant-list-wrapper" onClick={() => this.onClickParticipantToAdd(item)}>
          <Avatar resourceId={directImage} name={directName} subtitle={subInfo} />
        </div>
      )
    })

    const participantsAddedFormat = _.map(participantsAdded, item => {
      const itemId = _.get(item, '_id', '')
      const directName = _.get(item, 'fullname', '')
      const directImage = _.get(item, 'image._id', '')
      const subInfo = _.get(item, 'phone', '')

      return (
        <div key={itemId} className="participant-list-wrapper" onClick={() => this.onClickParticipantRemove(item)}>
          <Avatar resourceId={directImage} name={directName} subtitle={subInfo} />
        </div>
      )
    })

    return (
      <ComponentStyled>
        <form
          onSubmit={e => {
            e.preventDefault()
            handleOnSubmit(participantsAdded)
          }}
        >
          <div className="search-field-wrapper">
            <SearchField placeholder={i18n.editThemeParticipants.searchParticipants} handleOnChange={this.onSearch} />
          </div>

          <Row>
            <Col xs={12} sm={6} className="list-wrapper">
              <p className="title-list">{i18n.editThemeParticipants.addParticipants}</p>
              {themesToAddFormat}
              {participantsToAddFormat}
              {isFetchingUsers && (
                <div className="spinner-wrapper">
                  <Spinner className="spinner" />
                </div>
              )}
            </Col>
            <Col xs={12} sm={6} className="list-wrapper">
              <p className="title-list">{i18n.editThemeParticipants.participantAdded}</p>
              {participantsAddedFormat}
            </Col>
          </Row>
          {_.size(participantsAddedFormat) > 0 && (
            <div className="submit-participants-wrapper">
              <Button isFetching={this.props.isFetchingForm} type="submit" variant="big" label={i18n.editThemeParticipants.save} />
            </div>
          )}
          {_.size(participantsToAddFormat) > 0 && (
            <p className="check-all">
              <span onClick={this.onCheckAll}>{i18n.editThemeParticipants.addAll}</span>
            </p>
          )}
        </form>
      </ComponentStyled>
    )
  }
}

EditThemeParticipants.propTypes = {
  isFetchingForm: PropTypes.bool,
  handleOnSubmit: PropTypes.func,
  initAdminsList: PropTypes.func,
  initParticipantsList: PropTypes.func,
  fetchOrganizationUsers: PropTypes.func,
  isFetchingUsers: PropTypes.bool,
  theme: PropTypes.object,
  themes: PropTypes.array,
  organization: PropTypes.object
}

export default EditThemeParticipants
