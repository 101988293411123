import * as types from './types'

export const setFetching = value => {
  return {
    type: types.STATS_SET_FETCHING,
    value
  }
}

export const setFetchingTheme = value => {
  return {
    type: types.STATS_SET_FETCHING_THEME,
    value
  }
}

export const updateStats = value => {
  return {
    type: types.STATS_UPDATE_INFO,
    value
  }
}

export const updateThemes = value => {
  return {
    type: types.STATS_UPDATE_THEMES,
    value
  }
}

export const updateNumThemes = value => {
  return {
    type: types.STATS_UPDATE_NUM_THEMES,
    value
  }
}

export const updateNumUsers = value => {
  return {
    type: types.STATS_UPDATE_NUM_USERS,
    value
  }
}

export const setSelectedTheme = value => {
  return {
    type: types.STATS_SET_SELECTED_THEME,
    value
  }
}

export const updateSelectedThemeInfo = value => {
  return {
    type: types.STATS_UPDATE_SELECTED_THEME,
    value
  }
}

export const setThemeAdmins = value => {
  return {
    type: types.STATS_SET_THEME_ADMINS,
    value
  }
}

export const setUserUsageRecors = value => {
  return {
    type: types.STATS_SET_USER_USAGE_RECORDS,
    value
  }
}

export const setNumUserUsageRecors = value => {
  return {
    type: types.STATS_SET_NUM_USER_USAGE_RECORDS,
    value
  }
}

export const setThemeData = value => {
  return {
    type: types.STATS_SET_THEME_DATA,
    value
  }
}

export const updateGlobalMessage = value => {
  return {
    type: types.STATS_SET_GLOBAL_MESSAGE,
    value
  }
}

export const updateNumGlobalMessage = value => {
  return {
    type: types.STATS_SET_NUM_GLOBAL_MESSAGE,
    value
  }
}

export const updateAnswersMessage = value => {
  return {
    type: types.STATS_SET_ANSWERS_MESSAGE,
    value
  }
}

export const updateNumAnswersMessage = value => {
  return {
    type: types.STATS_SET_NUM_ANSWERS_MESSAGE,
    value
  }
}
export const updateDates = value => {
  return {
    type: types.STATS_SET_DATES,
    value
  }
}
