import styled from 'styled-components'

const ComponentStyled = styled.div`
  && {
    margin-top: 30px;

    .success {
      text-align: center;
      margin-bottom: 50px;
    }
  }
`

export default ComponentStyled
