import styled, { css } from 'styled-components'
import colors from '../../../assets/colors'

const ComponentStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 50px;
  padding: 10px 15px;
  box-shadow: 0px 2px 11px rgba(0, 0, 0, 0.06);
  border-radius: 27px;

  .info-wrapper {
    display: flex;
    align-items: center;

    .title {
      font-size: 14px;
      line-height: 16px;
      color: ${colors.grey2};
      margin-bottom: 0;
      margin-right: 10px;
    }

    .desc {
      font-size: 14px;
      line-height: 16px;
      margin-bottom: 0;
      margin-right: 10px;
    }

    .more-info {
      font-size: 14px;
      line-height: 16px;
      color: ${colors.yellow};
      margin-bottom: 0;
      margin-right: 10px;
    }
  }

  .download-icon {
    cursor: pointer;
    max-width: 20px;
  }

  ${props =>
    props.hasClickEvent === true &&
    css`
      cursor: pointer;
    `};
`

export default ComponentStyled
