import styled, { css } from 'styled-components'
import colors from '../../../assets/colors'

const ComponentStyled = styled.div`
  && {
    .billing-wrapper {
      padding: 10px 0;
      display: flex;
      align-items: center;
      transition: 0.5s ease all;
      justify-content: space-between;
      position: relative;
      margin: 25px;

      ${props =>
        props.hasClick === true &&
        css`
          cursor: pointer;
        `}

      .name {
        font-size: 16px;
        line-height: 16px;
        color: ${colors.black};
        margin-bottom: 10px;
      }

      .address,
      .account {
        font-size: 14px;
        line-height: 14px;
        color: ${colors.grey2};
        margin-bottom: 5px;
      }

      img,
      svg {
        position: relative;
        z-index: 1;
      }
    }

    .billing-title {
      font-size: 14px;
      font-weight: 600;
      line-height: 16px;
      letter-spacing: 1.08025px;
      text-transform: uppercase;
      color: ${colors.black};
    }
  }
`

export default ComponentStyled
