import { connect } from 'react-redux'
import compose from 'recompose/compose'
import { organizationsOperations } from '../../../redux/organizations'
import View from './view'

const mapStateToProps = state => {
  return {
    organization: state.organizations.item
  }
}

const mapDispatchToProps = dispatch => {
  return {
    updateOrganization: (organizationId, data) => dispatch(organizationsOperations.updateOrganization(organizationId, data))
  }
}

const enhance = compose(connect(mapStateToProps, mapDispatchToProps))

export default enhance(View)
