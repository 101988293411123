import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import compose from 'recompose/compose'
import View from './view'
import { organizationsOperations } from '../../../redux/organizations'

const mapStateToProps = state => {
  return {
    invitedUsers: state.organizations.invitedUsers
  }
}

const mapDispatchToProps = dispatch => {
  return { removeInvitation: invitationId => dispatch(organizationsOperations.removeInvitation(invitationId)) }
}

const enhance = compose(connect(mapStateToProps, mapDispatchToProps))

export default withRouter(enhance(View))
