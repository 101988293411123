import React, { Component } from 'react'
import ComponentStyled from './styled'
import { UpdateOrganizationForm } from '../../molecules'
import { toast } from 'react-toastify'
import PropTypes from 'prop-types'
import _ from 'lodash'
import i18n from '../../../assets/i18n'

class SideBarUpdateOrganization extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isFetching: false
    }
  }

  onSubmitOrganizationData = organizationFormData => {
    this.setState({ organizationFormData, isFetching: true }, async () => {
      const organizationId = _.get(this.props.organization, '_id')
      const organizationEdited = await this.props.updateOrganization(organizationId, organizationFormData)
      this.setState({ isFetching: false }, () => {
        if (!organizationEdited) {
          toast(i18n.generalError, { type: toast.TYPE.ERROR })
        }
      })
    })
  }

  render() {
    const { organization } = this.props
    const { isFetching } = this.state

    return (
      <ComponentStyled>
        <UpdateOrganizationForm onSubmit={this.onSubmitOrganizationData} organization={organization} isFetching={isFetching} />
      </ComponentStyled>
    )
  }
}

SideBarUpdateOrganization.propTypes = {
  organization: PropTypes.object,
  updateOrganization: PropTypes.func,
  isFetching: PropTypes.bool
}

export default SideBarUpdateOrganization
