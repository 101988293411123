import styled from 'styled-components'
import colors from '../../../assets/colors'

const ComponentStyled = styled.div`
  && {
    background-color: ${colors.grey};

    .terms-wrapper {
      padding: 50px 0;

      a {
        color: inherit;
        text-decoration: underline;
      }

      h1 {
        margin-bottom: 30px;
      }

      .section-title {
        margin-top: 30px;
        font-weight: bold;
      }

      li {
        margin-bottom: 15px;
      }
    }
  }

  @media (min-width: 576px) {
  }
  @media (min-width: 768px) {
  }

  @media (min-width: 992px) {
  }

  @media (min-width: 1200px) {
  }
`

export default ComponentStyled
