import React, { Component } from 'react'
import ComponentStyled from './styled'
import { Container, Row, Col } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import routes from '../../../config/routes'
import { ReactComponent as BeeIcon } from '../../../assets/images/bee.svg'
import i18n from '../../../assets/i18n'

class PageNotFound extends Component {
  render() {
    return (
      <ComponentStyled>
        <Container>
          <Row>
            <Col xs={12}>
              <p className="error-number">
                <span>4</span>
                <BeeIcon className="bee-icon" />
                <span>4</span>
              </p>
              <p>{i18n.pageNotFound.error}</p>
              <p>
                <Link to={routes.home}>{i18n.pageNotFound.backToHome}</Link>
              </p>
            </Col>
          </Row>
        </Container>
      </ComponentStyled>
    )
  }
}

export default PageNotFound
