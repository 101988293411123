import styled from 'styled-components'
import colors from '../../../assets/colors'

const ImageBgStyled = styled.div`
  && {
    min-height: 200px;
    background-color: ${colors.grey};
    background-position: center;
    background-size: cover;

    @media (min-width: 768px) {
      min-height: 355px;
    }
  }
`

export default ImageBgStyled
