import * as comercialPaymentsActions from './actions'
import _ from 'lodash'

export const fetchList = () => async (dispatch, getState, { client, api, setError }) => {
  try {
    dispatch(comercialPaymentsActions.setFetching(true))
    const customersQueryRes = await client.query({ query: api.queries.GET_COMERCIAL_PAYMENTS, fetchPolicy: 'network-only' })
    const comercialPayments = _.map(_.get(customersQueryRes, 'data.comercialPayments.edges', []), v => v.node)

    dispatch(comercialPaymentsActions.updateList(comercialPayments))
    return comercialPayments
  } catch (e) {
    dispatch(setError(e))
    return []
  } finally {
    dispatch(comercialPaymentsActions.setFetching(false))
  }
}

export const selectItem = item => async (dispatch, getState, { client, api, setError }) => {
  dispatch(comercialPaymentsActions.updateItem(item))
}
