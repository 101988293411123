import { configureAxiosDefaults, instance as axios } from './utils'
import { BASE_URL } from '../config/api'
import * as queriesObject from './queries'
import _ from 'lodash'
import qs from 'qs'
import { URL_OK, URL_KO } from '../config/paymethod'
const IS_DEV = process.env.NODE_ENV === 'development'

export const queries = queriesObject

export function configureToken(token) {
  configureAxiosDefaults(`Authorization`, token ? `Bearer ${token}` : null)
}

export function configureLanguage(language) {
  configureAxiosDefaults(`Accept-Language`, language)
}

// AUTH
export const checkPhone = data => {
  const url = `/api/public/checkPhone`
  return axios.post(url, data)
}

export const register = data => {
  const url = `/api/public/users`
  return axios.post(url, data)
}

export const login = data => {
  const url = `/api/login_check`
  return axios.post(url, data)
}

// USER
export const getUserInfo = () => {
  const url = `/api/me`
  return axios.get(url).then(res => {
    return { ...res, data: { ...res.data, id: res.data[`@id`], _id: res.data[`id`], image: { _id: _.get(res, 'data.image.id') } } }
  })
}

export const updateUserInfo = data => {
  const url = `/api/me`
  return axios.patch(url, data).then(res => {
    return { ...res, data: { ...res.data, id: res.data[`@id`], _id: res.data[`id`], image: { _id: _.get(res, 'data.image.id') } } }
  })
}

export const updateBillingData = (id, data) => {
  const url = `/api/billing_datas/${id}`
  return axios.patch(url, data).then(res => {
    return { ...res, data: { ...res.data, id: res.data[`@id`], _id: res.data[`id`] } }
  })
}

export const createBillingData = data => {
  const url = `/api/billing_datas`
  return axios.post(url, data).then(res => {
    return { ...res, data: { ...res.data, id: res.data[`@id`], _id: res.data[`id`] } }
  })
}

export const resetPassword = data => {
  const url = `/api/user/reset`
  return axios.post(url, data)
}

export const disableUser = () => {
  const url = `/api/me/disabled`
  return axios.patch(url, { enabled: false }, { headers: { 'content-type': 'application/json' } })
}

// ORGANIZATION
export const postOrganization = data => {
  const url = `/api/organizations`
  return axios.post(url, data)
}

export const updateOrganization = (organizationId, data) => {
  const url = `/api/organizations/${organizationId}`
  return axios.patch(url, data)
}

export const postOrganizationUsers = data => {
  const url = `/api/invitations`
  return axios.post(url, data)
}

export const removeInvitation = data => {
  const url = `/api/remove_invitation`
  return axios.post(url, data)
}

export const exitOrganization = data => {
  const url = `/api/organization_unsubscribe`
  return axios.post(url, data)
}

export const postOrganizationPaymentMethod = organizationId => {
  const url = `/api/v2/organizations/${organizationId}/setup-intent`
  return axios.post(url)
}

export const postSubscription = (organizationId, stripePaymentMethodId) => {
  const url = `/api/v2/organizations/${organizationId}/subscription?paymentMethodId=${stripePaymentMethodId}`
  return axios.post(url)
}

export const putPaymentMethod = (subscriptionId, stripePaymentMethodId) => {
  const url = `/api/v2/subscriptions/${subscriptionId}/payment-method?paymentMethodId=${stripePaymentMethodId}`
  return axios.put(url)
}

export const putSubscriptionPaymentMethod = (subscriptionId, organizationId) => {
  const url = `/api/v2/subscriptions/${subscriptionId}/cancel?${qs.stringify({
    urlOk: URL_OK + `&isChangingPayment=true&subscriptionId=${subscriptionId}&organizationId=${organizationId}`,
    urlKo: URL_KO
  })}`
  return axios.put(url)
}

export const deleteOrganization = subscriptionId => {
  const url = `/api/v2/subscriptions/${subscriptionId}/cancel`
  return axios.post(url, { enabled: false }, { headers: { 'content-type': 'application/json' } })
}

export const fetchOrganizationCurrentBillingData = organizationId => {
  const url = `/api/organization/${organizationId}/current-billing`
  return axios.get(url)
}

export const fetchOrganizationPayments = organizationId => {
  const url = `/api/organization/${organizationId}/payments`
  return axios.get(url)
}

export const getBilling = paymentId => {
  const url = `/api/payment/signedUrl/${paymentId}`
  return axios.get(url)
}

// THEMES
export const postOrganizationTheme = data => {
  const url = `/api/themes`
  return axios.post(url, data)
}

export const updateOrganizationTheme = (themeId, data) => {
  const url = `/api/themes/${themeId}`
  return axios.patch(url, data)
}

export const updateOrganizationThemeParticipants = (themeId, data) => {
  const url = `/api/themes/participants/${themeId}`
  return axios.put(url, data)
}

export const updateOrganizationThemeAdmins = (themeId, data) => {
  const url = `/api/themes/admins/${themeId}`
  return axios.put(url, data)
}

export const deleteTheme = data => {
  const url = `/api/remove_theme`
  return axios.post(url, data)
}

export const exitTheme = data => {
  const url = `/api/unsubscribe_theme`
  return axios.post(url, data)
}

// MESSAGES
export const postMessage = data => {
  const url = `/api/messages`
  return axios.post(url, data)
}

// UNREAD MESSAGES
export const getUnreadMessagesList = organizationId => {
  const url = `/api/organizations/${organizationId}/unread-messages`
  return axios.get(url)
}

export const getUnreadMessagesItem = (organizationId, directId) => {
  const url = `/api/organizations/${organizationId}/directs/${directId}/unread-messages`
  return axios.get(url)
}

// MEDIA
export const getSignedUrl = resourceId => {
  const url = `/api/signedUrl/${resourceId}`
  return axios
    .get(url)
    .then(res => (IS_DEV ? { data: _.get(res, 'data', '').replace('http://localhost', BASE_URL) } : res))
    .catch(e => {
      throw e
    })
}

export const postResource = file => {
  const url = `/api/media_objects`
  const formData = new FormData()

  formData.append('file', file)

  return axios.post(url, formData, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
}

// POLL
export const postThemePoll = data => {
  const url = `/api/polls`
  return axios.post(url, data)
}

export const postPollVote = data => {
  const url = `/api/poll_vote`
  return axios.post(url, data)
}

// NOTIFY
export const postNotifyDirect = data => {
  const url = `/api/notify_direct`
  return axios.post(url, data)
}
