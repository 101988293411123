import styled, { css } from 'styled-components'
import colors from '../../../assets/colors'

const StepStyled = styled.div`
  && {
    .circle-step {
      background-color: ${colors.darkGrey};
      border-radius: 50px;
      display: inline-block;
      width: 30px;
      height: 30px;
      position: relative;
      span {
        font-size: 16px;
        line-height: 16px;
        letter-spacing: -0.256px;
        color: ${colors.white};
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }

    p {
      font-size: 13px;
      line-height: 13px;
      letter-spacing: 0.6px;
      color: ${colors.darkGrey};
      margin-bottom: 0;
    }

    ${props =>
      props.active &&
      css`
        .circle-step {
          background-color: ${colors.yellow};
          span {
            font-weight: 600;
          }
        }
        p {
          font-weight: 600;
        }
      `}
  }
`

export default StepStyled
