import { connect } from 'react-redux'
import compose from 'recompose/compose'
import { unreadMessagesOperations } from '../../../redux/unread-messages'
import View from './view'

const mapStateToProps = state => {
  return {
    unreadMessagesFetching: state.unreadMessages.isFetching,
    unreadMessages: state.unreadMessages.list,
    userInfo: state.me.userInfo
  }
}

const mapDispatchToProps = dispatch => {
  return {
    initUnreadMessages: () => dispatch(unreadMessagesOperations.initList())
  }
}

const enhance = compose(connect(mapStateToProps, mapDispatchToProps))

export default enhance(View)
