import styled from 'styled-components'
import colors from '../../../assets/colors'

const ComponentStyled = styled.div`
  && {
    display: flex;
    padding: 15px 0;
    justify-content: flex-end;
    text-align: right;

    .message-wrapper {
      max-width: 80%;

      .message-box {
        padding: 15px;
        background-color: ${colors.yellow};
        border-radius: 20px;
        display: inline-block;
        border-bottom-right-radius: 0;

        .message-author {
          font-size: 14px;
          line-height: 14px;
          color: ${colors.darkGrey7};
          margin-bottom: 10px;
        }

        .message-content {
          font-size: 14px;
          line-height: 19px;
          color: ${colors.darkGrey};
          margin-bottom: 0;
          text-align: right;
          -ms-word-break: break-all;
          word-break: break-all;
          word-break: break-word;
          & a {
            font-size: 14px;
            line-height: 19px;
            color: ${colors.darkGrey};
            margin-bottom: 0;
            text-align: right;
            text-decoration: underline;
          }
        }

        .message-time {
          color: ${colors.white};
          font-size: 10px;
          line-height: 10px;
          margin-top: 5px;
          margin-bottom: 0;
          text-align: left;
        }
      }

      .btn-wrapper {
        margin-top: 5px;
      }
    }

    .actions-author-wrapper {
      margin-right: 10px;
      cursor: pointer;
    }
  }
`

export default ComponentStyled
