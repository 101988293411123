import React, { Component } from 'react'
import RegisterFormStep1Styled from './styled'
import { RegularInput, RegularSelect, Button } from '../../atoms'
import i18n from '../../../assets/i18n'
import PropTypes from 'prop-types'
import { Formik } from 'formik'
import { countryOptions, getCountryPhoneCodeByIso2 } from '../../../utils'
import { checkPhone } from '../../../api'
import _ from 'lodash'
import { toast } from 'react-toastify'
import { Link } from 'react-router-dom'
import routes from '../../../config/routes'

class RegisterFormStep1 extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isFetching: false
    }
  }

  render() {
    const { onSubmit, isFetching } = this.props
    const spainValue = 'ES'
    return (
      <RegisterFormStep1Styled>
        <Formik
          initialValues={{
            country: spainValue,
            phone: '',
            terms: false,
            privacy: false
          }}
          validate={values => {
            let errors = {}
            if (!values.country) {
              errors.country = i18n.registerFormStep1.requiredField
            }
            if (!values.phone) {
              errors.phone = i18n.registerFormStep1.requiredField
            }
            if (!values.terms) {
              errors.terms = i18n.registerPassword.requiredField
            }
            if (!values.privacy) {
              errors.privacy = i18n.registerPassword.requiredField
            }
            return errors
          }}
          onSubmit={data => {
            this.setState({ isFetching: true }, async () => {
              const prefix = getCountryPhoneCodeByIso2(data.country)
              const formattedPhone = `+${prefix}${data.phone}`
              try {
                const phone = { phoneNumber: formattedPhone }
                await checkPhone(phone)
                toast(i18n.registerFormStep1.phoneRegistered, { type: toast.TYPE.ERROR })
                this.setState({ isFetching: false })
              } catch (e) {
                const status = _.get(e, 'response.status')
                this.setState({ isFetching: false })
                if (status === 404) {
                  onSubmit(formattedPhone, data.country, data.terms)
                } else {
                  toast(i18n.registerFormStep1.error, { type: toast.TYPE.ERROR })
                }
              }
            })
          }}
        >
          {({ values, errors, touched, handleChange, handleBlur, handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <p className="title">{i18n.registerFormStep1.title}</p>
              <RegularSelect
                options={countryOptions}
                label={i18n.registerFormStep1.countryLabel}
                handleOnChange={handleChange}
                handleOnBlur={handleBlur}
                name="country"
                value={values.country}
                hasError={errors.country && touched.country && true}
                defaultSelectedValue={spainValue} // Se hardcodea el código de España
              />
              {errors.country && touched.country && <p className="error-msg-input">{errors.prefix}</p>}
              <RegularInput
                label={i18n.registerFormStep1.enterPhone}
                placeholder="000 000 000"
                prepend={values.country ? `+${getCountryPhoneCodeByIso2(values.country)}` : '+00'}
                handleOnChange={handleChange}
                handleOnBlur={handleBlur}
                name="phone"
                value={values.phone}
                type="number"
                hasError={errors.phone && touched.phone && true}
              />
              {errors.phone && touched.phone && <p className="error-msg-input">{errors.phone}</p>}
              <div className="check-wrapper">
                <RegularInput
                  type="checkbox"
                  variant="checkbox"
                  handleOnChange={handleChange}
                  handleOnBlur={handleBlur}
                  name="terms"
                  checked={values.terms}
                  hasError={errors.terms && touched.terms && true}
                />

                <span className="accept-terms">
                  <span>
                    {i18n.registerPassword.accept}{' '}
                    <Link to={routes.terms} target="_blank">
                      {i18n.registerPassword.terms}
                    </Link>
                  </span>
                </span>
                {errors.terms && touched.terms && <p className="error-msg-input">{errors.terms}</p>}
              </div>
              <div className="check-wrapper">
                <RegularInput
                  type="checkbox"
                  variant="checkbox"
                  handleOnChange={handleChange}
                  handleOnBlur={handleBlur}
                  name="privacy"
                  checked={values.privacy}
                  hasError={errors.privacy && touched.privacy && true}
                />

                <span className="accept-terms">
                  <span>
                    {i18n.registerPassword.accept2}{' '}
                    <Link to={routes.privacyPolicy} target="_blank">
                      {i18n.registerPassword.privacyPolicy}
                    </Link>
                  </span>
                </span>
                {errors.privacy && touched.privacy && <p className="error-msg-input">{errors.privacy}</p>}
              </div>
              <p className="privacy-copy">
                {i18n.registerPassword.privacyCopy}{' '}
                <Link to={routes.privacyPolicy} target="_blank">
                  {i18n.registerPassword.privacyPolicy}
                </Link>
              </p>
              <Button id="sign-in-button" isFetching={isFetching} type="submit" variant="big" label={i18n.registerFormStep1.send} />{' '}
            </form>
          )}
        </Formik>
      </RegisterFormStep1Styled>
    )
  }
}

RegisterFormStep1.propTypes = {
  onSubmit: PropTypes.func,
  showCodeInput: PropTypes.bool,
  isFetching: PropTypes.bool,
  renderStep: PropTypes.func
}

export default RegisterFormStep1
