import React from 'react'

import { getSignedUrl } from '../../../api'

function useResourceLoader(resourceId) {
  const [resourceUri, setResourceUri] = React.useState(null)
  let _mounted = true // FIX UNMOUNTED UPDATE COMPONENTS
  React.useEffect(() => {
    if (resourceId) {
      getSignedUrl(resourceId)
        .then(({ data }) => {
          //console.log('getSignedUrl data: ', data)
          _mounted && setResourceUri(data)
        })
        .catch(() => {
          //console.log('getSignedUrl e: ', e)
        })
    }
    return () => {
      _mounted = false
    }
  }, [resourceId])

  return resourceUri
}

export default useResourceLoader
