import * as types from './types'
import { LOG_OUT } from '../auth/types'
import moment from 'moment'

const initialState = {
  dates: {
    start: moment()
      .startOf('month')
      .toDate(),
    end: moment()
      .endOf('month')
      .toDate()
  },
  themes: [],
  isFetching: false,
  isFetchingTheme: false,
  numThemes: 0,
  numUsers: 0,
  selectedTheme: null,
  themeAdmins: [],
  userUsageRecords: [],
  numUserUsageRecords: 0,
  globalMessages: [],
  numGlobalMessages: 0,
  answersMessages: [],
  numAnswersMessages: 0,
  themeData: {
    usageRecords: {
      num: 0,
      list: []
    },
    globalMessages: {
      num: 0,
      list: []
    },
    answersMessages: {
      num: 0,
      list: []
    }
  }
}

const reducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case types.STATS_UPDATE_THEMES:
      return {
        ...state,
        themes: action.value
      }

    case types.STATS_UPDATE_NUM_THEMES:
      return {
        ...state,
        numThemes: action.value
      }

    case types.STATS_UPDATE_NUM_USERS:
      return {
        ...state,
        numUsers: action.value
      }

    case types.STATS_SET_FETCHING:
      return {
        ...state,
        isFetching: action.value
      }
    
    case types.STATS_SET_FETCHING_THEME:
      return {
        ...state,
        isFetchingTheme: action.value
      }

    case types.STATS_SET_SELECTED_THEME:
      return {
        ...state,
        selectedTheme: action.value
      }

    case types.STATS_SET_THEME_ADMINS:
      return {
        ...state,
        themeAdmins: action.value
      }

    case types.STATS_SET_USER_USAGE_RECORDS:
      return {
        ...state,
        userUsageRecords: action.value
      }

    case types.STATS_SET_NUM_USER_USAGE_RECORDS:
      return {
        ...state,
        numUserUsageRecords: action.value
      }

    case types.STATS_SET_THEME_DATA:
      return {
        ...state,
        themeData: {...state.themeData, ...action.value}
      }

    case types.STATS_SET_GLOBAL_MESSAGE:
      return {
        ...state,
        globalMessages: action.value
      }

    case types.STATS_SET_NUM_GLOBAL_MESSAGE:
      return {
        ...state,
        numGlobalMessages: action.value
      }
    
    case types.STATS_SET_ANSWERS_MESSAGE:
      return {
        ...state,
        answersMessages: action.value
      }

    case types.STATS_SET_NUM_ANSWERS_MESSAGE:
      return {
        ...state,
        numAnswersMessages: action.value
      }

    case types.STATS_SET_DATES:
      return {
        ...state,
        dates: action.value
      }

    case LOG_OUT:
      return initialState

    default:
      return state
  }
}

export default reducer
