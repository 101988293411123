import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import RegularSelectStyled from './styled'

const RegularSelect = ({
  hasMarginBottom,
  variant,
  options,
  label,
  name,
  defaultLabel,
  handleOnBlur,
  handleOnChange,
  hasError,
  defaultSelectedValue,
  value,
  nullable,
  ...rest
}) => {
  const [selectValue, setSelectValue] = useState(defaultSelectedValue || '')

  useEffect(() => {
    setSelectValue(value)
  }, [value])

  const optionList = options.map((option, i) => (
    <option key={i} value={option.value}>
      {option.label}
    </option>
  ))

  function onChangeSelect(e) {
    setSelectValue(e.target.value)
    handleOnChange(e, e.target.value, e.target.name)
  }

  return (
    <RegularSelectStyled hasMarginBottom={hasMarginBottom} variant={variant} hasError={hasError} {...rest}>
      {label && <p>{label}</p>}
      <div className="select-wrapper">
        <select name={name} value={selectValue} onBlur={handleOnBlur} onChange={onChangeSelect}>
          {nullable && <option value="">{defaultLabel}</option>}
          {optionList}
        </select>
        <img src={require('../../../assets/images/black-arrow.png')} alt="Arrow" />
      </div>
    </RegularSelectStyled>
  )
}

RegularSelect.defaultProps = {
  nullable: true,
  defaultLabel: '-------',
  options: [],
  handleOnBlur: () => {},
  handleOnChange: () => {},
  hasError: false,
  hasMarginBottom: true,
  defaultSelectedValue: ''
}

RegularSelect.propTypes = {
  variant: PropTypes.string,
  hasMarginBottom: PropTypes.bool,
  label: PropTypes.string,
  name: PropTypes.string,
  defaultLabel: PropTypes.string,
  options: PropTypes.array,
  handleOnBlur: PropTypes.func,
  handleOnChange: PropTypes.func,
  hasError: PropTypes.bool,
  defaultSelectedValue: PropTypes.string,
  nullable: PropTypes.bool
}

export default RegularSelect
