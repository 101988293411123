import { connect } from 'react-redux'
import compose from 'recompose/compose'
import { organizationsOperations } from '../../../redux/organizations'
import View from './view'

const mapStateToProps = state => {
  return {
    organization: state.organizations.item,
    organizations: state.organizations.list,
    isFetchingOrganization: state.organizations.isFetching
  }
}

const mapDispatchToProps = dispatch => {
  return {
    updateSelectedOrganization: organization => dispatch(organizationsOperations.updateSelectedOrganization(organization)),
    onExitOrganization: organization => dispatch(organizationsOperations.exitOrganization(organization))
  }
}

const enhance = compose(connect(mapStateToProps, mapDispatchToProps))

export default enhance(View)
