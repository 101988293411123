import styled, { css } from 'styled-components'
import colors from '../../../assets/colors'

const ComponentStyled = styled.div`
  && {
    position: relative;

    .title-wrapper {
      padding-bottom: 25px;
      position: relative;
      display: flex;
      align-items: center;
      margin-bottom: 25px;

      ::before {
        content: '';
        position: absolute;
        bottom: 0;
        width: 1000px;
        right: 50%;
        transform: translateX(50%);
        height: 1px;
        background-color: ${colors.lightGrey};
      }

      .title {
        font-size: 20px;
        line-height: 24px;
        letter-spacing: -0.62px;
        color: ${colors.black};
        margin-bottom: 0;
      }

      .arrow {
        margin-right: 15px;
      }
    }

    ${props =>
      props.hasClickEvent === true &&
      css`
        cursor: pointer;
      `}
  }
`

export default ComponentStyled
