/* eslint-disable react/no-unescaped-entities */
import React from 'react'

export const EsContent = () => (
  <div>
    <h1>Política de Privacidad de BEENFO</h1>
    <p>Información básica de protección de datos</p>
    <p>Responsable: COCOMU INTERNET SLU.</p>
    <p>Finalidad: Facilitar el uso de la aplicación. Gestionar la relación comercial con los clientes y usuarios de la app.</p>
    <p>Legitimación: Ejecución de los Términos y Condiciones de la aplicación. Ejecución de los Términos y Condiciones de Clientes.</p>
    <p>Destinatarios: Prestadores de servicios.</p>
    <p>
      Derechos: Puede ejercitar sus derechos de acceso, rectificación y supresión, entre otros, poniéndose en contacto con nosotros tal y como se detalla en la
      Información Adicional.
    </p>
    <p>Información Adicional: Puede encontrar información adicional en nuestra Política de Privacidad aquí.</p>

    <p>
      Cocomu Internet SLU es transparente y pone a disposición de todos los usuarios de la app toda la información necesaria para que entiendan el
      funcionamiento de la App, así como las ventajas que ofrece y los derechos y responsabilidades en relación con el uso de la misma.
    </p>

    <p className="section-title">¿Quién es el responsable del tratamiento de sus datos?</p>
    <p>
      Una vez te descargues la aplicación y te registres en la misma, de acuerdo con los Términos y Condiciones de Uso de la App así como con Términos y
      Condiciones de Clientes, tus datos serán tratados por: COCOMU INTERNET SLU, con domicilio social en Avenida Miguel de Cervantes 63, 4ºB 16400 Tarancón,
      Cuenca, España y correo electrónico info@beenfo.com ("Cocomu").
    </p>

    <p className="section-title">Información y consentimiento</p>
    <p>
      Mediante la lectura de la presente Política de Privacidad, quedas informado sobre la forma en que Cocomu recaba, trata y protege los datos de carácter
      personal que le son facilitados en el contexto de la participación, mantenimiento y la gestión de la aplicación BEENFO. Debes leer con atención esta
      Política de Privacidad, que ha sido redactada de forma clara y sencilla, para facilitar su comprensión, y determinar libre y voluntariamente si deseas
      facilitar tus datos personales a Cocomu.
    </p>

    <p className="section-title">Obligatoriedad de facilitar los datos</p>
    <p>
      Los datos solicitados son, con carácter general, obligatorios (salvo que se especifique lo contrario) para cumplir con las finalidades establecidas y con
      la correcta prestación de los servicios. Por lo tanto, si no se facilitan los mismos o no se facilitan correctamente puede que Cocomu no pueda prestar los
      servicios solicitados correctamente.
    </p>

    <p className="section-title">¿Con qué finalidad se tratarán los datos personales y durante cuánto tiempo?</p>
    <p>Trataremos tus datos personales, que nos facilites o que obtengamos de otra fuente, conforme a las siguientes finalidades:</p>

    <ul>
      <li>
        Gestionar tu uso participación en la Aplicación, así como prestarte correctamente el servicio y prestaciones de los que te beneficias por tu condición
        de Usuario. Nuestra legitimación para tratar tus datos personales con esta finalidad es la ejecución de los Términos y Condiciones de Uso de la
        Aplicación, esto es, la ejecución de la relación contractual que existe entre tú y Cocomu.
      </li>
      <li>
        Gestionar tu uso participación en la Aplicación, así como prestarte correctamente el servicio y prestaciones de los que te beneficias por tu condición
        de Cliente. Nuestra legitimación para tratar tus datos personales con esta finalidad es la ejecución de los Términos y Condiciones de Clientes, esto es,
        la ejecución de la relación contractual que existe entre tú y Cocomu.
      </li>
    </ul>

    <p>
      Cocomu conservará tus datos durante el período de vigencia de tu relación contractual con Cocomu, para poder asegurar la gestión de tu descarga y uso y la
      correcta prestación de los servicios. Adicionalmente, Cocomu podrá conservar tus datos personales por el período que resulte necesario para la
      formulación, el ejercicio y la defensa de acciones legales, que dependerá de los períodos de prescripción que puedan resultar aplicables. Además, Cocomu
      deberá conservar tus datos personales debidamente bloqueados durante el período en el que pudieran derivarse responsabilidades en materia de protección de
      datos o el requerido por la normativa de protección de datos aplicable.
    </p>

    <p className="section-title">¿Qué datos serán tratados?</p>
    <p>
      Cocomu puede tratar diversas categorías de datos personales en función de la actividad concreta de que se trate y el tipo de relación comercial entre tú y
      Cocomu. Por ejemplo, Cocomu puede tratar datos identificativos, datos de contacto, datos financieros de Clientes, etc. En caso de que facilites datos de
      terceros, debes asegurarte de contar con el consentimiento de los mismos y te comprometes a trasladarles la información contenida en esta Política de
      Privacidad, eximiendo a Cocomu de cualquier responsabilidad en este sentido. No obstante, Cocomu podrá llevar a cabo las verificaciones periódicas para
      constatar este hecho, adoptando las medidas de diligencia debida que correspondan, conforme a la normativa de protección de datos.
    </p>

    <p className="section-title">¿A qué destinatarios se comunicarán los datos?</p>
    <p>
      Tus datos personales podrán ser comunicados a proveedores de servicios de Cocomu en los sectores de plataformas de pago, servicios informáticos, redes
      sociales, agencias de publicidad, etc. Dichos proveedores de servicios pueden estar situados dentro o fuera del Espacio Económico Europeo, por ejemplo, en
      los Estados Unidos. Con el fin de garantizar la seguridad y protección de sus datos personales, Cocomu ha implementado las garantías correspondientes
      como, por ejemplo, las Cláusulas Contractuales Tipo o el Escudo de Privacidad. Para más información al respecto, así como para obtener una copia de estas
      garantías, puedes ponerte en contacto con nosotros a través de info@beenfo.com.
    </p>

    <p className="section-title">Responsabilidad del afectado</p>
    <p>El afectado por el tratamiento de datos personales del que Cocomu es responsable:</p>

    <ul>
      <li>
        Garantiza que es mayor de 18 años y que los datos que facilita a Cocomu son verdaderos, exactos, completos y actualizados. A estos efectos, el afectado
        responde de la veracidad de todos los datos que comunique y mantendrá convenientemente actualizada la información facilitada, de tal forma que responda
        a su situación real.
      </li>
      <li>
        Garantiza que ha informado a los terceros de los que facilite sus datos, en caso de hacerlo, de los aspectos contenidos en este documento. Asimismo,
        garantiza que ha obtenido su autorización para facilitar sus datos a Cocomu para los fines señalados.
      </li>
      <li>
        Será responsable de las informaciones falsas o inexactas que proporcione a través de la App y de los daños y perjuicios, directas o indirectas, que ello
        cause a Cocomu o a terceros.
      </li>
    </ul>

    <p className="section-title">Comunicaciones comerciales y promocionales</p>
    <p>
      Si prestas tu consentimiento para ello, Cocomu puede estar interesado en tratar los datos personales proporcionados para remitirles comunicaciones
      electrónicas personalizadas con información relativa a productos, servicios, eventos, cursos, programas, promociones y noticias relevantes. Siempre que se
      realice alguna comunicación de este tipo, ésta será dirigida única y exclusivamente a aquellos usuarios que prestado con anterioridad su consentimiento
      válido y expreso. Para llevar a cabo dicha finalidad, se elaborará un perfil comercial basado en tus preferencias, obtenido en base a la información
      proporcionada por ti u obtenida, incluso de fuentes externas. En caso de que desees dejar de recibir comunicaciones comerciales o promocionales puedes
      solicitar la baja del servicio enviando un email a la siguiente dirección de correo electrónico: info@beenfo.com, o a través de cualquiera de las
      comunicaciones recibidas.
    </p>

    <p className="section-title">Ejercicio de derechos</p>
    <p>
      Sujeto a los requisitos legales aplicables a cada uno de los derechos en materia de protección de datos que correspondan, puedes enviar un escrito a
      COCOMU INTERNET SLU Avenida Miguel de Cervantes 63, 4ºB 16400 Tarancón, Cuenca, España, con la Referencia “Protección de Datos”, adjuntando fotocopia de
      tu documento de identidad, en cualquier momento y de manera gratuita, para:
    </p>

    <ul>
      <li>Acceder a tus datos personales.</li>
      <li>Rectificar los datos inexactos o incompletos.</li>
      <li>Solicitar la supresión de tus datos cuando, entre otros motivos, los datos ya no sean necesarios para los fines que fueron recogidos.</li>
      <li>
        Obtener de Cocomu la limitación del tratamiento de los datos cuando se cumpla alguna de las condiciones previstas en la normativa de protección de
        datos.
      </li>
      <li>Solicitar la portabilidad de tus datos personales.</li>
      <li>Oponerte al tratamiento de tus datos personales.</li>
      <li>
        Reclamar ante la Autoridad de Protección de Datos, cuando el interesado considere que Cocomu ha vulnerado los derechos que le son reconocidos por la
        normativa aplicable en protección de datos.
      </li>
    </ul>
    <p>Sin perjuicio de lo anterior, podrás ponerse en contacto en cualquier momento con Cocomu a través del correo electrónico info@beenfo.com</p>

    <p className="section-title">Medidas de seguridad</p>
    <p>
      Tus datos se tratarán en todo momento de forma absolutamente confidencial y guardando el preceptivo deber de secreto respecto de los mismos, de
      conformidad con lo previsto en la normativa de aplicación, adoptando al efecto las medidas de índole técnica y organizativas necesarias que garanticen la
      seguridad de sus datos y eviten su alteración, pérdida, tratamiento o acceso no autorizado, habida cuenta del estado de la tecnología, la naturaleza de
      los datos almacenados y los riesgos a que están expuestos.
    </p>
  </div>
)
