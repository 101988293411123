import { put, all, takeLatest, call, select } from 'redux-saga/effects'
import moment from 'moment'
import * as types from './types'
import * as statsOperations from './operations'
import client from 'config/apollo'
import * as api from 'api'
import { formatDate, numElems } from './utils'
import { setError } from '../error/operations'
import { statsActions } from '.'

export function* updateStatsInfo(action) {
  try {
    yield put(statsActions.setFetching(true))
    const { organizationId } = action?.value
    const { dates } = yield select(state => state?.stats)
    const before = formatDate(
      moment(dates?.end)
        .add(1, 'days')
        .toDate()
    )
    const after = formatDate(dates?.start)

    const organizationNumThemes = yield call(client.query, {
      query: api.queries.GET_ORGANIZATION_NUM_THEMES,
      variables: { organizationId: organizationId, theme: null, after, before, first: numElems },
      fetchPolicy: 'network-only'
    })

    const userUsageRecords = yield call(client.query, {
      query: api.queries.GET_USER_USAGE_RECORDS,
      variables: { organizationId: organizationId, theme: null, after, before, first: numElems },
      fetchPolicy: 'network-only'
    })

    const globalMessageUsageRecords = yield call(client.query, {
      query: api.queries.GET_GLOBAL_MESSAGE_USAGE_RECORDS,
      variables: { organizationId: organizationId, theme: null, after, before, first: numElems },
      fetchPolicy: 'network-only'
    })

    const answersMessageUsageRecords = yield call(client.query, {
      query: api.queries.GET_ANSWERS_MESSAGE_USAGE_RECORDS,
      variables: { organizationId: organizationId, theme: null, after, before, first: numElems },
      fetchPolicy: 'network-only'
    })

    yield put(statsOperations.updateOrganizationThemes(organizationNumThemes))
    yield put(statsOperations.updateUserUsageRecords(userUsageRecords))
    yield put(statsOperations.updateGlobalMessage(globalMessageUsageRecords))
    yield put(statsOperations.updateAnswersMessage(answersMessageUsageRecords))
    yield put(statsActions.setFetching(false))
  } catch (error) {
    yield put(setError(error))
    yield put(statsActions.setFetching(false))
  }
}

export function* updateStatsByTheme(themeId) {
  try {
    const { dates } = yield select(state => state?.stats)

    const { id: organizationId } = yield select(state => state?.organizations?.item)

    const before = formatDate(
      moment(dates?.end)
        .add(1, 'days')
        .toDate()
    )
    const after = formatDate(dates?.start)

    yield put(statsActions.setFetchingTheme(true))

    const userUsageRecords = yield call(client.query, {
      query: api.queries.GET_USER_USAGE_RECORDS,
      variables: { organizationId: organizationId, theme: themeId, after, before, first: numElems },
      fetchPolicy: 'network-only'
    })

    const globalMessageUsageRecords = yield call(client.query, {
      query: api.queries.GET_GLOBAL_MESSAGE_USAGE_RECORDS,
      variables: { organizationId: organizationId, theme: themeId, after, before, first: numElems },
      fetchPolicy: 'network-only'
    })

    const answersMessageUsageRecords = yield call(client.query, {
      query: api.queries.GET_ANSWERS_MESSAGE_USAGE_RECORDS,
      variables: { organizationId: organizationId, theme: themeId, after, before, first: numElems },
      fetchPolicy: 'network-only'
    })

    yield put(statsOperations.updateUserUsageRecordsByTheme(userUsageRecords))
    yield put(statsOperations.updateGlobalMessageRecordsByTheme(globalMessageUsageRecords))
    yield put(statsOperations.updateAnswersMessageRecordsByTheme(answersMessageUsageRecords))
    yield put(statsActions.setFetchingTheme(false))
  } catch (error) {
    yield put(setError(error))
    yield put(statsActions.setFetchingTheme(false))
  }
}

export function* updateThemeStatsInfo(action) {
  try {
    yield put(statsOperations.updateOrganizationThemes(action?.value))
  } catch (error) {
    yield put(setError(error))
  }
}

export function* updateSelectedThemeStatsInfo(action) {
  const themeId = action?.value
  try {
    yield put(statsOperations.updateThemeAdmins(themeId))
    yield put(statsActions.setSelectedTheme(themeId))
    yield updateStatsByTheme(themeId)
  } catch (error) {
    yield put(setError(error))
  }
}

function* watchUpdateStatsInfo() {
  yield takeLatest(types.STATS_UPDATE_INFO, updateStatsInfo)
}
function* watchUpdateSelectedTheme() {
  yield takeLatest(types.STATS_UPDATE_SELECTED_THEME, updateSelectedThemeStatsInfo)
}

export default function* root() {
  yield all([watchUpdateStatsInfo(), watchUpdateSelectedTheme()])
}
