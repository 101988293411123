import styled from 'styled-components'
import colors from '../../../assets/colors'

const LoginFormStyled = styled.div`
  && {
    .forget-pass {
      text-align: center;
      font-size: 13px;
      letter-spacing: -0.43px;
      color: ${colors.darkGrey2};
      margin-bottom: 30px;
      margin-top: -10px;

      span,
      a {
        color: ${colors.yellow};
      }
    }

    .error-msg-input {
      margin-top: -25px;
    }
  }
`

export default LoginFormStyled
