import React from 'react'
import 'react-toastify/dist/ReactToastify.min.css'
import 'bootstrap/dist/css/bootstrap.min.css'
import { BrowserRouter } from 'react-router-dom'
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import Routes from '../routes'
import { ThemeProvider } from 'styled-components'
import { theme as styledTheme } from '../../../config/styled'
import { GlobalStyle } from '../../../config/styled'
import { ToastContainer, toast } from 'react-toastify'
import { STRIPE_KEY } from '../../../api/utils'

import { ApolloProvider as ApolloHocProvider } from '@apollo/react-hoc'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import client from '../../../config/apollo'
import { store, persistor } from '../../../config/redux'

import Firebase, { FirebaseContext } from '../../../components/Firebase'

const App = () => {
  const stripePromise = loadStripe(STRIPE_KEY)

  return (
    <FirebaseContext.Provider value={new Firebase()}>
      <ApolloHocProvider client={client}>
        <Provider store={store}>
          <Elements stripe={stripePromise}>
            <PersistGate loading={null} persistor={persistor}>
              <BrowserRouter>
                <ThemeProvider theme={styledTheme}>
                  <GlobalStyle />
                  <Routes />
                  <ToastContainer
                    autoClose={3000}
                    hideProgressBar={false}
                    bodyClassName="grow-font-size"
                    draggable={false}
                    position={toast.POSITION.BOTTOM_CENTER}
                  />
                </ThemeProvider>
              </BrowserRouter>
            </PersistGate>
          </Elements>
        </Provider>
      </ApolloHocProvider>
    </FirebaseContext.Provider>
  )
}

export default App
