import React, { Component } from 'react'
import ComponentStyled from './styled'
import { Avatar } from '../../atoms'
import PropTypes from 'prop-types'
import _ from 'lodash'
import moment from 'moment'
import i18n from '../../../assets/i18n'

class UserInfo extends Component {
  render() {
    const { user } = this.props
    const imageId = _.get(user, 'image._id')
    const fullname = _.get(user, 'fullname', '')
    const zipCode = _.get(user, 'zipCode', '')
    const phone = _.get(user, 'username', '')
    let birthDate = _.get(user, 'birthDate', '')
    birthDate = _.isNil(birthDate) ? '' : moment(birthDate).format('DD/MM/YYYY')
    return (
      <ComponentStyled>
        <div className="avatar-wrapper">
          <Avatar resourceId={imageId} size="big" />
        </div>
        <div>
          <label>{i18n.userInfo.name}</label>
          <p>{fullname}</p>
          <label>{i18n.userInfo.postalCode}</label>
          <p>{zipCode}</p>
          <label>{i18n.userInfo.birthDate}</label>
          <p>{birthDate}</p>
          <label>{i18n.userInfo.phone}</label>
          <p>{phone}</p>
        </div>
      </ComponentStyled>
    )
  }
}

UserInfo.propTypes = {
  user: PropTypes.object
}

export default UserInfo
