import styled from 'styled-components'
import colors from '../../../assets/colors'

const RegisterFormStep3Styled = styled.div`
  && {
    .title {
      font-size: 16px;
      line-height: 24px;
      text-align: center;
      letter-spacing: 0.06px;
      color: ${colors.darkGrey2};
      margin-bottom: 30px;
    }

    .forget-pass {
      text-align: center;
      font-size: 13px;
      letter-spacing: -0.43px;
      color: ${colors.darkGrey2};
      margin-bottom: 30px;
      margin-top: -10px;

      span,
      a {
        color: ${colors.yellow};
      }
    }

    .error-msg-input {
      margin-top: -25px;
    }

    .upload-image-wrapper {
      text-align: center;
      margin-bottom: 30px;
      .circle-image-wrapper {
        box-shadow: 0px 2px 13px rgba(0, 0, 0, 0.220662);
        position: relative;
        padding: 0 15px;
        width: 90px;
        height: 90px;
        border-radius: 50px;
        background-color: ${colors.darkGrey};
        border: 4px solid ${colors.white};
        cursor: pointer;
        background-position: center;
        background-size: cover;
        text-align: center;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        p {
          font-size: 14px;
          line-height: 17px;
          text-align: center;
          font-weight: 600;
          letter-spacing: 0.58px;
          color: ${colors.white};
          margin-bottom: 0;
        }

        .input-wrapper {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          opacity: 0;
          * {
            cursor: pointer;
          }
        }
      }
    }
  }
`

export default RegisterFormStep3Styled
