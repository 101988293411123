import styled from 'styled-components'
import colors from '../../../assets/colors'

const ComponentStyled = styled.div`
  && {
    .title-update {
      font-size: 20px;
      line-height: 24px;
      letter-spacing: -0.62px;
      color: ${colors.black};
      padding-bottom: 30px;
      position: relative;

      ::before {
        content: '';
        position: absolute;
        bottom: 0;
        width: 1000px;
        right: 50%;
        transform: translateX(50%);
        height: 1px;
        background-color: ${colors.lightGrey};
      }
    }

    .user-form-wrapper {
      margin-bottom: 140px;
    }

    .error-msg-input {
      margin-top: -25px;
    }

    .upload-image-wrapper {
      text-align: center;
      margin-bottom: 30px;
      .circle-image-wrapper {
        box-shadow: 0px 2px 13px rgba(0, 0, 0, 0.22);
        position: relative;
        padding: 0 15px;
        width: 90px;
        height: 90px;
        border-radius: 50px;
        background-color: ${colors.darkGreyAlpha50};
        border: 4px solid ${colors.white};
        cursor: pointer;
        background-position: center;
        background-size: cover;
        text-align: center;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;

        :hover {
          .add-photo-title {
            opacity: 1;
          }
        }

        .add-photo-title {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background-color: ${colors.darkGreyAlpha50};
          opacity: 0;
          transition: 0.5s ease all;
          border-radius: 50px;

          p {
            font-size: 14px;
            line-height: 17px;
            text-align: center;
            font-weight: 600;
            letter-spacing: 0.58px;
            color: ${colors.white};
            margin-bottom: 0;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
          }
        }

        .input-wrapper {
          position: absolute;
          z-index: 2;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          opacity: 0;
          * {
            cursor: pointer;
          }
        }
      }
    }

    .delete-user {
      text-align: center;
      font-size: 14px;
      line-height: 14px;
      color: ${colors.red};
      margin-top: 30px;

      span {
        cursor: pointer;
      }
    }
  }
`

export default ComponentStyled
