import React from 'react'
import PropTypes from 'prop-types'
import ContainerStyled from './styled'
import Modal from 'react-bootstrap/Modal'
import { CreateThemeForm } from '../../organism'

const ModalCreateTheme = ({ show, onHide }) => {
  return (
    <Modal show={show} onHide={onHide} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
      <ContainerStyled>
        <Modal.Body>
          <CreateThemeForm onFinishButton={onHide} />
        </Modal.Body>
      </ContainerStyled>
    </Modal>
  )
}

ModalCreateTheme.propTypes = {
  onHide: PropTypes.func,
  show: PropTypes.bool
}

export default ModalCreateTheme
