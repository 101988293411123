import styled from 'styled-components'

const ComponentStyled = styled.div`
  && {
    .avatar-wrapper {
      margin-bottom: 30px;
      display: inline-block;
    }

    label {
      font-size: 14px;
      font-weight: 600;
      line-height: 16px;
      letter-spacing: 1.08px;
      text-transform: uppercase;
    }

    p {
      margin-bottom: 20px;
      font-size: 16px;
      line-height: 24px;
      color: #383838;
    }
  }
`

export default ComponentStyled
