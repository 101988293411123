import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import compose from 'recompose/compose'
import View from './view'

const mapStateToProps = state => {
  return {}
}

const mapDispatchToProps = dispatch => {
  return {}
}

const enhance = compose(connect(mapStateToProps, mapDispatchToProps))

export default withRouter(enhance(View))
