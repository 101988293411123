import { connect } from 'react-redux'
import compose from 'recompose/compose'
import View from './view'
import { withRouter } from 'react-router'
import { organizationsOperations } from '../../../redux/organizations'
import { usersOperations } from '../../../redux/users'

const mapStateToProps = state => {
  return {
    isFetchingUsers: state.users.organizationUsersLoading,
    organization: state.organizations.item
  }
}

const mapDispatchToProps = dispatch => {
  return {
    fetchOrganizationUsers: searchText => dispatch(usersOperations.initOrganizationUsersList(searchText)),
    removeUserFromOrganization: (orgIri, userIri) => dispatch(organizationsOperations.removeUserFromOrganization(orgIri, userIri))
  }
}

const enhance = compose(connect(mapStateToProps, mapDispatchToProps))

export default withRouter(enhance(View))
