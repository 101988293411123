import styled from 'styled-components'
import colors from '../../../assets/colors'

const FooterStyled = styled.div`
  && {
    text-align: center;

    .box-wrapper {
      padding: 30px 15px;
      background-color: ${colors.white};
    }

    a {
      font-size: 14px;
      line-height: 14px;
      letter-spacing: -0.16px;
      color: ${colors.darkGrey2};
      margin-top: 7px;
      margin-bottom: 7px;
      margin: 10px 20px;
      display: inline-block;
    }

    .lang-wrapper {
      text-align: center;

      a {
        position: relative;
        font-weight: 600;
        img {
          margin-left: 5px;
        }
      }

      .toggle-lang {
        cursor: pointer;
        ::after {
          content: none;
        }
      }

      .lang-menu {
        padding: 10px;

        li {
          cursor: pointer;
        }
      }
    }

    @media (min-width: 768px) {
      text-align: inherit;

      .box-wrapper {
        padding: 30px 50px;
      }

      .lang-wrapper {
        text-align: right;
      }

      a {
        margin-right: 40px;
      }
    }
  }
`

export default FooterStyled
