import React, { useState, useRef } from 'react'
import { WriteChatBarStyled } from './styled'
import { RegularInput, RegularTextArea } from '../../atoms'
import { ReactComponent as PlusIcon } from '../../../assets/images/plus.svg'
import { ReactComponent as CameraIcon } from '../../../assets/images/camera.svg'
import { ReactComponent as RightArrowIcon } from '../../../assets/images/send-arrow.svg'
import i18n from '../../../assets/i18n'
import { PopUpWriteMsgAdd } from '../../atoms'
import PropTypes from 'prop-types'
import { MESSAGES_TYPES } from '../../../config/constants'
import ModalAccept from '../../modals/modalAccept'
import { Dropdown } from 'react-bootstrap'

const WriteChatBar = ({ onSendMessage, handleOpenCreatePollSidebar, placeholder }) => {
  const [messageText, setMessageText] = useState('')
  const [openPopup, setOpenPopup] = useState(false)
  const [previewImageModal, setPreviewImageModal] = useState(null)

  const inputRef = useRef(null)

  function togglePopup() {
    setOpenPopup(!openPopup)
  }

  function handleClickSend() {
    setMessageText('')
    onSendMessage({ type: MESSAGES_TYPES.TEXT, value: messageText })
  }

  function handleClickImage(event) {
    if (event?.currentTarget?.files[0]) {
      var reader = new FileReader()
      reader.onload = function(e) {
        setPreviewImageModal(e.target.result)
      }
      reader.readAsDataURL(event.currentTarget.files[0])
    }
  }

  function handleCloseImage() {
    setPreviewImageModal(null)
    inputRef.current.value = ''
  }

  function handleSendImage() {
    onSendMessage({ type: MESSAGES_TYPES.IMAGE, value: inputRef.current.files[0] })
    inputRef.current.value = ''
    setPreviewImageModal(null)
  }

  return (
    <WriteChatBarStyled>
      <div className="write-chat-wrapper">
        <div className="add-btn-wrapper">
          <Dropdown onToggle={togglePopup} show={openPopup}>
            <Dropdown.Toggle as="span" className="custom-toggle">
              <PlusIcon className="add-btn" />
            </Dropdown.Toggle>
            <Dropdown.Menu className="menu-popup">
              <PopUpWriteMsgAdd
                onClickItems={togglePopup}
                onSendMessage={onSendMessage}
                handleOpenCreatePollSidebar={handleOpenCreatePollSidebar}
                onUploadFile={event => {
                  onSendMessage({ type: MESSAGES_TYPES.FILE, value: event.currentTarget.files[0] })
                }}
              />
            </Dropdown.Menu>
          </Dropdown>
        </div>

        <RegularTextArea
          value={messageText}
          hasMarginBottom={false}
          variant="filled"
          handleOnChange={(e, value) => {
            setMessageText(value)
          }}
          placeholder={placeholder || i18n.writeChatBar.writeYourMessage}
        />

        <div className="photo-btn">
          <label>
            <RegularInput
              ref={inputRef}
              type="file"
              name="image"
              variant="image-file"
              accept={'image/*'}
              hasMarginBottom={false}
              multiple
              handleOnChange={handleClickImage}
            />
            <CameraIcon />
          </label>
        </div>
      </div>
      <p className="send-btn" onClick={handleClickSend}>
        <RightArrowIcon />
      </p>
      <ModalAccept
        show={previewImageModal}
        onAccept={handleSendImage}
        onHide={handleCloseImage}
        onReject={handleCloseImage}
        acceptButtonText={i18n.writeChatBar.send}
        rejectButonText={i18n.writeChatBar.cancel}
      >
        {<img width={'100%'} src={previewImageModal} />}
      </ModalAccept>
    </WriteChatBarStyled>
  )
}

WriteChatBar.propTypes = {
  onSendMessage: PropTypes.func,
  handleOpenCreatePollSidebar: PropTypes.func,
  placeholder: PropTypes.string
}

export default WriteChatBar
