import React, { Component } from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import { ReactComponent as Logo } from '../../../assets/images/logo.svg'
import HeaderStyled from './styled'
import { Button } from '../../atoms'
import i18n from '../../../assets/i18n'
import { Link } from 'react-router-dom'
import routes from '../../../config/routes'
import { ModalLogin, ModalRegister, ModalResetPass } from '../../modals'
import PropTypes from 'prop-types'

const ACTIVEMODAL = {
  login: 1,
  register: 2,
  reset: 3
}

class Header extends Component {
  constructor(props) {
    super(props)
    this.state = {
      activeModal: 0
    }
  }

  toggleModal = (activeModal = 0) => {
    this.setState({ activeModal })
  }

  render() {
    const { activeModal } = this.state
    const { token, history } = this.props
    return (
      <HeaderStyled>
        <Container>
          <Row>
            <Col xs={12} sm={4}>
              <Link to={routes.home}>
                <Logo className="logo" />
              </Link>
            </Col>
            <Col xs={12} sm={8} className="btns-wrapper">
              {token ? (
                <Button
                  handleOnClick={() => {
                    history.push(routes.dashboard)
                  }}
                  label={i18n.header.goDashboard}
                />
              ) : (
                <>
                  <Button
                    handleOnClick={() => {
                      this.toggleModal(ACTIVEMODAL.login)
                    }}
                    variant={'white'}
                    label={i18n.header.getOn}
                  />
                  <Button
                    handleOnClick={() => {
                      this.toggleModal(ACTIVEMODAL.register)
                    }}
                    label={i18n.header.signUp}
                  />
                </>
              )}
            </Col>
          </Row>
        </Container>
        <ModalLogin
          show={activeModal === ACTIVEMODAL.login}
          onHide={this.toggleModal}
          onRegisterTapped={() => {
            this.toggleModal(ACTIVEMODAL.register)
          }}
          onResetTapped={() => {
            this.toggleModal(ACTIVEMODAL.reset)
          }}
        />
        <ModalRegister
          show={activeModal === ACTIVEMODAL.register}
          onHide={this.toggleModal}
          onLoginTapped={() => {
            this.toggleModal(ACTIVEMODAL.login)
          }}
        />
        <ModalResetPass
          show={activeModal === ACTIVEMODAL.reset}
          onHide={this.toggleModal}
          onLoginTapped={() => {
            this.toggleModal(ACTIVEMODAL.login)
          }}
        />
      </HeaderStyled>
    )
  }
}

Header.propTypes = {
  token: PropTypes.string,
  history: PropTypes.object
}

export default Header
