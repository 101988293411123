import * as types from './types'

export const setFetching = value => {
  return {
    type: types.COMERCIAL_PAYMENTS_SET_FETCHING,
    value
  }
}

export const updateList = value => {
  return {
    type: types.COMERCIAL_PAYMENTS_UPDATE_LIST,
    value
  }
}

export const updateItem = value => {
  return {
    type: types.COMERCIAL_PAYMENTS_UPDATE_ITEM,
    value
  }
}
