import * as meActions from './actions'
import { authOperations } from '../auth'
import * as directsOperations from '../directs/operations'
import _ from 'lodash'
import i18n from '../../assets/i18n'

export const updateUserInfo = ({ ...data }) => async (dispatch, getState, { api, toast }) => {
  try {
    dispatch(meActions.setFetching(true))
    let userData = { ...data }
    if (data.image) {
      const postResourceRes = await api.postResource(data.image)
      userData.image = _.get(postResourceRes, 'data[@id]')
    }

    await api.updateUserInfo(userData) // NO RES DATA
    const userInfoRes = await api.getUserInfo()
    let userInfo = _.get(userInfoRes, 'data', null)

    dispatch(meActions.updateInfo(userInfo))
    dispatch(directsOperations.initList())
  } catch (e) {
    toast(i18n.updateUserForm.updateError, { type: toast.TYPE.ERROR })
  } finally {
    dispatch(meActions.setFetching(false))
  }
}

export const updateBillingData = data => async (dispatch, getState, { api, toast }) => {
  const state = getState()
  let userInfo = state.me.userInfo

  dispatch(meActions.setFetching(true))

  let result = false
  try {
    if (userInfo.billingData) {
      result = await api.updateBillingData(userInfo.billingData.id, data)
    } else {
      result = await api.createBillingData(data)
    }

    const userInfoRes = await api.getUserInfo()
    userInfo = _.get(userInfoRes, 'data', null)

    dispatch(meActions.updateInfo(userInfo))
  } catch (e) {
    toast(i18n.updateUserForm.updateError, { type: toast.TYPE.ERROR })
  } finally {
    dispatch(meActions.setFetching(false))
  }

  return result
}

export const disableUser = history => async (dispatch, getState, { api, setError }) => {
  try {
    dispatch(meActions.setFetching(true))
    await api.disableUser()
    dispatch(authOperations.logout(history))
  } catch (e) {
    dispatch(setError(e))
  } finally {
    dispatch(meActions.setFetching(false))
  }
}
