/* eslint-disable react/no-unescaped-entities */
import React from 'react'
import { Link } from 'react-router-dom'
import routes from '../../../config/routes'

export const EsContent = () => (
  <div>
    <h1>TÉRMINOS Y CONDICIONES DE CLIENTES Y LICENCIA DE USO DE BEENFO</h1>

    <p>
      Los presentes términos y condiciones (en adelante, el "Contrato") rigen (i) la contratación y prestación del servicio al cliente por parte de COCOMU
      INTERNET SLU y (ii) el otorgamiento de la licencia de uso de la aplicación “Beenfo” (en adelante, "Aplicación” o “App”) en los términos que se regulan en
      este Contrato y sus anexos y en las Condiciones de Uso de la App.
    </p>
    <p>
      La Aplicación es titularidad de COCOMU INTERNET SLU, con domicilio social en Avda. Miguel de cervantes 63, 4ºB 16400 Tarancón (Cuenca) ESPAÑA y número de
      identificación fiscal B16343972, (en adelante, "Cocomu"). Cocomu es titular de la App que pone a disposición de sus clientes (en adelante, "Cliente" o
      "Clientes") y cuya funcionalidad principal consiste en un servicio de mensajería para teléfonos móviles inteligentes, ordenadores y tablets (en adelante
      "Servicios"). Los Clientes de Cocomu podrán enviar mensajes difundidos a una serie de usuarios (en adelante, “Usuario” o “Usuarios”) que previamente habrá
      comunicado a Cocomu. Además de la mensajería básica, el Cliente podrá enviar imágenes, vídeos, mensajes de audio y cualquier otro archivo binario o
      incluso convocatorias a eventos, invitaciones o encuestas. Los Usuarios únicamente podrán comunicarse con el emisor del mensaje, es decir, con el Cliente
      y/o las personas físicas designadas por éste como administradores (en adelante, “Administrador” o ”Administradores”). Cada conversación será referida de
      ahora en adelante como “Tema”.
    </p>
    <p>
      El Cliente está interesado en hacer uso de la App BEENFO y para ello, contrata con Cocomu la prestación de los Servicios y el acceso a la App de acuerdo
      con los términos que se establecen en el presente Contrato y en las condiciones generales de uso de (en adelante{' '}
      <Link to={routes.terms}>"Términos y Condiciones de Uso"</Link>). Al descargar o usar la App como Cliente o en representación del mismo, reconoces tener la
      capacidad legal necesaria y autorización para representar al Cliente y aceptar este Contrato, así como todas las demás condiciones, políticas y
      documentación aplicables.
    </p>
    <p>
      La intención de Cocomu es ser transparente y poner a disposición del Cliente y de sus Administradores toda la información necesaria para que entiendan el
      funcionamiento de la App, así como las ventajas que ofrece y los derechos y responsabilidades en relación con el uso de la misma.{' '}
    </p>
    <p className="section-title">VIGENCIA DEL CONTRATO.</p>

    <p>
      Este Contrato tendrá una duración mínima de un (1) mes desde la fecha de la aceptación, prorrogable automáticamente por periodos adicionales de un (1)
      mes, a menos que el Cliente notifique a Cocomu por escrito su voluntad de no renovarlo con una antelación de 48 horas al momento de la finalización del
      plazo inicial o de cualquiera de sus prórrogas; (la “Vigencia del Contrato”). En el momento en que el Cliente tenga un usuario activo, empezará a pagar
      por dicho Usuario el mes completo según lo dispuesto en el Anexo I con independencia de cuando se dé de alta el Usuario a lo largo del mes.{' '}
    </p>

    <p className="section-title">BAJA DEL SERVICIO.</p>

    <p>
      En caso de que el usuario decida no continuar utilizando el servicio deberá solicitar la baja de la organización. Dicha baja será efectiva en el momento
      solicitada y no supondrá ningún coste. Para realizar la baja el usuario deberá dirigirse al apartado "Facturación", luego hacer clic en "Eliminar
      organización" y confirmar el mensaje.
    </p>

    <p className="section-title">OBLIGACIONES Y DERECHOS DE COCOMU.</p>
    <p>
      <strong>Obligaciones generales de Cocomu</strong>
    </p>
    <p>Por el presente Contrato Cocomu se obliga a:</p>
    <ul>
      <li>Ejecutar el Servicio de manera diligente y responsable, con el nivel de competencia exigible en la prestación de este tipo de Servicios.</li>
      <li>Poner a disposición del Cliente y de los Administradores los recursos necesarios para garantizar el acceso y uso de la App.</li>
      <li>Permitir la disponibilidad y funcionamiento de los Servicios de acuerdo con lo descrito en el presente Contrato.</li>
      <li>Poner a disposición del Cliente, sus Administradores y los Usuarios, un servicio de atención al usuario.</li>
    </ul>

    <p>
      <strong>Licencia sobre la Aplicación</strong>
    </p>
    <p>
      En relación a la licencia de uso de la App, a través del presente Contrato, Cocomu concede una licencia de uso sobre la App sin carácter exclusivo y sin
      facultad de sublicenciar a terceros y con ámbito territorial mundial y durante la Vigencia del Contrato. El Cliente no tendrá ningún otro derecho o
      facultad que exceda la mera utilización de la App para la finalidad de obtener la prestación de los Servicios. Esta licencia se concede en todo caso de
      conformidad con lo previsto en la cláusula sobre Derechos de Propiedad Intelectual e Industrial.
    </p>
    <p>
      <strong>Derechos de Cocomu</strong>
    </p>
    <p>
      Cocomu tendrá derecho a modificar, suspender o cancelar el uso de nuestros Servicios o el acceso a estos por parte del Cliente, así como este Contrato, en
      cualquier momento y por cualquier motivo. Cocomu informará al Cliente de toda modificación a través de info@beenfo.com. En caso de que el Cliente o el
      Administrador incumplan el Contrato o no cumplan con sus obligaciones pertinentes:
    </p>
    <ul>
      <li>
        Cocomu podrá suspender, sin perjuicio de otros derechos o posibles recursos, la prestación de los Servicios hasta que el Cliente subsane el
        incumplimiento en cuestión y a exigir que el Cliente lo exima de cualquiera de sus obligaciones en tanto en cuanto el incumplimiento del Cliente impida
        o dificulte la prestación del Servicio.
      </li>
      <li>
        Cocomu no será responsable de los gastos o pérdidas que sufra o en los que incurra el Cliente y que se deriven directa o indirectamente del
        incumplimiento del Cliente o Administrador.
      </li>
      <li>
        Cocomu no será responsable de las puntuales o periódicas interrupciones que pudiera sufrir el Servicio como consecuencia del mantenimiento o
        actualización de la App.
      </li>
    </ul>

    <p>
      Los Servicios contratados serán prestados por Cocomu con medios adecuados y con personal de su dependencia que no tendrá vinculación laboral ni de ningún
      otro tipo con el Cliente. Cocomu actuará en todo momento, prestando sus Servicios como empresa independiente del Cliente frente a terceros.
    </p>

    <p className="section-title">OBLIGACIONES DEL CLIENTE</p>
    <p>
      <strong>Obligaciones generales </strong>
    </p>
    <p>Por el presente Contrato, el Cliente se obliga a:</p>

    <ul>
      <li>
        Crear una cuenta en la App a través de los procesos establecidos proporcionando información precisa, actual y completa, incluidos datos de carácter
        identificativo, datos bancarios y otros datos que solicitemos.
      </li>
      <li>
        Cumplir con las obligaciones estipuladas en el presente Contrato y en particular, a las establecidas en el Anexo I relativas al Precio y forma de pago.
      </li>
      <li>
        Contar con conectividad y conexiones de datos que proporcionan terceros para poder hacer uso y disfrutar de los Servicios y hacer frente a los pagos de
        las tarifas y otros gastos asociados.
      </li>
      <li>
        Designar a un Administrador o Administradores para que accedan a la App y hagan uso de los Servicios prestados en nombre del Cliente. El Cliente podrá
        nombrar a un máximo de dos Administradores.
      </li>
      <li>
        Poner a disposición de Cocomu el listado de números de teléfono de los Usuarios con los que quiera comunicarse a través de la App garantizando en todo
        caso estar legitimado para ello y que estos contactos son mayores de 18 años.
      </li>
      <li>
        Garantizar un uso responsable y diligente tanto por parte del Cliente como de los Administradores designados a tal efecto por el mismo, cumpliendo en
        todo momento con las obligaciones estipuladas en el presente Contrato y en las Condiciones de Uso de la App.
      </li>
      <li>Hacer uno de la App de acuerdo con el ordenamiento jurídico aplicable, la moral y buenas costumbres.</li>
      <li>Responder ante el mal uso de la App que hagan los Administradores.</li>
      <li>
        No realizar un uso de la App no autorizado o fraudulento con fines o efectos ilícitos y prohibidos en las presentes Condiciones. El Cliente podrá usar
        la App para fines comerciales o económicos pero no para fines personales, o fines lesivos para los derechos e intereses de terceros, o que de cualquier
        forma puedan dañar, inutilizar, sobrecargar, deteriorar o impedir la normal utilización de los Servicios.
      </li>
      <li>
        No utilizar nuestros Servicios o la Aplicación para vender, contratar, promocionar o divulgar publicidad de terceras personas sin autorización expresa
        por parte de Cocomu.
      </li>
      <li>No realizar acto alguno que de cualquier forma pudiera suponer la lesión de derechos o dañar la imagen de Cocomu.</li>
      <li>
        No provocar daño alguno en los sistemas informáticos de Cocomu o en los de terceros, ni introducir o difundir virus informáticos y demás sistemas que
        puedan causar algún daño en dichos sistemas informáticos.
      </li>
      <li>No acceder o intentar acceder a recursos o áreas restringidas de la App, sin cumplir las condiciones exigidas para dicho acceso.</li>
      <li>
        No obtener e intentar obtener los Servicios empleando para ello medios o procedimientos distintos de los que, según los casos, se hayan puesto a su
        disposición a este efecto o se hayan indicado expresamente en la App o, en general, de los que se empleen habitualmente en Internet por no entrañar un
        riesgo de daño o inutilización de la App.
      </li>
      <li>
        En el acceso a la App, aceptar expresamente los términos aplicables asociados al uso de la App que se incluyen en los distintos textos legales que rigen
        el uso y funcionamiento de la App.
      </li>
      <li>
        Si cancela los Servicios, a dejar de usar de inmediato nuestros Servicios, desinstalar la App y destruir todas las copias de software, y eliminar toda
        la información de los usuarios que obtuvo tras usar nuestros Servicios.
      </li>
      <li>
        El Cliente podrá designar a un máximo de dos Administradores por Tema que tendrán acceso a la App para hacer uso de la misma y poder comunicarse con los
        Usuarios que previamente el Cliente haya definido y, con ello, obtener el máximo rendimiento de la App en su nombre.
      </li>
    </ul>

    <p className="section-title">COMUNICACIONES</p>
    <p>
      Cocomu usará la información para enviarte, a través de la App, comunicaciones con información relativa a la cuenta, cambios de contraseña, autorizaciones
      de pago y otra información relativa a transacciones; e información sobre productos, servicios, encuestas, eventos, noticias y promociones que ofrece
      Cocomu.
    </p>

    <p className="section-title">EXTINCIÓN Y RESOLUCIÓN DEL CONTRATO Y LÍMITES DE RESPONSABILIDAD</p>
    <p>
      <strong>Causas de resolución del Contrato</strong>
    </p>
    <p>
      El Contrato podrá resolverse anticipadamente en caso de incumplimiento por cualquiera de las Partes de cualquiera de sus obligaciones asumidas en virtud
      del presente Contrato. En caso de extinción del Contrato por cualquier razón, se liquidará el pago del Precio pendiente de pago que pudiera corresponder a
      Cocomu. Finalizada la relación contractual por cualquier causa, las Partes deberán cumplir con los deberes de confidencialidad y protección de datos de
      carácter personal estipulados en el Contrato.
    </p>

    <p>
      <strong>Limitación de responsabilidad</strong>
    </p>
    <p>
      Se conviene expresamente que el Cliente, bajo ninguna circunstancia, ejercitará acciones en relación con el presente Contrato, directa y personalmente
      contra empleados, directores, miembros o consultores de Cocomu.
    </p>
    <p>
      La responsabilidad de Cocomu por la eventual indemnización de daños y perjuicios al Cliente que pueda derivarse de este Contrato quedará limitada a la
      cantidad del Precio efectivamente pagada por el Cliente a Cocomu en el momento en el que sea efectiva la resolución del Contrato. Esta limitación
      económica de responsabilidad legal se aplicará a todas las reclamaciones (extrajudiciales y/o judiciales –civiles, penales, administrativas o de cualquier
      otro orden-) que se puedan seguir frente a Cocomu.
    </p>
    <p>
      Cocomu no será responsable en ningún caso del lucro cesante, pérdida de ingresos o ahorros previstos, o por pérdidas especiales, indirectas o de carácter
      secundario derivadas o ligadas a los Servicios, ya se produzcan éstas por razones contractuales, por un acto ilícito civil extracontractual (lo cual
      incluye la negligencia) o por el incumplimiento de una obligación legal u otros.
    </p>
    <p>Cocomu no se responsabilizará de las consecuencias que se deriven:</p>

    <ul>
      <li>
        De que el Cliente no proporcione la información que razonablemente requiera Cocomu para la prestación de los Servicios, o de que tal información sea
        incorrecta o esté incompleta;
      </li>
      <li>Del uso de la App y del contenido publicado a través de la misma por parte del Cliente y/o sus administradores;</li>
      <li>De los actos de los que sean responsables el Cliente;</li>
      <li>
        De las interferencias, interrupciones, fallos, omisiones, averías telefónicas, retrasos, bloqueos o desconexiones en el funcionamiento del sistema
        electrónico, motivadas por deficiencias, sobrecargas y errores en las líneas y redes de telecomunicaciones, o por cualquier otra causa ajena al control
        de Cocomu;
      </li>
      <li>
        De las intromisiones ilegítimas mediante el uso de programas malignos de cualquier tipo y a través de cualquier medio de comunicación, tales como virus
        informáticos o cualesquiera otros;
      </li>
      <li>Del uso indebido o inadecuado de la App; y/o</li>
      <li>De los errores de seguridad o navegación producidos por un mal funcionamiento del navegador o por el uso de versiones no actualizadas del mismo.</li>
    </ul>

    <p>Cocomu está obligado a cumplir su deber de diligencia y asumir responsabilidades únicamente ante el Cliente.</p>
    <p>
      Ninguna Parte será responsable frente a la otra Parte por retrasos o incumplimientos de las obligaciones dispuestas en el Contrato derivados de
      circunstancias ajenas al control, en términos razonables, de alguna de ellas; esto incluye huelgas, cierres patronales y otros conflictos laborales (si
      estos afectan a los empleados de Cocomu o del Cliente), fallo de servicios públicos o redes de transporte, casos de fuerza mayor, guerras, revueltas,
      disturbios sociales, daños dolosos, cumplimiento de la legislación o de una orden, norma, regulación o dirección gubernamentales, accidente, averías en
      instalaciones o maquinaria, incendios, inundaciones, tormentas o incumplimientos por parte de proveedores y subcontratistas.
    </p>
    <p>
      Cocomu no garantiza el acceso continuado, ni la correcta visualización, descarga o utilidad de los elementos e informaciones contenidas en la App, que
      pueden verse impedidos, dificultados o interrumpidos por factores o circunstancias que están fuera de su control.
    </p>

    <p className="section-title">LINKS A TERCEROS</p>
    <p>
      Nuestros Servicios pueden permitir al Cliente acceder a sitios web, aplicaciones, contenido y otros productos o servicios no proporcionados por Cocomu,
      acceder a ellos o interactuar con estos. Cuando el Cliente acceda a dichos terceros, el Cliente se regirá por los términos y condiciones legales de esos
      servicios. Cocomu no será responsable en ningún caso por el uso que hagas de esos servicios, las condiciones del tercero o las medidas que tomes en virtud
      de dichas condiciones.
    </p>

    <p className="section-title">ROL DE COCOMU</p>

    <p>
      Cocomu no se hace responsable del contenido comunicado por los Clientes a través de nuestra App. Cocomu es un mero intermediario entre el Cliente y los
      Usuarios. En particular, esto significa que los Clientes serán los únicos responsables del contenido que envíen a través de nuestra App a los Usuarios
      designados y que la relación existente derivada del uso de nuestros Servicios es únicamente entre los Clientes, los Administradores y los Usuarios, sin
      que Cocomu intervenga de ninguna forma en esta relación. Cocomu y el Cliente declaran que actúan como partes independientes entre sí y que no existe entre
      ellas ningún tipo de vinculación profesional distinta a la aquí indicada, excluyendo expresamente la existencia de cualquier tipo de relación laboral
      entre Cocomu y el Cliente. Por lo tanto, el Cliente declara que Cocomu no asume ningún tipo de responsabilidad, ni directa ni subsidiaria, de ámbito
      laboral, de seguridad social y/o fiscal, por el Cliente o su personal y que, en este sentido, el Cliente es el único responsable del uso y gestión de la
      App y sus comunicaciones por parte del/delos Administradores designados.
    </p>

    <p className="section-title">PRECIO Y FORMA DE PAGO DEL ENCARGO</p>

    <p>
      <strong>Cuantía</strong>
    </p>
    <p>
      El precio convenido por la prestación de los servicios objeto del presente Contrato (en adelante, el "Precio") será el definido en el Anexo I (Lista de
      precios). Cocomu se reserva el derecho a modificar el precio por la prestación de los servicios cuando considere. Cocumu notificará al Cliente con una
      antelación de 15 días naturales a través info@beenfo.com para que este último pueda decidir si acepta o no la nueva tarifa. Si el Cliente no manifiesta en
      el plazo de 15 días su oposición a la nueva tarificación, Cocomu entenderá que la misma ha sido aceptada.
    </p>
    <p>
      Y por otro lado, imaginemos que una empresa empieza a mandar muchos archivos y el coste de almacenamiento asignado a cada una de las empresas/colectivos
      sobrepasa el ingreso que tenemos de esa empresa/colectivo al mes. Es decir, es más caro mantener los archivos que han subido en nuestros servidores de
      almacenamiento que el ingreso que tenemos por usuario de esa empresa. Tendremos que cobrarles un sobre precio, no por usuario, sino por almacenamiento.
      Este tema nos lo tiene que dejar resuelto CLOUD DISTRICT para tenerlo en cuenta y preveerlo.
    </p>

    <p>
      <strong>Devengo y forma de pago</strong>
    </p>
    <p>
      El Precio se devengará de acuerdo con lo indicado durante el proceso de registro y alta. El Precio se devengará mensualmente el primer día hábil del mes
      siguiente en la parte proporcional que corresponda por los Servicios prestados al Cliente durante ese mes. El Cliente pagara por usuario invitado y
      registrado en la App. No importa el día del mes que el Usuario dé de alta en la Aplicación; el Cliente deberá pagar el precio equivalente al mes completo.
    </p>

    <p>
      <strong>Intereses</strong>
    </p>
    <p>
      El retraso en el pago de alguna de las cantidades convenidas en que se ha establecido el pago del Precio por los Servicios contratados, da derecho a
      Cocomu a exigir el pago del interés de demora sobre las cantidades adeudadas. Además, el Cliente asumirá los gastos y/o costes derivados del pago de las
      facturas, no pudiendo repercutir ningún coste por estos conceptos a Cocomu sea cual fuere la forma de pago elegida por el Cliente.
    </p>

    <p className="section-title">PROTECCIÓN DE DATOS DE CARÁCTER PERSONAL.</p>

    <p>
      El tratamiento de datos derivado de la prestación de los Servicios y, por tanto, del uso de la App por parte del Cliente así como de sus Administradores,
      estará sujeto a lo estipulado en la <Link to={routes.privacyPolicy}>Política de privacidad</Link> de la App. En cualquier caso, el Cliente garantiza el
      pleno cumplimiento de la normativa de protección de datos. De esta forma, el Cliente reconoce estar debidamente legitimado para compartir con Cocomu la
      información relativa a cada uno de los Usuarios. Cocomu no se hace responsable de las potenciales consecuencias que pudieran derivarse de la compartición
      ilegitima de los datos personales de Usuarios.
    </p>

    <p className="section-title">CONFIDENCIALIDAD.</p>
    <p>
      Cocomu reconoce que la información compartida a través de la App entre el Cliente, los Administradores y los Usuarios tendrá la consideración de
      confidencial, por lo que se compromete a guardar el más absoluto secreto sobre la información a la que tengan acceso, en cumplimiento de este Contrato.
    </p>
    <p>
      Los deberes de confidencialidad derivados de lo establecido en el presente Contrato, permanecerán durante su periodo de vigencia y subsistirán con
      posterioridad a la extinción del mismo, por tres años.
    </p>
    <p>
      En particular, Cocomu se compromete a no copiar, reproducir, ceder información o material facilitado por el Cliente, así como a no permitir a ninguna otra
      persona física o jurídica la copia, reproducción o divulgación, sea total, parcial o, de cualquier forma, de la información o materiales facilitados en
      cualquier momento, sin la autorización previa de la otra parte manifestada expresamente y por escrito.
    </p>

    <p className="section-title">PROPIEDAD INTELECTUAL</p>

    <p>
      Se entenderá por "Derechos de Propiedad Intelectual e Industrial" en adelante, toda idea o invención susceptible de ser registrada y/o protegida por
      cualquier normativa aplicable y, en particular, toda patente, marca, modelo de utilidad, programa de ordenador, know-how, invención, logotipo, nombre
      comercial, secreto comercial, diseño, derechos de diseño, desarrollo, nombre de dominio de Internet, dato, código fuente, informe, dibujo, especificación,
      método de negocio, derechos moral, derechos de autor, derechos "sui generis" sobre las bases de datos, derechos de semiconductor, derechos topográficos,
      ya sean registrados o no registrados, y, en general y entendido en el sentido más amplio posible, cualquier Derecho de Propiedad Intelectual e Industrial
      preexistente o que se pueda generar en el marco de la prestación de los Servicios en el presente Contrato, incluyendo todo material preparatorio y
      documentación. En este sentido, el Cliente acepta que todos los Derechos de Propiedad Intelectual e Industrial sobre la App, materiales, información y
      demás elementos que se muestran y generan a través de la misma pertenecen a Cocomu. El Cliente, el Administrador y los Usuarios reconocen que los logos,
      nombres comerciales, dominios y marcas que se muestren en la App son propiedad de Cocomu.
    </p>
    <p>
      Sin perjuicio de lo anterior, Cocomu otorga al Cliente, para la correcta ejecución del Contrato, una licencia de uso no exclusiva, no licenciable sobre la
      App, con efectos únicos durante la Vigencia de este Contrato y para fines directamente relacionados con la prestación del Servicio y sujeto a las
      condiciones y requisitos expuesto en presente Contrato y las Condiciones de Uso de la App.
    </p>
    <p>
      Queda expresamente prohibido modificar, fijar, reproducir, transformar, comunicar públicamente, distribuir o de alguna forma utilizar los materiales y
      demás elementos que se muestran en la App para su inclusión en páginas webs ajenas, de forma que diera la impresión de que los mismos pertenecen o están
      de alguna forma asociados al Cliente, sin contar con la autorización previa y por escrito de Cocomu.
    </p>
    <p>
      El Cliente, los Administradores y los Usuarios serán, respectivamente, los únicos titulares de los Derechos de Propiedad Intelectual derivados de los
      contenidos (por ejemplo, archivos binarios, fotografías, textos, etc.).
    </p>

    <p className="section-title">LEGISLACIÓN APLICABLE Y JURISDICCIÓN</p>
    <p>
      Para cualquier litigio que tenga su origen en el presente Contrato, será de aplicación la legislación española y serán competentes los Juzgados y
      Tribunales de la ciudad de Madrid, renunciando las Partes a cualquier otro fuero que pudiera corresponderles.
    </p>
    <p>
      Las Partes se comprometen a negociar de buena fe la resolución de cualquier conflicto, reclamación, pregunta o desacuerdo que pueda surgir en relación el
      presente Contrato, su ejecución o incumplimiento durante al menos dos (2) semanas antes de cualquiera de las Partes pueda acudir a la jurisdicción
      competente, de conformidad con la siguiente estipulación.
    </p>

    <p className="section-title">ANEXO I</p>

    <p>
      <strong>Lista de precios</strong>
    </p>

    <p>PRECIO</p>
    <p>Los honorarios por la prestación de los Servicios objeto de este Anexo se convienen en las cantidades de que se detallan a continuación:</p>
    <p>0,49EUROS por usuario/mes </p>
    <p>
      La cantidad detallada se devengará a favor de Cocomu que serán pagados por el Cliente de forma automatica a travez de los datos de cobro que haya
      proporcionado el usuario.
    </p>
  </div>
)
