import React from 'react'
import ThemeListStyled from './styled'
import { ThemeListItem, PopUpTheme } from '../../atoms'
import i18n from '../../../assets/i18n'
import _ from 'lodash'
import PropTypes from 'prop-types'
import { Dropdown } from 'react-bootstrap'

const ThemeList = ({ onThemeTapped, themes, onClickThemeItem, organization, onClickExitTheme, onClickDeleteTheme, onClickEdit }) => {
  const newList = _.map(themes, (theme, key) => {
    const isThemeAdmin = theme?.isAdmin
    const isOrgClient = organization?.isClient

    return (
      <div key={key} className="theme-list-wrapper">
        <div
          className="themes-list"
          onClick={() => {
            onClickThemeItem(theme, 'themes')
          }}
        >
          <ThemeListItem name={`#${theme.name}`} />
        </div>
        {(isOrgClient || isThemeAdmin) && theme.editable && (
          <div className="dots-wrapper">
            <Dropdown>
              <Dropdown.Toggle as="span" className="theme-actions">
                ...
              </Dropdown.Toggle>
              <Dropdown.Menu className="menu-popup">
                <PopUpTheme
                  isThemeAdmin={isThemeAdmin}
                  isOrgClient={isOrgClient}
                  theme={theme}
                  onClickEdit={() => {
                    onThemeTapped(theme)
                    onClickEdit()
                  }}
                  onClickExit={() => {
                    onClickExitTheme(theme)
                  }}
                  onClickDelete={() => {
                    onClickDeleteTheme(theme)
                  }}
                />
              </Dropdown.Menu>
            </Dropdown>
          </div>
        )}
      </div>
    )
  })

  if (_.size(newList) === 0) return null

  return (
    <ThemeListStyled>
      <p className="themes-title-list">{i18n.themeList.title}</p>
      {newList}
    </ThemeListStyled>
  )
}

ThemeList.propTypes = {
  themes: PropTypes.array,
  onClickThemeItem: PropTypes.func,
  onClickExitTheme: PropTypes.func,
  onClickEdit: PropTypes.func,
  onThemeTapped: PropTypes.func,
  organization: PropTypes.object,
  onClickDeleteTheme: PropTypes.func
}

export default ThemeList
