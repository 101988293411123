import React from 'react'
import DirectChatListMoreDetailsStyled from './styled'
import { Avatar, PopUpDirectUserInfo } from '../../atoms'
import _ from 'lodash'
import PropTypes from 'prop-types'
import { Dropdown } from 'react-bootstrap'

const CustomToggle = React.forwardRef(({ children, onClick }, ref) => {
  return (
    <span
      ref={ref}
      onClick={e => {
        e.preventDefault()
        onClick(e)
      }}
    >
      {children}
    </span>
  )
})

const DirectChatListMoreDetails = ({ participants, showProfile }) => {
  const newList = _.map(participants, (user, key) => {
    const name = _.get(user, 'fullname', '')
    const avatar = _.get(user, 'avatar', '')
    return (
      <div key={key} className="direct-user-wrapper">
        <Avatar imageUrl={avatar} name={name} />
        <div className="dots-wrapper">
          <Dropdown>
            <Dropdown.Toggle as={CustomToggle}>...</Dropdown.Toggle>
            <Dropdown.Menu className="menu-popup">
              <PopUpDirectUserInfo
                showProfile={() => {
                  showProfile(user)
                }}
              />
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </div>
    )
  })

  if (_.size(newList) === 0) return null

  return <DirectChatListMoreDetailsStyled>{newList}</DirectChatListMoreDetailsStyled>
}

DirectChatListMoreDetails.propTypes = {
  participants: PropTypes.array,
  showProfile: PropTypes.func
}

export default DirectChatListMoreDetails
