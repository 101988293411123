import styled from 'styled-components'
import colors from '../../../assets/colors'

export const PopUpWriteMsgAddStyled = styled.div`
  && {
    .pop-up {
      .name {
        font-size: 16px;
        line-height: 22px;
        color: ${colors.black};
        font-weight: 600;
        margin-bottom: 5px;
        padding-right: 15px;
      }

      .phone {
        font-size: 14px;
        line-height: 16px;
        color: ${colors.black};
        font-weight: 600;
        margin-bottom: 5px;
        padding-right: 15px;
      }

      ul {
        list-style: none;
        margin-bottom: 0;
        padding: 0 15px;

        li {
          margin: 20px 0;
          font-size: 16px;
          line-height: 16px;
          color: ${colors.black};
          cursor: pointer;
        }
      }
    }
  }
`

export const UploadFile = styled.label`
  cursor: pointer;
`

export const InputContainer = styled.div`
  height: 0;
  width: 0 !important;
  overflow: hidden;
`
