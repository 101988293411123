import styled from 'styled-components'
import colors from '../../../assets/colors'

const ComponentStyled = styled.div`
  && {
    .title-chat-wrapper {
      padding-bottom: 30px;
      position: relative;
      margin-bottom: 15px;

      .title-chat {
        font-size: 20px;
        line-height: 24px;
        letter-spacing: -0.62px;
        color: ${colors.black};
        margin-bottom: 30px;
      }
    }

    .total-votes-wrapper {
      text-align: center;
      margin-bottom: 30px;

      p {
        margin-bottom: 0;
      }

      .total {
        margin-bottom: 10px;
        font-size: 27px;
        line-height: 27px;
      }
    }

    .poll-name {
      margin-bottom: 30px;
    }

    .answer-wrapper {
      margin-bottom: 30px;

      .answer-name {
        margin-bottom: 15px;
        font-size: 14px;
        font-weight: 600;
        line-height: 14px;
        letter-spacing: 1.08px;
        text-transform: uppercase;
      }

      .percent,
      .votes {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
    }
  }
`

export default ComponentStyled
