import React, { Component } from 'react'
import { Col } from 'react-bootstrap'
import UserLoginStyled from './styled'
import { LoginForm } from '../../molecules'
import { CircleHeader } from '../../atoms'
import i18n from '../../../assets/i18n'
import { withRouter } from 'react-router'
import PropTypes from 'prop-types'

class UserLogin extends Component {
  state = {
    formErrors: ''
  }

  handleOnSubmit = ({ phone, password }) => {
    const data = {
      username: phone,
      password: password
    }
    this.props.login(data)
  }

  render() {
    const { formErrors } = this.state
    const { isFetching, onRegisterTapped, onResetTapped } = this.props
    return (
      <UserLoginStyled>
        <div className="login-wrapper">
          <div className="circle-header">
            <CircleHeader />
          </div>
          <Col xs={12} sm={{ span: 8, offset: 2 }}>
            <LoginForm isFetchingForm={isFetching} handleOnSubmit={this.handleOnSubmit} />
            <p className="error-msg-form">{formErrors}</p>
          </Col>
          <Col xs={12} sm={{ span: 8, offset: 2 }} className="text-center">
            <p className="no-account">
              {i18n.userLogin.noAccount}{' '}
              <span onClick={onRegisterTapped} className="pointer">
                {i18n.userLogin.signUp}
              </span>
            </p>
            <p className="forgot-pass">
              <span onClick={onResetTapped}>{i18n.userLogin.forgotPass}</span>
            </p>
          </Col>
        </div>
      </UserLoginStyled>
    )
  }
}

UserLogin.propTypes = {
  history: PropTypes.object,
  login: PropTypes.func,
  onResetTapped: PropTypes.func,
  isFetching: PropTypes.bool,
  onRegisterTapped: PropTypes.func
}

export default withRouter(UserLogin)
