import styled from 'styled-components'
import colors from '../../../assets/colors'

const SearchChatsStyled = styled.div`
  && {
    padding: 30px 0;

    .search-titles-wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 5px;

      .search-title {
        font-size: 20px;
        line-height: 24px;
        letter-spacing: -0.62px;
        color: ${colors.black};
      }

      .new-theme {
        font-size: 14px;
        line-height: 14px;
        letter-spacing: 1.08px;
        text-transform: uppercase;
        color: ${colors.black};
        cursor: pointer;
      }
    }
  }
`

export default SearchChatsStyled
