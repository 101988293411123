import { connect } from 'react-redux'
import compose from 'recompose/compose'
import { themesOperations } from '../../../redux/themes'
import View from './view'

const mapStateToProps = state => {
  return {
    isFetching: state.themes.isFetching,
    theme: state.themes.item
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onSubmit: data => dispatch(themesOperations.postThemePoll(data))
  }
}

const enhance = compose(connect(mapStateToProps, mapDispatchToProps))

export default enhance(View)
