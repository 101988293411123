import styled from 'styled-components'
import colors from '../../../assets/colors'

const ChatWindowStyled = styled.div`
  && {
    display: flex;
    flex-flow: column;
    height: 100%;
    background-color: ${colors.grey};
    position: relative;
    height: 100%;
    flex: 10;

    .block-chat-wrapper {
      text-align: center;
      background: ${colors.darkGrey5};
      padding: 15px;

      p {
        margin-bottom: 0;
        font-size: 14px;
      }
    }

    .spinner-wrapper {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      .spinner {
        animation: rotating 2s linear infinite;
        width: 50px;
        height: 50px;
        max-height: 50px;
        max-width: 50px;
        transition: 0.5s ease all;
      }
    }

    @keyframes rotating {
      from {
        transform: rotate(0deg);
      }
      to {
        transform: rotate(360deg);
      }
    }

    .top-bar-chat-wrapper {
      background-color: ${colors.grey};
    }

    .chat-messages {
      overflow-y: scroll;
      padding: 0 45px 0 45px;
      -ms-overflow-style: none;
      scrollbar-width: none;
      flex: 1;

      .load-more-wrapper {
        background: transparent;
        text-align: center;
        padding: 10px;
        position: relative;

        span {
          text-transform: uppercase;
          display: inline-block;
          cursor: pointer;
          font-size: 12px;
          line-height: 12px;
          color: ${colors.darkGrey4};
        }
      }

      ::-webkit-scrollbar {
        display: none;
      }

      > .messages-date {
        margin: 20px 0;
        font-size: 9px;
        line-height: 9px;
        text-align: center;
        letter-spacing: 0.057px;
        text-transform: uppercase;
        color: ${colors.darkGrey6};
      }
    }

    .bottom-write-chat-wrapper {
      padding: 30px 45px;
    }
  }
`

export default ChatWindowStyled
