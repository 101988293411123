import styled from 'styled-components'
import colors from '../../../assets/colors'

const ComponentStyled = styled.div`
  && {
    padding: 15px 0;

    .invitation-wrapper {
      padding: 10px 0;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .phone-number {
        font-size: 16px;
        line-height: 16px;
        color: ${colors.darkGrey};
        margin-bottom: 0;
        padding-right: 15px;
      }

      .remove {
        color: ${colors.red};
        font-size: 14px;
        margin-bottom: 0;
        cursor: pointer;
      }
    }

    .confirm-wrapper {
      border-top: 1px solid ${colors.darkGrey};
      margin-top: 15px;
      padding-top: 15px;

      .confirm-title,
      .confirm-phone {
        text-align: center;
        font-size: 16px;
        line-height: 16px;
        color: ${colors.darkGrey};
      }

      .actions-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;

        .confirm,
        .cancel {
          margin: 0 10px;
          font-size: 14px;
          cursor: pointer;
        }

        .confirm {
          color: ${colors.red};
        }
      }
    }

    .title-list {
      font-size: 13px;
      line-height: 15px;
      text-transform: uppercase;
      font-weight: 600;
      text-align: center;
      color: ${colors.darkGrey};
    }
  }
`

export default ComponentStyled
