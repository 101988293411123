import * as messagesActions from './actions'
import * as messagesUtils from './utils'
import { usersActions } from '../users'
import { directsUtils } from '../directs'
import _ from 'lodash'

export const initThemeMessagesList = showBroadcastOnly => dispatch => {
  dispatch(messagesActions.updateList([], 0, null))
  dispatch(usersActions.updateThemeParticipantsList([], 0, null))
  dispatch(fetchThemeMessagesList(showBroadcastOnly))
}

export const fetchThemeMessagesList = (showBroadcastOnly = false) => async (dispatch, getState, { api, client, setError }) => {
  const { item: theme } = getState().themes
  const themeId = _.get(theme, 'id')
  if (_.isNil(themeId)) {
    return
  }

  try {
    dispatch(messagesActions.setFetching(true))
    const { list: currentMessages, pageInfo } = getState().messages
    const startCursor = _.get(pageInfo, 'startCursor', null)
    const variables = { themeId, broadcast: showBroadcastOnly ? true : null, startCursor }
    const messagesQueryRes = await client.query({ query: api.queries.GET_THEME_MESSAGES, variables, fetchPolicy: 'network-only' })

    // UPDATE THEME MESSAGES
    const newMessages = _.map(_.get(messagesQueryRes, 'data.theme.messages.edges', []), v => v.node)
    const messagesList = _.uniqBy([...newMessages, ...currentMessages], '_id')

    const total = _.get(messagesQueryRes, 'data.theme.messages.totalCount')
    const newPageInfo = _.get(messagesQueryRes, 'data.theme.messages.pageInfo')

    dispatch(messagesActions.updateList(messagesList, total, newPageInfo))
  } catch (e) {
    dispatch(setError(e))
  } finally {
    dispatch(messagesActions.setFetching(false))
  }
}

export const postThemeMessage = data => async (dispatch, getState, { api, setError }) => {
  const { item: theme } = getState().themes
  const { userInfo } = getState().me
  if (_.isNil(theme)) {
    return
  }

  try {
    dispatch(messagesActions.setFetching(true))
    const message = await messagesUtils.formatMessageData(data)
    if (!message) {
      return
    }

    dispatch(messagesActions.postSagaMessage(message, userInfo))
  } catch (e) {
    dispatch(setError(e))
  } finally {
    dispatch(messagesActions.setFetching(false))
  }
}

export const initDirectMessagesList = () => dispatch => {
  dispatch(messagesActions.updateList([], 0, null))
  dispatch(fetchDirectMessagesList())
}

export const fetchDirectMessagesList = () => async (dispatch, getState, { api, client, setError }) => {
  const { item: direct } = getState().directs
  const { item: theme } = getState().themes
  const directId = _.get(direct, 'id')
  const themeId = _.get(theme, 'id')

  if (_.isNil(directId) || _.isNil(themeId)) {
    return
  }

  try {
    dispatch(messagesActions.setFetching(true))
    const { list: currentMessages, pageInfo } = getState().messages
    const startCursor = _.get(pageInfo, 'startCursor', null)
    const messagesQueryRes = await client.query({
      query: api.queries.GET_DIRECT_MESSAGES,
      variables: { themeId, directId, startCursor },
      fetchPolicy: 'network-only'
    })

    // UPDATE DIRECT MESSAGES AS AUTHOR
    const newMessages = _.map(_.get(messagesQueryRes, 'data.themeDirectMessages.edges', []), v => v.node)
    const messagesList = _.sortBy(_.uniqBy([...newMessages, ...currentMessages], '_id'), ['created'])
    const total = _.get(messagesQueryRes, 'data.themeDirectMessages.totalCount')
    const newPageInfo = { hasPreviousPage: messagesList.length < total, startCursor: messagesList.length }
    dispatch(messagesActions.updateList(messagesList, total, newPageInfo))
  } catch (e) {
    dispatch(setError(e))
  } finally {
    dispatch(messagesActions.setFetching(false))
  }
}

export const postDirectMessage = data => async (dispatch, getState, { setError }) => {
  const { item: theme } = getState().themes
  const { userInfo } = getState().me
  if (_.isNil(theme)) {
    return
  }

  try {
    dispatch(messagesActions.setFetching(true))
    const message = await messagesUtils.formatMessageData(data)
    if (!message) {
      return
    }

    dispatch(messagesActions.postSagaMessage(message, userInfo))
  } catch (e) {
    dispatch(setError(e))
  } finally {
    dispatch(messagesActions.setFetching(false))
  }
}

export const fetchMessage = messageId => async (dispatch, getState, { client, api }) => {
  const getMessageRes = await client.query({
    query: api.queries.GET_MESSAGE,
    variables: { messageId },
    fetchPolicy: 'network-only'
  })
  const message = _.get(getMessageRes, 'data.message')
  dispatch(addListMessage(message))
}

// GENERIC LIST MESSAGES UTILS FOR SAGA ACTIONS
export const addListMessage = message => async (dispatch, getState) => {
  const { list } = getState().messages
  const messagesList = _.uniqBy([...list, message], '_id')
  dispatch(messagesActions.updateList(messagesList))
}

export const updateListMessage = (message, messageId) => async (dispatch, getState) => {
  const messagesList = [...getState().messages.list]
  const messageIndex = _.findIndex(messagesList, { id: messageId })
  if (messageIndex !== -1) {
    messagesList.splice(messageIndex, 1, message)
    dispatch(messagesActions.updateList(messagesList))
  }
}

export const removeListMessage = messageId => async (dispatch, getState) => {
  const messagesList = [...getState().messages.list]
  const messageIndex = _.findIndex(messagesList, { id: messageId })
  if (messageIndex !== -1) {
    messagesList.splice(messageIndex, 1)
    dispatch(messagesActions.updateList(messagesList))
  }
}
