import React, { Component } from 'react'
import ComponentStyled from './styled'
import { Button } from '../../atoms'
import i18n from '../../../assets/i18n'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'

class StepCompleted extends Component {
  constructor(props) {
    super(props)
    this.state = {
      addedError: '',
      prefix: '',
      number: '',
      phones: []
    }
  }

  render() {
    return (
      <ComponentStyled>
        <p className="success">{this.props.title}</p>
        <Button type="submit" variant="big" label={i18n.registerCompleted.goToHome} handleOnClick={this.props.onFinish} />
      </ComponentStyled>
    )
  }
}

StepCompleted.propTypes = {
  handleOnSubmit: PropTypes.func,
  isFetchingForm: PropTypes.bool,
  history: PropTypes.object,
  isLogin: PropTypes.func,
  onFinish: PropTypes.func,
  title: PropTypes.string
}

export default withRouter(StepCompleted)
