import React, { useRef } from 'react'
import { PropTypes } from 'prop-types'
import { useReactToPrint } from 'react-to-print'
import { ReactComponent as RightArrow } from '../../../assets/images/right-arrow.svg'
import i18n from 'assets/i18n'
import { LineChart, NumberDataList } from 'components/molecules'
import { Button, RangeDatePicker, Spinner } from 'components/atoms'
import { SECTIONS } from './utils'
import moment from 'moment'
import * as S from './styled'

const StatsView = ({
  chartData,
  data,
  section,
  onChangeSection,
  isFetching,
  isFetchingTheme,
  themesOptions,
  onChangeTheme,
  onChangeStartDate,
  onChangeEndDate,
  themeAdmins,
  selectedTheme,
  dates,
  themeData,
  themeChartNumThemes,
  printView,
  setPrintView,
  onThemeTapped
}) => {
  const refContainer = useRef(null)

  const reactToPrintContent = React.useCallback(() => {
    return refContainer.current
  }, [refContainer.current])

  const handlePrint = useReactToPrint({
    content: reactToPrintContent,
    documentTitle: 'AwesomeFileName',
    onBeforeGetContent: () => setPrintView(true),
    onAfterPrint: () => setPrintView(false),
    removeAfterPrint: true
  })

  const onClickPrint = () => {
    setPrintView(true)
    setTimeout(() => {
      handlePrint(handlePrint)
    }, 1000)
  }

  return (
    <S.Container ref={refContainer} printView={printView}>
      <S.Header>
        <span className="stats-header-label">{i18n.statsDashboard.viewDataOf}</span>
        <RangeDatePicker
          start={dates?.start}
          end={dates?.end}
          onChangeStart={onChangeStartDate}
          onChangeEnd={onChangeEndDate}
          maxStartDate={moment()
            .startOf('month')
            .toDate()}
          maxEndDate={moment()
            .endOf('month')
            .toDate()}
        />
      </S.Header>
      <S.Panel>
        <S.SectionHeader>
          <Button
            variant={section === SECTIONS.data ? 'yellow' : 'white'}
            label={i18n.statsDashboard.showData}
            handleOnClick={() => onChangeSection(SECTIONS.data)}
          />
          <Button
            variant={section === SECTIONS.charts ? 'yellow' : 'white'}
            label={i18n.statsDashboard.showCharts}
            handleOnClick={() => onChangeSection(SECTIONS.charts)}
          />
          <Button label={i18n.statsDashboard.print} onClick={onClickPrint} disabled={isFetching || isFetchingTheme} />
        </S.SectionHeader>
        <S.DataContainer>
          <S.DataList className={section === SECTIONS.data || printView ? 'show' : 'hide'}>
            {isFetching ? <Spinner /> : <NumberDataList data={data} />}
          </S.DataList>
          <div className={section === SECTIONS.charts || printView ? 'show' : 'hide'}>
            {isFetching ? (
              <Spinner />
            ) : (
              <S.ChartList>
                {chartData?.map(({ id, title, ...itemProps }) => {
                  return <LineChart key={id} title={title} data={itemProps} />
                })}
              </S.ChartList>
            )}
          </div>
          <S.DataList className={section === SECTIONS.data || printView ? 'show' : 'hide'}>
            {!isFetching && (
              <>
                <S.SectionSubheader>
                  <span>{i18n.statsDashboard.themeSelectorLabel}</span>
                  <S.Selector nullable={false} value={selectedTheme} variant="filled-button" options={themesOptions} onChange={onChangeTheme} />
                  <S.ButtonGoToTheme variant="link" onClick={onThemeTapped} label={i18n.statsDashboard.goToTheme} iconRight={<RightArrow />} />
                </S.SectionSubheader>
                <S.SectionSubheader>
                  <S.LabelAdministrator>{i18n.statsDashboard.themeAdminsLabel}: </S.LabelAdministrator>
                  {themeAdmins?.map(item => (
                    <S.Administrator key={item?.node?.id}>{item?.node?.fullname}</S.Administrator>
                  ))}
                </S.SectionSubheader>
              </>
            )}
            {isFetchingTheme ? <Spinner /> : <NumberDataList data={themeData} />}
          </S.DataList>
        </S.DataContainer>
      </S.Panel>
      <S.Panel className={section === SECTIONS.charts || printView ? 'show' : 'hide'}>
        {!printView && (
          <S.SectionSubheader>
            <span>{i18n.statsDashboard.themeSelectorLabel}</span>
            <S.Selector nullable={false} variant="filled-button-yellow" value={selectedTheme} options={themesOptions} onChange={onChangeTheme} />
          </S.SectionSubheader>
        )}

        {isFetchingTheme ? (
          <Spinner />
        ) : (
          <S.ChartList>
            {themeChartNumThemes?.map(({ id, title, ...itemProps }) => {
              return <LineChart key={id} title={title} data={itemProps} />
            })}
          </S.ChartList>
        )}
      </S.Panel>
    </S.Container>
  )
}

StatsView.propTypes = {
  chartData: PropTypes.array,
  data: PropTypes.array,
  section: PropTypes.string.isRequired,
  onChangeSection: PropTypes.func.isRequired,
  isFetching: PropTypes.bool,
  isFetchingTheme: PropTypes.bool,
  themesOptions: PropTypes.array,
  onChangeTheme: PropTypes.func.isRequired,
  onChangeStartDate: PropTypes.func.isRequired,
  onChangeEndDate: PropTypes.func.isRequired,
  themeAdmins: PropTypes.array,
  selectedTheme: PropTypes.string,
  dates: PropTypes.shape({ start: PropTypes.instanceOf(Date), end: PropTypes.instanceOf(Date) }),
  themeData: PropTypes.array,
  themeChartNumThemes: PropTypes.array,
  onPrint: PropTypes.func.isRequired,
  setPrintView: PropTypes.func.isRequired,
  printView: PropTypes.bool,
  onThemeTapped: PropTypes.func.isRequired
}

StatsView.defaultProps = {
  chartData: [],
  data: [],
  isFetching: false,
  isFetchingTheme: false,
  themesOptions: [],
  themeAdmins: [],
  selectedTheme: null,
  dates: null,
  themeData: [],
  themeChartNumThemes: [],
  printView: false,
  setPrintView: () => {}
}

export default StatsView
