import React, { Component } from 'react'
import PropTypes from 'prop-types'
import ContainerStyled from './styled'
import Modal from 'react-bootstrap/Modal'
import { UpdateOrganizationUsers, UserInfo } from '../../molecules'
import { OrganizationUsers } from '../../organism'
import { CircleHeader } from '../../atoms'
import i18n from '../../../assets/i18n'

const STEPS = {
  users: 1,
  addUsers: 2,
  showUser: 3
}

class ModalManageUsers extends Component {
  constructor(props) {
    super(props)
    this.state = {
      step: STEPS.users,
      user: {}
    }
  }

  onSubmitUpdateUsers = async data => {
    const updated = await this.props.postOrganizationUsers(data)

    if (updated) {
      this.setState({ step: STEPS.users })
    }
  }

  onClickUser = user => {
    this.setState({ user, step: STEPS.showUser })
  }

  onUsersTapped = () => {
    this.setState({ step: STEPS.users })
  }

  onUpdateUserTapped = () => {
    this.setState({ step: STEPS.addUsers })
  }

  onClickHide = () => {
    const { onHide } = this.props
    onHide()
    this.setState({ step: STEPS.users })
  }

  renderSteps = () => {
    const { step, user } = this.state
    const { isFetching } = this.props

    switch (step) {
      case STEPS.users:
        return <OrganizationUsers onClickUser={this.onClickUser} />
      case STEPS.addUsers:
        return <UpdateOrganizationUsers isFetchingForm={isFetching} handleOnSubmit={this.onSubmitUpdateUsers} />
      case STEPS.showUser:
        return <UserInfo user={user} />
      default:
        return null
    }
  }

  render() {
    const { show } = this.props
    const { step } = this.state
    return (
      <Modal show={show} onHide={this.onClickHide} size="md" aria-labelledby="contained-modal-title-vcenter" centered>
        <ContainerStyled>
          <Modal.Body>
            <CircleHeader />
            <div className="users-wrapper">
              {this.renderSteps()}
              {step === STEPS.users && (
                <p className="add-link">
                  <span onClick={this.onUpdateUserTapped}>{i18n.modalManageUsers.add}</span>
                </p>
              )}
              {(step === STEPS.addUsers || step === STEPS.showUser) && (
                <p className="back-link">
                  <span onClick={this.onUsersTapped}>{i18n.modalManageUsers.back}</span>
                </p>
              )}
            </div>
          </Modal.Body>
        </ContainerStyled>
      </Modal>
    )
  }
}

ModalManageUsers.propTypes = {
  onHide: PropTypes.func,
  isFetching: PropTypes.bool,
  show: PropTypes.bool,
  postOrganizationUsers: PropTypes.func
}

export default ModalManageUsers
