import React from 'react'
import ComponentStyled from './styled'
import { Avatar, Button } from '../../atoms'
import PropTypes from 'prop-types'
import i18n from '../../../assets/i18n'
import { ReactComponent as ReplyIcon } from '../../../assets/images/right-arrow.svg'
import { ReactComponent as YellowArrow } from '../../../assets/images/yellow-arrow-right.svg'
import { ImageInChat, FileInChat } from '../../atoms'
import Linkify from 'react-linkify'
import { MESSAGES_TYPES } from '../../../config/constants'

const UserMessage = ({ type, avatarResourceId, altPollText, onOpenPoll, name, message, time, seeMore, showReply, onClickSeeMore, mediaObject, isDeleted }) => {
  function renderMediaContent() {
    if (type === MESSAGES_TYPES.TEXT || type === MESSAGES_TYPES.POLL || message) {
      return (
        <Linkify
          componentDecorator={(decoratedHref, decoratedText, key) => (
            <a target="blank" href={decoratedHref} key={key}>
              {decoratedText}
            </a>
          )}
        >
          <p className="message-content">{message}</p>
        </Linkify>
      )
    } else if (type === MESSAGES_TYPES.FILE || type === MESSAGES_TYPES.AUDIO || type === MESSAGES_TYPES.IMAGE) {
      if (mediaObject && (mediaObject.mimeType === 'image/png' || mediaObject.mimeType === 'image/jpg' || mediaObject.mimeType === 'image/jpeg')) {
        return <ImageInChat resourceId={mediaObject._id} />
      } else {
        return <FileInChat isAudio={type === MESSAGES_TYPES.AUDIO ? true : false} mediaObject={mediaObject} />
      }
    }
  }

  return (
    <ComponentStyled>
      <div className="avatar-message-wrapper">
        <Avatar variant="shadow" name={name} showFullname={false} resourceId={avatarResourceId} />
      </div>
      <div className="message-wrapper">
        <div className="message-box">
          {name && <p className="message-author">{name}</p>}
          {renderMediaContent()}
          {time && <p className="message-time">{time}</p>}
        </div>
        {type === MESSAGES_TYPES.POLL && onOpenPoll && (
          <div className="btn-wrapper">
            <Button handleOnClick={onOpenPoll} label={altPollText || i18n.userMessage.answerPoll} />
          </div>
        )}
      </div>

      <div className="actions-wrapper">
        {showReply && (
          <div className="reply-btn">
            <ReplyIcon />
          </div>
        )}
        {seeMore && (
          <div className="see-more-btn" onClick={onClickSeeMore}>
            <p>
              {i18n.userMessage.seeMore}
              <YellowArrow />
            </p>
          </div>
        )}
      </div>
    </ComponentStyled>
  )
}

UserMessage.defaultProps = {
  showReply: false,
  seeMore: false
}

UserMessage.propTypes = {
  altPollText: PropTypes.string,
  avatarResourceId: PropTypes.any,
  name: PropTypes.string,
  message: PropTypes.string,
  type: PropTypes.string,
  time: PropTypes.string,
  seeMore: PropTypes.bool,
  showReply: PropTypes.bool,
  onClickSeeMore: PropTypes.func,
  mediaObject: PropTypes.any,
  onOpenPoll: PropTypes.func,
  isDeleted: PropTypes.bool
}

export default UserMessage
