import * as authActions from './actions'
import _ from 'lodash'
import { meActions } from '../me'
import { organizationsOperations } from '../organizations'
import { wsOperations } from '../ws'
import routes from '../../config/routes'
import i18n from '../../assets/i18n'
import firebase from 'firebase/app'
import 'firebase/analytics'

export const initializeApp = history => (dispatch, getState) => {
  const { token } = getState().auth
  if (token) {
    dispatch(setToken(token))
    dispatch(initAuthData(history))
  } else {
    //actions.replace('Login')
  }
}

// PROVISIONAL FIX TO ROUTES RELOAD BUG
export const initializeAppDebounce = _.debounce(dispatch => dispatch(initializeApp()), 1000)

export const login = (data, history) => async (dispatch, getState, { api, toast }) => {
  try {
    dispatch(authActions.setFetching(true))
    const loginRes = await api.login(data)
    const token = _.get(loginRes, 'data.token')
    dispatch(setToken(token))
    await dispatch(initAuthData(history))
    // GO DASHBOARD
    history.push(routes.dashboard)
  } catch (e) {
    toast(i18n.auth.errorLogin, { type: toast.TYPE.ERROR })
    dispatch(authActions.setFetching(false))
  }
}

export const register = data => async (dispatch, getState, { api, setError }) => {
  try {
    dispatch(authActions.setFetching(true))
    const { phoneNumber, plainPassword } = data

    const registerData = { ...data, image: null }

    // REGISTER USER
    await api.register(registerData)

    // LOGIN TO GET TOKEN
    const loginData = { username: phoneNumber, password: plainPassword }
    const loginRes = await api.login(loginData)
    const token = _.get(loginRes, 'data.token')
    dispatch(setToken(token))

    // CREATE IMAGE RESOURCE
    if (data.image) {
      const postResourceRes = await api.postResource(data.image)
      const image = _.get(postResourceRes, 'data[@id]')
      await api.updateUserInfo({ image })
    }

    await dispatch(initAuthData())

    return true
  } catch (e) {
    dispatch(setError(e))
    return false
  } finally {
    dispatch(authActions.setFetching(false))
  }
}

export const initAuthData = history => async (dispatch, getState, { api, setError }) => {
  try {
    dispatch(authActions.setFetching(true))

    // GET USER INFO
    const userInfoRes = await api.getUserInfo()
    const userInfo = _.get(userInfoRes, 'data', null)
    dispatch(meActions.updateInfo(userInfo))

    // ANALYTICS
    firebase.analytics().setUserId(userInfo?.id)
    firebase.analytics().setUserProperties({
      fullname: userInfo?.fullname,
      id: userInfo?.id,
      _id: userInfo?._id,
      phoneNumber: userInfo?.phoneNumber
    })

    // GET USER ORGANIZATIONS
    const organizations = await dispatch(organizationsOperations.initList())
    const subscribedOrganizations = _.filter(organizations, { subscribed: true })

    // CHECK IF SELECTED ORGANIZATION IS UPDATED
    const { item: selectedOrganization } = getState().organizations

    let defaultOrganization = _.first(subscribedOrganizations)

    //IF ORGANIZATION IS CREATED AND ID COMES FROM URL
    if (history?.location) {
      const search = _.get(history, 'location.search')
      const params = new URLSearchParams(search)
      let organizationUrlId = params.get('organizationId')
      if (organizationUrlId) {
        organizationUrlId = _.toInteger(organizationUrlId)
        defaultOrganization = _.find(subscribedOrganizations, { _id: organizationUrlId })
      }
    }

    if (_.find(subscribedOrganizations, { id: _.get(selectedOrganization, 'id', '-1') })) {
      dispatch(organizationsOperations.updateSelectedOrganization(selectedOrganization))
    } else if (defaultOrganization) {
      dispatch(organizationsOperations.updateSelectedOrganization(defaultOrganization))
    }

    // OPEN SOCKET
    dispatch(wsOperations.openAuthSocket())
  } catch (e) {
    dispatch(setError(e))
  } finally {
    dispatch(authActions.setFetching(false))
  }
}

export const setToken = token => async (dispatch, getState, { api }) => {
  api.configureToken(token)
  dispatch(authActions.setToken(token))
  await localStorage.setItem('token', token)
}

export const logout = history => async (dispatch, getState, { api, client }) => {
  dispatch(authActions.logOut())
  api.configureToken(null)
  client.resetStore()
  await localStorage.removeItem('token')
  await dispatch(wsOperations.closeAuthSocket())
  history.push(routes.home)
}

export const resetPassword = data => async (dispatch, getState, { api, setError }) => {
  try {
    dispatch(authActions.setFetching(true))
    await api.resetPassword(data)
    return true
  } catch (e) {
    dispatch(setError(e))
    return false
  } finally {
    dispatch(authActions.setFetching(false))
  }
}
