import React from 'react'
import PropTypes from 'prop-types'
import ComponentStyled from './styled'
import { ReactComponent as ArroIcon } from '../../../assets/images/simple-left-arrow.svg'

const SideBarTitle = ({ title, onClick }) => {
  return (
    <ComponentStyled hasClickEvent={onClick ? true : false} onClick={onClick}>
      <div className="title-wrapper">
        {onClick && <ArroIcon className="arrow" />}
        <p className="title">{title}</p>
      </div>
    </ComponentStyled>
  )
}

SideBarTitle.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  moreInfo: PropTypes.string,
  onClick: PropTypes.func
}

export default SideBarTitle
