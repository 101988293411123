import i18n from '../assets/i18n/'
import * as api from '../api/'
import { createStore, applyMiddleware, combineReducers } from 'redux'
import thunk from 'redux-thunk'
import { authOperations } from '../redux/auth/'
import { errorOperations } from '../redux/error/'
import { createBrowserHistory } from 'history'
import * as reducers from '../redux/'
import { toast } from 'react-toastify'
import { all } from 'redux-saga/effects'
import { createLogger } from 'redux-logger'
import { persistStore, persistReducer } from 'redux-persist'
import { connectRouter, routerMiddleware } from 'connected-react-router'
import createSagaMiddleware from 'redux-saga'
import storage from 'redux-persist/lib/storage'
import client from './apollo'
import messagesSagas from '../redux/messages/sagas'
import statsSagas from '../redux/stats/sagas'

const setError = errorOperations.setError

function* rootSaga() {
  yield all([messagesSagas(), statsSagas()])
}

const logger = createLogger({
  collapsed: true
})

const rootPersistConfig = {
  key: 'root',
  storage,
  whitelist: ['auth', 'organizations'],
  timeout: 10000
}

const authPersistConfig = {
  key: 'auth',
  storage,
  whitelist: ['token'],
  timeout: 10000
}

const organizationsPersistConfig = {
  key: 'organizations',
  storage,
  whitelist: ['item'],
  timeout: 10000
}

const rootReducer = history =>
  combineReducers({
    ...reducers,
    auth: persistReducer(authPersistConfig, reducers.auth),
    organizations: persistReducer(organizationsPersistConfig, reducers.organizations),
    router: connectRouter(history)
  })

const sagaMiddleware = createSagaMiddleware()

const persistedReducer = history => persistReducer(rootPersistConfig, rootReducer(history))
const thunkWithExtraArguments = thunk.withExtraArgument({ api, i18n, toast, setError, client })

export const history = createBrowserHistory()

export const store =
  process.env.NODE_ENV === 'development'
    ? createStore(persistedReducer(history), applyMiddleware(thunkWithExtraArguments, sagaMiddleware, logger, routerMiddleware(history)))
    : createStore(persistedReducer(history), applyMiddleware(thunkWithExtraArguments, sagaMiddleware, routerMiddleware(history)))

sagaMiddleware.run(rootSaga)

export const persistor = persistStore(store, {}, () => store.dispatch(authOperations.initializeApp(history)))
