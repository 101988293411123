import styled from 'styled-components'
import colors from '../../../assets/colors'

const ComponentStyled = styled.div`
  && {
    .box-wrapper {
      padding: 30px 15px;
      background-color: ${colors.darkGrey};

      .title {
        color: ${colors.white};
        margin-bottom: 50px;
      }

      .row-images {
        justify-content: space-evenly;
      }
    }

    @media (min-width: 768px) {
      .box-wrapper {
        padding: 60px 50px;
      }
    }
  }
`

export default ComponentStyled
