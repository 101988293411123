/* eslint-disable react/no-unescaped-entities */
import React from 'react'
import { Link } from 'react-router-dom'
import routes from '../../../config/routes'

export const EsContent = () => (
  <div>
    <h1>Aviso Legal</h1>
    <p>
      El titular de esta App es COCOMU INTERNET SLU (en adelante "BEENFO”) con C.I.F. B16343972 y domicilio social en Avda. Miguel de Cervantes 63, 4ºB 16400
      Tarancón (Cuenca) ESPAÑA. BEENFO se encuentra debidamente registrada en el Registro Mercantil de Cuenca Tomo: 755 Folio: 104 Hoja: CU-11206.
    </p>
    <p>
      El contenido de esta App incluye mensajes publicitarios y está dirigido a mayores de 18 años. Si tienes cualquier duda, por favor escríbenos a{' '}
      <a href="mailto:info@beenfo.com">info@beenfo.com</a>. El acceso y uso del mismo implica la aceptación por parte del usuario de los{' '}
      <Link to={routes.terms} target="_blank">
        Términos y Condiciones de uso de BEENFO
      </Link>
      .
    </p>
  </div>
)
