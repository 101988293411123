import React, { Component } from 'react'
import ComponentStyled from './styled'
import { EditThemeInfo, EditThemeParticipants, EditThemeAdmins } from '../../molecules'
import { withRouter } from 'react-router'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { toast } from 'react-toastify'
import i18n from '../../../assets/i18n'

const STEPS = {
  themeData: 1,
  addParticipants: 2,
  addAdmins: 3
}

class EditThemeForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      step: STEPS.themeData
    }
  }

  onUpdated = async data => {
    const { onSubmit } = this.props
    try {
      await onSubmit(data)
      this.props.onSubmitEdit()
    } catch {
      toast(i18n.generalError, { type: toast.TYPE.ERROR })
    }
  }

  handleOnSubmitStep1 = ({ themeName, themeDesc, readOnly, temporalLimit, limitDate }) => {
    const { organization } = this.props
    const data = {
      name: themeName,
      description: themeDesc,
      organization: _.get(organization, 'id'),
      readOnly: readOnly,
      endDate: temporalLimit && limitDate ? limitDate : null
    }
    this.onUpdated(data)
  }

  handleOnSubmitStep2 = participantsAdded => {
    const { organization } = this.props
    const data = {
      organization: _.get(organization, 'id'),
      participants: _.map(participantsAdded, v => _.get(v, 'id'))
    }
    this.onUpdated(data)
  }

  handleOnSubmitStep3 = adminsAdded => {
    const { organization } = this.props
    const data = {
      organization: _.get(organization, 'id'),
      admins: _.map(adminsAdded, v => _.get(v, 'id'))
    }
    this.onUpdated(data)
  }

  renderSteps() {
    const { step } = this.state
    const { isFetching } = this.props
    switch (step) {
      case STEPS.themeData:
        return (
          <div className="small-wrapper">
            <EditThemeInfo isFetchingForm={isFetching} handleOnSubmit={this.handleOnSubmitStep1} />
          </div>
        )
      case STEPS.addParticipants:
        return <EditThemeParticipants isFetchingForm={isFetching} handleOnSubmit={this.handleOnSubmitStep2} />
      case STEPS.addAdmins:
        return <EditThemeAdmins isFetchingForm={isFetching} handleOnSubmit={this.handleOnSubmitStep3} />
      default:
        return null
    }
  }

  render() {
    const { step } = this.state

    return (
      <ComponentStyled>
        <div className="edit-tabs">
          <span
            className={step === STEPS.themeData ? 'active' : ''}
            onClick={() => {
              this.setState({ step: STEPS.themeData })
            }}
          >
            {i18n.editThemeForm.themeInfo}
          </span>
          <span
            className={step === STEPS.addParticipants ? 'active' : ''}
            onClick={() => {
              this.setState({ step: STEPS.addParticipants })
            }}
          >
            {i18n.editThemeForm.participants}
          </span>
          <span
            className={step === STEPS.addAdmins ? 'active' : ''}
            onClick={() => {
              this.setState({ step: STEPS.addAdmins })
            }}
          >
            {i18n.editThemeForm.admins}
          </span>
        </div>
        {this.renderSteps()}
      </ComponentStyled>
    )
  }
}

EditThemeForm.propTypes = {
  history: PropTypes.object,
  login: PropTypes.func,
  isFetching: PropTypes.bool,
  onClickOtherTheme: PropTypes.func,
  onSubmit: PropTypes.func,
  organization: PropTypes.object,
  onFinishButton: PropTypes.func,
  onSubmitEdit: PropTypes.func
}

export default withRouter(EditThemeForm)
