import * as themesActions from './actions'
import { organizationsOperations } from '../organizations'
import { directsOperations, directsActions } from '../directs'
import { messagesOperations } from '../messages'
import { themesOperations } from '../themes'
import { usersOperations } from '../users'
import _ from 'lodash'
import i18n from '../../assets/i18n'

export const onThemeTapped = theme => async (dispatch, getState, { actions }) => {
  // UPDATE THEME DATA
  const themeTitle = `#${_.get(theme, 'name', '')}`
  dispatch(themesActions.updateItem(theme))
  dispatch(directsActions.updateItem(null))

  // LOAD THEME PARTICIPANTS AND ADMINS
  dispatch(usersOperations.initThemeParticipantsList())
  dispatch(usersOperations.initThemeAdminsList())

  // LOAD THEME MESSAGES LIST
  dispatch(messagesOperations.initThemeMessagesList())
  actions.Theme({ title: themeTitle })
}

export const initList = () => {
  return async dispatch => {
    dispatch(themesActions.updateList([]))
    return dispatch(fetchList())
  }
}

export const cleanTheme = () => async (dispatch, getState, { actions }) => {
  dispatch(themesActions.updateItem(null))
}

export const fetchList = () => async (dispatch, getState, { api, client, setError }) => {
  const { item: organization } = getState().organizations
  const { userInfo } = getState().me
  const organizationId = _.get(organization, 'id')
  const userId = _.get(userInfo, 'id')
  if (_.isNil(organizationId) || _.isNil(userId)) {
    return []
  }

  try {
    dispatch(themesActions.setFetching(true))
    const themesQueryRes = await client.query({
      query: api.queries.GET_ORGANIZATION_THEMES,
      variables: { organizationId, userId },
      fetchPolicy: 'network-only'
    })
    const themes = _.map(_.get(themesQueryRes, 'data.themes.edges', []), v => v.node)
    dispatch(themesActions.updateList(themes))
    return themes
  } catch (e) {
    dispatch(setError(e))
    return []
  } finally {
    dispatch(themesActions.setFetching(false))
  }
}

export const createTheme = data => async (dispatch, getState, { api, setError, toast }) => {
  let isCreated = false

  try {
    dispatch(themesActions.setFetching(true))
    await api.postOrganizationTheme(data)
    dispatch(fetchList())
    dispatch(directsOperations.initList())
    isCreated = true
  } catch (e) {
    toast(i18n.themes.noCreate, { type: toast.TYPE.ERROR })
    dispatch(setError(e))
  } finally {
    dispatch(themesActions.setFetching(false))
  }

  return isCreated
}

export const updateTheme = data => async (dispatch, getState, { api, setError }) => {
  const { item: theme } = getState().themes
  const themeId = _.get(theme, '_id')
  if (_.isNil(themeId)) {
    return
  }

  try {
    dispatch(themesActions.setFetching(true))
    await api.updateOrganizationTheme(themeId, data)
    dispatch(fetchList())
    dispatch(themesActions.updateItem(null))
    dispatch(directsOperations.initList())
  } catch (e) {
    dispatch(setError(e))
  } finally {
    dispatch(themesActions.setFetching(false))
  }
}

export const updateThemeParticipants = participants => async (dispatch, getState, { api, setError }) => {
  const { item: theme } = getState().themes
  const themeId = _.get(theme, '_id')
  if (_.isNil(themeId)) {
    return
  }

  try {
    dispatch(themesActions.setFetching(true))
    const data = { participants: _.map(participants, v => v.id) }
    await api.updateOrganizationThemeParticipants(themeId, data)
    // TODO: LOAD ONLY PARTICIPANTS
    dispatch(messagesOperations.initThemeMessagesList())
    dispatch(themesOperations.initList())
    dispatch(directsOperations.initList())
  } catch (e) {
    dispatch(setError(e))
  } finally {
    dispatch(themesActions.setFetching(false))
  }
}

export const postThemePoll = data => async (dispatch, getState, { api, setError }) => {
  try {
    dispatch(themesActions.setFetching(true))
    await api.postThemePoll(data)
    dispatch(messagesOperations.initThemeMessagesList())
  } catch (e) {
    dispatch(setError(e))
  } finally {
    dispatch(themesActions.setFetching(false))
  }
}

export const postPollVote = data => async (dispatch, getState, { api, setError }) => {
  try {
    dispatch(themesActions.setFetchingVote(true))
    await api.postPollVote(data)
    dispatch(messagesOperations.initThemeMessagesList())
  } catch (e) {
    dispatch(setError(e))
  } finally {
    dispatch(themesActions.setFetchingVote(false))
  }
}

export const getPollByMessageId = (pollData, userInfo) => async (dispatch, getState, { api, client, setError }) => {
  const pollId = _.get(pollData, '_id')
  if (_.isNil(pollId) || _.isNil(userInfo)) {
    return []
  }

  try {
    const pollQueryRes = await client.query({
      query: api.queries.GET_POLL_BY_MESSAGE,
      variables: { messageId: pollId, userId: _.get(userInfo, 'id') },
      fetchPolicy: 'network-only'
    })
    const polls = _.map(_.get(pollQueryRes, 'data.polls.edges', []), v => v.node)
    const poll = _.last(polls)
    const pollAuthor = _.get(pollData, 'author', {})
    _.assign(poll, { pollAuthor })
    dispatch(themesActions.updatePoll(poll))
    return pollQueryRes
  } catch (e) {
    dispatch(setError(e))
    return []
  }
}

export const deleteTheme = theme => async (dispatch, getState, { api, setError }) => {
  const themeIri = _.get(theme, '_id')
  if (_.isNil(themeIri)) {
    return
  }

  try {
    dispatch(themesActions.setFetching(true))
    const data = { theme: themeIri }
    await api.deleteTheme(data)

    // Wait to reload on WS delete event
    // dispatch(organizationsOperations.loadOrganizationData())
  } catch (e) {
    dispatch(themesActions.setFetching(false))
    dispatch(setError(e))
  }
}

export const exitTheme = theme => async (dispatch, getState, { api, setError }) => {
  const themeIri = _.get(theme, '_id')
  if (_.isNil(themeIri)) {
    return
  }

  try {
    dispatch(themesActions.setFetching(true))
    const data = { theme: themeIri }
    await api.exitTheme(data)
    dispatch(organizationsOperations.loadOrganizationData())
  } catch (e) {
    dispatch(themesActions.setFetching(false))
    dispatch(setError(e))
  }
}
