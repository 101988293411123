import styled from 'styled-components'
import colors from '../../../assets/colors'

const ContainerStyled = styled.div`
  && {
    .modal-header,
    .modal-body {
      border: 0;
      padding: 0;
    }

    .users-wrapper {
      padding: 30px;
      max-width: 400px;
      margin: 0 auto;
      > .add-link {
        text-align: center;
        margin-top: 30px;
        font-size: 13px;
        line-height: 15px;
        color: ${colors.yellow};

        span {
          cursor: pointer;
        }
      }

      > .back-link {
        text-align: center;
        margin-top: 30px;
        text-transform: uppercase;
        font-size: 13px;
        line-height: 15px;

        span {
          cursor: pointer;
        }
      }
    }
  }
`

export default ContainerStyled
