import React, { useState, useEffect } from 'react'
import ComponentStyled from './styled'
import { BillingItem, BillingRowItem } from '../../atoms'
import { ModalChangePayment, ModalAccept } from '../../modals'
import i18n from '../../../assets/i18n'
import PropTypes from 'prop-types'
import _ from 'lodash'
import moment from 'moment'
import { formatMoney } from '../../../utils'
import { getBilling, postOrganizationPaymentMethod, putPaymentMethod } from '../../../api'
import { toast } from 'react-toastify'

const SideBarBilling = ({
  organization,
  fetchOrganizationCurrentBillingData,
  fetchOrganizationSubscription,
  fetchOrganizationPaymentsData,
  deleteOrganization
}) => {
  const [billingInfo, setBillingInfo] = useState(null)
  const [subscriptionInfo, setSubscriptionInfo] = useState(null)
  const [payments, setPayments] = useState([])
  const [showPaymentModal, setShowPaymentModal] = useState(false)
  const [showAcceptModal, setShowAcceptModal] = useState(false)
  const [clientSecret, setClientSecret] = useState('')

  useEffect(() => {
    loadOrganzationCurrentBillingData()
    loadOrganizationSubscriptionData()
    loadOrganzationPayments()
  }, [])

  const togglePaymentModal = () => {
    setShowPaymentModal(!showPaymentModal)
  }

  const toggleAcceptModal = () => {
    setShowAcceptModal(!showAcceptModal)
  }

  const loadOrganzationPayments = async () => {
    const payments = await fetchOrganizationPaymentsData(organization)
    if (payments) {
      setPayments(payments)
    }
  }

  const loadOrganzationCurrentBillingData = async () => {
    const billingData = await fetchOrganizationCurrentBillingData(organization)
    if (billingData) {
      setBillingInfo(billingData)
    }
  }

  const loadOrganizationSubscriptionData = async () => {
    const subscription = await fetchOrganizationSubscription(organization)
    if (subscription) {
      setSubscriptionInfo(subscription)
    }
  }

  const formatBillingItem = billingInfo => {
    const amount = _.get(billingInfo, 'amount', '')
    const dateString = _.get(billingInfo, 'periodSubscriptionStart.date')
    const participants = _.get(billingInfo, 'participants', '')
    const date = !_.isNil(dateString) ? moment(dateString).format('ll') : ''
    const description = `${i18n.sideBarBilling.billing} ${participants} ${i18n.sideBarBilling.users}`
    return (
      <div className="section-wrapper">
        <p className="section-title">{i18n.sideBarBilling.next_billing}</p>
        <div className="item-wrapper">
          <BillingItem title={formatMoney(amount)} description={description} moreInfo={date} />
        </div>
      </div>
    )
  }

  const changePaymentMethodHandler = async () => {
    togglePaymentModal()
  }

  const handleSubmitChangePayment = async paymentMethod => {
    try {
      await putPaymentMethod(subscriptionInfo._id, paymentMethod)
    } catch {
      toast(i18n.generalError, { type: toast.TYPE.ERROR })
    } finally {
      togglePaymentModal()
    }
  }

  const onConfirmDeleteOrg = async () => {
    try {
      await deleteOrganization(organization, subscriptionInfo._id)
    } catch {
      toast(i18n.generalError, { type: toast.TYPE.ERROR })
    } finally {
      toggleAcceptModal()
    }
  }

  const renderSubscriptionInfo = subscriptionInfo => {
    if (!subscriptionInfo) return ''

    const paymentMethod = _.get(subscriptionInfo, 'paymentMethod', false)
    const status = _.get(subscriptionInfo, 'status', '')
    const currentPeriodStart = _.get(subscriptionInfo, 'currentPeriodStart', null)
    const currentPeriodStartDate = !_.isNil(currentPeriodStart) ? moment(currentPeriodStart).format('DD/MM/YYYY') : ''
    const currentPeriodEnd = _.get(subscriptionInfo, 'currentPeriodEnd', null)
    const currentPeriodEndDate = !_.isNil(currentPeriodEnd) ? moment(currentPeriodEnd).format('DD/MM/YYYY') : ''

    return (
      <div className="section-wrapper">
        <p className="section-title">{i18n.sideBarBilling.subscription}</p>
        <div className="billing-card">
          <p className="section-subtitle">{i18n.sideBarBilling.status}</p>
          <p className="section-desc">{status}</p>
          <p className="section-subtitle">{i18n.sideBarBilling.startDate}</p>
          <p className="section-desc">{currentPeriodStartDate}</p>
          <p className="section-subtitle">{i18n.sideBarBilling.endDate}</p>
          <p className="section-desc"> {currentPeriodEndDate}</p>
        </div>
        <div className="payment-method">
          {paymentMethod && (
            <>
              <p className="section-subtitle">{i18n.sideBarBilling.paymentMethod}</p>
              <p className="section-desc">{paymentMethod.brand}</p>
              <p className="section-desc">{paymentMethod.pan}</p>
              <p className="section-desc">
                {paymentMethod.expiryYear} / {paymentMethod.expiryMonth}
              </p>
              <p className="change-payment">
                <span onClick={() => changePaymentMethodHandler(organization, subscriptionInfo)}>{i18n.sideBarBilling.changePaymentMethod}</span>
              </p>
            </>
          )}

          <p className="cancel-sub">
            {subscriptionInfo?.cancelAtPeriodEnd ? (
              <span>{i18n.sideBarBilling.deletedOrganization}</span>
            ) : (
              <span className="cancel-sub-clickable" onClick={toggleAcceptModal}>
                {i18n.sideBarBilling.deleteOrganization}
              </span>
            )}
          </p>
        </div>
      </div>
    )
  }

  const getBillingUrl = async paymentId => {
    try {
      const billingRes = await getBilling(paymentId)
      window.open(billingRes?.data, 'Download')
    } catch {
      toast(i18n.generalError, { type: toast.TYPE.ERROR })
    }
  }

  const renderPayments = payments => {
    payments = _.orderBy(payments, ['createdAt.date'], ['desc'])

    const paymentsItems = _.map(payments, (item, key) => {
      const amount = _.get(item, 'amount', '')
      const users = _.get(item, 'participants', '')
      const createdAt = _.get(item, 'createdAt.date', '')

      return (
        <div className="item-wrapper" key={key}>
          <BillingRowItem
            onClickDownload={() => getBillingUrl(item?.id)}
            moreInfo={formatMoney(amount)}
            description={`${users} ${i18n.sideBarBilling.users}`}
            title={moment(createdAt).format('DD/MM/YYYY')}
          />
        </div>
      )
    })

    return (
      <div className="section-wrapper">
        <p className="section-title">{i18n.sideBarBilling.payments}</p>
        {paymentsItems}
      </div>
    )
  }

  const renderBillingInfo = () => {
    const businessName = _.get(organization, 'businessName', '---')
    const document = _.get(organization, 'document', '---')
    const address = _.get(organization, 'address', '---')
    const postalCode = _.get(organization, 'postalCode', '---')
    const province = _.get(organization, 'province', '---')
    const city = _.get(organization, 'city', '---')

    return (
      <div className="section-wrapper">
        <p className="section-title">{i18n.sideBarBilling.billingData}</p>
        <div className="billing-card">
          <p className="section-subtitle">{i18n.sideBarBilling.businessName}</p>
          <p className="section-desc">{businessName}</p>
          <p className="section-subtitle">{i18n.sideBarBilling.document}</p>
          <p className="section-desc">{document}</p>
          <p className="section-subtitle">{i18n.sideBarBilling.address}</p>
          <p className="section-desc">{address}</p>
          <p className="section-subtitle">{i18n.sideBarBilling.postalCode}</p>
          <p className="section-desc">{postalCode}</p>
          <p className="section-subtitle">{i18n.sideBarBilling.province}</p>
          <p className="section-desc">{province}</p>
          <p className="section-subtitle">{i18n.sideBarBilling.city}</p>
          <p className="section-desc">{city}</p>
        </div>
      </div>
    )
  }

  const currentPeriodEnd = moment(_.get(subscriptionInfo, 'currentPeriodEnd', '')).format('DD/MM/YYYY')

  return (
    <ComponentStyled>
      <div className="title-billing-wrapper">
        <p className="title-billing">{i18n.sideBarBilling.billing}</p>
      </div>
      {formatBillingItem(billingInfo)}
      {renderPayments(payments)}
      {renderSubscriptionInfo(subscriptionInfo)}
      {renderBillingInfo(subscriptionInfo)}
      <ModalChangePayment organizationId={organization._id} show={showPaymentModal} onHide={togglePaymentModal} handleSubmit={handleSubmitChangePayment} />
      <ModalAccept
        desc={i18n.sideBarBilling.confirmDelete.replace('{{currentPeriodEnd}}', currentPeriodEnd)}
        onAccept={onConfirmDeleteOrg}
        onReject={toggleAcceptModal}
        show={showAcceptModal}
        onHide={toggleAcceptModal}
      />
    </ComponentStyled>
  )
}

SideBarBilling.propTypes = {
  organization: PropTypes.object.isRequired,
  fetchOrganizationCurrentBillingData: PropTypes.func,
  fetchOrganizationSubscription: PropTypes.func,
  fetchOrganizationPaymentsData: PropTypes.func,
  deleteOrganization: PropTypes.func
}

export default SideBarBilling
