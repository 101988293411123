import styled from 'styled-components'

export const DownloadFile = styled.a`
  display: flex;
  color: inherit;
  font-size: 14px;
  align-items: center;
  &:hover {
    color: inherit;
    text-decoration: underline;
  }
  svg {
    margin: 0 5px 0 0;
  }
`
