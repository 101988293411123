import * as types from './types'
import { LOG_OUT } from '../auth/types'

const initialState = {
  list: [],
  item: null,
  poll: null,
  isFetching: false,
  isFetchingVote: false
}

const reducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case types.THEMES_UPDATE_ITEM:
      return {
        ...state,
        item: action.value
      }
    case types.THEMES_UPDATE_POLL:
      return {
        ...state,
        poll: action.value
      }

    case types.THEMES_UPDATE_LIST:
      return {
        ...state,
        list: action.value
      }

    case types.THEMES_SET_FETCHING:
      return {
        ...state,
        isFetching: action.value
      }

    case types.THEMES_SET_FETCHING_VOTE:
      return {
        ...state,
        isFetchingVote: action.value
      }

    case LOG_OUT:
      return initialState

    default:
      return state
  }
}

export default reducer
