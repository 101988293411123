import React from 'react'
import PropTypes from 'prop-types'
import ContainerStyled from './styled'
import Modal from 'react-bootstrap/Modal'
import { UserRegister } from '../../organism'

const ModalRegister = ({ show, onHide, onLoginTapped, onResetTapped }) => {
  return (
    <Modal show={show} onHide={onHide} size="md" aria-labelledby="contained-modal-title-vcenter" centered>
      <ContainerStyled>
        <Modal.Body>
          <UserRegister onFinish={onHide} onResetTapped={onResetTapped} onLoginTapped={onLoginTapped} />
        </Modal.Body>
      </ContainerStyled>
    </Modal>
  )
}

ModalRegister.propTypes = {
  onHide: PropTypes.func,
  show: PropTypes.bool,
  onLoginTapped: PropTypes.func,
  onResetTapped: PropTypes.func
}

export default ModalRegister
