//import * as wsActions from './actions'
import _ from 'lodash'
import { toast } from 'react-toastify'
import { WS_URL } from '../../config/api'
import { messagesOperations } from '../messages'
import { unreadMessagesOperations } from '../unread-messages'
import { themesOperations } from '../themes'
import { directsOperations } from '../directs'
import { organizationsOperations } from '../organizations'
import { SUBSCRIPTION_STATUS } from 'config/constants'
import i18n from '../../assets/i18n'
import { setAnalyticsWSReceiveEvent } from 'config/analytics'

const IS_DEV = process.env.NODE_ENV === 'development'
const RECONNECT_MS_DEBOUCE = 5000
let ws = null

const reconnectDebounce = _.debounce(dispatch => dispatch(openAuthSocket()), RECONNECT_MS_DEBOUCE)

export const openAuthSocket = () => async (dispatch, getState) => {
  const { token } = getState().auth
  if (_.isNil(token)) {
    return
  }

  ws = new WebSocket(WS_URL + '?Authorization=' + token)

  ws.onopen = () => {
    IS_DEV && console.log('onopen')
  }

  ws.onmessage = e => {
    try {
      const data = JSON.parse(e.data)
      dispatch(onSocketReceiveEvent(data))
    } catch (e) {
      IS_DEV && console.error('onmessage error: ', e)
    }
  }

  ws.onclose = e => {
    IS_DEV && console.log('onclose: ', e)
    reconnectDebounce(dispatch)
  }

  ws.onerror = e => {
    IS_DEV && console.log('onerror e: ', e)
  }
}

export const closeAuthSocket = () => async () => {
  try {
    await ws.close()
    return true
  } catch (e) {
    console.log('closeAuthSocket e: ', e)
    return false
  }
}

export const onSocketReceiveEvent = data => dispatch => {
  IS_DEV && console.log('onSocketReceiveMessage data: ', data)
  // EXAMPLE RESPONSE OBJECT: { objectType: 'chat', objectId: 1, eventType: 'new', data: {} }
  const objectType = _.get(data, 'objectType')
  const eventType = _.get(data, 'eventType')

  // ANALYTICS
  setAnalyticsWSReceiveEvent(data)

  if (objectType === 'message' && eventType === 'create') {
    //NEW MESSAGE
    dispatch(onSocketReceiveNewMessage(data))
  } else if (objectType === 'theme' && eventType === 'create') {
    //NEW THEME
    dispatch(onSocketReceiveNewTheme(data, true))
  } else if (objectType === 'theme' && eventType === 'update') {
    //NEW THEME
    dispatch(onSocketReceiveNewTheme(data))
  } else if (objectType === 'added_to_theme' && eventType === 'update') {
    // USER ADDED TO THEME
    dispatch(onSocketReceiveAddedToTheme(data))
  } else if (objectType === 'theme' && eventType === 'delete') {
    //USER EXIT THEME
    dispatch(onSocketReceiveExitTheme(data))
  } else if (objectType === 'invitation' && eventType === 'create') {
    //NEW ORGANIZATION
    dispatch(onSocketReceiveNewOrganization(data))
  } else if (objectType === 'organization' && eventType === 'delete') {
    //USER EXIT ORGANIZATION
    dispatch(onSocketReceiveExitOrganization(data))
  } else if (objectType === 'organization' && eventType === 'update') {
    dispatch(onSocketReceiveUpdatedOrganization(data))
  }
}

export const onSocketReceiveNewMessage = data => (dispatch, getState) => {
  // LOAD DIRECT MESSAGES IF NEEDED
  const messageId = _.get(data, 'objectId')
  const messageIri = messageId ? `/api/messages/${messageId}` : null
  if (messageIri) {
    const { item: currentDirect } = getState().directs
    const currentDirectId = _.get(currentDirect, '_id', -1)
    const directId = _.get(data, 'data.direct')

    const { item: currentTheme } = getState().themes
    const currentThemeId = _.get(currentTheme, '_id', -1)
    const themeId = _.get(data, 'data.theme')

    const { item: currentOrganization } = getState().organizations
    const currentOrganizationId = _.get(currentOrganization, '_id', -1)
    const organizationId = _.get(data, 'data.organization')

    if (currentOrganizationId === organizationId) {
      if (directId === currentDirectId) {
        // IF DIRECT IS OPEN LOAD MESSAGE AND SET READED
        dispatch(messagesOperations.fetchMessage(messageIri))
        dispatch(unreadMessagesOperations.postNotifyDirect(currentDirect))
      } else if (themeId === currentThemeId && data?.data?.broadcast) {
        // IF THEME IS OPEN LOAD MESSAGE AND SET READED
        dispatch(messagesOperations.fetchMessage(messageIri))
        // TODO: POST THEME NOTIFY WHEN EP IMPLEMENTED
      } else if (directId) {
        // LOAD UNREAD MESSAGES
        const direct = {
          _id: directId,
          id: `/api/directs/${directId}`,
          author: { id: `/api/users/${_.get(data, 'data.directAuthor')}` },
          authorRead: _.get(data, 'data.directAuthorRead', null),
          adminRead: _.get(data, 'data.directAdminRead', null)
        }
        dispatch(unreadMessagesOperations.fetchItem(direct))
      }
    }
  }
}

export const onSocketReceiveNewTheme = data => async (dispatch, getState) => {
  const { item: currentOrganization } = getState().organizations
  const currentOrganizationId = _.get(currentOrganization, '_id', -1)
  const organizationId = _.get(data, 'data.organization')

  if (currentOrganizationId === organizationId) {
    // LOAD DIRECTS
    dispatch(directsOperations.initList())

    // LOAD THEMES
    dispatch(themesOperations.fetchList())
  }
}

export const onSocketReceiveAddedToTheme = data => async (dispatch, getState, { client, api }) => {
  const { item: currentOrganization } = getState().organizations
  const currentOrganizationId = _.get(currentOrganization, '_id', -1)
  const organizationId = _.get(data, 'data.organization')

  if (currentOrganizationId === organizationId) {
    // LOAD THEME
    const themeIri = parseInt(_.get(data, 'objectId', -1))
    const themeRes = await client.query({
      query: api.queries.GET_THEME,
      variables: { themeId: '/api/themes/' + themeIri }
    })
    const theme = themeRes?.data?.theme

    // SHOW ALERT
    if (theme) {
      const succesText = i18n.themes.added.replace('{{theme}}', _.get(theme, 'name', ''))
      toast(succesText, { type: toast.TYPE.SUCCESS })
    }
  }
}

export const onSocketReceiveNewOrganization = data => async dispatch => {
  // LOAD ORGANIZATIONS
  const organizations = await dispatch(organizationsOperations.initList())

  // CHECK ORG ID IN LIST
  const organizationId = _.get(data, 'data.organization')
  const organization = _.find(organizations, { _id: organizationId })

  // SHOW ALERT
  if (organization) {
    toast(`${i18n.organizations.invitation} ${_.get(organization, 'name', '')}`, { type: toast.TYPE.SUCCESS })
  }
}

export const onSocketReceiveUpdatedOrganization = data => async dispatch => {
  const organizationId = _.get(data, 'data.organization')
  const newStatus = _.get(data, 'data.subscriptionStatus')
  const organizations = await dispatch(organizationsOperations.initList())
  const organization = _.find(organizations, { _id: organizationId })
  const currentStatus = _.get(organization, 'data.subscriptionStatus')

  const isActivated = newStatus === SUBSCRIPTION_STATUS.ACTIVE && currentStatus !== SUBSCRIPTION_STATUS.ACTIVE
  if (isActivated && organization) {
    const succesText = i18n.modalOrganizationInProgress.updateSuccess.replace('{{organization}}', _.get(organization, 'name', ''))
    toast(succesText, { type: toast.TYPE.SUCCESS })
  }

  if (organization) {
    if (newStatus === SUBSCRIPTION_STATUS.ACTIVE && currentStatus !== SUBSCRIPTION_STATUS.ACTIVE) {
      const succesText = i18n.organizations.subscriptionActiveBody.replace('{{organization}}', _.get(organization, 'name', ''))
      toast(succesText, { type: toast.TYPE.SUCCESS })
    } else if (newStatus && newStatus !== SUBSCRIPTION_STATUS.ACTIVE && newStatus !== SUBSCRIPTION_STATUS.PENDING) {
      const succesText = i18n.organizations.subscriptionExpiredBody.replace('{{organization}}', _.get(organization, 'name', ''))
      toast(succesText, { type: toast.TYPE.SUCCESS })
    }
  }
}

export const onSocketReceiveExitOrganization = data => async (dispatch, getState) => {
  const { item: selectedOrganization, list: previousOrganizations } = getState().organizations
  const { userInfo } = getState().me

  // CHECK ORG ID IN LIST
  const organizationId = _.parseInt(_.get(data, 'objectId', -1))
  const organization = _.find(previousOrganizations, { _id: organizationId })

  // SHOW ALERT
  const userName = _.get(data, 'data.removedUser', -1)
  if (organization && userName == userInfo?.phoneNumber) {
    const succesText = i18n.organizations.deletedBody.replace('{{organization}}', _.get(organization, 'name', ''))
    toast(succesText, { type: toast.TYPE.SUCCESS })
  }

  // LOAD ORGANIZATIONS
  const organizations = await dispatch(organizationsOperations.initList())
  const selectedOrganizationId = _.parseInt(_.get(selectedOrganization, '_id', -2))

  if (organizationId == selectedOrganizationId) {
    if (userName === userInfo?.phoneNumber) {
      dispatch(organizationsOperations.updateSelectedOrganization(previousOrganizations[0]))
    }

    if (!organizations.length) {
      dispatch(organizationsOperations.resetOrganizationData())
    }
  }
}

export const onSocketReceiveExitTheme = data => async (dispatch, getState) => {
  const { item: selectedOrganization } = getState().organizations
  const { item: selectedTheme, list: themesList } = getState().themes
  const { userInfo } = getState().me

  const organizationId = _.parseInt(_.get(data, 'data.organization', -1))
  const selectedOrganizationId = _.parseInt(_.get(selectedOrganization, '_id', -2))
  const removedUser = _.get(data, 'data.removedUser')

  if (organizationId === selectedOrganizationId) {
    const themeId = _.parseInt(_.get(data, 'objectId', -1))
    const themeName = themesList?.find(v => v._id === themeId)?.name || ''

    dispatch(organizationsOperations.loadOrganizationData())

    if (!removedUser) {
      const exitText = i18n.themes.deletedThemeBody.replace('{{theme}}', themeName)
      toast(exitText, { type: toast.TYPE.ERROR })
    } else if (removedUser === userInfo?.phoneNumber) {
      const exitText = i18n.themes.deletedUserBody.replace('{{theme}}', themeName)
      toast(exitText, { type: toast.TYPE.ERROR })
    }

    if (selectedTheme?._id === themeId) {
      dispatch(themesOperations.cleanTheme())
      dispatch(directsOperations.cleanDirect())
    }
  }
}
