import React from 'react'
import ComponentStyled from './styled'
import PropTypes from 'prop-types'
import { ReactComponent as ArroIcon } from '../../../assets/images/simple-right-arrow.svg'
import i18n from '../../../assets/i18n'
import _ from 'lodash'

const BillingResume = ({ userInfo }) => {
  const billingData = _.get(userInfo, 'billingData', null)

  const name = _.get(userInfo, 'name', '')
  const surname = _.get(userInfo, 'surname', '')
  const address = _.get(billingData, 'address', '')
  const province = _.get(billingData, 'province', '')
  const city = _.get(billingData, 'city', '')
  const iban = _.get(billingData, 'iban', '')

  return (
    <div>
      <p className="name">
        {name} {surname}
      </p>
      <p className="address">
        {address}, {province} {city}
      </p>
      <p className="account">{iban}</p>
    </div>
  )
}
BillingResume.propTypes = {
  userInfo: PropTypes.object
}

const ComercialBillingItem = ({ title, userInfo, onClick }) => {
  const billingData = _.get(userInfo, 'billingData', null)

  return (
    <ComponentStyled hasClick={onClick ? true : false}>
      <p className="billing-title">{title}</p>

      <div className="billing-wrapper" onClick={onClick}>
        {billingData ? (
          <BillingResume userInfo={userInfo} />
        ) : (
          <div>
            <p className="name">{i18n.comercialBillingItem.registerBillingdata}</p>
          </div>
        )}
        {onClick && <ArroIcon />}
      </div>
    </ComponentStyled>
  )
}

ComercialBillingItem.propTypes = {
  title: PropTypes.string,
  userInfo: PropTypes.object,
  onClick: PropTypes.func
}

export default ComercialBillingItem
