import React from 'react'
import PropTypes from 'prop-types'
import i18n from '../../../assets/i18n'
import { RegularInput, Spinner } from '../../atoms'

import { FooterButton, SectionFooter, Container, ErrorContainer, CardElementStyled, SpinnerContainer } from './styled'

const View = ({ handleSubmit, values, messages, submitButtonLabel, handleChange, errors, touched, isLoading, clientSecret }) => {
  return (
    <Container isLoading>
      {clientSecret ? (
        <form onSubmit={handleSubmit}>
          <RegularInput
            label={'Nombre'}
            value={values.name}
            handleOnChange={handleChange}
            name="name"
            message={touched.name && errors.name}
            hasError={!!(errors.name && touched.name)}
          />
          <CardElementStyled
            className="card-element"
            options={{
              style: {
                base: {
                  fontSize: '16px'
                }
              }
            }}
          />

          <ErrorContainer>{messages}</ErrorContainer>
          <SectionFooter>
            <FooterButton type="submit" label={`${submitButtonLabel || i18n.paymentMethodForm.submit}`} isFetching={isLoading} />
          </SectionFooter>
        </form>
      ) : (
        <Spinner />
      )}
    </Container>
  )
}

View.propTypes = {
  handleSubmit: PropTypes.func,
  messages: PropTypes.string,
  submitButtonLabel: PropTypes.string,
  isLoading: PropTypes.bool,
  values: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired,
  errors: PropTypes.object,
  touched: PropTypes.object,
  clientSecret: PropTypes.string
}

View.defaultProps = {
  handleSubmit: () => null,
  messages: null,
  submitButtonLabel: null,
  isLoading: false,
  errors: {},
  touched: {},
  clientSecret: null
}

export default View
