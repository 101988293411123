import React, { Component } from 'react'
import FilterAnswersBarStyled from './styled'
import { RegularInput, Button } from '../../atoms'
import i18n from '../../../assets/i18n'
import PropTypes from 'prop-types'
import { ModalEditTheme } from '../../modals'

class FilterAnswersBar extends Component {
  constructor(props) {
    super(props)
    this.state = {
      showBroadcastOnly: false,
      openEditModal: false
    }
  }

  onChangeOnlyAnswers = e => {
    const showBroadcastOnly = e.target.checked
    this.setState({ showBroadcastOnly }, () => {
      this.props.onChangeBroadcastOnly(showBroadcastOnly)
      this.props.initThemeMessagesList(showBroadcastOnly)
    })
  }

  toggleModalEdit = () => {
    const { openEditModal } = this.state
    this.setState({ openEditModal: !openEditModal })
  }

  render() {
    return (
      <FilterAnswersBarStyled>
        <div className="check-wrapper-filter">
          <RegularInput
            hasMarginBottom={false}
            checked={this.state.showBroadcastOnly}
            type="checkbox"
            variant="checkbox"
            handleOnChange={this.onChangeOnlyAnswers}
          />
          <span className="see-answers">{i18n.filterAnswersBar.seeOnlyAnswers}</span>
        </div>
        {this.props.edit && <Button variant="white-small" label={i18n.filterAnswersBar.editTheme} handleOnClick={this.toggleModalEdit} />}
        <ModalEditTheme onSubmitEdit={this.toggleModalEdit} show={this.state.openEditModal} onHide={this.toggleModalEdit} />
      </FilterAnswersBarStyled>
    )
  }
}

FilterAnswersBar.propTypes = {
  onChangeBroadcastOnly: PropTypes.func,
  participants: PropTypes.array,
  initThemeMessagesList: PropTypes.func,
  updateThemeParticipants: PropTypes.func,
  edit: PropTypes.bool
}

export default FilterAnswersBar
