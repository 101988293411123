import styled from 'styled-components'
import colors from '../../../assets/colors'

const UserLoginStyled = styled.div`
  && {
    display: flex;
    align-items: center;
    width: 100%;

    .login-wrapper {
      background-color: ${colors.white};
      box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.3);
      padding-bottom: 20px;
      width: 100%;
      max-width: 600px;
    }

    .circle-header {
      margin-bottom: 50px;
    }

    .forgot-pass {
      text-align: center;
      font-size: 14px;
      letter-spacing: -0.43px;
      color: ${colors.darkGrey2};
      margin-top: 30px;
      font-weight: 600;
      margin-bottom: 0;
      color: ${colors.yellow};

      span {
        cursor: pointer;
      }
    }

    .no-account {
      text-align: center;
      font-size: 14px;
      letter-spacing: -0.43px;
      color: ${colors.darkGrey2};
      margin-top: 30px;
      font-weight: 600;
      margin-bottom: 0;

      span,
      a {
        color: ${colors.yellow};
      }
    }

    .pointer {
      cursor: pointer;
    }
  }
`

export default UserLoginStyled
