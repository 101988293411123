import { connect } from 'react-redux'
import compose from 'recompose/compose'
import View from './view'
import { withRouter } from 'react-router'
import { directsActions } from '../../../redux/directs'
import { themesActions } from '../../../redux/themes'
import { messagesOperations } from '../../../redux/messages'

const mapStateToProps = state => {
  return {
    themes: state.themes.list,
    isFetching: state.themes.isFetching,
    themesFetching: state.themes.isFetching,
    organization: state.organizations.item
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onThemeTapped: theme => {
      dispatch(themesActions.updateItem(theme))
      dispatch(directsActions.updateItem(null))
      dispatch(messagesOperations.initThemeMessagesList())
    }
  }
}

const enhance = compose(connect(mapStateToProps, mapDispatchToProps))

export default withRouter(enhance(View))
