import React from 'react'
import * as S from './styled'
import PropTypes from 'prop-types'

const InfoAnswersBar = ({ text }) => <S.Container>{text}</S.Container>

InfoAnswersBar.propTypes = {
  text: PropTypes.string
}

export default InfoAnswersBar
