import styled, { css } from 'styled-components'
import colors from '../../../assets/colors'

const ComponentStyled = styled.div`
  display: flex;
  margin-bottom: 30px;

  textarea {
    width: 100%;
    outline: none;
    border: 0;
    border-bottom: 1px solid ${colors.darkGreyAlpha50};
    background-color: transparent;
    padding-bottom: 10px;
    font-size: 17px;
    line-height: 24px;
    letter-spacing: -0.53px;
    color: ${colors.darkGrey};
    resize: none;

    ::placeholder {
      color: ${colors.darkGreyAlpha50};
    }
  }

  ${props =>
    props.variant === 'filled' &&
    css`
      textarea {
        border: 0;
        background-color: ${colors.white};
        box-shadow: 0px 2px 11px rgba(0, 0, 0, 0.06);
        border-radius: 27px;
        min-width: 215px;
        max-width: 100%;
        padding: 10px 50px;
        font-size: 16px;
        line-height: 24px;
        color: ${colors.darkGrey4};
      }
    `};

  ${props =>
    props.hasError &&
    css`
      textarea {
        border-color: ${colors.red};
      }
    `};

  ${props =>
    props.hasMarginBottom === false &&
    css`
      margin-bottom: 0;
    `};
`

export default ComponentStyled
