import styled from 'styled-components'
import colors from '../../../assets/colors'

const DirectChatListMoreDetails = styled.div`
  && {
    padding: 15px 0;

    .direct-user-wrapper {
      padding: 10px 0;
      display: flex;
      align-items: center;
      transition: 0.5s ease all;
      justify-content: space-between;
    }

    .dots-wrapper {
      position: relative;
      cursor: pointer;
      span {
        font-size: 41px;
        line-height: 0;
        text-align: center;
        letter-spacing: -0.65px;
        color: ${colors.yellow};
      }

      .menu-popup {
        background-color: ${colors.white};
        box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.14);
        border-radius: 5px;
        border: 0;
        min-width: 200px;
        padding: 0;
      }
    }

    .direct-title-list {
      font-size: 14px;
      font-weight: 600;
      line-height: 16px;
      letter-spacing: 1.08025px;
      text-transform: uppercase;
      color: ${colors.black};
    }
  }
`

export default DirectChatListMoreDetails
