import { connect } from 'react-redux'
import compose from 'recompose/compose'
import { authOperations } from '../../../redux/auth'
import View from './view'

const mapStateToProps = state => {
  return {
    isFetching: state.auth.isFetching
  }
}

const mapDispatchToProps = dispatch => {
  return {
    resetPassword: data => dispatch(authOperations.resetPassword(data)),
    initializeApp: () => dispatch(authOperations.initializeApp())
  }
}

const enhance = compose(connect(mapStateToProps, mapDispatchToProps))

export default enhance(View)
