import React from 'react'
import * as S from './styled'
import PropTypes from 'prop-types'
import { ReactComponent as InfoIcon } from '../../../assets/images/info.svg'

const ChatBarName = ({ name, handleOpenMembers, showInfo, label }) => {
  return (
    <S.Container>
      <p>
        {name} {label ? <S.Label>{label}</S.Label> : null}
      </p>
      {showInfo && <InfoIcon onClick={handleOpenMembers} className="info-btn" />}
    </S.Container>
  )
}

ChatBarName.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  handleOpenMembers: PropTypes.func,
  showInfo: PropTypes.bool
}

export default ChatBarName
