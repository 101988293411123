import styled, { css } from 'styled-components'
import Button from '../../atoms/button'
import colors from '../../../assets/colors'
import { CardElement, CardNumberElement, CardExpiryElement, CardCvcElement } from '@stripe/react-stripe-js'

export const paymentInput = css`
  width: 100%;
  outline: none;
  border: 0;
  border-bottom: 1px solid ${colors.darkGreyAlpha50};
  background-color: transparent;
  padding-bottom: 10px;
  font-size: 17px;
  line-height: 24px;
  letter-spacing: -0.53px;
  color: ${colors.darkGrey};

  &[type='number']::-webkit-inner-spin-button,
  &[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  ::placeholder {
    color: ${colors.darkGreyAlpha50};
  }
`

export const Container = styled.div`
  && {
    margin-top: 70px;
  }
`

export const CardElementStyled = styled(CardElement)`
  ${paymentInput}
`
export const CardNumberElementStyled = styled(CardNumberElement)`
  ${paymentInput}
  margin-bottom: 20px;
`

export const CardExpiryElementStyled = styled(CardExpiryElement)`
  ${paymentInput}
  margin-bottom: 20px;
`

export const CardCvcElementStyled = styled(CardCvcElement)`
  ${paymentInput}
`

export const ErrorContainer = styled.p`
  margin-top: 20px;
  font-size: 14px;
  color: ${colors.red};
`

export const FooterButton = styled(Button)`
  margin: 12px;
`

export const SectionFooter = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 40px;
`
