import React from 'react'
import NoReadMsgListStyled from './styled'
import { NoReadMsgItem } from '../../atoms'
import i18n from '../../../assets/i18n'
import _ from 'lodash'
import PropTypes from 'prop-types'
import moment from 'moment'

const NoReadMsgList = ({ onClickUnreadMessage, unreadMessages, userInfo }) => {
  if (_.size(unreadMessages) === 0) return null

  const newList = _.map(unreadMessages, (item, key) => {
    const lastMessageDate = item?.lastMessageDate
    const isToday = moment().isSame(item?.lastMessageDate, 'day')
    const time = isToday ? moment(item?.lastMessageDate).format('HH:mm') : moment(item?.lastMessageDate).format('DD/MM/YY')
    const themeName = _.get(item, 'direct.theme.name', '')
    const authorId = _.get(item, 'direct.author._id', 1)
    const myId = _.get(userInfo, '_id', 2)
    const isAuthor = authorId === myId

    const hour = !isAuthor && lastMessageDate ? time : ''
    const title = isAuthor ? themeName : _.get(item, 'direct.author.fullname', '')
    const subtitle = isAuthor ? '' : `#${themeName}`

    return (
      <div
        key={key}
        className="no-read-msg-wrapper"
        onClick={() => {
          onClickUnreadMessage(item, 'unreadMessages')
        }}
      >
        <NoReadMsgItem title={title} subtitle={subtitle} hour={hour} totalMessages={item.unreadMessages} />
      </div>
    )
  })

  return (
    <NoReadMsgListStyled>
      <p className="no-read-title-list">{i18n.noReadMsgList.title}</p>
      {newList}
    </NoReadMsgListStyled>
  )
}

NoReadMsgList.propTypes = {
  onClickUnreadMessage: PropTypes.func,
  unreadMessages: PropTypes.array,
  userInfo: PropTypes.object
}

export default NoReadMsgList
