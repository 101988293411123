import styled from 'styled-components'
import colors from '../../../assets/colors'

const NoReadMsgItemStyled = styled.div`
  && {
    padding: 10px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    transition: 0.5s ease all;
    cursor: pointer;

    > .right-wrapper {
      text-align: right;
    }

    .title-no-read {
      font-size: 16px;
      line-height: 24px;
      color: ${colors.darkGrey3};
      margin-bottom: 0;
    }

    .msg-no-read,
    .hour-no-read {
      font-size: 16px;
      line-height: 24px;
      color: ${colors.darkGrey3Alpha50};
      margin-bottom: 0;
    }

    .hour-no-read {
      margin-bottom: 5px;
    }

    .total-no-read {
      display: inline-block;
      min-height: 23px;
      width: 23px;
      max-width: 23px;
      background-color: ${colors.yellow};
      text-align: center;
      border-radius: 50px;
      position: relative;
      span {
        font-size: 12px;
        line-height: 12px;
        color: #ffffff;
        position: relative;
        top: -2px;
      }
    }
  }
`

export default NoReadMsgItemStyled
