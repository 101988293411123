import styled, { css } from 'styled-components'
import colors from '../../../assets/colors'

const RegularSelectStyled = styled.div`
  && {
    p {
      font-size: 13px;
      line-height: 15px;
      letter-spacing: 1px;
      text-transform: uppercase;
      color: ${colors.darkGrey};
      font-weight: 600;
      margin-bottom: 5px;
    }

    .select-wrapper {
      position: relative;
      margin-bottom: 30px;

      img,
      svg {
        position: absolute;
        top: 50%;
        right: 0;
        transform: translateY(-50%);
        pointer-events: none;
      }
    }

    select {
      appearance: none;
      width: 100%;
      outline: none;
      border: 0;
      border-bottom: 1px solid ${colors.darkGreyAlpha50};
      background-color: transparent;
      padding-bottom: 10px;
      padding-left: 0;
      font-size: 17px;
      line-height: 24px;
      letter-spacing: -0.53px;
      color: ${colors.darkGrey};
      border-radius: 0;

      ::placeholder {
        color: ${colors.darkGreyAlpha50};
      }
    }

    ${props =>
      props.variant === 'filled' &&
      css`
        select {
          border: 0;
          background-color: ${colors.white};
          box-shadow: 0px 2px 11px rgba(0, 0, 0, 0.06);
          border-radius: 27px;
          min-width: 215px;
          max-width: 100%;
          padding: 5px 25px;
          font-size: 16px;
          line-height: 24px;
          color: ${colors.darkGrey4};
        }

        .select-wrapper {
          position: relative;

          img,
          svg {
            right: 20px;
          }
        }
      `}
    
      ${props =>
        props.variant === 'filled-button' &&
        css`
          select {
            border: 0;
            background-color: ${colors.white};
            box-shadow: 0px 2px 11px rgba(0, 0, 0, 0.06);
            border-radius: 27px;
            min-width: 215px;
            max-width: 100%;
            padding: 15px 25px;
            font-size: 16px;
            line-height: 12px;
            font-weight: 600;
            line-height: 24px;
            color: ${colors.darkGrey4};
          }

          .select-wrapper {
            position: relative;

            img,
            svg {
              right: 20px;
            }
          }
        `}
      
        ${props =>
          props.variant === 'filled-button-yellow' &&
          css`
            select {
              border: 0;
              background-color: ${colors.yellow};
              box-shadow: 0px 2px 11px rgba(0, 0, 0, 0.06);
              border-radius: 27px;
              min-width: 215px;
              max-width: 100%;
              padding: 15px 25px;
              font-size: 16px;
              line-height: 12px;
              font-weight: 600;
              line-height: 24px;
              color: ${colors.darkGrey4};
            }

            .select-wrapper {
              position: relative;

              img,
              svg {
                right: 20px;
              }
            }
          `}

    ${props =>
      props.hasError &&
      css`
        select {
          border-color: ${colors.red};
        }
      `}

    ${props =>
      props.hasMarginBottom === false &&
      css`
        .select-wrapper {
          margin-bottom: 0;
        }
      `}
  }

  
`

export default RegularSelectStyled
