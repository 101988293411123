import React from 'react'
import PropTypes from 'prop-types'
import ContainerStyled from './styled'
import Modal from 'react-bootstrap/Modal'
import { CircleHeader, Button } from '../../atoms'
import i18n from '../../../assets/i18n'

const ModalAccept = ({ show, onHide, isFetching, desc, onAccept, onReject, children, acceptButtonText, rejectButonText }) => {
  return (
    <Modal show={show} onHide={onHide} size="md" aria-labelledby="contained-modal-title-vcenter" centered>
      <ContainerStyled>
        <Modal.Body>
          <CircleHeader />
          <div className="info-wrapper">
            <p className="desc">{desc}</p>
            {children}
            <Button isFetching={isFetching} handleOnClick={onAccept} label={acceptButtonText || i18n.modalAccept.accept} />
            {onReject && (
              <p className="back">
                <span onClick={onReject}>{rejectButonText || i18n.modalAccept.back}</span>
              </p>
            )}
          </div>
        </Modal.Body>
      </ContainerStyled>
    </Modal>
  )
}

ModalAccept.propTypes = {
  isFetching: PropTypes.bool,
  onHide: PropTypes.func,
  desc: PropTypes.string,
  show: PropTypes.bool,
  onAccept: PropTypes.func,
  onReject: PropTypes.func,
  children: PropTypes.node,
  acceptButtonText: PropTypes.string,
  rejectButonText: PropTypes.string
}

export default ModalAccept
