import { connect } from 'react-redux'
import compose from 'recompose/compose'
import { authOperations } from '../../../redux/auth'
import View from './view'
import { withRouter } from 'react-router'

const mapStateToProps = state => {
  return {
    isFetching: state.auth.isFetching
  }
}

const mapDispatchToProps = (dispatch, props) => {
  return {
    logout: () => dispatch(authOperations.logout(props.history))
  }
}

const enhance = compose(connect(mapStateToProps, mapDispatchToProps))

export default withRouter(enhance(View))
