import React, { useState } from 'react'
import { Container, Row, Col, Dropdown } from 'react-bootstrap'
import FooterStyled from './styled'
import { Link, withRouter } from 'react-router-dom'
import routes from '../../../config/routes'
import i18n from '../../../assets/i18n'
import _ from 'lodash'
import PropTypes from 'prop-types'

const Footer = ({ history }) => {
  const [openLangs, setOpenLangs] = useState(false)
  const actualLangCode = i18n.getLanguage()

  const availableLangs = [
    {
      code: 'es',
      label: i18n.footer.spanish
    }
  ]

  function toggleLangs() {
    setOpenLangs(!openLangs)
  }

  function onClickLang(langCode) {
    i18n.setLanguage(langCode)
    toggleLangs()
    history.push(routes.home)
  }

  const actualLang = _.remove(availableLangs, { code: actualLangCode })

  const formatMenu = _.map(availableLangs, (lang, key) => {
    return (
      <li
        key={key}
        onClick={() => {
          onClickLang(lang.code)
        }}
      >
        {lang.label}
      </li>
    )
  })

  return (
    <FooterStyled>
      <Container>
        <div className="box-wrapper">
          <Row className="align-items-center">
            <Col xs={12} sm={8}>
              <Link to={routes.privacyPolicy}>{i18n.footer.privacyPolicy}</Link>
            </Col>
            <Col xs={12} sm={4} className="lang-wrapper">
              <Dropdown show={openLangs} onToggle={toggleLangs}>
                <Dropdown.Toggle as="span" className="toggle-lang">
                  {_.get(actualLang, '[0].label')} <img src={require('../../../assets/images/yellow-arrow.png')} alt="Arrow" />
                </Dropdown.Toggle>
                <Dropdown.Menu className="lang-menu">{formatMenu}</Dropdown.Menu>
              </Dropdown>
            </Col>
          </Row>
        </div>
      </Container>
    </FooterStyled>
  )
}

Footer.propTypes = {
  history: PropTypes.object
}

export default withRouter(Footer)
