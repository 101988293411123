import ApolloClient, { InMemoryCache } from 'apollo-boost'
import { GRAPH_QL_BASE_URL } from './api'

const request = async operation => {
  const token = localStorage.getItem('token')
  operation.setContext({
    headers: {
      Authorization: token ? `Bearer ${token}` : ''
    }
  })
}

const cache = new InMemoryCache()
const client = new ApolloClient({
  request,
  uri: GRAPH_QL_BASE_URL,
  cache,
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'network-only'
    },
    query: {
      fetchPolicy: 'network-only'
    }
  }
})

// SET DATA ON CACHE RESET
const data = {}
client.onResetStore(() => cache.writeData({ data }))

export default client
