import styled from 'styled-components'
import colors from '../../../assets/colors'

const Dashboard = styled.div`
  && {
    height: 100vh;
    display: flex;
    flex-flow: column;

    .header-dashboard-wrapper {
      width: 100%;
    }

    .dashboard-wrapper {
      display: flex;
      flex: 1;
      height: 100%;
      overflow: hidden;
    }

    .dashboard-sidebar-right {
      width: 0;
      min-width: 0;
      background-color: ${colors.white};
      transition: 0.5s ease all;
      flex: 0;
      overflow: scroll;
      -ms-overflow-style: none;
      scrollbar-width: none;

      ::-webkit-scrollbar {
        display: none;
      }

      &.show {
        width: 400px;
        max-width: 100%;
        min-width: 400px;
        flex: 1;
      }

      .sidebar-right-wrapper {
        width: 400px;
        max-width: 400px;
        padding: 25px 55px;
        position: relative;
        min-height: 100%;

        .close-sidebar {
          cursor: pointer;
          position: absolute;
          top: 20px;
          right: 20px;
        }
      }
    }
  }
`

export default Dashboard
