import styled from 'styled-components'
import colors from '../../../assets/colors'

const ComponentStyled = styled.div`
  && {
    .small-wrapper {
      max-width: 400px;
      margin: 0 auto;
    }

    .edit-tabs {
      margin-bottom: 30px;
      span {
        font-size: 13px;
        display: inline-block;
        line-height: 15px;
        letter-spacing: 1px;
        text-transform: uppercase;
        color: ${colors.darkGrey};
        font-weight: 600;
        margin: 5px 10px;
        cursor: pointer;

        &.active {
          border-bottom: 1px solid ${colors.darkGrey};
        }
      }
    }
  }
`

export default ComponentStyled
