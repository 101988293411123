import styled from 'styled-components'
import colors from '../../../assets/colors'

const ThemeListStyled = styled.div`
  && {
    padding: 15px 0;

    .themes-title-list {
      font-size: 14px;
      font-weight: 600;
      line-height: 16px;
      letter-spacing: 1.08px;
      text-transform: uppercase;
      color: ${colors.black};
    }

    .theme-actions {
      ::after {
        content: none;
      }
    }

    .theme-list-wrapper {
      border-bottom: 1px solid ${colors.grey};
      cursor: pointer;
      position: relative;
      display: flex;
      justify-content: space-between;
      align-items: center;

      > .themes-list {
        position: relative;
        z-index: 1;
        flex: 1;
      }

      ::before {
        content: '';
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        width: 1000px;
        height: 100%;
        background-color: ${colors.grey};
        opacity: 0;
        transition: 0.5s ease all;
      }

      &:hover:before,
      &.active:before {
        opacity: 1;
      }

      :last-child {
        border: 0;
      }

      .dots-wrapper {
        position: relative;
        cursor: pointer;

        .menu-popup {
          background-color: ${colors.white};
          box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.14);
          border-radius: 5px;
          border: 0;
          min-width: 200px;
          padding: 0;
        }

        span {
          font-size: 41px;
          line-height: 0;
          text-align: center;
          letter-spacing: -0.65px;
          color: ${colors.yellow};
        }
      }
    }
  }
`

export default ThemeListStyled
