import { connect } from 'react-redux'
import compose from 'recompose/compose'
import View from './view'
import { withRouter } from 'react-router-dom'

const mapStateToProps = state => {
  return {
    theme: state.themes.item,
    organization: state.organizations.item
  }
}

const mapDispatchToProps = dispatch => {
  return {}
}

const enhance = compose(connect(mapStateToProps, mapDispatchToProps), withRouter)

export default enhance(View)
