import * as types from './types'

export const setFetching = value => ({
  type: types.MESSAGES_SET_FETCHING,
  value
})

export const updateList = (list, total = 0, pageInfo) => ({
  type: types.MESSAGES_UPDATE_LIST,
  list,
  total,
  pageInfo
})

export const updateItem = value => ({
  type: types.MESSAGES_UPDATE_ITEM,
  value
})

export const postSagaMessage = (message, userInfo) => ({
  type: types.MESSAGES_POST_SAGA_MESSAGE,
  payload: { data: message, userInfo }
})
