import styled from 'styled-components'
import colors from '../../../assets/colors'

export const Container = styled.div`
  && {
    padding: 10px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: ${colors.darkGrey};
    min-height: 65px;
    padding: 0 45px;
    p {
      font-size: 20px;
      line-height: 24px;
      letter-spacing: -0.62px;
      color: ${colors.white};
      margin-bottom: 0;
    }

    .info-btn {
      cursor: pointer;
    }
  }
`

export const Label = styled.span`
  margin-left: 10px;
  color: ${({ theme }) => theme.colors.darkGrey5};
`
