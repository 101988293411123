import styled from 'styled-components'

const ComponentStyled = styled.div`
  && {
    .modal-header,
    .modal-body {
      border: 0;
      padding: 0;
    }
  }
`

export default ComponentStyled
