import styled from 'styled-components'
import breakpoints from '../../../assets/breakpoints'

export const Container = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
`

export const Wrapper = styled.div`
  padding: 0px 20px;
  width: 100%;
  max-width: ${breakpoints.md}px;
`
