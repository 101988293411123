import React, { useState } from 'react'
import PropTypes from 'prop-types'
import DatePicker, { registerLocale } from 'react-datepicker'
import { Button } from 'components/atoms'
import 'react-datepicker/dist/react-datepicker.css'
import es from 'date-fns/locale/es'
import moment from 'moment'
import * as S from './styled'
import 'moment/min/locales'

registerLocale('es', es)
moment.locale('es')

const RangeDatePicker = ({ maxStartDate, maxEndDate, start, onChangeStart, end, onChangeEnd }) => {
  return (
    <S.Container>
      <DatePicker
        className="range-date-picker-input"
        selected={start}
        onChange={date => onChangeStart(date)}
        dateFormat="MM/yyyy"
        showMonthYearPicker
        showFullMonthYearPicker
        customInput={<DatePickerInput right={false} />}
        maxDate={maxStartDate}
        locale="es"
      />
      <div className="range-date-picker-separator">
        <span>-</span>
      </div>
      <DatePicker
        className="range-date-picker-input"
        selected={end}
        onChange={date => onChangeEnd(date)}
        dateFormat="MM/yyyy"
        showMonthYearPicker
        showFullMonthYearPicker
        customInput={<DatePickerInput right={true} />}
        maxDate={maxEndDate}
        locale="es"
      />
    </S.Container>
  )
}

RangeDatePicker.propTypes = {
  maxStartDate: PropTypes.instanceOf(Date),
  maxEndDate: PropTypes.instanceOf(Date),
  start: PropTypes.instanceOf(Date),
  onChangeStart: PropTypes.func.isRequired,
  end: PropTypes.instanceOf(Date),
  onChangeEnd: PropTypes.func.isRequired
}

const DatePickerInput = React.forwardRef(({ value, onClick, right }, ref) => {
  return (
    <Button
      ref={ref}
      className={right ? 'range-date-picker-right-button' : 'range-date-picker-left-button'}
      variant="white"
      label={value ? moment(value, 'MM/yyyy').format('MMMM YYYY') : '--'}
      handleOnClick={onClick}
    />
  )
})

DatePickerInput.propTypes = {
  onClick: PropTypes.func,
  right: PropTypes.bool,
  value: PropTypes.string
}

export default RangeDatePicker
