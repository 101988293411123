import React from 'react'
import PropTypes from 'prop-types'
import ContainerStyled from './styled'
import Modal from 'react-bootstrap/Modal'

const ModalImage = ({ show, onHide, resourceUri }) => {
  return (
    <Modal show={show} onHide={onHide} aria-labelledby="contained-modal-title-vcenter" centered>
      <ContainerStyled>
        <Modal.Body>
          <div className="close-icon-wrapper" onClick={onHide}>
            <img alt="Close icon" className="close-icon" src={require('../../../assets/images/close.png')} />
          </div>
          <img className="image-chat" src={resourceUri} alt="Chat icon" />
        </Modal.Body>
      </ContainerStyled>
    </Modal>
  )
}

ModalImage.propTypes = {
  onHide: PropTypes.func,
  show: PropTypes.bool,
  resourceUri: PropTypes.string
}

export default ModalImage
