import styled from 'styled-components'
import colors from '../../../assets/colors'

const ComponentStyled = styled.div`
  && {
    .pop-up {
      text-align: right;

      .admin-spaces {
        max-height: 220px;
        overflow-y: scroll;
        -ms-overflow-style: none;
        scrollbar-width: none;

        ::-webkit-scrollbar {
          display: none;
        }
      }

      ul {
        list-style: none;
        margin-bottom: 0;
        padding: 0 15px;

        li {
          margin: 17px 0;
          font-size: 14px;
          line-height: 14px;
          color: ${colors.black};
          & a {
            color: ${colors.black};
          }

          .is-admin {
            font-size: 13px;
            color: ${colors.darkGrey8};
          }

          &.exit {
            color: ${colors.red};
          }

          &:not(.title) {
            cursor: pointer;
          }

          &.title {
            font-size: 16px;
            line-height: 22px;
            color: ${colors.black};
            font-weight: 600;
            margin-bottom: 5px;
          }
        }
      }
    }
  }
`

export default ComponentStyled
