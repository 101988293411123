import * as statsActions from './actions'
import _ from 'lodash'
import i18n from '../../assets/i18n'

export const updateOrganizationThemes = organizationNumThemes => async (dispatch, getState, { client, api, toast }) => {
  const themes = _.get(organizationNumThemes, 'data.themeUsageRecords.edges')
  const maxThemes = _.maxBy(themes, o => {
    return o?.node?.quantity
  })

  dispatch(statsActions.updateThemes(themes))
  dispatch(statsActions.updateNumThemes(_.get(maxThemes, 'node.quantity')))
}

export const updateUserUsageRecords = userUsageRecords => async (dispatch, getState, { client, api, toast }) => {
  const data = _.get(userUsageRecords, 'data.userUsageRecords.edges')
  const maxUsers = _.maxBy(data, o => {
    return o?.node?.quantity
  })
  dispatch(statsActions.setUserUsageRecors(data))
  dispatch(statsActions.setNumUserUsageRecors(_.get(maxUsers, 'node.quantity')))
}

export const updateThemeAdmins = themeId => async (dispatch, getState, { client, api, toast }) => {
  try {
    const organizationNumThemes = await client.query({
      query: api.queries.GET_THEME_ADMINS,
      variables: { themeId },
      fetchPolicy: 'network-only'
    })
    dispatch(statsActions.setThemeAdmins(organizationNumThemes?.data?.themeAdminsUsers?.edges))
  } catch (e) {
    toast(i18n.updateUserForm.updateError, { type: toast.TYPE.ERROR })
  }
}

export const updateUserUsageRecordsByTheme = userUsageRecords => async (dispatch, getState, { client, api, toast }) => {
  const data = _.get(userUsageRecords, 'data.userUsageRecords.edges')
  const maxUsers = _.maxBy(data, o => {
    return o?.node?.quantity
  })
  dispatch(
    statsActions.setThemeData({
      usageRecords: { num: _.get(maxUsers, 'node.quantity'), list: data }
    })
  )
}

export const updateGlobalMessageRecordsByTheme = userUsageRecords => async (dispatch, getState, { client, api, toast }) => {
  const data = _.get(userUsageRecords, 'data.globalMessageUsageRecords.edges')
  const maxUsers = _.maxBy(data, o => {
    return o?.node?.quantity
  })
  dispatch(
    statsActions.setThemeData({
      globalMessages: { num: _.get(maxUsers, 'node.quantity'), list: data }
    })
  )
}

export const updateGlobalMessage = data => async (dispatch, getState, { client, api, toast }) => {
  const themes = _.get(data, 'data.globalMessageUsageRecords.edges')
  const max = _.maxBy(themes, o => {
    return o?.node?.quantity
  })

  dispatch(statsActions.updateGlobalMessage(themes))
  dispatch(statsActions.updateNumGlobalMessage(_.get(max, 'node.quantity')))
}

export const updateAnswersMessageRecordsByTheme = userUsageRecords => async (dispatch, getState, { client, api, toast }) => {
  const data = _.get(userUsageRecords, 'data.answerMessageUsageRecords.edges')
  const maxUsers = _.maxBy(data, o => {
    return o?.node?.quantity
  })
  dispatch(
    statsActions.setThemeData({
      answersMessages: { num: _.get(maxUsers, 'node.quantity'), list: data }
    })
  )
}

export const updateAnswersMessage = data => async (dispatch, getState, { client, api, toast }) => {
  const themes = _.get(data, 'data.answerMessageUsageRecords.edges')
  const max = _.maxBy(themes, o => {
    return o?.node?.quantity
  })

  dispatch(statsActions.updateAnswersMessage(themes))
  dispatch(statsActions.updateNumAnswersMessage(_.get(max, 'node.quantity')))
}
