import styled, { css } from 'styled-components'
import colors from '../../../assets/colors'

const SearchFieldStyled = styled.div`
  && {
    .search-wrapper {
      position: relative;
    }
    .search-icon {
      position: absolute;
      top: 50%;
      left: 20px;
      transform: translateY(-50%);
      cursor: pointer;
    }
    input {
      width: 100%;
      outline: none;
      border: 0;
      background-color: ${colors.white};
      font-size: 16px;
      line-height: 20px;
      letter-spacing: 0.2px;
      color: ${colors.darkGrey4};
      padding: 13px 30px 13px 50px;
      border-radius: 30px;
      box-shadow: 0px 2px 11px rgba(0, 0, 0, 0.1);
      ::placeholder {
        color: ${colors.darkGreyAlpha50};
      }
    }

    ${props =>
      props.hasError &&
      css`
        input {
          border-color: ${colors.red};
        }
      `}
  }
`

export default SearchFieldStyled
