import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import compose from 'recompose/compose'
import View from './view'
import { directsOperations } from '../../../redux/directs'

const mapStateToProps = state => {
  return {
    directs: state.directs.list,
    directsFetching: state.directs.isFetching,
    directsPageInfo: state.directs.pageInfo
  }
}

const mapDispatchToProps = dispatch => {
  return {
    fetchDirects: () => dispatch(directsOperations.fetchList())
  }
}

const enhance = compose(connect(mapStateToProps, mapDispatchToProps))

export default withRouter(enhance(View))
