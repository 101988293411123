import { connect } from 'react-redux'
import compose from 'recompose/compose'
import { meOperations } from '../../../redux/me'
import { customersOperations } from '../../../redux/customers'
import { profitsOperations } from '../../../redux/profits'
import { comercialPaymentsOperations } from '../../../redux/comercialPayments'

import View from './view'

const mapStateToProps = state => {
  return {
    isFetching: state.organizations.isFetching,
    organization: state.organizations.item,
    userInfo: state.me.userInfo,
    customers: state.customers.list,
    customersIsFetching: state.customers.isFetching,
    customerSelected: state.customers.item,
    profits: state.profits.list,
    comercialPayments: state.comercialPayments.list,
    comercialPaymentSelected: state.comercialPayments.item
  }
}
const mapDispatchToProps = dispatch => {
  return {
    updateBillingData: data => dispatch(meOperations.updateBillingData(data)),
    getCustomers: data => dispatch(customersOperations.fetchList(data)),
    getProfits: () => dispatch(profitsOperations.fetchList()),
    getComercialPayments: () => dispatch(comercialPaymentsOperations.fetchList()),
    selectCustomer: item => dispatch(customersOperations.selectItem(item)),
    selectComercialPayment: item => dispatch(comercialPaymentsOperations.selectItem(item))
  }
}

const enhance = compose(connect(mapStateToProps, mapDispatchToProps))

export default enhance(View)
