import styled from 'styled-components'
import colors from '../../../assets/colors'

const OrganizationBarStyled = styled.div`
  && {
    position: relative;
    background-color: ${colors.darkGrey5};

    .organization-bar {
      position: relative;
    }

    .menu-popup {
      background-color: ${colors.white};
      box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.14);
      border-radius: 5px;
      min-width: 240px;
      border: 0;
      top: 94% !important;
      padding: 0;
      right: 0 !important;
      transform: inherit !important;
    }

    .organization-wrapper {
      padding: 10px 45px;
      cursor: pointer;

      .arrow {
        position: absolute;
        right: 45px;
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }
`

export default OrganizationBarStyled
