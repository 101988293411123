import React, { Component } from 'react'
import CreateOrganizationFormStyled from './styled'
import { RegularInput, Button } from '../../atoms'
import i18n from '../../../assets/i18n'
import PropTypes from 'prop-types'
import { Formik } from 'formik'
import { Link } from 'react-router-dom'
import routes from '../../../config/routes'
import validate from './validate'

class CreateOrganizationForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      image: '',
      file: ''
    }
  }

  render() {
    let style = {}
    const { file, image } = this.state
    const { handleOnSubmit, isFetchingForm } = this.props

    if (image) {
      style = {
        backgroundImage: 'url(' + file + ')'
      }
    }
    return (
      <CreateOrganizationFormStyled>
        <Formik
          initialValues={{
            name: '',
            businessName: '',
            document: '',
            address: '',
            postalCode: '',
            province: '',
            city: ''
          }}
          validate={validate}
          onSubmit={data => {
            handleOnSubmit(data)
          }}
        >
          {({ values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue }) => {
            const handleInputChange = ({ ...props }) => {
              this.props.cleanErrors()
              handleChange({ ...props })
            }
            return (
              <form onSubmit={handleSubmit}>
                <div className="upload-image-wrapper">
                  <div className="circle-image-wrapper" style={style}>
                    {!image && <p>{i18n.createOrganizationForm.addPhoto}</p>}
                    <div className="input-wrapper">
                      <RegularInput
                        type="file"
                        name="image"
                        variant="image-file"
                        handleOnChange={event => {
                          this.props.cleanErrors()
                          setFieldValue('image', event.currentTarget.files[0])
                          this.setState({ image: event.currentTarget.files[0] })

                          let reader = new FileReader()
                          reader.onloadend = () => {
                            this.setState({ file: reader.result })
                          }
                          if (event.target.files[0]) {
                            reader.readAsDataURL(event.target.files[0])
                          }
                        }}
                      />
                    </div>
                  </div>
                </div>
                <RegularInput
                  label={i18n.createOrganizationForm.orgName}
                  placeholder={i18n.createOrganizationForm.orgNamePlaceholder}
                  handleOnChange={handleInputChange}
                  handleOnBlur={handleBlur}
                  name="name"
                  value={values.name}
                  hasError={touched.name && (errors.name || this.props.errors.name)}
                  hasMarginBottom={true}
                />
                {touched.name && (errors.name || this.props.errors.name) && <p className="error-msg-input">{errors.name || this.props.errors.name}</p>}

                <RegularInput
                  label={i18n.createOrganizationForm.businessName}
                  placeholder={i18n.createOrganizationForm.businessNamePlaceholder}
                  handleOnChange={handleInputChange}
                  handleOnBlur={handleBlur}
                  name="businessName"
                  value={values.businessName}
                  hasError={errors.businessName && touched.businessName && true}
                  hasMarginBottom={true}
                />
                {errors.businessName && touched.businessName && <p className="error-msg-input">{errors.businessName}</p>}

                <RegularInput
                  label={i18n.createOrganizationForm.document}
                  placeholder={i18n.createOrganizationForm.documentPlaceholder}
                  handleOnChange={handleInputChange}
                  handleOnBlur={handleBlur}
                  name="document"
                  value={values.document}
                  hasError={touched.document && (errors.document || this.props.errors.document)}
                  hasMarginBottom={true}
                />
                {touched.document && (errors.document || this.props.errors.document) && (
                  <p className="error-msg-input">{errors.document || this.props.errors.document}</p>
                )}

                <RegularInput
                  label={i18n.createOrganizationForm.address}
                  placeholder={i18n.createOrganizationForm.addressPlaceholder}
                  handleOnChange={handleInputChange}
                  handleOnBlur={handleBlur}
                  name="address"
                  value={values.address}
                  hasError={touched.address && (errors.address || this.props.errors.address)}
                  hasMarginBottom={true}
                />
                {touched.address && (errors.address || this.props.errors.address) && (
                  <p className="error-msg-input">{errors.address || this.props.errors.address}</p>
                )}

                <RegularInput
                  label={i18n.createOrganizationForm.postalCode}
                  placeholder={i18n.createOrganizationForm.postalCodePlaceholder}
                  handleOnChange={handleInputChange}
                  handleOnBlur={handleBlur}
                  name="postalCode"
                  value={values.postalCode}
                  hasError={touched.postalCode && (errors.postalCode || this.props.errors.postalCode)}
                  hasMarginBottom={true}
                />
                {touched.postalCode && (errors.postalCode || this.props.errors.postalCode) && (
                  <p className="error-msg-input">{errors.postalCode || this.props.errors.postalCode}</p>
                )}

                <RegularInput
                  label={i18n.createOrganizationForm.province}
                  placeholder={i18n.createOrganizationForm.provincePlaceholder}
                  handleOnChange={handleInputChange}
                  handleOnBlur={handleBlur}
                  name="province"
                  value={values.province}
                  hasError={touched.province && (errors.province || this.props.errors.province)}
                  hasMarginBottom={true}
                />
                {touched.province && (errors.province || this.props.errors.province) && (
                  <p className="error-msg-input">{errors.province || this.props.errors.province}</p>
                )}

                <RegularInput
                  label={i18n.createOrganizationForm.city}
                  placeholder={i18n.createOrganizationForm.cityPlaceholder}
                  handleOnChange={handleInputChange}
                  handleOnBlur={handleBlur}
                  name="city"
                  value={values.city}
                  hasError={touched.city && (errors.city || this.props.errors.city)}
                  hasMarginBottom={true}
                />
                {touched.city && (errors.city || this.props.errors.city) && <p className="error-msg-input">{errors.city || this.props.errors.city}</p>}

                <div className="check-wrapper">
                  <RegularInput
                    type="checkbox"
                    variant="checkbox"
                    handleOnChange={handleInputChange}
                    handleOnBlur={handleBlur}
                    name="terms"
                    checked={values.terms}
                  />

                  <span className="accept-terms">
                    <span>
                      {i18n.createOrganizationForm.accept}{' '}
                      <Link to={routes.b2bPolicy} target="_blank">
                        {i18n.createOrganizationForm.terms}
                      </Link>
                    </span>
                  </span>
                  {errors.terms && touched.terms ? <p className="error-msg-input">{errors.terms}</p> : null}
                </div>

                <Button isFetching={isFetchingForm} variant="big" type="submit" label={i18n.createOrganizationForm.continue} />
              </form>
            )
          }}
        </Formik>
      </CreateOrganizationFormStyled>
    )
  }
}

CreateOrganizationForm.propTypes = {
  handleOnSubmit: PropTypes.func,
  isFetchingForm: PropTypes.bool,
  errors: PropTypes.object,
  cleanErrors: PropTypes.func
}

CreateOrganizationForm.defaultProps = {
  cleanErrors: () => null
}

export default CreateOrganizationForm
