import React, { Component } from 'react'
import { Header, Footer } from '../../organism'
import ComponentStyled from './styled'
import { Container, Row, Col } from 'react-bootstrap'
import { EsContent } from './es'

class B2bPolicy extends Component {
  constructor(props) {
    super(props)
    this.state = {
      modalIsOpen: false,
      isModalLogin: false,
      isModalRegister: false
    }
  }

  openModal = option => {
    this.setState({
      modalIsOpen: true
    })
    option === 'login' ? this.setState({ isModalLogin: true }) : this.setState({ isModalRegister: true })
  }

  closeModal = () => {
    this.setState({
      modalIsOpen: false,
      isModalLogin: false,
      isModalRegister: false
    })
  }

  setIsLogin = () => {
    this.setState({
      isModalLogin: true,
      isModalRegister: false
    })
  }

  setIsRegister = () => {
    this.setState({
      isModalLogin: false,
      isModalRegister: true
    })
  }

  render() {
    return (
      <ComponentStyled>
        <Header
          openModal={this.openModal}
          closeModal={this.closeModal}
          setIsLogin={this.setIsLogin}
          setIsRegister={this.setIsRegister}
          modalIsOpen={this.state.modalIsOpen}
          isModalLogin={this.state.isModalLogin}
          isModalRegister={this.state.isModalRegister}
        />
        <Container>
          <Row>
            <Col>
              <div className="terms-wrapper">
                <EsContent />
              </div>
            </Col>
          </Row>
        </Container>

        <Footer />
      </ComponentStyled>
    )
  }
}

export default B2bPolicy
