import styled from 'styled-components'
import colors from '../../../assets/colors'

const TermsStyled = styled.div`
  && {
    background-color: ${colors.grey};

    .terms-wrapper {
      padding: 50px 0;

      h1 {
        margin-bottom: 30px;
      }

      .section-title {
        margin-top: 30px;
        font-weight: bold;
      }

      li {
        margin-bottom: 15px;
      }
    }
  }
`

export default TermsStyled
