import React from 'react'
import PropTypes from 'prop-types'
import ContainerStyled from './styled'
import Modal from 'react-bootstrap/Modal'
import { CircleHeader } from '../../atoms'
import { EditThemeForm } from '../../organism'

const ModalEditTheme = ({ onSubmitEdit, show, onHide }) => {
  return (
    <Modal show={show} onHide={onHide} size="xl" aria-labelledby="contained-modal-title-vcenter" centered>
      <ContainerStyled>
        <Modal.Body>
          <CircleHeader />
          <div className="info-wrapper">
            <EditThemeForm onSubmitEdit={onSubmitEdit} />
          </div>
        </Modal.Body>
      </ContainerStyled>
    </Modal>
  )
}

ModalEditTheme.propTypes = {
  onHide: PropTypes.func,
  show: PropTypes.bool,
  onSubmitEdit: PropTypes.func
}

export default ModalEditTheme
