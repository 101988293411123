import * as types from './types'

export const setAppInit = () => ({
  type: types.SET_AUTH_INIT
})

export const setToken = value => ({
  type: types.SET_AUTH_TOKEN,
  payload: value
})

export const setFetching = (isFetching, isFetchingInfo = '') => {
  return { type: types.SET_AUTH_FETCHING, payload: { isFetching, isFetchingInfo } }
}

export const logOut = () => ({
  type: types.LOG_OUT
})
