import styled from 'styled-components'
import colors from '../../../assets/colors'

const DirectChatListStyled = styled.div`
  && {
    padding: 15px 0;

    .direct-user-wrapper {
      padding: 10px 0;
      display: flex;
      align-items: center;
      transition: 0.5s ease all;
      justify-content: space-between;
      cursor: pointer;
      position: relative;

      > div {
        position: relative;
        z-index: 1;
        flex: 1;
      }

      ::before {
        content: '';
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        width: 1000px;
        height: 100%;
        background-color: ${colors.grey};
        opacity: 0;
        transition: 0.5s ease all;
      }

      &:hover:before {
        opacity: 1;
      }

      img,
      svg {
        position: relative;
        z-index: 1;
      }
    }

    .direct-title-list {
      font-size: 14px;
      font-weight: 600;
      line-height: 16px;
      letter-spacing: 1.08025px;
      text-transform: uppercase;
      color: ${colors.black};
    }

    .see-more {
      text-align: center;
      font-size: 14px;
      letter-spacing: -0.43px;
      color: ${colors.yellow};
      margin-top: 15px;
      font-weight: 600;
      margin-bottom: 0px;

      span {
        cursor: pointer;
      }
    }
  }
`

export default DirectChatListStyled
