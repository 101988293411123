import React, { Component } from 'react'
import CreateThemeStep1Styled from './styled'
import { RegularInput, Button, SingleDatePicker } from '../../atoms'
import i18n from '../../../assets/i18n'
import PropTypes from 'prop-types'
import { Formik } from 'formik'
import moment from 'moment'

class CreateThemeStep1 extends Component {
  constructor(props) {
    super(props)
    this.state = {
      themeName: '',
      themeDesc: '',
      onlyAnswers: false,
      temporalLimit: false,
      limitDate: ''
    }
  }

  render() {
    return (
      <CreateThemeStep1Styled>
        <h4>{i18n.createThemeStep1.newTheme}</h4>
        <Formik
          initialValues={{
            themeName: '',
            themeDesc: ''
          }}
          validate={values => {
            let errors = {}
            if (!values.themeName) {
              errors.themeName = i18n.createThemeStep1.requiredField
            }
            return errors
          }}
          onSubmit={data => {
            this.props.handleOnSubmit(data)
          }}
        >
          {({ values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue }) => (
            <form onSubmit={handleSubmit}>
              <RegularInput
                label={i18n.createThemeStep1.themeName}
                placeholder={i18n.createThemeStep1.themeNamePlaceholder}
                handleOnChange={handleChange}
                handleOnBlur={handleBlur}
                name="themeName"
                value={values.themeName}
                hasError={errors.themeName && touched.themeName && true}
              />
              {errors.themeName && touched.themeName && <p className="error-msg-input">{errors.themeName}</p>}

              <RegularInput
                label={i18n.createThemeStep1.themeDesc}
                placeholder={i18n.createThemeStep1.themeDescPlaceholder}
                handleOnChange={handleChange}
                handleOnBlur={handleBlur}
                name="themeDesc"
                value={values.themeDesc}
                hasError={errors.themeDesc && touched.themeDesc && true}
              />

              <div className="check-wrapper">
                <p>{i18n.createThemeStep1.answers}</p>
                <RegularInput
                  type="checkbox"
                  variant="checkbox"
                  handleOnChange={handleChange}
                  handleOnBlur={handleBlur}
                  name="onlyAnswers"
                  checked={values.onlyAnswers}
                  hasError={errors.onlyAnswers && touched.onlyAnswers && true}
                />

                <span>{i18n.createThemeStep1.answersValue}</span>
              </div>
              <div className="check-wrapper">
                <p>{i18n.createThemeStep1.temporalLimit}</p>
                <RegularInput
                  type="checkbox"
                  variant="checkbox"
                  handleOnChange={handleChange}
                  handleOnBlur={handleBlur}
                  name="temporalLimit"
                  checked={values.temporalLimit}
                  hasError={errors.temporalLimit && touched.temporalLimit && true}
                />
                <span>{i18n.createThemeStep1.temporalLimitValue}</span>
              </div>
              {values.temporalLimit && (
                <SingleDatePicker
                  handleOnChange={date => {
                    setFieldValue('limitDate', date)
                  }}
                  name="limitDate"
                  minDate={moment().toDate()}
                  value={values.limitDate}
                  hasError={errors.limitDate && touched.limitDate && true}
                />
              )}
              {errors.limitDate && touched.limitDate && <p className="error-msg-input">{errors.limitDate}</p>}

              <div className="submit-wrapper">
                <Button type="submit" variant="big" label={i18n.createThemeStep1.next} isFetching={this.props.isFetchingForm} />
              </div>
            </form>
          )}
        </Formik>
      </CreateThemeStep1Styled>
    )
  }
}

CreateThemeStep1.propTypes = {
  handleOnSubmit: PropTypes.func,
  isFetchingForm: PropTypes.bool
}

export default CreateThemeStep1
