import React from 'react'
import AuthorMessageStyled from './styled'
import PropTypes from 'prop-types'
import { ImageInChat, FileInChat, Button } from '../../atoms'
import { MESSAGES_TYPES } from '../../../config/constants'
import Linkify from 'react-linkify'
import i18n from '../../../assets/i18n'

const AuthorMessage = ({ message, onOpenPollInfo, time, mediaObject, type }) => {
  function renderMediaContent() {
    if (type === MESSAGES_TYPES.TEXT || type === MESSAGES_TYPES.POLL || type === MESSAGES_TYPES.TEMP) {
      return (
        <Linkify
          componentDecorator={(decoratedHref, decoratedText, key) => (
            <a target="blank" href={decoratedHref} key={key}>
              {decoratedText}
            </a>
          )}
        >
          <p className="message-content">{message}</p>
        </Linkify>
      )
    } else if (type === MESSAGES_TYPES.FILE || type === MESSAGES_TYPES.AUDIO || type === MESSAGES_TYPES.IMAGE) {
      if (mediaObject && (mediaObject.mimeType === 'image/png' || mediaObject.mimeType === 'image/jpg' || mediaObject.mimeType === 'image/jpeg')) {
        return <ImageInChat resourceId={mediaObject._id} />
      } else {
        return <FileInChat isAudio={type === MESSAGES_TYPES.AUDIO ? true : false} mediaObject={mediaObject} />
      }
    }
  }

  return (
    <AuthorMessageStyled>
      <div className="message-wrapper">
        <div className="message-box">
          {renderMediaContent()}
          {time && <p className="message-time">{time}</p>}
        </div>
        {type === MESSAGES_TYPES.POLL && (
          <div className="btn-wrapper">
            <Button handleOnClick={onOpenPollInfo} label={i18n.authorMessage.seePoll} />
          </div>
        )}
      </div>
    </AuthorMessageStyled>
  )
}

AuthorMessage.propTypes = {
  type: PropTypes.string,
  message: PropTypes.string,
  time: PropTypes.string,
  mediaObject: PropTypes.any,
  onOpenPollInfo: PropTypes.func
}

export default AuthorMessage
