import React from 'react'
import PropTypes from 'prop-types'
import ContainerStyled from './styled'
import Modal from 'react-bootstrap/Modal'
import { UserResetPass } from '../../organism'

const ModalResetPass = ({ show, onHide, onLoginTapped }) => {
  return (
    <Modal show={show} onHide={onHide} size="md" aria-labelledby="contained-modal-title-vcenter" centered>
      <ContainerStyled>
        <Modal.Body>
          <UserResetPass onFinish={onHide} onLoginTapped={onLoginTapped} />
        </Modal.Body>
      </ContainerStyled>
    </Modal>
  )
}

ModalResetPass.propTypes = {
  onHide: PropTypes.func,
  show: PropTypes.bool,
  onLoginTapped: PropTypes.func
}

export default ModalResetPass
