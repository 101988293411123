import { connect } from 'react-redux'
import compose from 'recompose/compose'
import View from './view'

const mapStateToProps = state => {
  return {
    userInfo: state.me.userInfo
  }
}

const mapDispatchToProps = () => {
  return {}
}

const enhance = compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)

export default enhance(View)
