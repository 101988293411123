import * as types from './types'
import { LOG_OUT } from '../auth/types'

const initialState = {
  organizationClientsList: [],
  organizationClientsTotal: 0,
  organizationClientsPageInfo: null,
  organizationClientsLoading: false,

  organizationUsersList: [],
  organizationUsersTotal: 0,
  organizationUsersPageInfo: null,
  organizationUsersLoading: false,

  themeParticipantsList: [],
  themeParticipantsTotal: 0,
  themeParticipantsPageInfo: null,
  themeParticipantsLoading: false,

  themeAdminsList: [],
  themeAdminsTotal: 0,
  themeAdminsPageInfo: null,
  themeAdminsLoading: false
}

const reducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case types.ORGANIZATION_CLIENTS_UPDATE_LIST:
      return {
        ...state,
        organizationClientsList: action.list,
        organizationClientsTotal: action.total,
        organizationClientsPageInfo: action.pageInfo
      }

    case types.ORGANIZATION_CLIENTS_SET_FETCHING:
      return {
        ...state,
        organizationClientsLoading: action.value
      }

    case types.ORGANIZATION_USERS_UPDATE_LIST:
      return {
        ...state,
        organizationUsersList: action.list,
        organizationUsersTotal: action.total,
        organizationUsersPageInfo: action.pageInfo
      }

    case types.ORGANIZATION_USERS_SET_FETCHING:
      return {
        ...state,
        organizationUsersLoading: action.value
      }

    case types.THEME_PARTICIPANTS_UPDATE_LIST:
      return {
        ...state,
        themeParticipantsList: action.list,
        themeParticipantsTotal: action.total,
        themeParticipantsPageInfo: action.pageInfo
      }

    case types.THEME_PARTICIPANTS_SET_FETCHING:
      return {
        ...state,
        themeParticipantsLoading: action.value
      }

    case types.THEME_ADMINS_UPDATE_LIST:
      return {
        ...state,
        themeAdminsList: action.list,
        themeAdminsTotal: action.total,
        themeAdminsPageInfo: action.pageInfo
      }

    case types.THEME_ADMINS_SET_FETCHING:
      return {
        ...state,
        themeAdminsLoading: action.value
      }

    case LOG_OUT:
      return initialState

    default:
      return state
  }
}

export default reducer
