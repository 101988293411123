import React from 'react'
import PropTypes from 'prop-types'
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from 'chart.js'
import { Line } from 'react-chartjs-2'
import * as S from './styled'
import colors from 'assets/colors'

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend)

const LineChart = ({ title, data }) => {
  const options = {
    responsive: true,
    scales: {
      x: {
        grid: {
          display: false,
          drawBorder: true,
          borderWidth: 1,
          borderColor: colors.darkGrey
        },
        ticks: {
          color: colors.darkGrey,
          textStrokeWidth: 0.3,
          textStrokeColor: colors.darkGrey
        }
      },
      y: {
        grid: {
          drawBorder: true,
          borderWidth: 1,
          borderColor: colors.darkGrey,
          borderDash: [4, 4]
        },
        ticks: {
          color: colors.darkGrey,
          textStrokeWidth: 0.3,
          textStrokeColor: colors.darkGrey,
          stepSize: 1
        }
      }
    },
    plugins: {
      legend: {
        position: 'top',
        display: data?.lenght > 1
      },
      title: {
        display: !!title,
        text: title,
        padding: 20
      }
    }
  }

  return (
    <S.Container>
      <S.Wrapper>
        <Line options={options} data={data} />
      </S.Wrapper>
    </S.Container>
  )
}

LineChart.propTypes = {
  title: PropTypes.string,
  data: PropTypes.object
}

LineChart.defaultProps = {
  title: null,
  data: {
    labels: [],
    datasets: []
  }
}

export default LineChart
