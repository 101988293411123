/* eslint-disable react/no-unescaped-entities */
import React from 'react'

export const EsContent = () => (
  <div>
    <h1>TÉRMINOS Y CONDICIONES DE USO DEL SERVICIO</h1>
    <p>
      Los Términos y Condiciones (en adelante, "Términos y Condiciones de Uso") que se explican a continuación, resultarán de aplicación a los servicios
      prestados por COCOMU INTERNET SLU con domicilio social en Avda. Miguel de Cervantes 63, 4ºB 16400 Tarancón, Cuenca, España (en adelante, "Cocomu" o
      "Nosotros"), a sus clientes y usuarios a través de la puesta a disposición de aplicaciones, software y demás servicios (en adelante, "Servicios").  Estos
      Términos y Condiciones de Uso, formalizados en castellano, son el acuerdo que regula las relaciones existentes entre Cocomu y los usuarios que disfrutan
      de cualquiera de nuestros Servicios en su propio nombre. Podrás consultar una copia de estos Términos y Condiciones de Uso en cualquier momento, ya que
      estarán a tu disposición en todo momento en nuestra aplicación móvil (en adelante, la "Aplicación" o "App"). La intención de Cocomu es ser transparente y
      poner a disposición de empresas y usuarios finales (en adelante, "Clientes" y "Usuarios", respectivamente), toda la información necesaria para que ambos
      entiendan el funcionamiento de los Servicios, así como las ventajas que ofrecemos y los derechos y responsabilidades de los Clientes y Usuarios en
      relación con el uso de los mismos. Los Clientes y Usuarios deberán aceptar nuestros Términos y Condiciones de Uso, para poder registrarse, acceder, y
      finalmente, utilizar nuestros Servicios.
    </p>
    <p className="section-title">
      <strong>1. Nuestros Servicios</strong>
    </p>
    <p>
      Los Servicios de Cocomu constituyen una plataforma tecnológica, que permite a nuestros Clientes crear una forma distinta de comunicación con los Usuarios.
      Mediante el uso de esta Aplicación, los Clientes podrán comunicarse con los Usuarios, a través del envío de mensajes instantáneos, los cuales podrán
      contener imágenes, vídeos, mensajes de audio y cualquier otro archivo binario o incluso convocatorias a eventos, invitaciones o encuestas. Los Usuarios
      únicamente podrán comunicarse con el emisor del mensaje, es decir, con el Cliente y/o las personas físicas designadas por éste como administradores (en
      adelante, “Administrador” o "Administradores”). Cada conversación será referida de ahora en adelante como “Tema”. La participación y uso de la App por
      parte de los Usuarios, dependerá de la previa comunicación o invitación por parte del Cliente. El Cliente pagará un precio estipulado por cada Usuario que
      efectivamente se dé de alta en la App. En este sentido, los Usuarios no deberán pagar ninguna contraprestación por el uso de la App. Aquellos Usuarios que
      deseen desarrollar la actividad comercial de Cocomu, haciendo partícipe a terceros de los Servicios de Cocomu, podrán percibir una compensación por ello.
      Si quieres conocer más sobre esto, por favor, contacta con nosotros a través del servicio de mensajería que ponemos a tu disposición una vez que haces
      "log-in" en nuestra App. Allí, te podrás poner en contacto con nosotros de manera fácil y rápida y te responderemos a la mayor brevedad. Para acceder a
      este chat, solo tienes que clicar el apartado "Ser Manager”.
    </p>
    <p className="section-title">
      <strong>2. Edad mínima</strong>
    </p>
    <p>
      La edad mínima para utilizar nuestros Servicios es 18 años, por lo que queda prohibido el acceso y utilización de los mismos a personas menores de esa
      edad. Al registrarte en nuestra App estarás manifestando que tienes 18 años o más. ​ De este modo, la utilización de nuestros Servicios por parte de
      menores de 18 años es contrario a los presentes Términos y Condiciones de Uso.
    </p>
    <p className="section-title">
      <strong>3. Obligaciones de los Usuarios</strong>
    </p>
    <p>
      La utilización de los Servicios implica que los Usuarios acepten todas las obligaciones que se establecen en estos Términos y Condiciones de Uso y se
      creen una cuenta de Usuario (en adelante, "Cuenta de Usuario") para acceder a la App. Es por ello que, al darte de alta en nuestros Servicios, te
      comprometes a:
    </p>
    <ul>
      <li>
        Garantizar la veracidad y exactitud de los datos que suministres para crear o actualizar tu Cuenta de Usuario o tu perfil, así como cualquier otro dato
        que puedas facilitar a Cocomu o a terceros al utilizar los Servicios.
      </li>
      <li>Mantener tus datos actualizados en todo momento.</li>
      <li>
        Custodiar con la máxima diligencia las credenciales de acceso de tu Cuenta de Usuario, evitando su acceso o utilización por parte de cualquier tercero.
        La Cuenta de Usuario será personal e intransferible, por lo que cualquier acción realizada utilizando la misma se entenderá realizada por ti, salvo que
        hubieras notificado previamente a Cocomu la pérdida de la misma.{' '}
      </li>
      <li>
        Acceder a la Aplicación a través de dispositivos debidamente securizados y utilizar proveedores de comunicaciones (acceso a internet, teléfono, etc.) y
        de pago adecuados. Cocomu no asumirá ninguna clase de responsabilidad derivada de los servicios que te prestan estos proveedores u otros terceros.
      </li>
      <li>No introducir virus informáticos o cualquier clase de malware en la Aplicación que pueda generar un perjuicio para Cocomu o cualquier tercero. </li>
      <li>No realizar actos que en cualquier forma menoscaben o puedan menoscabar la reputación de Cocomu o de terceros.</li>
      <li>No suplantar la identidad de otra persona para acceder haciéndose pasar por la misma.</li>
      <li>
        No utilizar nuestros Servicios o la Aplicación de cualquier forma que vulnere la normativa aplicable, estos Términos y Condiciones de Uso o vaya en
        contra de los legítimos intereses de Cocomu o de terceros.
      </li>
      <li>
        No utilizar nuestros Servicios o la Aplicación para enviar, usar o reutilizar material ilegal, ofensivo, pornográfico, abusivo, difamatorio, injurioso,
        obsceno o amenazante; o que suponga una violación de derechos de autor, marcas registradas o confidencialidad, privacidad o cualquier otro derecho de
        Cocomu o de terceros.
      </li>
      <li>
        Modificar o eliminar de cualquier modo los dispositivos de seguridad, protección o identificación establecidos por Cocomu o sus legítimos titulares.
      </li>
    </ul>

    <p className="section-title">
      <strong>4. Contenidos y enlaces</strong>
    </p>
    <p>
      La App incluye algunos contenidos y enlaces de terceros sobre los que Cocomu no realiza un control activo. Por tanto, no seremos responsables de cualquier
      tipo de daño o perjuicio que te pudieran causar esos contenidos o enlaces. En el caso de que consideres que cualquier contenido es ilícito, ofensivo,
      contrario al orden público o vulnera tus derechos o intereses o derechos o intereses de terceros, puedes hacérnoslo saber info@beenfo.com .
    </p>
    <p className="section-title">
      <strong>5. Disponibilidad y cancelación de nuestros Servicios</strong>
    </p>
    <p>
      De cara a poder mejorar nuestros Servicios, es posible que, en algún momento agreguemos o eliminemos algunos de ellos, así como las funciones, y
      compatibilidad con ciertos dispositivos o determinadas plataformas. Cocomu no será responsable en caso de que nuestros Servicios sean interrumpidos, ya
      sea por motivos de mantenimiento, reparaciones, actualizaciones, errores en la red o en casos de fuerza mayor.
    </p>
    <p>
      Los Usuarios podrán eliminar su Cuenta X, en cualquier momento y por cualquier motivo. Para obtener instrucciones sobre cómo hacerlo, accede a nuestra App
      y clica en la sección “Mi perfil” y selecciona "Eliminar cuenta”
    </p>
    <p>
      Asimismo, los Usuarios que sufran incidencias o extravíen su contraseña, podrán recurrir al procedimiento de "Recuperación de contraseña" que les enviara
      un SMS con el codigo de recuperacion de contraseña.
    </p>
    <p>
      Por otra parte, Cocomu podrá modificar, suspender o cancelar tu Cuenta o el acceso a los Servicios en cualquier momento, conductas sospechosas o ilegales,
      incluido fraude, o si tenemos razones para creer que infringiste nuestras Condiciones o generaste algún daño, riesgo o exposición legal para nosotros,
      nuestros usuarios u otras personas.
    </p>
    <p>
      Asimismo, las Cuentas de Usuarios podrán ser cancelada, bien, en caso de que el Usuario incumpla sus obligaciones, o bien, porque el Cliente haya
      cancelado la contratación de nuestros Servicios.
    </p>
    <p className="section-title">
      <strong>6. Responsabilidad</strong>
    </p>
    <p>
      Cocomu no asume el riesgo de falta de exactitud o integridad del contenido de los Servicios. En particular, Cocomu no asume el riesgo de que los Servicios
      se mantengan accesibles continuamente, estén libre de defectos y sean seguros, o de que el contenido de estos Servicios se pueda descargar de forma
      segura.
    </p>
    <p>
      Asimismo, Cocomu no se hace responsable del contenido publicado por los Clientes a través de nuestros Servicios. En particular, esto significa que los
      Clientes serán los únicos responsables del contenido publicado a través de nuestros Servicios y que la relación existente derivada del uso de nuestros
      Servicios es únicamente entre los Clientes y los Usuarios, sin que Cocomu intervenga de ninguna forma en esta relación.{' '}
    </p>
    <p className="section-title">
      <strong>7. Propiedad Intelectual</strong>
    </p>
    <p>
      Todos los derechos sobre los Servicios y cualesquiera de los elementos que los integran son titularidad exclusiva de Cocomu. Por ello y en virtud de lo
      dispuesto en la normativa aplicable en materia de propiedad intelectual e industrial, queda expresamente prohibida la reproducción, distribución,
      comunicación pública, incluida su modalidad de puesta a disposición, transmisión, transformación, adaptación, traducción o cualquier utilización diferente
      a la permitida expresamente en este apartado de los Términos y Condiciones de Uso de la totalidad o parte de la App, los Servicios y/o de los elementos
      que lo componen.
    </p>
    <p>
      Únicamente dispones de una licencia de uso sobre la App, y los contenidos de los Servicios en su versión ejecutable, no transferible, sin límite
      territorial, limitada temporalmente a la vigencia de la relación contractual existente para la prestación de los Servicios y con la única finalidad de que
      puedas utilizar nuestros Servicios para tus propios fines. Dicha licencia terminará automáticamente cuando finalice la relación contractual regulada por
      medio de estos Términos y Condiciones de Uso.
    </p>
    <p>
      El Cliente, los Administradores y los Usuarios serán, respectivamente, los únicos titulares de los Derechos de Propiedad Intelectual derivados de
      los contenidos (por ejemplo, archivos binarios, fotografías, textos, etc.).
    </p>
    <p>
      Para el supuesto de que los Usuarios aportaran contenidos protegidos por derechos de propiedad intelectual o industrial a través de la App, los Usuarios
      otorgan a Cocomu una licencia mundial, no exclusiva, gratuita y libre de regalías, transferible, durante todo el periodo de vigencia de los mismos, con el
      fin de operar, promocionar y mejorar nuestros Servicios.
    </p>
    <p className="section-title">
      <strong>8. Legislación aplicable</strong>
    </p>
    <p>
      Cualesquiera disputas que pudieran surgir en relación con los Servicios se resolverán de conformidad con la legislación que resulte aplicable y ante los
      tribunales que resulten competentes en virtud de las normas procesales oportunas. ​ Asimismo, te recordamos que la Comisión Europea ha impulsado una
      plataforma de resolución extrajudicial de conflictos en línea a la que puedes acceder a la misma a través de la siguiente URL:
      http://ec.europa.eu/consumers/odr/. Sin embargo, te informamos de que no estamos obligados a resolver los conflictos que puedan surgir a través de esa
      vía.
    </p>

    <p className="section-title">
      <strong>9. Modificaciones</strong>
    </p>
    <p>
      Podremos modificar los Términos y Condiciones de Uso en cualquier momento, pero si el cambio es sustancial te avisaremos a través del sistema de
      notificaciones en la propia App de la fecha en que la nueva versión comenzará a ser aplicable. Lo haremos con una antelación razonable, de forma que
      puedas decidir si estás o no de acuerdo con las modificaciones que introduzcamos. Entenderemos que aceptas la nueva versión de los Términos y Condiciones
      de Uso si no manifiestas lo contrario antes de la fecha en que comience a ser aplicable o si continúas utilizando los Servicios. Las modificaciones no
      serán retroactivas.
    </p>
    <p className="section-title">
      <strong>10. Separabilidad</strong>
    </p>
    <p>
      La declaración de nulidad de cualquiera de las disposiciones incluidas en estos Términos y Condiciones de Uso no afectará el resto de disposiciones, que
      seguirán siendo aplicables siempre y cuando sea posible. Se sustituirá la cláusula o cláusulas afectadas por otra u otras que se asemejen lo máximo
      posible y preserven los efectos perseguidos por los Términos y Condiciones de Uso.
    </p>
  </div>
)
