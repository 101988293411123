import styled from 'styled-components'
import colors from '../../../assets/colors'

const StepsStyled = styled.div`
  && {
    display: inline-flex;
    justify-content: space-between;
    width: 100%;
    max-width: 400px;
    margin-bottom: 35px;
    position: relative;
    margin-top: 30px;

    ::before {
      content: '';
      position: absolute;
      top: 15px;
      left: 50%;
      width: calc(100% - 30px);
      height: 1px;
      background-color: ${colors.darkGrey2};
      transform: translateX(-50%);
    }
  }
`

export default StepsStyled
