import styled from 'styled-components'
import colors from '../../../assets/colors'

const ImageTextStyled = styled.div`
  && {
    .image-bg {
      margin-bottom: 30px;
      min-height: 200px;
    }
    p {
      color: ${colors.white};
    }

    @media (min-width: 768px) {
      .image-bg {
        margin-bottom: 30px;
        min-height: 230px;
      }
    }
  }
`

export default ImageTextStyled
