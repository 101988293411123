import React, { Component } from 'react'
import ComponentStyled from './styled'
import { RegularInput, RegularSelect, Button } from '../../atoms'
import i18n from '../../../assets/i18n'
import PropTypes from 'prop-types'
import { Formik } from 'formik'
import { countryOptions, getCountryPhoneCodeByIso2 } from '../../../utils'
import { checkPhone } from '../../../api'
import _ from 'lodash'
import { toast } from 'react-toastify'

class ResetPassPhone extends Component {
  constructor(props) {
    super(props)
    this.state = {
      phone: '',
      password: ''
    }
  }

  render() {
    const { onSubmit, isFetching } = this.props
    const spainValue = 'ES' // Se hardcodea el código de España
    return (
      <ComponentStyled>
        <Formik
          initialValues={{
            country: spainValue,
            phone: ''
          }}
          validate={values => {
            let errors = {}
            if (!values.country) {
              errors.country = i18n.resetPassPhone.requiredField
            }
            if (!values.phone) {
              errors.phone = i18n.resetPassPhone.requiredField
            }
            return errors
          }}
          onSubmit={data => {
            this.setState({ isFetching: true }, async () => {
              const formattedPhone = `+${getCountryPhoneCodeByIso2(data.country)}${data.phone}`
              try {
                const phone = { phoneNumber: formattedPhone }
                await checkPhone(phone)
                toast(i18n.resetPassPhone.phoneRegistered, { type: toast.TYPE.ERROR })
                this.setState({ isFetching: false })
                onSubmit(formattedPhone, data.country)
              } catch (e) {
                const status = _.get(e, 'response.status')
                this.setState({ isFetching: false })
                if (status === 404) {
                  toast(i18n.resetPassPhone.phoneNotRegistered, { type: toast.TYPE.ERROR })
                } else {
                  toast(i18n.resetPassPhone.error, { type: toast.TYPE.ERROR })
                }
              }
            })
          }}
        >
          {({ values, errors, touched, handleChange, handleBlur, handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <p className="title">{i18n.resetPassPhone.title}</p>
              <RegularSelect
                options={countryOptions}
                label={i18n.resetPassPhone.countryLabel}
                handleOnChange={handleChange}
                handleOnBlur={handleBlur}
                name="country"
                value={values.country}
                hasError={errors.country && touched.country && true}
                defaultSelectedValue={spainValue} // Se hardcodea el código de España
              />
              {errors.country && touched.country && <p className="error-msg-input">{errors.country}</p>}
              <RegularInput
                label={i18n.resetPassPhone.enterPhone}
                placeholder="000 000 000"
                prepend={values.country ? `+${getCountryPhoneCodeByIso2(values.country)}` : '+00'}
                handleOnChange={handleChange}
                handleOnBlur={handleBlur}
                name="phone"
                value={values.phone}
                type="number"
                hasError={errors.phone && touched.phone && true}
              />
              {errors.phone && touched.phone && <p className="error-msg-input">{errors.phone}</p>}
              <Button id="sign-in-button" isFetching={isFetching} type="submit" variant="big" label={i18n.resetPassPhone.send} />{' '}
            </form>
          )}
        </Formik>
      </ComponentStyled>
    )
  }
}

ResetPassPhone.propTypes = {
  onSubmit: PropTypes.func,
  showCodeInput: PropTypes.bool,
  isFetching: PropTypes.bool,
  renderStep: PropTypes.func
}

export default ResetPassPhone
