import styled from 'styled-components'
import colors from '../../../assets/colors'

export const WriteChatBarStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  .send-btn {
    margin-bottom: 0;
    margin-left: 15px;
    cursor: pointer;
    min-width: 44px;
    min-height: 44px;
    background: ${colors.darkGrey9};
    color: ${colors.white};
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 2px 17px rgba(0, 0, 0, 0.3);

    svg path {
      fill: ${colors.white};
    }
  }

  .write-chat-wrapper {
    position: relative;
    width: 100%;

    .add-btn-wrapper {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 20px;

      .add-btn {
        cursor: pointer;
      }

      .custom-toggle {
        ::after {
          content: none;
        }
      }
    }

    .menu-popup {
      background-color: ${colors.white};
      box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.14);
      position: absolute;
      z-index: 2;
      bottom: 130% !important;
      left: 0 !important;
      transform: inherit !important;
      top: inherit !important;
      border-radius: 5px;
      min-width: 200px;
      border: 0;
      padding: 10px 15px;
    }

    .photo-btn {
      position: absolute;
      top: 50%;
      right: 20px;
      transform: translateY(-50%);
      height: 20px;
      width: 20px;
      label {
        cursor: pointer;
        > div {
          top: 0;
          position: relative;
          input {
            opacity: 0;
            width: 20px;
            height: 20px;
            cursor: pointer;
            padding: 0;
            overflow: hidden;
            font-size: 0;
          }
        }
        > svg {
          position: absolute;
          top: 3px;
        }
      }
    }
  }
`
