import React, { Component } from 'react'
import ComponentStyled from './styled'
import { Avatar, SearchField } from '../../atoms'
import { PendingInvitationList } from '../../molecules'
import i18n from '../../../assets/i18n'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { ReactComponent as Spinner } from '../../../assets/images/yellow-spinner.svg'
import { toast } from 'react-toastify'

class OrganizationUsers extends Component {
  constructor(props) {
    super(props)
    this.state = {
      participantsToAdd: []
    }
  }

  componentDidMount() {
    this.loadOrganizationUsers()
  }

  onSearch = _.debounce((e, searchText) => {
    this.loadOrganizationUsers(searchText)
  }, 1000)

  loadOrganizationUsers = async (searchText = '') => {
    const participantsToAdd = await this.props.fetchOrganizationUsers(searchText)
    this.setState({ participantsToAdd })
  }

  deleteUser = async (orgIri, userIri) => {
    const userDeleted = await this.props.removeUserFromOrganization(orgIri, userIri)
    if (userDeleted) {
      const participantsToAdd = [...this.state.participantsToAdd]
      const userIndex = _.findIndex(participantsToAdd, { id: userIri })
      if (userIndex > -1) {
        participantsToAdd.splice(userIndex, 1)
        this.setState({ participantsToAdd })
      }
    } else {
      toast(i18n.generalError, { type: toast.TYPE.ERROR })
    }
  }

  render() {
    const { participantsToAdd } = this.state
    const { isFetchingUsers, onClickUser } = this.props

    const participantsToAddFormat = _.map(participantsToAdd, user => {
      const directName = _.get(user, 'fullname', '')
      const directImage = _.get(user, 'image._id', '')
      const subInfo = _.get(user, 'phone', '')

      const userIri = _.get(user, 'id')
      const orgIri = _.get(this.props.organization, 'id')

      return (
        <div key={userIri} className="participant-list-wrapper">
          <div onClick={() => onClickUser(user)}>
            <Avatar resourceId={directImage} name={directName} subtitle={subInfo} />
          </div>
          <p
            className="remove"
            onClick={() => {
              this.deleteUser(orgIri, userIri)
            }}
          >
            {i18n.organizationUsers.remove}
          </p>
        </div>
      )
    })

    return (
      <ComponentStyled>
        <div className="list-wrapper">
          <p className="title-list">{i18n.organizationUsers.orgUsers}</p>
          <div className="search-field-wrapper">
            <SearchField placeholder={i18n.organizationUsers.searchParticipants} handleOnChange={this.onSearch} />
          </div>
          {isFetchingUsers ? (
            <div className="spinner-wrapper">
              <Spinner className="spinner" />
            </div>
          ) : (
            participantsToAddFormat
          )}
          <PendingInvitationList title={i18n.organizationUsers.pendingInvitations} />
        </div>
      </ComponentStyled>
    )
  }
}

OrganizationUsers.propTypes = {
  fetchOrganizationUsers: PropTypes.func,
  isFetchingUsers: PropTypes.bool,
  onClickUser: PropTypes.func,
  organization: PropTypes.object,
  removeUserFromOrganization: PropTypes.func
}

export default OrganizationUsers
