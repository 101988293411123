import React from 'react'
import ComponentStyled from './styled'
import PropTypes from 'prop-types'
import i18n from '../../../assets/i18n'

const PopUpTheme = ({ /*onClickExit,*/ onClickEdit, isOrgClient, isThemeAdmin, onClickDelete }) => {
  return (
    <ComponentStyled>
      <div className="pop-up">
        <ul>
          {(isOrgClient || isThemeAdmin) && <li onClick={onClickEdit}>{i18n.popUpTheme.edit}</li>}
          {/*!isOrgClient && !isThemeAdmin && (
            <li onClick={onClickExit} className="warning-select">
              {i18n.popUpTheme.exit}
            </li>
          )*/}
          {isOrgClient && (
            <li onClick={onClickDelete} className="warning-select">
              {i18n.popUpTheme.delete}
            </li>
          )}
        </ul>
      </div>
    </ComponentStyled>
  )
}

PopUpTheme.defaultProps = {
  onClickEdit: () => {},
  onClickExit: () => {},
  onClickDelete: () => {}
}

PopUpTheme.propTypes = {
  onClickExit: PropTypes.func,
  onClickEdit: PropTypes.func,
  theme: PropTypes.object,
  userInfo: PropTypes.object,
  organization: PropTypes.object,
  isOrgClient: PropTypes.bool,
  isThemeAdmin: PropTypes.bool,
  onClickDelete: PropTypes.func
}

export default PopUpTheme
