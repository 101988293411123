import { connect } from 'react-redux'
import compose from 'recompose/compose'
import View from './view'
import { themesOperations } from '../../../redux/themes'

const mapStateToProps = state => {
  return {
    poll: state.themes.poll,
    isFetchingVote: state.themes.isFetchingVote
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onSubmit: data => dispatch(themesOperations.postPollVote(data))
  }
}

const enhance = compose(connect(mapStateToProps, mapDispatchToProps))

export default enhance(View)
