import React, { useState } from 'react'
import ComponentStyled from './styled'
import { RegularInput, Button, SingleDatePicker } from '../../atoms'
import i18n from '../../../assets/i18n'
import { Formik } from 'formik'
import _ from 'lodash'
import PropTypes from 'prop-types'
import moment from 'moment'
import { useResourceLoader } from '../../hooks'
import { ModalAccept } from '../../modals'

const UpdateUserForm = ({ userInfo, updateUserInfo, isFetching, disableUser }) => {
  const imageId = _.get(userInfo, 'image._id')
  const imageUri = useResourceLoader(imageId)
  const [image, setImage] = useState('')
  const [showAcceptModal, setShowAcceptModal] = useState(false)

  const toggleAcceptModal = () => {
    setShowAcceptModal(!showAcceptModal)
  }

  const formatImage = image ? image : imageUri
  const style = {
    backgroundImage: 'url(' + formatImage + ')'
  }

  const name = _.get(userInfo, 'name', '')
  const surname = _.get(userInfo, 'surname', '')
  const zipCode = _.get(userInfo, 'zipCode', '')
  let birthDate = _.get(userInfo, 'birthDate', null)
  birthDate = _.isNil(birthDate) ? null : moment(birthDate)

  return (
    <ComponentStyled>
      <Formik
        initialValues={{
          name,
          surname,
          zipCode,
          birthDate
        }}
        validate={values => {
          let errors = {}
          if (!values.name) {
            errors.name = i18n.loginForm.requiredField
          }
          if (!values.surname) {
            errors.surname = i18n.loginForm.requiredField
          }

          return errors
        }}
        onSubmit={data => {
          data = {
            ...data,
            birthDate: moment(data.birthDate, 'DD/MM/YYYY').toDate()
          }
          updateUserInfo(data)
        }}
      >
        {({ values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue }) => (
          <form onSubmit={handleSubmit}>
            <div className="user-form-wrapper">
              <p className="title-update">{i18n.updateUserForm.myData}</p>
              <div className="upload-image-wrapper">
                <div className="circle-image-wrapper" style={style}>
                  <div className="add-photo-title">
                    <p>{i18n.updateUserForm.changePhoto}</p>
                  </div>
                  <div className="input-wrapper">
                    <RegularInput
                      type="file"
                      name="image"
                      variant="image-file"
                      handleOnChange={event => {
                        setFieldValue('image', event.currentTarget.files[0])

                        let reader = new FileReader()
                        reader.onloadend = () => {
                          setImage(reader.result)
                        }
                        if (event.target.files[0]) {
                          reader.readAsDataURL(event.target.files[0])
                        }
                      }}
                    />
                  </div>
                </div>
              </div>

              <RegularInput
                label={i18n.updateUserForm.nameLabel}
                placeholder={i18n.updateUserForm.namePlaceholder}
                handleOnChange={handleChange}
                handleOnBlur={handleBlur}
                name="name"
                value={values.name}
                hasError={errors.name && touched.name && true}
              />
              {errors.name && touched.name && <p className="error-msg-input">{errors.name}</p>}

              <RegularInput
                label={i18n.updateUserForm.surnameLabel}
                placeholder={i18n.updateUserForm.surnamePlaceholder}
                handleOnChange={handleChange}
                handleOnBlur={handleBlur}
                name="surname"
                value={values.surname}
                hasError={errors.surname && touched.surname && true}
              />
              {errors.surname && touched.surname && <p className="error-msg-input">{errors.surname}</p>}

              <RegularInput
                label={i18n.updateUserForm.postalCodeLabel}
                placeholder={i18n.updateUserForm.postalCodePlaceholder}
                handleOnChange={handleChange}
                name="zipCode"
                value={values.zipCode}
              />

              <SingleDatePicker
                label={i18n.updateUserForm.birthDate}
                handleOnChange={date => {
                  setFieldValue('birthDate', date)
                }}
                name="birthDate"
                value={values.birthDate}
              />
            </div>
            <Button type="submit" isFetching={isFetching} variant="big" label={i18n.updateUserForm.save} />
          </form>
        )}
      </Formik>
      <p className="delete-user">
        <span onClick={toggleAcceptModal}>{i18n.updateUserForm.deleteUser}</span>
      </p>
      <ModalAccept
        isFetching={isFetching}
        desc={i18n.updateUserForm.confirmDelete}
        onAccept={disableUser}
        onReject={toggleAcceptModal}
        show={showAcceptModal}
        onHide={toggleAcceptModal}
      />
    </ComponentStyled>
  )
}

UpdateUserForm.propTypes = {
  userInfo: PropTypes.object,
  updateUserInfo: PropTypes.func,
  isFetching: PropTypes.bool,
  disableUser: PropTypes.func
}

export default UpdateUserForm
