import * as profitsActions from './actions'
import _ from 'lodash'

export const fetchList = () => async (dispatch, getState, { client, api, setError }) => {
  try {
    dispatch(profitsActions.setFetching(true))
    const customersQueryRes = await client.query({ query: api.queries.GET_PROFITS, fetchPolicy: 'network-only' })
    const profits = _.map(_.get(customersQueryRes, 'data.collectionQueryProfits.edges', []), v => v.node)

    dispatch(profitsActions.updateList(profits))
    return profits
  } catch (e) {
    dispatch(setError(e))
    return []
  } finally {
    dispatch(profitsActions.setFetching(false))
  }
}
