import React from 'react'
import PropTypes from 'prop-types'
import ContainerStyled from './styled'
import Modal from 'react-bootstrap/Modal'
import { UserCreateOrganization } from '../../organism'

const ModalCreateOrganization = ({ organizationId, activateOrg, show, onHide, openCreateThemeOrganization, toggleModalCreateOrganization }) => {
  return (
    <Modal show={show} onHide={onHide} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
      <ContainerStyled>
        <Modal.Body>
          <UserCreateOrganization
            activateOrg={activateOrg}
            organizationId={organizationId}
            onClickCreateTheme={openCreateThemeOrganization}
            onClickGoBeenfo={toggleModalCreateOrganization}
          />
        </Modal.Body>
      </ContainerStyled>
    </Modal>
  )
}

ModalCreateOrganization.propTypes = {
  organizationId: PropTypes.any,
  onHide: PropTypes.func,
  show: PropTypes.bool,
  toggleModalCreateOrganization: PropTypes.func,
  openCreateThemeOrganization: PropTypes.func,
  activateOrg: PropTypes.bool
}

export default ModalCreateOrganization
