import styled from 'styled-components'
import colors from '../../../assets/colors'

const RegisterFormStep1Styled = styled.div`
  && {
    .title {
      font-size: 16px;
      line-height: 24px;
      text-align: center;
      letter-spacing: 0.06px;
      color: ${colors.darkGrey2};
      margin-bottom: 30px;
    }

    .check-wrapper {
      margin-bottom: 0;
    }

    .accept-terms {
      text-align: center;
      font-size: 14px;
      letter-spacing: -0.43px;
      color: ${colors.darkGrey2};
      margin-top: 30px;
      font-weight: 600;
      margin-bottom: 0;

      span {
        margin-left: 10px;
        display: inline-block;
      }

      a {
        color: ${colors.yellow};
      }
    }

    .privacy-copy {
      font-size: 11px;

      a {
        color: ${colors.yellow};
      }
    }

    .forget-pass {
      text-align: center;
      font-size: 13px;
      letter-spacing: -0.43px;
      color: ${colors.darkGrey2};
      margin-bottom: 30px;
      margin-top: -10px;

      span,
      a {
        color: ${colors.yellow};
      }
    }

    .error-msg-input {
      margin-top: -25px;
    }
  }
`

export default RegisterFormStep1Styled
