import React, { Component } from 'react'
import { Row, Col } from 'react-bootstrap'
import ComponentStyled from './styled'
import { ResetPassPhone, CodeForm, ResetNewPassForm, StepCompleted } from '../../molecules'
import { CircleHeader } from '../../atoms'
import i18n from '../../../assets/i18n'
import { withRouter } from 'react-router'
import PropTypes from 'prop-types'
import { withFirebase } from '../../Firebase'
import firebase from 'firebase/app'
import { toast } from 'react-toastify'
import { TOO_MANY_ATTEMTPS_ERROR, INVALID_CODE_ERROR } from '../../../config/constants'
import _ from 'lodash'

const STEPS = {
  PHONE: 1,
  CODE: 2,
  PASSWORD: 3,
  COMPLETED: 4
}

class UserResetPass extends Component {
  state = {
    step: STEPS.PHONE,
    password: '',
    phone: null,
    code: null,
    country: null,
    isFetching: false,
    tokenFirebase: null
  }

  onSubmitPhone = (phone, country) => {
    const recaptchaVerifier = new firebase.auth.RecaptchaVerifier('sign-in-button', {
      size: 'invisible'
    })

    this.setState({ isFetching: true, phone, country }, async () => {
      try {
        const confirmResult = await this.props.firebase.signInWithPhoneNumber(phone, recaptchaVerifier)
        this.setState({ step: STEPS.CODE, confirmResult })
      } catch (e) {
        const message = _.get(e, 'code', i18n.generalError)

        if (message.includes(TOO_MANY_ATTEMTPS_ERROR)) {
          toast(i18n.userResetPass.tooManyAttempts, { type: toast.TYPE.ERROR })
        } else {
          toast(i18n.generalError, { type: toast.TYPE.ERROR })
        }
      } finally {
        this.setState({ isFetching: false })
      }
    })
  }

  onSubmitCode = ({ code }) => {
    const { confirmResult } = this.state
    if (confirmResult && code) {
      this.setState({ isFetching: true, code }, async () => {
        try {
          const { user } = await confirmResult.confirm(code.toString())
          const idToken = await user.getIdToken(true)
          this.setState({ tokenFirebase: idToken, step: STEPS.PASSWORD })
        } catch (e) {
          const message = _.get(e, 'code', i18n.generalError)
          if (message.includes(INVALID_CODE_ERROR)) {
            toast(i18n.userResetPass.errorCode, { type: toast.TYPE.ERROR })
          } else {
            toast(i18n.generalError, { type: toast.TYPE.ERROR })
          }
        } finally {
          this.setState({ isFetching: false })
        }
      })
    }
  }

  onSubmitPassword = ({ password }) => {
    const { resetPassword } = this.props
    const { tokenFirebase, phone } = this.state
    this.setState({ password, isFetching: true }, async () => {
      const resetPasswordData = {
        phoneNumber: phone,
        plainPassword: password,
        tokenFirebase: tokenFirebase
      }
      const resetPasswordSuccess = await resetPassword(resetPasswordData)
      if (resetPasswordSuccess) {
        this.setState({ step: STEPS.COMPLETED })
      } else {
        toast(i18n.generalError, { type: toast.TYPE.ERROR })
      }
      this.setState({ isFetching: false })
    })
  }

  renderStep = () => {
    const { step, isFetching } = this.state

    switch (step) {
      case STEPS.PHONE:
        return <ResetPassPhone isFetching={isFetching} onSubmit={this.onSubmitPhone} />
      case STEPS.CODE:
        return <CodeForm isFetching={isFetching} onSubmit={this.onSubmitCode} />
      case STEPS.PASSWORD:
        return <ResetNewPassForm isFetching={isFetching} onSubmit={this.onSubmitPassword} />
      case STEPS.COMPLETED:
        return <StepCompleted title={i18n.userResetPass.success} onFinish={this.props.onFinish} />
      default:
        break
    }
  }

  render() {
    const { step } = this.state
    const { onLoginTapped } = this.props
    return (
      <ComponentStyled>
        <div className="login-wrapper">
          <div className="circle-header">
            <CircleHeader />
          </div>
          <Row>
            <Col xs={12} sm={{ span: 8, offset: 2 }}>
              <div className="steps-wrapper">{this.renderStep()}</div>
            </Col>
          </Row>
          {step === STEPS.PHONE && (
            <Row>
              <Col xs={12} sm={{ span: 8, offset: 2 }} className="text-center">
                <p className="have-account">
                  {i18n.userResetPass.haveAccount}{' '}
                  <span className="pointer" onClick={onLoginTapped}>
                    {i18n.userResetPass.access}
                  </span>
                </p>
              </Col>
            </Row>
          )}
        </div>
      </ComponentStyled>
    )
  }
}

UserResetPass.propTypes = {
  firebase: PropTypes.object,
  signInWithPhoneNumber: PropTypes.func,
  history: PropTypes.object,
  onFinish: PropTypes.func,
  onLoginTapped: PropTypes.func,
  resetPassword: PropTypes.func
}

export default withFirebase(withRouter(UserResetPass))
