import _ from 'lodash'

export function getInitials(name) {
  const parts = _.split(name, ' ')
  let initials = ''
  for (let i = 0; i < parts.length; i++) {
    if (parts[i].length > 0 && parts[i] !== '') {
      initials += parts[i][0]
    }
  }
  return initials
}
