import i18n from '../../../assets/i18n'
import { /*validateIDDoc,*/ validatePostalCode } from '../../../utils'

const validate = values => {
  let errors = {}

  if (!values.name) {
    errors.name = i18n.createOrganizationForm.requiredField
  }

  if (!values.terms) {
    errors.terms = i18n.createOrganizationForm.requiredField
  }

  if (!values.businessName) {
    errors.businessName = i18n.createOrganizationForm.requiredField
  }

  if (!values.document) {
    errors.document = i18n.createOrganizationForm.requiredField
    /*
    } else if (validateIDDoc(values.document)) {
      errors.document = i18n.createOrganizationForm.wrongFormat
    }
    */
  }

  if (!values.address) {
    errors.address = i18n.createOrganizationForm.requiredField
  }

  if (!values.postalCode) {
    errors.postalCode = i18n.createOrganizationForm.requiredField
  } else if (validatePostalCode(values.postalCode)) {
    errors.postalCode = i18n.createOrganizationForm.wrongFormat
  }

  if (!values.province) {
    errors.province = i18n.createOrganizationForm.requiredField
  }

  if (!values.city) {
    errors.city = i18n.createOrganizationForm.requiredField
  }

  return errors
}

export default validate
