import React, { useState } from 'react'
import ComponentStyled from './styled'
import { Avatar } from '../../atoms'
import PropTypes from 'prop-types'
import { ReactComponent as ArroIcon } from '../../../assets/images/simple-right-arrow.svg'
import i18n from '../../../assets/i18n'
import { formatMoney } from '../../../utils'
import _ from 'lodash'

const MAX_NUMBER_SHORT_LIST = 2

const CustomerList = ({ items, title, onClickCustomer, withMemberNum = true }) => {
  const [showAll, setShowAll] = useState(false)

  function renderSectionFooter() {
    if (items.length === 0) {
      return (
        <p className="see-all">
          <span>{i18n.customerList.noItems}</span>
        </p>
      )
    }

    if (items.length <= MAX_NUMBER_SHORT_LIST) {
      return ''
    }

    if (!showAll) {
      return (
        <p className="see-all" onClick={() => setShowAll(true)}>
          <span>{i18n.customerList.seeAll}</span>
        </p>
      )
    }

    return (
      <p className="see-all" onClick={() => setShowAll(false)}>
        <span>{i18n.customerList.seeLess}</span>
      </p>
    )
  }

  let shortItems = _.clone(items)
  if (!showAll) {
    shortItems = shortItems.slice(0, MAX_NUMBER_SHORT_LIST)
  }

  return (
    <ComponentStyled onClickCustomer={onClickCustomer ? true : false}>
      <p className="customer-title">{title}</p>
      {shortItems.map((item, index) => {
        const resourceId = _.get(item, 'organization.image._id', null)
        const name = _.get(item, 'organization.name', '')
        const enabledMembers = _.get(item, 'organization.enabledMembers', 0)
        const amount = _.get(item, 'amount', 0)

        const subtitle = withMemberNum ? enabledMembers + ' ' + i18n.customerList.members : ''
        return (
          <div key={index} className="customer-wrapper" onClick={() => onClickCustomer(item)}>
            <div>
              <Avatar variant="customer" resourceId={resourceId} name={name} subtitle={subtitle} />
            </div>
            <p className="price">{formatMoney(amount)}</p>
            {onClickCustomer && <ArroIcon />}
          </div>
        )
      })}

      {renderSectionFooter()}
    </ComponentStyled>
  )
}

CustomerList.propTypes = {
  title: PropTypes.string,
  onClickCustomer: PropTypes.func,
  items: PropTypes.array,
  withMemberNum: PropTypes.bool
}

CustomerList.defaultProps = {
  items: [],
  withMemberNum: true
}

export default CustomerList
