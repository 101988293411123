import styled from 'styled-components'
import colors from '../../../assets/colors'

const CreateThemeStep4Styled = styled.div`
  && {
    text-align: center;

    .new-theme {
      font-size: 13px;
      letter-spacing: -0.43px;
      color: ${colors.darkGrey2};

      span,
      a {
        cursor: pointer;
        border-bottom: 1px solid ${colors.yellow};
      }
    }

    .button-wrapper {
      margin: 30px 0 15px 0;
    }
  }
`

export default CreateThemeStep4Styled
