import * as types from './types'

const initialState = {
  isFetching: false
}

const reducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case types.WS_SET_FETCHING:
      return {
        ...state,
        isFetching: action.value
      }

    default:
      return state
  }
}

export default reducer
