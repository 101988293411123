import * as unreadMessagesActions from './actions'
import _ from 'lodash'

const SET_READED_MS_DEBOUCE = 3000

export const initList = () => dispatch => {
  dispatch(unreadMessagesActions.updateList([], 0, null))
  return dispatch(fetchList())
}

export const fetchList = () => async (dispatch, getState, { api, client, setError }) => {
  try {
    dispatch(unreadMessagesActions.setFetching(true))

    const { item: organization } = getState().organizations
    const organizationId = _.get(organization, '_id')

    // GET UNREAD MESSAGE LIST
    const getUnreadMessagesRes = await api.getUnreadMessagesList(organizationId)

    // GET ASSOCIATED DIRECTS
    const directsIds = getUnreadMessagesRes?.data?.items?.map(v => `/api/directs/${v.directId}`)

    const getUnreadMessagesResDirects =
      directsIds.length > 0
        ? await client.query({
            query: api.queries.GET_DIRECTS,
            variables: { directsIds },
            fetchPolicy: 'network-only'
          })
        : []

    // ADD DIRECT DATA TO UNREAD MESSAGE LIST ITEM
    const unreadMessagesList = getUnreadMessagesRes?.data?.items?.map(v => {
      return { ...v, direct: getUnreadMessagesResDirects?.data?.directs?.edges?.find(d => d?.node?.['_id'] === v?.directId)?.node }
    })

    // SET LIST
    dispatch(unreadMessagesActions.updateList(unreadMessagesList))
    return unreadMessagesList
  } catch (e) {
    dispatch(setError(e))
    return []
  } finally {
    dispatch(unreadMessagesActions.setFetching(false))
  }
}

// POST NOTIFY DIRECT WITH DEBOUNCE
const postNotifyDirectDebounceCallback = direct => async (dispatch, getState, { api, setError }) => {
  const directId = _.get(direct, '_id')
  if (_.isNil(directId)) {
    return
  }

  try {
    const data = { direct: directId }
    const postNotifyDirectRes = await api.postNotifyDirect(data)

    const adminRead = _.get(postNotifyDirectRes, 'data.adminRead')
    const authorRead = _.get(postNotifyDirectRes, 'data.authorRead')

    const directFormatted = { ...direct, authorRead, adminRead }
    dispatch(fetchItem(directFormatted))
  } catch (e) {
    dispatch(setError(e))
  }
}

const postNotifyDirectDebounce = _.debounce((dispatch, direct) => dispatch(postNotifyDirectDebounceCallback(direct)), SET_READED_MS_DEBOUCE)

export const postNotifyDirect = direct => dispatch => {
  postNotifyDirectDebounce(dispatch, direct)
}

export const fetchItem = direct => async (dispatch, getState, { api, client, setError }) => {
  try {
    dispatch(unreadMessagesActions.setFetching(true))

    const { item: organization } = getState().organizations
    const organizationId = _.get(organization, '_id')

    // GET UNREAD MESSAGE ITEM
    const getUnreadMessagesRes = await api.getUnreadMessagesItem(organizationId, direct?.['_id'])

    // GET ASSOCIATED DIRECT
    const directId = `/api/directs/${getUnreadMessagesRes?.data?.directId}`
    const getUnreadMessagesResDirect = await client.query({
      query: api.queries.GET_DIRECT,
      variables: { directId },
      fetchPolicy: 'network-only'
    })

    // ADD DIRECT DATA TO UNREAD MESSAGE ITEM
    const unreadMessagesItem = { ...getUnreadMessagesRes?.data, direct: getUnreadMessagesResDirect?.data?.direct }

    // UPDATE ON LIST
    dispatch(updateDirectInUnreadMessagesList(unreadMessagesItem))
    return unreadMessagesItem
  } catch (e) {
    dispatch(setError(e))
    return null
  } finally {
    dispatch(unreadMessagesActions.setFetching(false))
  }
}

const updateDirectInUnreadMessagesList = unreadMessagesItem => async (dispatch, getState) => {
  if (!unreadMessagesItem) {
    return
  }

  const list = [...getState().unreadMessages.list]
  const unreadMessagesItemIndex = _.findIndex(list, { directId: unreadMessagesItem.directId })

  // ADD, UPDATE OR REMOVE DIRECT IN LIST
  if (unreadMessagesItemIndex > -1) {
    // DIRECT IS IN LIST
    if (unreadMessagesItem.unreadMessages > 0) {
      // REPLACE DIRECT IN LIST
      list.splice(unreadMessagesItemIndex, 1, unreadMessagesItem)
    } else {
      // REMOVE DIRECT IF NEEDED
      list.splice(unreadMessagesItemIndex, 1)
    }
  } else if (unreadMessagesItem.unreadMessages > 0) {
    // ADD DIRECT TO LIST
    list.push(unreadMessagesItem)
  }

  // SET DIRECTS LIST WITH UNREAD MESSAGES
  dispatch(unreadMessagesActions.updateList(list))
}
