import styled from 'styled-components'
import colors from '../../../assets/colors'

const HeaderDashboardStyled = styled.header`
  && {
    position: relative;
    padding: 15px 45px;
    background-color: ${colors.yellow};
    min-height: 75px;

    .menu-popup {
      background-color: ${colors.white};
      padding-top: 20px;
      box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.14);
      border-radius: 5px;
      border: 0;
      min-width: 200px;
      left: inherit !important;
      top: 130% !important;
      right: 0 !important;
      transform: inherit !important;
    }

    .header-avatar-popup-wrapper {
      position: relative;
      display: inline-block;

      .header-avatar-wrapper {
        display: inline-flex;
        align-items: center;
        justify-content: flex-end;
        cursor: pointer;

        .arrow {
          margin-left: 5px;
        }
      }
    }
  }
`

export default HeaderDashboardStyled
