import styled from 'styled-components'
import colors from '../../../assets/colors'

const ComponentStyled = styled.div`
  && {
    .title-chat-wrapper {
      padding-bottom: 30px;
      position: relative;
      margin-bottom: 15px;

      .title-chat {
        font-size: 20px;
        line-height: 24px;
        letter-spacing: -0.62px;
        color: ${colors.black};
        margin-bottom: 0;
      }
    }
    .button-wrapper {
      text-align: center;
      margin-bottom: 50px;
    }
  }
`

export default ComponentStyled
