import { connect } from 'react-redux'
import compose from 'recompose/compose'
import { organizationsOperations } from '../../../redux/organizations'
import View from './view'

const mapStateToProps = state => {
  return {
    isFetching: state.organizations.isFetching,
    organization: state.organizations.item
  }
}

const mapDispatchToProps = dispatch => {
  return {
    fetchOrganizationPaymentsData: organization => dispatch(organizationsOperations.fetchOrganizationPayments(organization)),
    fetchOrganizationCurrentBillingData: organization => dispatch(organizationsOperations.fetchOrganizationCurrentBillingData(organization)),
    fetchOrganizationSubscription: organization => dispatch(organizationsOperations.fetchOrganizationSubscriptionData(organization)),
    deleteOrganization: (organization, subscriptionId) => dispatch(organizationsOperations.deleteOrganization(organization, subscriptionId))
  }
}

const enhance = compose(connect(mapStateToProps, mapDispatchToProps))

export default enhance(View)
