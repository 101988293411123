import React, { Component } from 'react'
import ComponentStyled from './styled'
import { RegularInput, Button } from '../../atoms'
import i18n from '../../../assets/i18n'
import { Formik } from 'formik'
import _ from 'lodash'
import PropTypes from 'prop-types'
import { getSignedUrl } from '../../../api'

class UpdateOrganizationForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      image: ''
    }
  }

  componentDidMount() {
    const imageId = _.get(this.props.organization, 'image._id')

    if (imageId) {
      getSignedUrl(imageId)
        .then(({ data }) => {
          this.setState({ image: data })
        })
        .catch(() => {})
    }
  }

  render() {
    const { organization, onSubmit, isFetching } = this.props
    const { image } = this.state
    const name = _.get(organization, 'name', '')

    return (
      <ComponentStyled>
        <Formik
          initialValues={{
            name
          }}
          validate={values => {
            let errors = {}
            if (!values.name) {
              errors.name = i18n.loginForm.requiredField
            }
            return errors
          }}
          onSubmit={data => {
            onSubmit(data)
          }}
        >
          {({ values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue }) => (
            <form onSubmit={handleSubmit}>
              <div className="user-form-wrapper">
                <p className="title-update">{i18n.updateOrganizationForm.myOrg}</p>
                <div className="upload-image-wrapper">
                  <div
                    className="circle-image-wrapper"
                    style={{
                      backgroundImage: 'url(' + image + ')'
                    }}
                  >
                    <div className="add-photo-title">
                      <p>{i18n.updateOrganizationForm.changePhoto}</p>
                    </div>
                    <div className="input-wrapper">
                      <RegularInput
                        type="file"
                        name="image"
                        variant="image-file"
                        handleOnChange={event => {
                          setFieldValue('image', event.currentTarget.files[0])

                          let reader = new FileReader()
                          reader.onloadend = () => {
                            this.setState({ image: reader.result })
                          }
                          if (event.target.files[0]) {
                            reader.readAsDataURL(event.target.files[0])
                          }
                        }}
                      />
                    </div>
                  </div>
                </div>

                <RegularInput
                  label={i18n.updateOrganizationForm.nameLabel}
                  placeholder={i18n.updateOrganizationForm.namePlaceholder}
                  handleOnChange={handleChange}
                  handleOnBlur={handleBlur}
                  name="name"
                  value={values.name}
                  hasError={errors.name && touched.name && true}
                />
                {errors.name && touched.name && <p className="error-msg-input">{errors.name}</p>}
              </div>
              <Button isFetching={isFetching} variant="big" label={i18n.updateOrganizationForm.save} />
            </form>
          )}
        </Formik>
      </ComponentStyled>
    )
  }
}

UpdateOrganizationForm.propTypes = {
  organization: PropTypes.object,
  onSubmit: PropTypes.func,
  isFetching: PropTypes.bool
}

export default UpdateOrganizationForm
