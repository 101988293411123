import styled, { css } from 'styled-components'
import colors from '../../../assets/colors'

const ButtonStyled = styled.div`
  && {
    display: inline-block;
    position: relative;
    opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};

    .spinner {
      animation: rotating 2s linear infinite;
      position: absolute;
    }

    .spinner path {
      transition: 0.5s ease all;
    }

    @keyframes rotating {
      from {
        transform: rotate(0deg);
      }
      to {
        transform: rotate(360deg);
      }
    }

    button {
      font-size: 12px;
      line-height: 12px;
      font-weight: 600;
      min-height: 43px;
      min-width: 124px;
      letter-spacing: 1.5px;
      text-transform: uppercase;
      color: ${colors.white};
      background-color: ${colors.darkGrey};
      padding: 15px;
      outline: none;
      border-radius: 50px;
      border: 1px solid ${colors.darkGrey};
      transition: 0.5s ease all;
      display: flex;
      justify-content: center;
      align-items: center;

      .icon-right {
        margin-left: 5px;
        margin-top: -2px;
      }

      :hover {
        background-color: transparent;
        color: ${colors.darkGrey};
      }

      :hover svg path {
        fill: ${colors.darkGrey};
      }
    }

    ${({ hasShadow }) =>
      hasShadow &&
      css`
        button {
          box-shadow: 0px 2px 11px 0px #0000000f;
        }
      `};

    ${props =>
      props.variant === 'link' &&
      css`
        button {
          background-color: transparent;
          color: ${colors.black};
          border: none;
          & svg path {
            fill: ${colors.black};
          }
          :hover {
            color: ${colors.darkGrey};
            & svg path {
              fill: ${colors.darkGrey};
            }
          }
        }
      `};

    ${props =>
      props.variant === 'yellow' &&
      css`
        button {
          background-color: ${colors.yellow};
          color: ${colors.darkGrey};
          border-color: ${colors.yellow};
          :hover {
            color: ${colors.darkGrey};
          }
        }
      `};

    ${props =>
      props.variant === 'white' &&
      css`
        button {
          background-color: ${colors.white};
          color: ${colors.darkGrey};
          border-color: ${colors.white};
          :hover {
            color: ${colors.darkGrey};
          }
        }
      `};

    ${props =>
      props.variant === 'big' &&
      css`
        && {
          display: block;
          button {
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 25px;
            width: 100%;
            min-height: 62px;

            :hover svg path {
              fill: ${colors.darkGrey};
            }
          }
        }
      `};

    ${props =>
      props.variant === 'medium' &&
      css`
        && {
          display: block;
          button {
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 15px 25px;
            width: 100%;

            :hover svg path {
              fill: ${colors.darkGrey};
            }
          }
        }
      `};

    ${props =>
      props.variant === 'white-small' &&
      css`
        && {
          display: block;
          button {
            background-color: ${colors.white};
            color: ${colors.darkGrey};
            border-color: ${colors.white};
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 10px 20px;
            width: 100%;
            min-height: 20px;

            :hover {
              color: ${colors.darkGrey};
            }

            :hover svg path {
              fill: ${colors.darkGrey};
            }
          }
        }
      `};
  }
`

export default ButtonStyled
