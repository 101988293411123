import styled from 'styled-components'
import colors from '../../../assets/colors'

const ComponentStyled = styled.div`
  && {
    .spinner-wrapper {
      text-align: center;
      .spinner {
        animation: rotating 2s linear infinite;
        width: 40px;
        height: 40px;
        max-height: 40px;
        max-width: 40px;
        transition: 0.5s ease all;
        margin: 0 auto;
      }
    }

    @keyframes rotating {
      from {
        transform: rotate(0deg);
      }
      to {
        transform: rotate(360deg);
      }
    }

    .title-list {
      font-size: 13px;
      line-height: 15px;
      text-transform: uppercase;
      color: ${colors.darkGrey};
      font-weight: 600;
      text-align: center;
    }

    .add-link {
      text-align: center;
      font-size: 13px;
      line-height: 15px;
      color: ${colors.yellow};

      span {
        cursor: pointer;
      }
    }

    .error-msg-input {
      margin-top: -25px;
    }

    .participant-list-wrapper {
      margin-bottom: 15px;
      cursor: pointer;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .remove {
        color: ${colors.red};
        font-size: 14px;
        margin-bottom: 0;
      }
    }

    .participant-wrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .delete-participant {
        font-size: 13px;
        line-height: 15px;
        color: ${colors.red};
        cursor: pointer;
      }
    }

    .search-field-wrapper {
      margin-bottom: 30px;
    }

    .numbers-added {
      margin-bottom: 50px;
    }
  }
`

export default ComponentStyled
