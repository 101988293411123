import { connect } from 'react-redux'
import compose from 'recompose/compose'
import View from './view'
import { messagesOperations } from '../../../redux/messages'

const mapStateToProps = () => {
  return {}
}

const mapDispatchToProps = dispatch => {
  return {
    initThemeMessagesList: showBroadcastOnly => dispatch(messagesOperations.initThemeMessagesList(showBroadcastOnly))
  }
}

const enhance = compose(connect(mapStateToProps, mapDispatchToProps))

export default enhance(View)
