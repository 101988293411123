import React from 'react'
import PropTypes from 'prop-types'
import { Switch, Route, Redirect } from 'react-router-dom'
import { withRouter } from 'react-router-dom'
import routes from '../../../config/routes'
import * as pages from '../../pages'
import _ from 'lodash'
import qs from 'qs'

class Routes extends React.PureComponent {
  static propTypes = {
    location: PropTypes.object,
    auth: PropTypes.object,
    history: PropTypes.object
  }

  previousLocation = this.props.location

  componentDidMount() {
    const params = qs.parse(this.props.location.search, { ignoreQueryPrefix: true })
    if (params.commercialId) {
      localStorage.setItem('commercialId', params.commercialId)
    }
  }

  componentWillUpdate(nextProps) {
    const { location } = this.props

    // React Router Modal Fix
    if (nextProps.history.action !== 'POP' && (!location.state || !location.state.modal)) {
      this.previousLocation = location
    }
  }

  render() {
    const { location } = this.props
    const token = localStorage.getItem('token')

    return (
      <Switch location={location}>
        <Route exact path={routes.home} component={pages.Home} />
        <Route path={routes.terms} component={pages.Terms} />
        <Route path={routes.b2bPolicy} component={pages.B2bPolicy} />
        <Route path={routes.privacyPolicy} component={pages.PrivacyPolicy} />
        <Route path={routes.legalPolicy} component={pages.LegalPolicy} />
        <ProtectedRoute isAllowed={!_.isNil(token)} exact path={routes.dashboard} component={pages.Dashboard} />
        <ProtectedRoute isAllowed={!_.isNil(token)} exact path={routes.statsDashboard} component={pages.StatsDashboard} />
        <Route path={'*'} component={pages.PageNotFound} />
      </Switch>
    )
  }
}

const ProtectedRoute = ({ isAllowed, ...props }) => (isAllowed ? <Route {...props} /> : <Redirect to={routes.home} />)

export default withRouter(Routes)
