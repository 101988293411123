import { connect } from 'react-redux'
import compose from 'recompose/compose'
import View from './view'
import { withRouter } from 'react-router'
import { usersOperations } from '../../../redux/users'

const mapStateToProps = state => {
  return {
    isFetchingUsers: state.users.organizationUsersLoading
  }
}

const mapDispatchToProps = dispatch => {
  return {
    fetchOrganizationUsers: searchText => dispatch(usersOperations.initOrganizationUsersList(searchText))
  }
}

const enhance = compose(connect(mapStateToProps, mapDispatchToProps))

export default withRouter(enhance(View))
