import React, { Component } from 'react'
import PropTypes from 'prop-types'
import AvatarStyled from './styled'
import { getInitials } from './utils'
import { getSignedUrl } from '../../../api'

class Avatar extends Component {
  constructor(props) {
    super(props)
    this._mounted = true
    this.state = { resourceUri: null }

    if (props.resourceId) {
      getSignedUrl(props.resourceId)
        .then(({ data }) => {
          this._mounted && this.setState({ resourceUri: data })
        })
        .catch(() => {
          this._mounted && this.setState({ resourceUri: '' })
        })
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.resourceId !== prevProps.resourceId) {
      if (this.props.resourceId) {
        getSignedUrl(this.props.resourceId)
          .then(({ data }) => {
            this._mounted && this.setState({ resourceUri: data })
          })
          .catch(() => {
            this._mounted && this.setState({ resourceUri: '' })
          })
      } else {
        this._mounted && this.setState({ resourceUri: '' })
      }
    }
  }

  componentWillUnmount() {
    this._mounted = false
  }

  render() {
    const { variant, name, subtitle, handleOnClick, showFullname, size } = this.props
    const { resourceUri } = this.state

    return (
      <AvatarStyled variant={variant} size={size} onClick={handleOnClick} imageBg={resourceUri}>
        <div className="avatar">
          <span>{getInitials(name)}</span>
        </div>
        <div className="text-wrapper">
          {name && showFullname && <p className="avatar-name">{name}</p>}
          {subtitle && <p className="subtitle">{subtitle}</p>}
        </div>
      </AvatarStyled>
    )
  }
}

Avatar.defaultProps = {
  showFullname: true,
  handleOnClick: () => {}
}

Avatar.propTypes = {
  resourceId: PropTypes.any,
  resourceUri: PropTypes.string,
  size: PropTypes.string,
  showFullname: PropTypes.bool,
  variant: PropTypes.string,
  name: PropTypes.string,
  handleOnClick: PropTypes.func,
  subtitle: PropTypes.string
}

export default Avatar
