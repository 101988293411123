import styled from 'styled-components'
import colors from '../../../assets/colors'

const CreateThemeStep2Styled = styled.div`
  && {
    .spinner-wrapper {
      text-align: center;
      .spinner {
        animation: rotating 2s linear infinite;
        width: 40px;
        height: 40px;
        max-height: 40px;
        max-width: 40px;
        transition: 0.5s ease all;
        margin: 0 auto;
      }
    }

    @keyframes rotating {
      from {
        transform: rotate(0deg);
      }
      to {
        transform: rotate(360deg);
      }
    }

    .list-wrapper {
      margin-bottom: 30px;

      .title-list {
        font-size: 14px;
        font-weight: 600;
        line-height: 16px;
        letter-spacing: 1.08025px;
        text-transform: uppercase;
        color: ${colors.black};
      }

      .theme-list-wrapper {
        padding: 20px 0;
        display: flex;
        align-items: center;
        transition: 0.5s ease all;
        justify-content: space-between;
        cursor: pointer;
        position: relative;

        .theme-name {
          font-size: 16px;
          line-height: 16px;
          color: #000000;
          margin-bottom: 0;
        }
      }

      .participant-list-wrapper {
        padding: 10px 0;
        display: flex;
        align-items: center;
        transition: 0.5s ease all;
        justify-content: space-between;
        cursor: pointer;
        position: relative;
      }
    }

    .search-field-wrapper {
      max-width: 300px;
      margin: 0 auto;

      margin-bottom: 30px;
    }

    .submit-participants-wrapper {
      max-width: 300px;
      margin: 0 auto;
    }

    .check-all {
      font-size: 13px;
      letter-spacing: -0.43px;
      color: ${colors.darkGrey2};
      text-align: center;
      margin-top: 20px;

      span,
      a {
        cursor: pointer;
        border-bottom: 1px solid ${colors.yellow};
      }
    }

    .error-msg-input {
      margin-top: -25px;
    }
  }
`

export { CreateThemeStep2Styled }
