import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import ComponentStyled from './styled'
import i18n from '../../../assets/i18n'
import { ImageText } from '../../molecules'

const Advantages = () => {
  return (
    <ComponentStyled>
      <Container>
        <div className="box-wrapper">
          <Row>
            <Col xs={12} className="text-center">
              <h2 className="title">{i18n.advantages.quote}</h2>
            </Col>
          </Row>
          <Row className="row-images">
            <Col xs={12} sm={5}>
              <div className="image-wrapper">
                <ImageText imageUrl={require('../../../assets/images/city.jpg')} desc={i18n.advantages.desc1} />
              </div>
            </Col>
            <Col xs={12} sm={5}>
              <div className="image-wrapper">
                <ImageText imageUrl={require('../../../assets/images/comunication.jpg')} desc={i18n.advantages.desc2} />
              </div>
            </Col>
          </Row>
        </div>
      </Container>
    </ComponentStyled>
  )
}

export default Advantages
