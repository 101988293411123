import styled from 'styled-components'
import colors from '../../../assets/colors'

const ComponentStyled = styled.div`
  && {
    .pop-up {
      ul {
        list-style: none;
        margin-bottom: 0;
        padding: 0 15px;

        li {
          margin: 20px 0;
          font-size: 16px;
          line-height: 16px;
          color: ${colors.black};
          cursor: pointer;

          &.warning-select {
            color: ${colors.red};
          }
        }
      }
    }
  }
`

export default ComponentStyled
