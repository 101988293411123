import styled from 'styled-components'
import colors from '../../../assets/colors'

const CreateThemeStep3Styled = styled.div`
  && {
    .spinner-wrapper {
      text-align: center;

      .spinner {
        animation: rotating 2s linear infinite;
        width: 40px;
        height: 40px;
        max-height: 40px;
        max-width: 40px;
        transition: 0.5s ease all;
        margin: 0 auto;
      }
    }

    @keyframes rotating {
      from {
        transform: rotate(0deg);
      }
      to {
        transform: rotate(360deg);
      }
    }

    .list-wrapper {
      margin-bottom: 30px;

      .title-list {
        font-size: 14px;
        font-weight: 600;
        line-height: 16px;
        letter-spacing: 1.08025px;
        text-transform: uppercase;
        color: ${colors.black};
      }

      .admin-list-wrapper {
        padding: 10px 0;
        display: flex;
        align-items: center;
        transition: 0.5s ease all;
        justify-content: space-between;
        cursor: pointer;
        position: relative;
      }
    }

    .search-field-wrapper {
      max-width: 300px;
      margin: 0 auto;
      margin-bottom: 30px;
    }

    .submit-admins-wrapper {
      width: 100%;
      max-width: 300px;
      margin: 0 auto;
    }

    .footer-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;

      .check-all,
      .back-wrapper {
        font-size: 13px;
        letter-spacing: -0.43px;
        color: ${colors.darkGrey2};
        text-align: center;
        margin: 20px 10px 10px 10px;

        span,
        a {
          cursor: pointer;
          border-bottom: 1px solid ${colors.yellow};
        }
      }

      .error-msg-input {
        margin-top: -25px;
      }
    }
  }
`

export default CreateThemeStep3Styled
