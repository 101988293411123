import styled from 'styled-components'
import colors from '../../../assets/colors'

const ThemeListItemStyled = styled.div`
  && {
    padding: 15px 0;
    transition: 0.5s ease all;
    cursor: pointer;

    > p {
      margin-bottom: 0;
      font-size: 16px;
      line-height: 24px;
      color: ${colors.darkGrey3};
    }
  }
`

export default ThemeListItemStyled
