import styled from 'styled-components'
import colors from '../../../assets/colors'

const ComponentStyled = styled.div`
  && {
    .title-billing-wrapper {
      padding-bottom: 30px;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 30px;

      ::before {
        content: '';
        position: absolute;
        bottom: 0;
        width: 1000px;
        right: 50%;
        transform: translateX(50%);
        height: 1px;
        background-color: ${colors.lightGrey};
      }

      .title-billing {
        font-size: 20px;
        line-height: 24px;
        letter-spacing: -0.62px;
        color: ${colors.black};
        margin-bottom: 0;
      }
    }

    .section-title {
      font-size: 14px;
      font-weight: 600;
      line-height: 16px;
      letter-spacing: 1.08px;
      text-transform: uppercase;
      margin-bottom: 30px;

      &.monthly-section {
        margin-bottom: 20px;
      }
    }

    .item-wrapper {
      text-align: center;
      margin-bottom: 30px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    .monthly-wrapper {
      padding: 25px 0 25px 25px;
    }

    .see-all {
      text-align: center;
      font-size: 14px;
      line-height: 44px;
      letter-spacing: -0.43px;
      color: ${colors.darkGrey8};
    }

    .section-subtitle {
      font-size: 16px;
      line-height: 16px;
      margin-bottom: 10px;
    }

    .billing-card,
    .payment-method {
      padding-left: 25px;
    }

    .payment-method p.section-desc,
    .billing-card p.section-desc {
      font-size: 14px;
      line-height: 14px;
      color: ${colors.grey2};
      margin-bottom: 5px;
    }

    .cancel-sub {
      font-size: 14px;
      line-height: 14px;
      color: ${colors.red};
    }
    .cancel-sub-clickable {
      cursor: pointer;
    }
    .section-wrapper {
      margin-bottom: 60px;
    }

    .billing-card p:not(.section-subtitle) {
      margin-bottom: 15px;
    }

    .payment-method {
      .change-payment {
        font-size: 14px;
        line-height: 14px;
        color: ${colors.grey2};
        font-weight: bold;
        text-decoration: underline;
        margin-top: 15px;
        margin-bottom: 15px;

        span {
          cursor: pointer;
        }
      }
    }

    .btn-wrapper {
      text-align: center;
    }

    .back {
      cursor: pointer;

      .arrow {
        margin-right: 15px;
      }
    }
  }
`

export default ComponentStyled
