import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import compose from 'recompose/compose'
import { themesOperations } from '../../../redux/themes'
import { directsOperations } from '../../../redux/directs'
import { organizationsOperations } from '../../../redux/organizations'
import View from './view'

const mapStateToProps = state => {
  return {
    organization: state.organizations.item,
    userInfo: state.me.userInfo
  }
}

const mapDispatchToProps = dispatch => {
  return {
    getHomeSearchQuery: searchText => dispatch(organizationsOperations.getHomeSearchQuery(searchText)),
    initDirects: () => dispatch(directsOperations.initList()),
    initThemes: () => dispatch(themesOperations.initList())
  }
}

const enhance = compose(connect(mapStateToProps, mapDispatchToProps))

export default withRouter(enhance(View))
