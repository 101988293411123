import styled from 'styled-components'
import colors from '../../../assets/colors'

const DirectChatListStyled = styled.div`
  && {
    padding: 15px 0;

    .no-read-msg-wrapper {
      cursor: pointer;
      position: relative;

      > div {
        position: relative;
        z-index: 1;
      }

      ::before {
        content: '';
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        width: 1000px;
        height: 100%;
        background-color: ${colors.grey};
        opacity: 0;
        transition: 0.5s ease all;
      }

      &:hover:before {
        opacity: 1;
      }
    }

    .no-read-title-list {
      font-size: 14px;
      font-weight: 600;
      line-height: 16px;
      letter-spacing: 1.08025px;
      text-transform: uppercase;
      color: ${colors.black};
    }
  }
`

export default DirectChatListStyled
