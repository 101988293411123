import React, { Component } from 'react'
import PropTypes from 'prop-types'
import i18n from '../../../assets/i18n'
import { Button, RegularInput, RegularSelect } from '../../atoms'
import { ComponentStyled } from './styles'
import _ from 'lodash'
import { toast } from 'react-toastify'

class ThemePollCreate extends Component {
  constructor(props) {
    super(props)
    this.state = {
      question: '',
      multiple: null,
      answer: '',
      answers: [],
      answerEditingKey: null,
      isEditingField: false
    }
  }

  _onSubmit = async () => {
    const { onSubmit, theme, closeSidebars } = this.props
    const { question, multiple, answers } = this.state

    const data = {
      name: question,
      isMultiple: multiple,
      answers: _.map(answers, v => ({ textAnswer: v })),
      theme: _.get(theme, 'id')
    }

    try {
      await onSubmit(data)
      closeSidebars()
      this.setState({
        question: '',
        multiple: null,
        answer: '',
        answers: [],
        answerEditingKey: null,
        isEditingField: false
      })
    } catch {
      toast(i18n.generalError, { type: toast.TYPE.ERROR })
    }
  }

  _onAddAnswer = () => {
    const { answer, answers, previewRowKey } = this.state
    if (answer) {
      let state = { answer: '', answers: [...answers, answer] }
      if (!previewRowKey) {
        state.previewRowKey = `cell-${0}-${answer}`
      }
      this.setState(state)
    }
  }

  setIsEditing = () => {
    if (this.state.pollName) {
      this.setState({ creatingPoll: true })
    }
  }

  setMultiple = value => {
    let multiple = null

    if (value) {
      multiple = value === '0' ? false : true
    }

    this.setState({ multiple })
  }

  setEditField = (key, value) => {
    let answers = [...this.state.answers]
    answers[key] = value
    this.setState({ answers: answers })
  }

  setPollName = value => {
    this.setState({ pollName: value })
  }

  render() {
    const { isFetching } = this.props
    const { answer, answers, answerEditingKey, multiple, isEditingField, question } = this.state
    const multipleOptions = [
      { value: 0, label: i18n.poll.singleLabel },
      { value: 1, label: i18n.poll.multipleLabel }
    ]

    return (
      <ComponentStyled>
        <div className="poll-header">{i18n.poll.submitLabel}</div>

        <RegularInput
          label={i18n.poll.questionLabel}
          placeholder={i18n.poll.questionPlaceholder}
          handleOnChange={(e, value) => this.setState({ question: value })}
          value={question}
        />
        <RegularSelect
          label={i18n.poll.typeOfSelection}
          options={multipleOptions}
          defaultLabel={i18n.poll.selectAnOption}
          handleOnChange={(e, value) => this.setMultiple(value)}
        />
        {_.map(answers, (item, key) => {
          return (
            <div key={`item-${key}`}>
              <p className="answer-item-title">
                {i18n.poll.answerLabel} {key + 1}
              </p>
              <div className="answer-item">
                <RegularInput
                  handleOnChange={(e, value) => {
                    this.setEditField(key, value)
                  }}
                  value={item}
                  disabled={key === answerEditingKey && isEditingField ? false : true}
                />
                <p
                  className="answer-edit-item"
                  onClick={() => {
                    this.setState({ answerEditingKey: key, isEditingField: !isEditingField })
                  }}
                >
                  {answerEditingKey === key && isEditingField ? i18n.poll.save : i18n.poll.edit}
                </p>
              </div>
            </div>
          )
        })}
        {question && (
          <>
            <p className="answer-item-title">{i18n.poll.newAnswer}</p>
            <div className="answer-item">
              <RegularInput
                placeholder={i18n.poll.answerPlaceholder}
                handleOnChange={v => this.setState({ answer: v.currentTarget.value })}
                value={answer && answer}
              />
              <p className="answer-edit-item" onClick={this._onAddAnswer}>
                {i18n.addParticipantsForm.add}
              </p>
            </div>
          </>
        )}

        {answers.length > 1 && !_.isNil(multiple) && (
          <Button isFetching={isFetching} label={i18n.poll.ok} variant={'big'} id={answers.lenght} key={answers.lenght} handleOnClick={this._onSubmit} />
        )}
      </ComponentStyled>
    )
  }
}

ThemePollCreate.propTypes = {
  isFetching: PropTypes.bool,
  onSubmit: PropTypes.func,
  theme: PropTypes.object,
  closeSidebars: PropTypes.func
}

export default ThemePollCreate
