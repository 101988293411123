import { gql } from 'apollo-boost'
import _map from 'lodash/map'
import _get from 'lodash/get'

const ORGANIZATIONS_PER_PAGE = 100
const THEMES_PER_PAGE = 100
const MESSAGES_PER_PAGE = 20
const DIRECTS_PER_PAGE = 10
const CLIENTS_PER_PAGE = 100
const USERS_PER_PAGE = 100
const PARTICIPANTS_PER_PAGE = 100
const ADMINS_PER_PAGE = 100
const INVITATIONS_REMAINING_LIMIT = 100
const NO_PAGINATION = 1000

const MEDIA_OBJECT_DATA = `
  id,
  _id,
  contentUrl,
  mimeType,
  filePath,
  fileName
`

const USER_DATA = `
  id
  _id
  fullname
  name
  surname
  username
  image {
    _id
  }
`

const ORGANIZATION_DATA = `
  id
  _id
  isClient
  name
  image {
    _id
  }
  subscribed
  subscriptionStatus
  businessName
  document
  address
  postalCode
  province
  city
  clients {
    edges {
      node {
        id
        _id
      }
    }
  }
`

const THEME_DATA = `
  id
  _id
  isAdmin
  name
  description
  readOnly
  endDate
  editable
`

const DIRECT_DATA = `
  id
  _id
  adminRead
  authorRead
  author {
    ${USER_DATA}
  }
  theme {
    ${THEME_DATA}
  }
`

const MESSAGE_DATA = `
  id
  _id
  broadcast
  seeMore
  created
  text
  type
  author {
    ${USER_DATA}
  }
  mediaObject {
    ${MEDIA_OBJECT_DATA}
  }
`

export const GET_ORGANIZATION_SUBSCRIPTION = gql`
  query getOrganization($organizationId: ID!) {
    organization(id: $organizationId) {
      ${ORGANIZATION_DATA}
      subscription {
        _id
        cancelAtPeriodEnd
        canceledAt
        currentPeriodStart
        currentPeriodEnd
        status
        paymentMethod {
          pan
          expiryYear
          expiryMonth
          brand
        }
      }
    }
  }
`

// ORGANIZATION
export const GET_ORGANIZATIONS = gql`
  query getUserOrganizations($endCursor: String) {
    organizations(first: ${ORGANIZATIONS_PER_PAGE}, after: $endCursor) {
      totalCount
      pageInfo {
        endCursor
        hasNextPage
      }
      edges {
        node {
          ${ORGANIZATION_DATA}
        }
      }
    }
  }
`

export const GET_ORGANIZATION = gql`
  query getOrganization($organizationId: ID!) {
    organization(id: $organizationId) {
      ${ORGANIZATION_DATA}
    }
  }
`

export const GET_ORGANIZATION_CLIENTS = gql`
  query organizationClientsUsers($organizationId: ID!, $searchText: String, $endCursor: String) {
    organizationClientsUsers(organization: $organizationId, searchText: $searchText, first: ${CLIENTS_PER_PAGE}, after: $endCursor) {
        totalCount
        pageInfo {
          endCursor
          hasNextPage
        }
        edges {
          node {
            ${USER_DATA}
          }
        }
    }
  }
`

export const GET_ORGANIZATION_USERS = gql`
  query organizationUsersUsers($organizationId: ID!, $searchText: String, $endCursor: String) {
    organizationUsersUsers(organization: $organizationId, searchText: $searchText, first: ${USERS_PER_PAGE}, after: $endCursor) {
        totalCount
        pageInfo {
          endCursor
          hasNextPage
        }
        edges {
          node {
            ${USER_DATA}
          }
        }
    }
  }
`

export const CHECK_USER_IS_IN_ORGANIZATION = gql`
  query checkUserIsInOrganization($organizationId: ID!, $username: String) {
    organizationClientsUsers(organization: $organizationId, username: $username) {
        edges {
          node {
            ${USER_DATA}
          }
        }
    }
    organizationUsersUsers(organization: $organizationId, , username: $username) {
        edges {
          node {
            ${USER_DATA}
          }
        }
    }
  }
`

// THEMES
export const GET_THEME = gql`
  query getTheme($themeId: ID!) {
    theme(id: $themeId) {
      ${THEME_DATA}
    }
  }
`

export const GET_ORGANIZATION_THEMES = gql`
  query getOrganizationThemes($organizationId: String!, $userId: String) {
    themes(organization_id: $organizationId, order: {name: "asc"}, first: ${THEMES_PER_PAGE}) {
      edges {
        node {
          ${THEME_DATA}
          directs(author_id: $userId) {
            edges {
              node {
                ${DIRECT_DATA}
              }
            }
          }
        }
      }
    }
  }
`

export const GET_THEME_PARTICIPANTS = gql`
  query getThemeParticipants($themeId: ID!, $searchText: String, $endCursor: String) {
    themeParticipantsUsers(theme: $themeId, searchText: $searchText, first: ${PARTICIPANTS_PER_PAGE}, after: $endCursor) {
      totalCount
      pageInfo {
        endCursor
        hasNextPage
      }
      edges {
        node {
          ${USER_DATA}
        }
      }
    }
  }
`

export const GET_THEME_ADMINS = gql`
  query getThemeAdmins($themeId: ID!, $searchText: String, $endCursor: String) {
    themeAdminsUsers(theme: $themeId, searchText: $searchText, first: ${ADMINS_PER_PAGE}, after: $endCursor) {
      totalCount
      pageInfo {
        endCursor
        hasNextPage
      }
      edges {
        node {
          ${USER_DATA}
        }
      }
    }
  }
`

export const GET_THEME_MESSAGES = gql`
  query getThemeMessages($themeId: ID!, $broadcast: Boolean, $startCursor: String) {
    theme(id: $themeId) {
      id
      _id
      messages(broadcast: $broadcast, last: ${MESSAGES_PER_PAGE}, before: $startCursor) {
        totalCount
        pageInfo {
          startCursor
          hasPreviousPage
        }
        edges {
          cursor
          node {
            ${MESSAGE_DATA}
          }
        }
      }
    }
  }
`

export const GET_ORGANIZATION_NUM_THEMES = gql`
  query getOrganizationNumThemes($organizationId: String!, $after: String, $before: String, $first: Int) {
    themeUsageRecords(organization: $organizationId, date: { after: $after, before: $before }, first: $first, after: null, order: { date: "asc" }) {
      edges {
        node {
          date
          quantity
        }
      }
    }
  }
`

// DIRECTS

export const GET_DIRECT = gql`
  query getDirect($directId: ID!) {
    direct(id: $directId) {
      ${DIRECT_DATA}
    }
  }
`

export const GET_DIRECTS = gql`
  query getDirects($directsIds: [String!]!) {
    directs(id_list: $directsIds first: ${NO_PAGINATION}) {
      edges {
        node {
          ${DIRECT_DATA}
        }
      }
    }
  }
`

export const GET_ORGANIZATION_DIRECTS = gql`
  query getOrganizationDirects($userId: String!, $organizationId: String!, $endCursor: String) {
    directs(theme_admins_id: $userId, theme_organization_id: $organizationId, order: {theme_name: "asc"}, first: ${DIRECTS_PER_PAGE}, after: $endCursor) {
      totalCount
      pageInfo {
        endCursor
        hasNextPage
      }
      edges {
        cursor
        node {
          ${DIRECT_DATA}
        }
      }
    }
  }
`

export const GET_MESSAGE_DIRECTS = gql`
  query getMessageDirects($messageId: ID!, $authorId: String!) {
    message(id: $messageId) {
      directs(author_id: $authorId, first: 1) {
        edges {
          node {
            ${DIRECT_DATA}
          }
        }
      }
    }
  }
`

export const GET_DIRECT_MESSAGES = gql`
  query getDirectMessages($themeId: String!, $directId: ID, $startCursor: Int) { 
    themeDirectMessages(theme: $themeId, direct: $directId, last: ${MESSAGES_PER_PAGE}, first: $startCursor) {
      edges {
        node {
          ${MESSAGE_DATA}
        }
      }
      totalCount
    }
  }
`

// HOME SEARCH
export const GET_HOME_SEARCH_QUERY = gql`
  query getHomeSearchQuery($userId: String!, $organizationId: String!, $searchText: String) {
    directs(theme_admins_id: $userId, theme_organization_id: $organizationId, author_searchText: $searchText) {
      totalCount
      edges {
        node {
          ${DIRECT_DATA}
        }
      }
    }
    themes(organization_id: $organizationId, name: $searchText, order: {name: "asc"}) {
      edges {
        node {
          ${THEME_DATA}
          directs(author_id: $userId) {
            edges {
              node {
                ${DIRECT_DATA}
              }
            }
          }
        }
      }
    }
  }
`

// MESSAGES
export const GET_MESSAGE = gql`
  query getMessage($messageId: ID!) {
    message(id: $messageId) {
      ${MESSAGE_DATA}
    }
  }
`

// POLLS
export const GET_POLL_BY_MESSAGE = gql`
  query getPollByMessage($messageId: Int!, $userId: String!) {
    polls(messageId: $messageId) {
      edges {
        node {
          id
          _id
          isMultiple
          name
          messageId
          totalVotes
          theme {
            ${THEME_DATA}
          }
          answers {
            edges {
              node {
                id
                _id
                votes
                textAnswer
                users(id: $userId) {
                  edges {
                    node {
                      ${USER_DATA}
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
// COMMERCIAL
export const GET_COMMERCIA_DATA = gql`
  query getCommercialData {
    collectionQueryCustomers {
      edges {
        node {
          organization {
            ${ORGANIZATION_DATA}
            enabledMembers
          }
          amount
          finishedCommercialDate
        }
      }
    }
    collectionQueryProfits {
      edges {
        node {
          id
          organization {
            ${ORGANIZATION_DATA}
            enabledMembers
          }
          amount
        }
      }
    }
    comercialPayments {
      edges {
        node {
          id
          amount
          status
          createdAt
          updatedAt
          organizationName
        }
      }
    }
  }
`

export const GET_USER_CUSTOMERS = gql`
  query getCustomers {
    collectionQueryCustomers {
      edges {
        node {
          organization {
            ${ORGANIZATION_DATA}
            enabledMembers
          }
          amount
          finishedCommercialDate
        }
      }
    }
  }
`

export const GET_PROFITS = gql`
  query getProfits {
    collectionQueryProfits {
      edges {
        node {
          id
          organization {
            ${ORGANIZATION_DATA}
            enabledMembers
          }
          amount
        }
      }
    }
  }
`

export const GET_COMMERCIAL_PAYMENTS = gql`
  query getCommercialPayments {
    comercialPayments {
      edges {
        node {
          id
          amount
          status
          createdAt
          updatedAt
          organizationName
        }
      }
    }
  }
`

// GETTING THE INVITATIONS
export const GET_INVITATIONS_REMAINING = gql`
  query getInvitationsRemaining($organizationId: String!) {
    invitations(enabled: true, first: ${INVITATIONS_REMAINING_LIMIT}, organization_id: $organizationId, order: { created: "DESC" }) {
      edges {
        node {
          _id
          id
          created
          phoneNumber
          enabled
        }
      }
      totalCount
    }
  }
`

//USERS
export const GET_USER_USAGE_RECORDS = gql`
  query userUsageRecords($organizationId: String!, $after: String, $before: String, $theme: String, $first: Int) {
    userUsageRecords(
      organization: $organizationId
      theme: $theme
      date: { after: $after, before: $before }
      first: $first
      after: null
      order: { date: "asc" }
    ) {
      edges {
        node {
          date
          quantity
        }
      }
    }
  }
`

export const GET_GLOBAL_MESSAGE_USAGE_RECORDS = gql`
  query globalMessageUsageRecords($organizationId: String!, $after: String, $before: String, $theme: String, $first: Int) {
    globalMessageUsageRecords(
      organization: $organizationId
      theme: $theme
      date: { after: $after, before: $before }
      first: $first
      after: null
      order: { date: "asc" }
    ) {
      edges {
        node {
          date
          quantity
        }
      }
    }
  }
`

export const GET_ANSWERS_MESSAGE_USAGE_RECORDS = gql`
  query answerMessageUsageRecords($organizationId: String!, $after: String, $before: String, $theme: String, $first: Int) {
    answerMessageUsageRecords(
      organization: $organizationId
      theme: $theme
      date: { after: $after, before: $before }
      first: $first
      after: null
      order: { date: "asc" }
    ) {
      edges {
        node {
          date
          quantity
        }
      }
    }
  }
`
