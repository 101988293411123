import React, { useEffect, useState, useMemo, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import moment from 'moment'
import PropTypes from 'prop-types'
import i18n from 'assets/i18n'
import View from './view'
import { statsActions } from 'redux/stats'
import routes from 'config/routes'
import colors from 'assets/colors'
import { SECTIONS } from './utils'
import _find from 'lodash/find'

const Stats = ({ onThemeTapped }) => {
  const [section, setSection] = useState(SECTIONS.data)
  const [printView, setPrintView] = useState(false)
  const dispatch = useDispatch()
  const {
    selectedTheme,
    numThemes,
    themes,
    themeAdmins,
    userUsageRecords,
    numUserUsageRecords,
    dates,
    themeData,
    globalMessages,
    numGlobalMessages,
    answersMessages,
    numAnswersMessages,
    isFetching,
    isFetchingTheme
  } = useSelector(state => state.stats)

  const history = useHistory()
  const organization = useSelector(state => state.organizations?.item)
  const currentThemes = useSelector(state => state?.themes?.list)

  const themesOptions = useMemo(() => currentThemes.map(item => ({ value: item.id, label: `#${item.name}` })), [currentThemes])

  const percentAnswers = themeData?.answersMessages?.list?.map((item, index) => {
    const answeres = parseInt(item?.node?.quantity)
    const users = parseInt(themeData?.usageRecords?.list[index]?.node?.quantity)
    const percent = parseInt((100 * answeres) / users)
    return percent
  })

  const percentAnswersData = () => {
    let max = 0
    percentAnswers.forEach(element => {
      max = max + element
    })
    const result = Math.round(max / percentAnswers.length)
    return `${result || 0}%`
  }

  const data = [
    { id: 1, label: i18n.statsDashboard.numThemes, value: numThemes },
    { id: 2, label: i18n.statsDashboard.numUsersTotal, value: numUserUsageRecords },
    { id: 3, label: i18n.statsDashboard.numGlobalMessages, value: numGlobalMessages },
    { id: 4, label: i18n.statsDashboard.numAnswersMessages, value: numAnswersMessages }
  ]
  const chartNumThemes = [
    {
      id: 1,
      title: i18n.statsDashboard.numThemes,
      labels: themes.map(item => moment(item?.node?.date).format('MMM DD')),
      datasets: [
        {
          label: i18n.statsDashboard.day,
          data: themes.map(item => parseInt(item?.node?.quantity)),
          borderColor: colors.yellow,
          backgroundColor: colors.yellow2
        }
      ]
    },
    {
      id: 2,
      title: i18n.statsDashboard.numUsers,
      labels: userUsageRecords.map(item => moment(item?.node?.date).format('MMM DD')),
      datasets: [
        {
          label: i18n.statsDashboard.day,
          data: userUsageRecords.map(item => parseInt(item?.node?.quantity)),
          borderColor: colors.yellow,
          backgroundColor: colors.yellow2
        }
      ]
    },
    {
      id: 3,
      labels: globalMessages.map(item => moment(item?.node?.date).format('MMM DD')),
      title: i18n.statsDashboard.numGlobalMessages,
      datasets: [
        {
          label: i18n.statsDashboard.day,
          data: globalMessages.map(item => parseInt(item?.node?.quantity)),
          borderColor: colors.yellow,
          backgroundColor: colors.yellow2
        }
      ]
    },
    {
      id: 4,
      labels: answersMessages.map(item => moment(item?.node?.date).format('MMM DD')),
      title: i18n.statsDashboard.numAnswersMessages,
      datasets: [
        {
          label: i18n.statsDashboard.day,
          data: answersMessages.map(item => parseInt(item?.node?.quantity)),
          borderColor: colors.yellow,
          backgroundColor: colors.yellow2
        }
      ]
    }
  ]

  const themeDataNum = [
    { id: 1, label: i18n.statsDashboard.numUsersTotal, value: themeData?.usageRecords?.num },
    { id: 2, label: i18n.statsDashboard.numGlobalMessages, value: themeData?.globalMessages?.num },
    { id: 3, label: i18n.statsDashboard.numAnswersMessages, value: themeData?.answersMessages?.num },
    { id: 4, label: i18n.statsDashboard.percentAnswers, value: percentAnswersData() }
  ]

  const themeChartNumThemes = [
    {
      id: 1,
      title: i18n.statsDashboard.numUsers,
      labels: themeData?.usageRecords?.list?.map(item => moment(item?.node?.date).format('MMM DD')),
      datasets: [
        {
          label: i18n.statsDashboard.day,
          data: themeData?.usageRecords?.list?.map(item => parseInt(item?.node?.quantity)),
          borderColor: colors.yellow,
          backgroundColor: colors.yellow2
        }
      ]
    },
    {
      id: 2,
      labels: themeData?.globalMessages?.list?.map(item => moment(item?.node?.date).format('MMM DD')),
      title: i18n.statsDashboard.numGlobalMessages,
      datasets: [
        {
          label: i18n.statsDashboard.day,
          data: themeData?.globalMessages?.list?.map(item => parseInt(item?.node?.quantity)),
          borderColor: colors.yellow,
          backgroundColor: colors.yellow2
        }
      ]
    },
    {
      id: 3,
      labels: themeData?.answersMessages?.list?.map(item => moment(item?.node?.date).format('MMM DD')),
      title: i18n.statsDashboard.numAnswersMessages,
      datasets: [
        {
          label: i18n.statsDashboard.day,
          data: themeData?.answersMessages?.list?.map(item => parseInt(item?.node?.quantity)),
          borderColor: colors.yellow,
          backgroundColor: colors.yellow2
        }
      ]
    },
    {
      id: 4,
      labels: themeData?.answersMessages?.list?.map(item => moment(item?.node?.date).format('MMM DD')),
      title: i18n.statsDashboard.percentAnswers,
      datasets: [
        {
          label: i18n.statsDashboard.day,
          data: percentAnswers,
          borderColor: colors.yellow,
          backgroundColor: colors.yellow2
        }
      ]
    }
  ]

  useEffect(() => {
    if (organization) {
      dispatch(
        statsActions.updateStats({
          organizationId: organization.id
        })
      )
      if (selectedTheme) {
        dispatch(statsActions.updateSelectedThemeInfo(selectedTheme))
      }
    }
  }, [organization, dates])

  const handleChangeTheme = useCallback(value => {
    dispatch(statsActions.updateSelectedThemeInfo(value.target.value))
  }, [])

  useEffect(() => {
    if (themesOptions.length > 0) {
      const defaultTheme = themesOptions[0]?.value
      const theme = _find(currentThemes, item => item.id === defaultTheme)
      onThemeTapped(theme)
      dispatch(statsActions.updateSelectedThemeInfo(themesOptions[0]?.value))
    }
  }, [themesOptions])

  const handleChangeSection = useCallback(newSection => {
    setSection(newSection)
  }, [])

  const handleChangeStartDate = useCallback(
    startDate => {
      const start = moment(startDate)
        .startOf('month')
        .toDate()

      const endIsBeforeStart = moment(start).isAfter(moment(dates?.end))
      const end = endIsBeforeStart
        ? moment(start)
            .endOf('month')
            .toDate()
        : dates?.end
      dispatch(dispatch(statsActions.updateDates({ start, end })))
    },
    [dates]
  )

  const handleChangEndDate = useCallback(
    endDate => {
      const end = moment(endDate)
        .endOf('month')
        .toDate()

      const endIsBeforeStart = moment(dates?.start).isAfter(moment(end))
      const start = endIsBeforeStart
        ? moment(end)
            .startOf('month')
            .toDate()
        : dates?.start
      dispatch(dispatch(statsActions.updateDates({ start, end })))
    },
    [dates]
  )

  const handlePrint = ref => {
    var yourDOCTYPE = '<!DOCTYPE html...' // your doctype declaration
    var printPreview = window.open('', 'PRINT', 'height=400,width=600')
    var printDocument = printPreview.document
    const newHtml = document.cloneNode(true)

    const scripts = [...newHtml.body.getElementsByTagName('script')]
    scripts.map(item => item.remove())
    const hidenItems = [...newHtml.body.getElementsByClassName('hide')]
    hidenItems.map(item => {
      item.classList.remove('hide')
    })

    printDocument.write(yourDOCTYPE + '<html>' + newHtml.documentElement.innerHTML + '</html>')

    printPreview.open()

    return true
  }

  const handleThemeTapped = () => {
    const theme = _find(currentThemes, item => item.id === selectedTheme)
    onThemeTapped(theme)
    history.push(routes.dashboard)
  }

  return (
    <View
      printView={printView}
      themesOptions={themesOptions}
      onChangeTheme={handleChangeTheme}
      chartData={chartNumThemes}
      data={data}
      onPrint={handlePrint}
      themeData={themeDataNum}
      themeChartNumThemes={themeChartNumThemes}
      onChangeSection={handleChangeSection}
      onChangeStartDate={handleChangeStartDate}
      onChangeEndDate={handleChangEndDate}
      selectedTheme={selectedTheme}
      section={section}
      themeAdmins={themeAdmins}
      dates={dates}
      isFetching={isFetching}
      isFetchingTheme={isFetchingTheme || isFetching}
      setPrintView={setPrintView}
      onThemeTapped={handleThemeTapped}
    />
  )
}

Stats.propTypes = {
  onThemeTapped: PropTypes.func.isRequired
}

export default Stats
