import React from 'react'
import PropTypes from 'prop-types'
import ContainerStyled from './styled'
import Modal from 'react-bootstrap/Modal'
import { CircleHeader } from '../../atoms'
import { PaymentMethodForm } from '../../molecules'

const ModalChangePayment = ({ show, onHide, organizationId, handleSubmit }) => {
  return (
    <Modal show={show} onHide={onHide} size="md" aria-labelledby="contained-modal-title-vcenter" centered>
      <ContainerStyled>
        <Modal.Body>
          <CircleHeader />
          <div className="payment-form-wrapper">
            <div>
              <PaymentMethodForm organizationId={organizationId} handleOnSubmit={handleSubmit} />
            </div>
          </div>
        </Modal.Body>
      </ContainerStyled>
    </Modal>
  )
}

ModalChangePayment.propTypes = {
  onHide: PropTypes.func,
  show: PropTypes.bool,
  organizationId: PropTypes.number,
  handleSubmit: PropTypes.func.isRequired
}

export default ModalChangePayment
