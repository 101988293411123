import * as types from './types'

export const updateOrganizationClientsList = (list, total = 0, pageInfo) => {
  return {
    type: types.ORGANIZATION_CLIENTS_UPDATE_LIST,
    list,
    total,
    pageInfo
  }
}

export const setOrganizationClientsFetching = value => {
  return {
    type: types.ORGANIZATION_CLIENTS_SET_FETCHING,
    value
  }
}

export const updateOrganizationUsersList = (list, total = 0, pageInfo) => {
  return {
    type: types.ORGANIZATION_USERS_UPDATE_LIST,
    list,
    total,
    pageInfo
  }
}

export const setOrganizationUsersFetching = value => {
  return {
    type: types.ORGANIZATION_USERS_SET_FETCHING,
    value
  }
}

export const updateThemeParticipantsList = (list, total = 0, pageInfo) => {
  return {
    type: types.THEME_PARTICIPANTS_UPDATE_LIST,
    list,
    total,
    pageInfo
  }
}

export const setThemeParticipantsFetching = value => {
  return {
    type: types.THEME_PARTICIPANTS_SET_FETCHING,
    value
  }
}

export const updateThemeAdminsList = (list, total = 0, pageInfo) => {
  return {
    type: types.THEME_ADMINS_UPDATE_LIST,
    list,
    total,
    pageInfo
  }
}

export const setThemeAdminsFetching = value => {
  return {
    type: types.THEME_ADMINS_SET_FETCHING,
    value
  }
}
