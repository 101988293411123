import React, { useState } from 'react'
import ComponentStyled from './styled'
import i18n from '../../../assets/i18n'
import _get from 'lodash/get'
import _map from 'lodash/map'
import _find from 'lodash/find'
import _size from 'lodash/size'
import PropTypes from 'prop-types'

const PendingInvitationList = ({ invitedUsers, title, removeInvitation }) => {
  const [activeInvitation, setActiveInvitation] = useState(null)

  if (_size(invitedUsers) === 0) return null

  const newList = _map(invitedUsers, item => {
    const itemId = _get(item, '_id', '')
    const directName = _get(item, 'phoneNumber', '')

    return (
      <div key={itemId} className="invitation-wrapper">
        <p className="phone-number">{directName}</p>
        <p className="remove" onClick={() => setActiveInvitation(itemId)}>
          {i18n.organizationUsers.remove}
        </p>
      </div>
    )
  })

  const handleClickRemove = () => {
    setActiveInvitation(null)
    removeInvitation(activeInvitation)
  }

  const activePhoneNumber = _find(invitedUsers, { _id: activeInvitation })?.phoneNumber

  return (
    <ComponentStyled>
      <p className="title-list">{title}</p>
      {newList}
      {activeInvitation && (
        <div className="confirm-wrapper">
          <p className="confirm-title">{i18n.organizationUsers.confirmCopy}</p>
          <p className="confirm-phone">{activePhoneNumber}</p>
          <div className="actions-wrapper">
            <p>
              <span className="confirm" onClick={handleClickRemove}>
                {i18n.organizationUsers.confirm}
              </span>
            </p>
            <p>
              <span className="cancel" onClick={() => setActiveInvitation(null)}>
                {i18n.organizationUsers.cancel}
              </span>
            </p>
          </div>
        </div>
      )}
    </ComponentStyled>
  )
}

PendingInvitationList.defaultProps = {
  showArrow: false,
  removeInvitation: () => {}
}

PendingInvitationList.propTypes = {
  showArrow: PropTypes.bool,
  title: PropTypes.string,
  invitedUsers: PropTypes.array,
  removeInvitation: PropTypes.func
}

export default PendingInvitationList
