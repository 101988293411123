import React from 'react'
import PropTypes from 'prop-types'
import TextareaAutosize from 'react-textarea-autosize'
import ComponentStyled from './styled'

const RegularTextArea = ({
  hasMarginBottom,
  variant,
  value,
  autoComplete,
  placeholder,
  name,
  handleOnChange,
  handleOnBlur,
  hasError,
  disabled,
  multiple,
  accept
}) => {
  return (
    <ComponentStyled variant={variant} hasMarginBottom={hasMarginBottom} hasError={hasError}>
      <TextareaAutosize
        placeholder={placeholder}
        value={value}
        name={name}
        autoComplete={autoComplete}
        onBlur={handleOnBlur ? () => handleOnBlur() : null}
        onChange={handleOnChange ? e => handleOnChange(e, e.target.value, e.target.name) : null}
        disabled={disabled}
        multiple={multiple}
        accept={accept}
        rows={1}
        maxRows={5}
      />
    </ComponentStyled>
  )
}

RegularTextArea.defaultProps = {
  hasError: false,
  autoComplete: 'off',
  hasMarginBottom: true,
  disabled: false,
  multiple: false,
  accept: null
}

RegularTextArea.propTypes = {
  variant: PropTypes.string,
  hasMarginBottom: PropTypes.bool,
  value: PropTypes.any,
  placeholder: PropTypes.string,
  name: PropTypes.string,
  handleOnChange: PropTypes.func,
  handleOnBlur: PropTypes.func,
  hasError: PropTypes.bool,
  autoComplete: PropTypes.string,
  disabled: PropTypes.bool,
  multiple: PropTypes.bool,
  accept: PropTypes.string
}

export default RegularTextArea
