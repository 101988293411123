import * as types from './types'

export const setFetching = value => {
  return {
    type: types.ORGANIZATIONS_SET_FETCHING,
    value
  }
}

export const updateList = (list, total = 0, pageInfo) => {
  return {
    type: types.ORGANIZATIONS_UPDATE_LIST,
    list,
    total,
    pageInfo
  }
}

export const updateItem = value => {
  return {
    type: types.ORGANIZATIONS_UPDATE_ITEM,
    value
  }
}

export const updateInvitationList = value => {
  return {
    type: types.ORGANIZATIONS_UPDATE_INVITATION_LIST,
    value
  }
}
