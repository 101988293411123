import styled, { css } from 'styled-components'
import colors from '../../../assets/colors'

const RegularInputStyled = styled.div`
  && {
    margin-bottom: 30px;
    p {
      font-size: 13px;
      line-height: 15px;
      letter-spacing: 1px;
      text-transform: uppercase;
      color: ${colors.darkGrey};
      font-weight: 600;
      margin-bottom: 5px;
    }

    .input-message {
      margin-top: 5px;
      font-size: 12px;
      line-height: 22px;
      color: ${({ hasError }) => (hasError ? colors.red : colors.black)};
    }

    .input-wrapper {
      display: flex;
      span {
        margin-right: 10px;
        font-size: 17px;
        line-height: 24px;
        letter-spacing: -0.53px;
        color: ${colors.darkGrey};
      }
    }

    input {
      width: 100%;
      outline: none;
      border: 0;
      border-bottom: 1px solid ${colors.darkGreyAlpha50};
      background-color: transparent;
      padding-bottom: 10px;
      font-size: 17px;
      line-height: 24px;
      letter-spacing: -0.53px;
      color: ${colors.darkGrey};

      &[type=number]::-webkit-inner-spin-button, 
      &[type=number]::-webkit-outer-spin-button { 
        -webkit-appearance: none; 
        margin: 0; 
      }

      ::placeholder {
        color: ${colors.darkGreyAlpha50};
      }
    }

    ${props =>
      props.variant === 'checkbox' &&
      css`
        && {
          display: inline-block;
        }
        input {
          width: inherit;
          background: ${colors.white};
        }
      `}

    ${props =>
      props.variant === 'filled' &&
      css`
        input {
          border: 0;
          background-color: ${colors.white};
          box-shadow: 0px 2px 11px rgba(0, 0, 0, 0.06);
          border-radius: 27px;
          min-width: 215px;
          max-width: 100%;
          padding: 10px 50px;
          font-size: 16px;
          line-height: 24px;
          color: ${colors.darkGrey4};
        }
      `}

    ${props =>
      props.variant === 'image-file' &&
      css`
        input {
          width: inherit;
          border: 0;
        }
      `}

      ${props =>
        props.hasError &&
        css`
          input {
            border-color: ${colors.red};
          }
        `}

      ${props =>
        props.hasMarginBottom === false &&
        css`
          .input-wrapper {
            margin-bottom: 0;
          }
        `}

      
  }

  
`

export default RegularInputStyled
