import React, { Component } from 'react'
import { Col } from 'react-bootstrap'
import CreateThemeFormStyled from './styled'
import { CreateThemeStep1, CreateThemeStep2, CreateThemeStep3, CreateThemeStep4 } from '../../molecules'
import { CircleHeader } from '../../atoms'
import { withRouter } from 'react-router'
import PropTypes from 'prop-types'
import _ from 'lodash'
import moment from 'moment'

const STEPS = {
  themeData: 1,
  addParticipants: 2,
  addAdmins: 3,
  finishCreate: 4
}

class CreateThemeForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      step: STEPS.themeData,
      themeName: '',
      themeDesc: '',
      onlyAnswers: false,
      temporalLimit: '',
      limitDate: '',
      participantsAdded: [],
      adminsAdded: []
    }
  }

  handleOnSubmitStep1 = ({ themeName, themeDesc, onlyAnswers, temporalLimit, limitDate }) => {
    this.setState({ step: STEPS.addParticipants, themeName, themeDesc, onlyAnswers, temporalLimit, limitDate })
  }

  handleOnSubmitStep2 = participantsAdded => {
    this.setState({ step: STEPS.addAdmins, participantsAdded })
  }

  //FINISH SUBMIT
  handleOnSubmitStep3 = async adminsAdded => {
    const { themeName, themeDesc, participantsAdded, onlyAnswers, temporalLimit, limitDate } = this.state
    const { organization, createTheme } = this.props

    const data = {
      name: themeName,
      description: themeDesc,
      organization: _.get(organization, 'id'),
      admins: _.map(adminsAdded, v => _.get(v, 'id')),
      participants: _.map(participantsAdded, v => _.get(v, 'id')),
      readOnly: onlyAnswers,
      endDate: temporalLimit && limitDate ? moment(limitDate, 'DD/MM/YYYY').format() : null
    }
    const isCreated = await createTheme(data)
    if (isCreated) {
      this.setState({ step: STEPS.finishCreate, adminsAdded })
    }
  }

  onClickOtherTheme = () => {
    this.setState({ step: STEPS.themeData })
  }

  onClickBack = () => {
    this.setState({ step: STEPS.addParticipants })
  }

  renderSteps() {
    const { step } = this.state
    const { isFetchingCreateTheme, onFinishButton } = this.props
    const { participantsAdded } = this.state

    switch (step) {
      case STEPS.themeData:
        return (
          <Col xs={12} sm={{ span: 6, offset: 3 }}>
            <CreateThemeStep1 handleOnSubmit={this.handleOnSubmitStep1} />
          </Col>
        )
      case STEPS.addParticipants:
        return (
          <Col xs={12} sm={{ span: 10, offset: 1 }}>
            <CreateThemeStep2 handleOnSubmit={this.handleOnSubmitStep2} participantsAdded={participantsAdded} />
          </Col>
        )
      case STEPS.addAdmins:
        return (
          <Col xs={12} sm={{ span: 10, offset: 1 }}>
            <CreateThemeStep3
              onClickBack={this.onClickBack}
              participantsAdded={participantsAdded}
              isFetchingForm={isFetchingCreateTheme}
              handleOnSubmit={this.handleOnSubmitStep3}
            />
          </Col>
        )
      case STEPS.finishCreate:
        return (
          <Col xs={12} sm={{ span: 6, offset: 3 }}>
            <CreateThemeStep4 onFinishButton={onFinishButton} onClickOtherTheme={this.onClickOtherTheme} />
          </Col>
        )
      default:
        return null
    }
  }

  render() {
    return (
      <CreateThemeFormStyled>
        <div className="theme-wrapper">
          <div className="circle-header">
            <CircleHeader />
          </div>
          {this.renderSteps()}
        </div>
      </CreateThemeFormStyled>
    )
  }
}

CreateThemeForm.propTypes = {
  history: PropTypes.object,
  login: PropTypes.func,
  isFetchingCreateTheme: PropTypes.bool,
  onClickOtherTheme: PropTypes.func,
  createTheme: PropTypes.func,
  organization: PropTypes.object,
  onFinishButton: PropTypes.func
}

export default withRouter(CreateThemeForm)
