import React from 'react'
import ComponentStyled from './styled'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { Link, useLocation } from 'react-router-dom'
import routes from 'config/routes'
import i18n from '../../../assets/i18n'

const PopUpOrganizationBar = ({
  onCreateThemeTapped,
  onPreferencesTapped,
  onCreateOrganizationTapped,
  onToggleManageUsersModal,
  selectedOrganization,
  organizations,
  onOrganizationTapped,
  organization,
  onOpenBilling,
  togglePopup
}) => {
  const isSubscribed = _.get(organization, 'subscribed', false)
  const isClient = organization?.isClient
  let location = useLocation()

  return (
    <ComponentStyled>
      <div className="pop-up">
        <ul>
          <li className="title">{selectedOrganization}</li>
          {process.env.REACT_APP_SHOW_STATS === 'show' && organization && isClient && isSubscribed && (
            <li>
              {location?.pathname === routes.statsDashboard ? (
                <Link onClick={togglePopup} to={routes.dashboard}>
                  {i18n.popUpOrganizationBar.themes}
                </Link>
              ) : (
                <Link onClick={togglePopup} to={routes.statsDashboard}>
                  {i18n.popUpOrganizationBar.organizationData}
                </Link>
              )}
            </li>
          )}
          <li
            onClick={() => {
              onCreateOrganizationTapped()
              togglePopup()
            }}
          >
            {i18n.popUpOrganizationBar.createOrganization}
          </li>
          {organization && isClient && isSubscribed && (
            <li
              onClick={() => {
                onCreateThemeTapped()
                togglePopup()
              }}
            >
              {i18n.createOrganizationCompleted.createTheme}
            </li>
          )}
          {organization && isClient && (
            <li
              onClick={() => {
                onOpenBilling()
                togglePopup()
              }}
            >
              {i18n.popUpOrganizationBar.billing}
            </li>
          )}
          {organization && isClient && isSubscribed && (
            <li
              onClick={() => {
                onToggleManageUsersModal()
                togglePopup()
              }}
            >
              {i18n.popUpOrganizationBar.manageUsers}
            </li>
          )}
          {organization && isClient && (
            <li
              onClick={() => {
                onPreferencesTapped()
                togglePopup()
              }}
            >
              {i18n.popUpOrganizationBar.preferences}
            </li>
          )}
        </ul>
        <hr />
        <ul className="admin-spaces">
          <li className="title">{i18n.popUpOrganizationBar.spaces}</li>
          {_.map(organizations, org => {
            const isSubscribed = _.get(org, 'subscribed', false)
            const isOrgClient = org?.isClient

            return (
              <li
                organization={org}
                key={`organization-${org.id}`}
                onClick={() => {
                  onOrganizationTapped(org, isOrgClient, isSubscribed)
                  togglePopup()
                }}
              >
                {org.name}
                {isOrgClient && <p className="is-admin">({i18n.popUpOrganizationBar.admin})</p>}
              </li>
            )
          })}
        </ul>
      </div>
    </ComponentStyled>
  )
}

PopUpOrganizationBar.propTypes = {
  onCreateThemeTapped: PropTypes.func,
  selectedOrganization: PropTypes.string,
  organizations: PropTypes.array,
  onOrganizationTapped: PropTypes.func,
  toogleModal: PropTypes.func,
  onPreferencesTapped: PropTypes.func,
  onCreateOrganizationTapped: PropTypes.func,
  organization: PropTypes.object,
  userInfo: PropTypes.object,
  onToggleManageUsersModal: PropTypes.func,
  onClickExit: PropTypes.func,
  onOpenBilling: PropTypes.func,
  togglePopup: PropTypes.func
}

export default PopUpOrganizationBar
