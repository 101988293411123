import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import compose from 'recompose/compose'
import { messagesOperations } from '../../../redux/messages'
import { themesActions } from '../../../redux/themes'
import { directsActions } from '../../../redux/directs'
import { usersOperations } from '../../../redux/users'
import { unreadMessagesOperations } from '../../../redux/unread-messages'
import View from './view'
import _ from 'lodash'
import { themesOperations } from '../../../redux/themes'

const mapStateToProps = state => {
  return {
    themesFetching: state.themes.isFetching,
    themes: state.themes.list,
    unreadMessagesFetching: false,
    unreadMessages: [],
    organizationFetching: state.organizations.isFetching,
    directsFetching: state.directs.isFetching,
    directs: state.directs.list,
    organization: state.organizations.item
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onThemeTapped: theme => {
      dispatch(themesActions.updateItem(theme))
      dispatch(directsActions.updateItem(null))
      dispatch(messagesOperations.initThemeMessagesList())
      dispatch(usersOperations.initThemeParticipantsList(theme.id))
    },
    onExitTheme: theme => {
      dispatch(themesOperations.exitTheme(theme))
    },
    onDeleteTheme: theme => {
      dispatch(themesOperations.deleteTheme(theme))
    },
    onDirectTapped: direct => {
      const theme = _.get(direct, 'theme')
      dispatch(themesActions.updateItem(theme))
      dispatch(directsActions.updateItem(direct))
      dispatch(messagesOperations.initDirectMessagesList())
    }
  }
}

const enhance = compose(connect(mapStateToProps, mapDispatchToProps))

export default withRouter(enhance(View))
