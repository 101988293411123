import styled from 'styled-components'

export const Container = styled.div`
  position: relative;
  width: 100%;
`

export const Wrapper = styled.div`
  position: relative;
  width: 100%;
  overflow: hidden;
`
