import React from 'react'
import { ReactComponent as SpinnerImg } from '../../../assets/images/yellow-spinner.svg'
import * as S from './styled'

const Spinner = ({ ...props }) => (
  <S.Container {...props}>
    <SpinnerImg />
  </S.Container>
)

export default Spinner
