import styled from 'styled-components'
import colors from '../../../../assets/colors'

const Dashboard = styled.div`
  && {
    height: 100vh;
    display: flex;
    flex-flow: column;
    background-color: ${colors.grey};

    .header-dashboard-wrapper {
      width: 100%;
    }

    .dashboard-wrapper {
      display: flex;
      flex: 1;
      height: 100%;
      overflow: hidden;
    }

    .header-subheader-wrapper {
      display: flex;
      &__organization-bar {
        width: 100%;
        max-width: 400px;
      }
      &__title {
        flex-grow: 1;
        padding: 10px 0;
        display: flex;
        align-items: center;
        -webkit-box-pack: justify;
        -webkit-justify-content: space-between;
        -ms-flex-pack: justify;
        justify-content: space-between;
        background-color: ${colors.darkGrey};
        min-height: 65px;
        padding: 0 45px;
        & p {
          font-size: 20px;
          line-height: 24px;
          letter-spacing: -0.62px;
          color: ${colors.white};
          margin-bottom: 0;
        }
      }
    }

    .dashboard-sidebar-right {
      width: 0;
      min-width: 0;
      background-color: ${colors.white};
      transition: 0.5s ease all;
      flex: 0;
      overflow: scroll;
      -ms-overflow-style: none;
      scrollbar-width: none;

      ::-webkit-scrollbar {
        display: none;
      }

      &.show {
        width: 400px;
        max-width: 100%;
        min-width: 400px;
        flex: 1;
      }

      .sidebar-right-wrapper {
        width: 400px;
        max-width: 400px;
        padding: 25px 55px;
        position: relative;
        min-height: 100%;

        .close-sidebar {
          cursor: pointer;
          position: absolute;
          top: 20px;
          right: 20px;
        }
      }
    }
  }
`

export default Dashboard
