import React, { Component } from 'react'
import AddParticipantsFormStyled from './styled'
import { RegularInput, Button, RegularSelect } from '../../atoms'
import i18n from '../../../assets/i18n'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { countryOptions, getCountryPhoneCodeByIso2 } from '../../../utils'

class AddParticipantsForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      addedError: '',
      prefix: 'ES',
      number: '',
      phones: []
    }
  }

  render() {
    const { handleOnSubmit, isFetchingForm } = this.props
    const { prefix, number, phones } = this.state
    const formatPrefix = _.toString(getCountryPhoneCodeByIso2(prefix))

    const addParticipant = () => {
      const { prefix, number } = this.state
      const formatPrefix = _.toString(getCountryPhoneCodeByIso2(prefix))
      let { phones } = this.state
      const prefixNumber = `+${formatPrefix}${number}`

      if (_.indexOf(phones, prefixNumber) >= 0) {
        const bounced = _.debounce(() => {
          this.setState({ addedError: '' })
        }, 2000)
        this.setState({ addedError: i18n.addParticipantsForm.addedError }, bounced)
      } else {
        phones = _.concat(phones, prefixNumber)
        this.setState({ number: '', phones })
      }
    }

    const onChangePrefix = (e, prefix) => {
      this.setState({ prefix })
    }

    const onChangeNumber = (e, number) => {
      this.setState({ number })
    }

    const onDropParticipant = itemToRemove => {
      let { phones } = this.state
      phones = _.pull(phones, itemToRemove)
      this.setState({ phones })
    }

    const participantAdded = _.map(phones, (item, key) => {
      return (
        <div key={key} className="participant-wrapper">
          <p>{item}</p>
          <p
            className="delete-participant"
            onClick={() => {
              onDropParticipant(item)
            }}
          >
            {i18n.addParticipantsForm.delete}
          </p>
        </div>
      )
    })

    return (
      <AddParticipantsFormStyled>
        <form
          onSubmit={e => {
            e.preventDefault()
            handleOnSubmit(phones)
          }}
        >
          <p className="title">{i18n.addParticipantsForm.addUser}</p>
          <RegularSelect
            options={countryOptions}
            handleOnChange={onChangePrefix}
            label={i18n.addParticipantsForm.countryLabel}
            defaultSelectedValue={prefix}
            name="prefix"
          />
          <RegularInput
            label={i18n.addParticipantsForm.enterPhone}
            handleOnChange={onChangeNumber}
            placeholder="000 000 000"
            value={number}
            prepend={formatPrefix ? `+${formatPrefix}` : '+00'}
            name="phone"
            type="number"
          />

          {this.state.addedError && <p className="error-msg-input">{this.state.addedError}</p>}

          {prefix && number && _.size(number) >= 9 && (
            <p className="add-link">
              <span onClick={addParticipant}>{i18n.addParticipantsForm.add}</span>
            </p>
          )}

          {_.size(phones) > 0 && (
            <>
              <hr />
              <div className="numbers-added">
                <p className="title">{i18n.addParticipantsForm.usersAdded}</p>
                {participantAdded}
              </div>
              <Button type="submit" isFetching={isFetchingForm} variant="big" label={i18n.addParticipantsForm.finish} />
            </>
          )}
        </form>
      </AddParticipantsFormStyled>
    )
  }
}

AddParticipantsForm.propTypes = {
  handleOnSubmit: PropTypes.func,
  isFetchingForm: PropTypes.bool
}

export default AddParticipantsForm
