import styled from 'styled-components'
import colors from '../../../assets/colors'

const ComponentStyled = styled.div`
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  background: ${colors.grey};

  a {
    font-size: 13px;
    color: ${colors.yellow};
  }

  @keyframes beeMove {
    0% {
      top: -3px;
    }
    50% {
      top: 3px;
    }
    100% {
      top: -3px;
    }
  }

  .bee-icon {
    max-width: 25px;
    height: auto;
    margin: 0 5px;
    position: relative;
    animation: beeMove 1s infinite;
  }

  .error-number {
    font-size: 30px;
    line-height: 30px;
  }

  @media (min-width: 992px) {
    .error-number {
      font-size: 80px;
      line-height: 80px;
    }

    .bee-icon {
      max-width: 50px;
      height: auto;
      margin: 0 5px;
    }
  }
`

export default ComponentStyled
