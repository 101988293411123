import styled from 'styled-components'
import colors from '../../../assets/colors'

const HomeStyled = styled.div`
  && {
    background-color: ${colors.grey};
  }
`

export default HomeStyled
