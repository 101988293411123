import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { ImgChat } from './styled'
import { getSignedUrl } from '../../../api'
import { ModalImage } from '../../modals'

class ImageInChat extends Component {
  constructor(props) {
    super(props)
    this._mounted = true
    this.state = { resourceUri: null, modalIsOpen: false, setIsOpen: false }

    if (props.resourceId) {
      getSignedUrl(props.resourceId)
        .then(({ data }) => {
          this._mounted && this.setState({ resourceUri: data })
        })
        .catch(() => {})
    }
  }

  componentWillUnmount() {
    this._mounted = false
  }

  openModal = () => {
    this.setState({
      modalIsOpen: true
    })
  }

  closeModal = () => {
    this.setState({
      modalIsOpen: false
    })
  }

  render() {
    const { resourceUri, modalIsOpen } = this.state
    return (
      <>
        <ImgChat src={resourceUri} onClick={this.openModal} />
        <ModalImage show={modalIsOpen} resourceUri={resourceUri} onHide={this.closeModal} />
      </>
    )
  }
}

ImageInChat.defaultProps = {
  showFullname: true
}

ImageInChat.propTypes = {
  resourceId: PropTypes.any,
  resourceUri: PropTypes.string,
  showFullname: PropTypes.bool,
  variant: PropTypes.string,
  name: PropTypes.string,
  handleOnClick: PropTypes.func,
  subtitle: PropTypes.string
}

export default ImageInChat
