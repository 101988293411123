import React, { useState } from 'react'
import { Row, Col } from 'react-bootstrap'
import { ReactComponent as Logo } from '../../../assets/images/logo.svg'
import HeaderDashboardStyled from './styled'
import { Avatar } from '../../atoms'
import { Dropdown } from 'react-bootstrap'
import { PopUpUser } from '../../molecules'
import PropTypes from 'prop-types'
import _ from 'lodash'

const CustomToggle = React.forwardRef(({ children, onClick }, ref) => {
  return (
    <span
      ref={ref}
      onClick={e => {
        e.preventDefault()
        onClick(e)
      }}
    >
      {children}
    </span>
  )
})

const HeaderDashboard = ({ openComercialSidebar, handleOpenUpdateUser, userInfo }) => {
  const [openPopup, setOpenPopup] = useState(false)
  const fullName = _.get(userInfo, 'fullname', '')
  const phoneNumber = _.get(userInfo, 'phoneNumber', '')
  const userImgId = _.get(userInfo, 'image._id', '')

  function togglePopup() {
    setOpenPopup(!openPopup)
  }

  return (
    <HeaderDashboardStyled>
      <Row className="align-items-center">
        <Col xs={12} sm={6}>
          <Logo className="logo" />
        </Col>
        <Col xs={12} sm={6} className="text-right">
          <div className="header-avatar-popup-wrapper">
            <Dropdown onToggle={togglePopup} show={openPopup}>
              <Dropdown.Toggle as={CustomToggle}>
                <div className="header-avatar-wrapper">
                  {userInfo && <Avatar resourceId={userImgId} name={fullName} showFullname={false} />}
                  <img className="arrow" src={require('../../../assets/images/black-arrow.png')} alt="Arrow" />
                </div>
              </Dropdown.Toggle>
              <Dropdown.Menu className="menu-popup">
                <PopUpUser
                  openComercialSidebar={openComercialSidebar}
                  togglePopup={togglePopup}
                  fullName={fullName}
                  phone={phoneNumber}
                  handleOpenUpdateUser={handleOpenUpdateUser}
                />
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </Col>
      </Row>
    </HeaderDashboardStyled>
  )
}

HeaderDashboard.propTypes = {
  handleOpenUpdateUser: PropTypes.func,
  userInfo: PropTypes.object,
  openComercialSidebar: PropTypes.func
}

export default HeaderDashboard
