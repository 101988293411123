import React from 'react'
import { PopUpWriteMsgAddStyled, UploadFile, InputContainer } from './styled'
import PropTypes from 'prop-types'
import { RegularInput } from '../../atoms'
import i18n from '../../../assets/i18n'

const PopUpWriteMsgAdd = ({ onUploadFile, onClickItems, handleOpenCreatePollSidebar, direct }) => {
  return (
    <PopUpWriteMsgAddStyled>
      <div className="pop-up">
        <ul>
          <li>
            <UploadFile>
              <span>{i18n.popUpWriteMsgAdd.uploadFile}</span>
              <InputContainer>
                <RegularInput
                  type="file"
                  name="uploadFile"
                  variant="filled"
                  hasMarginBottom={false}
                  handleOnChange={event => {
                    onClickItems()
                    onUploadFile(event)
                    event.target.value = ''
                  }}
                />
              </InputContainer>
            </UploadFile>
          </li>
          {!direct && (
            <li
              onClick={() => {
                onClickItems()
                handleOpenCreatePollSidebar()
              }}
            >
              {i18n.popUpWriteMsgAdd.createPoll}
            </li>
          )}
        </ul>
      </div>
    </PopUpWriteMsgAddStyled>
  )
}

PopUpWriteMsgAdd.propTypes = {
  onUploadFile: PropTypes.func,
  handleOpenCreatePollSidebar: PropTypes.func,
  direct: PropTypes.object,
  onClickItems: PropTypes.func
}

export default PopUpWriteMsgAdd
