import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import compose from 'recompose/compose'
import { messagesOperations } from '../../../redux/messages'
import { themesActions } from '../../../redux/themes'
import { directsActions } from '../../../redux/directs'
import Container from './container'

const mapStateToProps = state => {
  return {}
}

const mapDispatchToProps = dispatch => {
  return {
    onThemeTapped: theme => {
      dispatch(themesActions.updateItem(theme))
      dispatch(directsActions.updateItem(null))
      dispatch(messagesOperations.initThemeMessagesList())
    }
  }
}

const enhance = compose(connect(mapStateToProps, mapDispatchToProps))

export default withRouter(enhance(Container))
