import { createGlobalStyle } from 'styled-components'
import colors from '../assets/colors'

export const GlobalStyle = createGlobalStyle`
  @font-face {
    font-family: 'Sofia';
    src: url(${require('../assets/fonts/sofia/SofiaProLight.eot')});
    src: url(${require('../assets/fonts/sofia/SofiaProLight.eot?#iefix')}) format('embedded-opentype'),
      url(${require('../assets/fonts/sofia/SofiaProLight.woff2')}) format('woff2'),
      url(${require('../assets/fonts/sofia/SofiaProLight.woff')}) format('woff'),
      url(${require('../assets/fonts/sofia/SofiaProLight.ttf')}) format('truetype'),
      url(${require('../assets/fonts/sofia/SofiaProLight.svg')}) format('svg');
    font-weight: 300;
    font-style: normal;
  }
  @font-face {
    font-family: 'Sofia';
    src: url(${require('../assets/fonts/sofia/SofiaProRegular.eot')});
    src: url(${require('../assets/fonts/sofia/SofiaProRegular.eot?#iefix')}) format('embedded-opentype'),
      url(${require('../assets/fonts/sofia/SofiaProRegular.woff2')}) format('woff2'),
      url(${require('../assets/fonts/sofia/SofiaProRegular.woff')}) format('woff'),
      url(${require('../assets/fonts/sofia/SofiaProRegular.ttf')}) format('truetype'),
      url(${require('../assets/fonts/sofia/SofiaProRegular.svg')}) format('svg');
    font-weight: 500;
    font-style: normal;
  }
  @font-face {
    font-family: 'Sofia';
    src: url(${require('../assets/fonts/sofia/SofiaProSemiBold.eot')});
    src: url(${require('../assets/fonts/sofia/SofiaProSemiBold.eot?#iefix')}) format('embedded-opentype'),
      url(${require('../assets/fonts/sofia/SofiaProSemiBold.woff2')}) format('woff2'),
      url(${require('../assets/fonts/sofia/SofiaProSemiBold.woff')}) format('woff'),
      url(${require('../assets/fonts/sofia/SofiaProSemiBold.ttf')}) format('truetype'),
      url(${require('../assets/fonts/sofia/SofiaProSemiBold.svg')}) format('svg');
    font-weight: 600;
    font-style: normal;
  }
  @font-face {
    font-family: 'Sofia';
    src: asset-url(${require('../assets/fonts/sofia/SofiaProBold.eot')});
    src: asset-url(${require('../assets/fonts/sofia/SofiaProBold.eot?#iefix')}) format('embedded-opentype'),
      asset-url(${require('../assets/fonts/sofia/SofiaProBold.woff2')}) format('woff2'),
      asset-url(${require('../assets/fonts/sofia/SofiaProBold.woff')}) format('woff'),
      asset-url(${require('../assets/fonts/sofia/SofiaProBold.ttf')}) format('truetype'),
      asset-url(${require('../assets/fonts/sofia/SofiaProBold.svg')}) format('svg');
    font-weight: 700;
    font-style: normal;
  }
  body, *{
    font-family:'Sofia';
    font-weight: 500;
  }

  .modal-content {
    border:0;
  }

  h1 {
    font-size: 28px;
    line-height: 36px;
    letter-spacing: -0.43px;
    font-weight: 600;
    color: ${colors.black};
  }

  h2 {
    font-size: 25px;
    line-height: 25px;
    letter-spacing: -0.43px;
    color: ${colors.black};
  }

  a {
    :hover {
      text-decoration: none;
    }
  }

  button {
    outline: none;
    box-shadow: none;
  }

  p,ul,li {   
    font-size: 18px;
    line-height: 21px;
    color: ${colors.black};
  }

  .error-msg-input,
  .error-msg-form  {
    font-size: 12px;
    line-height: 22px;
    color: ${colors.red};
  }

  .error-msg-form {
    text-align: center;
  }


  @media (min-width: 768px) {
    h1 {
      font-size: 36px;
      line-height: 42px;
    }

    h2 {
      font-size: 36px;
      line-height: 36px;
    }
  }

`

export const theme = {
  colors
}
