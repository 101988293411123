import React from 'react'
import PropTypes from 'prop-types'
import * as S from './styled'

const Panel = ({ children, ...rest }) => <S.Container {...rest}>{children}</S.Container>

Panel.defaultProps = {
  children: null
}

Panel.propTypes = {
  children: PropTypes.node
}

export default Panel
