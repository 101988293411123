export default {
  black: '#000000',
  lightGrey: '#E1E1E1',
  grey: '#F2F2F2',
  grey2: '#999999',
  darkGrey: '#2A3C44',
  darkGreyAlpha50: 'rgba(42,60,68,.5)',
  darkGrey2: '#32454E',
  darkGrey3: '#383838',
  darkGrey3Alpha50: 'rgba(56, 56, 56, .5)',
  darkGrey4: '#444343',
  darkGrey5: '#B4B9BB',
  darkGrey6: '#21292A',
  darkGrey7: '#3c4e58',
  darkGrey8: '#32444e',
  darkGrey9: '#2a3c44',
  white: '#FFFFFF',
  yellow: '#F9BA20',
  yellow2: '#FCDC8F',
  red: '#FF0000'
}
