import React, { Component } from 'react'
import RegisterFormStep3Styled from './styled'
import { RegularInput, Button, SingleDatePicker } from '../../atoms'
import i18n from '../../../assets/i18n'
import PropTypes from 'prop-types'
import { Formik } from 'formik'
import moment from 'moment'

class RegisterFormStep3 extends Component {
  constructor(props) {
    super(props)
    this.state = {
      image: '',
      file: ''
    }
  }

  render() {
    let style = {}
    const { file, image } = this.state
    const { onSubmit, isFetching } = this.props

    if (image) {
      style = {
        backgroundImage: 'url(' + file + ')'
      }
    }

    return (
      <RegisterFormStep3Styled>
        <Formik
          initialValues={{
            name: '',
            surname: '',
            cp: '',
            birhtdate: ''
          }}
          validate={values => {
            let errors = {}
            if (!values.name) {
              errors.name = i18n.loginForm.requiredField
            }
            if (!values.surname) {
              errors.surname = i18n.loginForm.requiredField
            }
            if (!values.cp) {
              errors.cp = i18n.loginForm.requiredField
            }
            if (!values.birhtdate) {
              errors.birhtdate = i18n.loginForm.requiredField
            }

            if (values.birhtdate && values.birhtdate > moment().subtract(18, 'years')) {
              errors.birhtdate = i18n.loginForm.minAge
            }

            return errors
          }}
          onSubmit={onSubmit}
        >
          {({ values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue }) => (
            <form onSubmit={handleSubmit}>
              <div className="upload-image-wrapper">
                <div className="circle-image-wrapper" style={style}>
                  {!image && <p>{i18n.registerFormStep3.addPhoto}</p>}
                  <div className="input-wrapper">
                    <RegularInput
                      type="file"
                      name="image"
                      variant="image-file"
                      handleOnChange={event => {
                        setFieldValue('image', event.currentTarget.files[0])
                        this.setState({ image: event.currentTarget.files[0] })

                        let reader = new FileReader()
                        reader.onloadend = () => {
                          this.setState({ file: reader.result })
                        }
                        if (event.target.files[0]) {
                          reader.readAsDataURL(event.target.files[0])
                        }
                      }}
                    />
                  </div>
                </div>
              </div>

              <RegularInput
                label={i18n.registerFormStep3.nameLabel}
                placeholder={i18n.registerFormStep3.namePlaceholder}
                handleOnChange={handleChange}
                handleOnBlur={handleBlur}
                name="name"
                value={values.name}
                hasError={errors.name && touched.name && true}
              />
              {errors.name && touched.name && <p className="error-msg-input">{errors.name}</p>}

              <RegularInput
                label={i18n.registerFormStep3.surnameLabel}
                placeholder={i18n.registerFormStep3.surnamePlaceholder}
                handleOnChange={handleChange}
                handleOnBlur={handleBlur}
                name="surname"
                value={values.surname}
                hasError={errors.surname && touched.surname && true}
              />
              {errors.surname && touched.surname && <p className="error-msg-input">{errors.surname}</p>}

              <RegularInput
                label={i18n.registerFormStep3.postalCodeLabel}
                placeholder={i18n.registerFormStep3.postalCodePlaceholder}
                handleOnChange={handleChange}
                handleOnBlur={handleBlur}
                name="cp"
                type="number"
                value={values.cp}
                hasError={errors.cp && touched.cp && true}
              />
              {errors.cp && touched.cp && <p className="error-msg-input">{errors.cp}</p>}

              <SingleDatePicker
                label={i18n.registerFormStep3.birthDate}
                handleOnChange={date => {
                  setFieldValue('birhtdate', date)
                }}
                handleOnBlur={handleBlur}
                name="birhtdate"
                value={values.birhtdate}
                hasError={errors.birhtdate && touched.birhtdate && true}
                maxDate={moment()
                  .subtract(18, 'years')
                  .toDate()}
              />
              {errors.birhtdate && touched.birhtdate && <p className="error-msg-input">{errors.birhtdate}</p>}
              <Button isFetching={isFetching} variant="big" label={i18n.registerFormStep3.continue} />
            </form>
          )}
        </Formik>
      </RegisterFormStep3Styled>
    )
  }
}

RegisterFormStep3.propTypes = {
  onSubmit: PropTypes.func,
  isFetching: PropTypes.bool
}

export default RegisterFormStep3
