import styled from 'styled-components'
import colors from '../../../assets/colors'

const HeaderStyled = styled.header`
  && {
    position: relative;
    padding: 20px 15px 10px 15px;
    background-color: ${colors.yellow};
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;

    .logo {
      z-index: 2;
      margin-bottom: 15px;
    }

    .btns-wrapper {
      text-align: center;
    }

    button {
      margin: 0 10px 10px 0;
    }

    @media (min-width: 768px) {
      padding: 20px 40px;
      text-align: inherit;

      .btns-wrapper {
        text-align: right;
      }

      button {
        margin: 0 0 0 10px;
      }
    }
  }
`

export default HeaderStyled
