import styled from 'styled-components'
import colors from '../../../assets/colors'

const ComponentStyled = styled.div`
  && {
    .customer-wrapper {
      margin-bottom: 90px;
      text-align: center;

      .avatar-wrapper {
        margin-bottom: 15px;
      }

      .name {
        font-size: 16px;
        line-height: 16px;
        color: ${colors.black};
        margin-bottom: 15px;
      }

      .members {
        font-size: 14px;
        line-height: 14px;
        color: ${colors.grey2};
        margin-bottom: 20px;
      }

      .price {
        font-size: 28px;
        line-height: 28px;
        color: ${colors.black};
      }
    }

    .info-wrapper {
      .title {
        font-weight: 600;
        font-size: 14px;
        line-height: 16px;
        letter-spacing: 1.08px;
        text-transform: uppercase;
      }

      .info {
        font-size: 16px;
        line-height: 24px;
        color: ${colors.darkGrey3};
        padding-left: 25px;
      }
    }
  }
`

export default ComponentStyled
