import React, { Component } from 'react'
import ComponentStyled from './styled'
import { PollAnswersList, UserMessage } from '../../molecules'
import i18n from '../../../assets/i18n'
import PropTypes from 'prop-types'
import _ from 'lodash'

class SideBarRepplyPoll extends Component {
  render() {
    const { poll, toggleSidebar } = this.props
    const authorName = _.get(poll, 'pollAuthor.fullname', '')
    const pollName = _.get(poll, 'name', '')
    const avatarResourceId = _.get(poll, 'pollAuthor.image._id', '')

    return (
      <ComponentStyled>
        <div className="title-chat-wrapper">
          <p className="title-chat">{i18n.sideBarRepplyPoll.poll}</p>
        </div>
        <UserMessage avatarResourceId={avatarResourceId} message={pollName} name={authorName} />
        <PollAnswersList toggleSidebar={toggleSidebar} />
      </ComponentStyled>
    )
  }
}

SideBarRepplyPoll.propTypes = {
  poll: PropTypes.object,
  toggleSidebar: PropTypes.func
}

export default SideBarRepplyPoll
