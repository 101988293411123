import React from 'react'
import ComponentStyled from './styled'
import i18n from '../../../assets/i18n'
import PropTypes from 'prop-types'
import { Button } from '../../atoms'

const PaymentInfo = ({ handleOnSubmit, isFetching }) => {
  return (
    <ComponentStyled>
      <p className="title">{i18n.paymentInfo.price}</p>
      <div className="resume-price">
        <p className="price">{i18n.paymentInfo.paymentPrice}</p>
        <p className="tax">{i18n.paymentInfo.taxPrice}</p>
        <p className="info">{i18n.paymentInfo.eachUser}</p>
      </div>
      <Button isFetching={isFetching} label={i18n.paymentInfo.next} variant="big" handleOnClick={handleOnSubmit} />
    </ComponentStyled>
  )
}

PaymentInfo.propTypes = {
  handleOnSubmit: PropTypes.func,
  isFetching: PropTypes.bool
}

export default PaymentInfo
