import React from 'react'
import PropTypes from 'prop-types'
import ButtonStyled from './styled'
import { ReactComponent as Spinner } from '../../../assets/images/spinner.svg'

const Button = React.forwardRef(({ id, disabled, variant, label, handleOnClick, type, isFetching, iconRight, ...rest }, ref) => {
  return (
    <ButtonStyled variant={variant} {...rest} disabled={disabled}>
      <button onClick={handleOnClick} disabled={isFetching || disabled} type={type} id={id} ref={ref}>
        {isFetching ? <Spinner className="spinner" /> : <span className="label">{label}</span>}
        {!isFetching && iconRight && <span className="icon-right">{iconRight}</span>}
      </button>
    </ButtonStyled>
  )
})

Button.defaultProps = {
  isFetching: false,
  iconRight: null
}

Button.propTypes = {
  id: PropTypes.string,
  variant: PropTypes.string,
  label: PropTypes.string,
  type: PropTypes.string,
  handleOnClick: PropTypes.func,
  isFetching: PropTypes.bool,
  iconRight: PropTypes.node
}

export default Button
