import styled from 'styled-components'
import colors from '../../../assets/colors'

const ComponentStyled = styled.div`
  && {
    background-color: ${colors.grey};
    height: 100vh;
    display: flex;
    flex-direction: column;

    .container {
      flex: 1;
    }

    .terms-wrapper {
      padding: 50px 0;

      h1 {
        margin-bottom: 30px;
      }

      .section-title {
        margin-top: 30px;
        font-weight: bold;
      }

      li {
        margin-bottom: 15px;
      }

      a {
        color: black;
        font-weight: bold;
      }
    }
  }

  @media (min-width: 576px) {
  }

  @media (min-width: 768px) {
  }

  @media (min-width: 992px) {
  }

  @media (min-width: 1200px) {
  }
`

export default ComponentStyled
