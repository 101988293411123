import React, { Component } from 'react'
import ComponentStyled from './styled'
import { Button, Avatar, SearchField } from '../../atoms'
import i18n from '../../../assets/i18n'
import PropTypes from 'prop-types'
import { getBlackListUsers } from '../../../utils'
import { Row, Col } from 'react-bootstrap'
import _ from 'lodash'
import { toast } from 'react-toastify'
import { ReactComponent as Spinner } from '../../../assets/images/yellow-spinner.svg'

class EditThemeAdmins extends Component {
  constructor(props) {
    super(props)
    this.state = {
      adminsToAdd: [],
      adminsAdded: [],
      participantsAdded: []
    }
  }

  async componentDidMount() {
    await this.loadThemeAdmins()
    await this.loadThemeParticipants()
    await this.loadOrganizationUsers()
  }

  loadThemeParticipants = async () => {
    const participantsAdded = await this.props.initParticipantsList()
    this.setState({ participantsAdded })
  }

  loadThemeAdmins = async () => {
    const adminsAdded = await this.props.initAdminsList()
    this.setState({ adminsAdded })
  }

  loadOrganizationUsers = async (searchText = '') => {
    let adminsToAdd = await this.props.fetchOrganizationUsers(searchText)
    adminsToAdd = _.differenceWith(adminsToAdd, this.state.adminsAdded, _.isEqual)
    this.setState({ adminsToAdd })
  }

  onSearch = _.debounce((e, searchText) => {
    this.loadOrganizationUsers(searchText)
  }, 1000)

  onCheckAll = () => {
    const { isFetchingUsers } = this.props
    const { adminsToAdd, adminsAdded, participantsAdded } = this.state
    if (!isFetchingUsers) {
      const { blackList } = getBlackListUsers(adminsToAdd, participantsAdded)

      if (blackList.length > 0) {
        toast(i18n.alreadyUsersParticipantMessage, { type: toast.TYPE.ERROR })
      } else {
        this.setState({ adminsAdded: _.concat(adminsToAdd, adminsAdded), adminsToAdd: [] })
      }
    }
  }

  onClickAdminToAdd = itemToAdd => {
    const { isFetchingUsers } = this.props
    const { participantsAdded } = this.state

    if (!isFetchingUsers) {
      const isInBlackList = _.findIndex(participantsAdded, { id: itemToAdd.id })

      if (isInBlackList > -1) {
        toast(i18n.alreadyParticipantMessage, { type: toast.TYPE.ERROR })
      } else {
        const { adminsToAdd } = this.state
        _.pull(adminsToAdd, itemToAdd)
        this.setState({ adminsToAdd })
        this.setState(previousState => ({
          adminsAdded: [...previousState.adminsAdded, itemToAdd]
        }))
      }
    }
  }

  onClickAdminRemove = itemToRemove => {
    const { isFetchingUsers } = this.props
    if (!isFetchingUsers) {
      const { adminsAdded } = this.state
      _.pull(adminsAdded, itemToRemove)
      this.setState({ adminsAdded })
      this.setState(previousState => ({
        adminsToAdd: [...previousState.adminsToAdd, itemToRemove]
      }))
    }
  }

  render() {
    const { adminsToAdd, adminsAdded } = this.state
    const { isFetchingUsers, handleOnSubmit } = this.props

    const adminsToAddedFormat = _.map(adminsToAdd, (item, key) => {
      const directName = _.get(item, 'fullname', '')
      const directImage = _.get(item, 'image._id', '')
      const subInfo = _.get(item, 'phone', '')

      return (
        <div key={key} className="admin-list-wrapper" onClick={() => this.onClickAdminToAdd(item)}>
          <Avatar resourceId={directImage} name={directName} subtitle={subInfo} />
        </div>
      )
    })

    const adminsAddedFormat = _.map(adminsAdded, (item, key) => {
      const directName = _.get(item, 'fullname', '')
      const directImage = _.get(item, 'image._id', '')
      const subInfo = _.get(item, 'phone', '')

      return (
        <div key={key} className="admin-list-wrapper" onClick={() => this.onClickAdminRemove(item)}>
          <Avatar resourceId={directImage} name={directName} subtitle={subInfo} />
        </div>
      )
    })

    return (
      <ComponentStyled>
        <form
          onSubmit={e => {
            e.preventDefault()
            handleOnSubmit(adminsAdded)
          }}
        >
          <div className="search-field-wrapper">
            <SearchField placeholder={i18n.editThemeAdmins.searchAdmins} handleOnChange={this.onSearch} />
          </div>
          <Row>
            <Col xs={12} sm={6} className="list-wrapper">
              <p className="title-list">{i18n.editThemeAdmins.addAdmins}</p>
              {adminsToAddedFormat}
              {isFetchingUsers && (
                <div className="spinner-wrapper">
                  <Spinner className="spinner" />
                </div>
              )}
            </Col>
            <Col xs={12} sm={6} className="list-wrapper">
              <p className="title-list">{i18n.editThemeAdmins.adminAdded}</p>
              {adminsAddedFormat}
            </Col>
          </Row>
          {_.size(adminsAddedFormat) > 0 && (
            <div className="submit-admins-wrapper">
              <Button type="submit" isFetching={this.props.isFetchingForm} variant="big" label={i18n.editThemeAdmins.save} />
            </div>
          )}
          {_.size(adminsToAddedFormat) > 0 && (
            <p className="check-all">
              <span onClick={this.onCheckAll}>{i18n.editThemeAdmins.addAll}</span>
            </p>
          )}
        </form>
      </ComponentStyled>
    )
  }
}

EditThemeAdmins.propTypes = {
  handleOnSubmit: PropTypes.func,
  fetchOrganizationUsers: PropTypes.func,
  initParticipantsList: PropTypes.func,
  isFetchingUsers: PropTypes.bool,
  isFetchingForm: PropTypes.bool,
  theme: PropTypes.object,
  initAdminsList: PropTypes.func
}

export default EditThemeAdmins
