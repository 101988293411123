import React, { Component } from 'react'
import LoginFormStyled from './styled'
import { RegularInput, Button, RegularSelect } from '../../atoms'
import i18n from '../../../assets/i18n'
import PropTypes from 'prop-types'
import { Formik } from 'formik'
import _ from 'lodash'
import { countryOptions, getCountryPhoneCodeByIso2 } from '../../../utils'

class LoginForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      phone: '',
      password: ''
    }
  }

  render() {
    const spainValue = 'ES' // Se hardcodea el código de España
    return (
      <LoginFormStyled>
        <Formik
          initialValues={{
            country: spainValue,
            phone: '',
            password: ''
          }}
          validate={values => {
            let errors = {}
            if (!values.phone) {
              errors.phone = i18n.loginForm.requiredField
            }
            if (!values.password) {
              errors.password = i18n.loginForm.requiredField
            }
            if (!values.country) {
              errors.country = i18n.loginForm.requiredField
            }
            return errors
          }}
          onSubmit={data => {
            data.phone = `+${_.toString(getCountryPhoneCodeByIso2(data.country) + data.phone)}`
            this.props.handleOnSubmit(data)
          }}
        >
          {({ values, errors, touched, handleChange, handleBlur, handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <RegularSelect
                options={countryOptions}
                label={i18n.loginForm.countryLabel}
                handleOnChange={handleChange}
                handleOnBlur={handleBlur}
                name="country"
                value={values.country}
                hasError={errors.country && touched.country && true}
                defaultSelectedValue={spainValue}
              />
              {errors.prefix && touched.prefix && <p className="error-msg-input">{errors.prefix}</p>}

              <RegularInput
                label={i18n.loginForm.enterPhone}
                placeholder="000 000 000"
                prepend={values.country ? `+${getCountryPhoneCodeByIso2(values.country)}` : '+00'}
                handleOnChange={handleChange}
                handleOnBlur={handleBlur}
                name="phone"
                value={values.phone}
                type="number"
                hasError={errors.phone && touched.phone && true}
              />
              {errors.phone && touched.phone && <p className="error-msg-input">{errors.phone}</p>}

              <RegularInput
                label={i18n.loginForm.enterPass}
                type="password"
                placeholder="*******"
                handleOnChange={handleChange}
                handleOnBlur={handleBlur}
                name="password"
                value={values.password}
                hasError={errors.password && touched.password && true}
              />
              {errors.password && touched.password && <p className="error-msg-input">{errors.password}</p>}

              <Button type="submit" variant="big" label={i18n.loginForm.enter} isFetching={this.props.isFetchingForm} />
            </form>
          )}
        </Formik>
      </LoginFormStyled>
    )
  }
}

LoginForm.propTypes = {
  handleOnSubmit: PropTypes.func,
  isFetchingForm: PropTypes.bool,
  spainValue: PropTypes.string
}

export default LoginForm
