import styled from 'styled-components'
import colors from '../../../assets/colors'

const CreateThemeStep1Styled = styled.div`
  && {
    h4 {
      text-align: center;
      margin-bottom: 50px;
      text-transform: uppercase;
    }

    .submit-wrapper {
      margin-top: 50px;
    }

    .error-msg-input {
      margin-top: -25px;
    }

    .check-wrapper {
      p {
        font-size: 13px;
        line-height: 15px;
        letter-spacing: 1px;
        text-transform: uppercase;
        color: ${colors.darkGrey};
        font-weight: 600;
        margin-bottom: 5px;
      }
      span {
        margin-left: 10px;
      }
    }
  }
`

export default CreateThemeStep1Styled
