import styled, { css } from 'styled-components'
import colors from '../../../assets/colors'

const AvatarStyled = styled.div`
  && {
    display: flex;
    align-items: center;

    .avatar {
      min-height: 45px;
      min-width: 45px;
      max-width: 45px;
      border-radius: 50px;
      background-color: ${colors.grey};
      background-position: center;
      background-size: cover;
      display: inline-flex;
      align-items: center;
      justify-content: center;

      span {
        font-weight: bold;
      }
    }

    .text-wrapper {
      margin-left: 10px;
      .avatar-name {
        font-size: 16px;
        line-height: 16px;
        color: ${colors.black};
        margin-bottom: 0;
      }
      .subtitle {
        margin-top: 5px;
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;
        color: ${colors.darkGrey3};
        margin-bottom: 0;

        ${props =>
          props.variant === 'customer' &&
          css`
            color: ${colors.grey2};
          `}
      }
    }

    ${props =>
      props.variant === 'shadow' &&
      css`
        .avatar {
          box-shadow: 0px 1px 10px rgba(50, 49, 49, 0.34);
        }
      `}
    
      ${props =>
        props.size === 'big' &&
        css`
          display: block;

          .avatar {
            min-height: 90px;
            min-width: 90px;
            max-width: 90px;
          }
        `}

    ${props =>
      props.imageBg &&
      css`
        .avatar {
          background-image: url(${props.imageBg});

          span {
            opacity: 0;
          }
        }
      `}
  }

  
`

export default AvatarStyled
