import styled from 'styled-components'
import colors from '../../../assets/colors'

const CreateThemeFormStyled = styled.div`
  && {
    display: flex;
    align-items: center;
    width: 100%;

    .theme-wrapper {
      background-color: ${colors.white};
      box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.3);
      padding-bottom: 20px;
      width: 100%;
    }

    .circle-header {
      margin-bottom: 50px;
    }
  }
`

export default CreateThemeFormStyled
