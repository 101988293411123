import React, { useState } from 'react'
import ComponentStyled from './styled'
import PropTypes from 'prop-types'
import i18n from '../../../assets/i18n'
import { ReactComponent as ArroIcon } from '../../../assets/images/simple-right-arrow.svg'
import _ from 'lodash'
import { formatMoney } from '../../../utils'

const MAX_NUMBER_SHORT_LIST = 2

const LiquidationList = ({ title, items, onClickLiquidation }) => {
  const [showAll, setShowAll] = useState(false)

  function renderSectionFooter() {
    if (items.length === 0) {
      return (
        <p className="see-all">
          <span>{i18n.liquidationList.noItems}</span>
        </p>
      )
    }

    if (items.length <= MAX_NUMBER_SHORT_LIST) {
      return ''
    }

    if (!showAll) {
      return (
        <p className="see-all" onClick={() => setShowAll(true)}>
          <span>{i18n.liquidationList.seeAll}</span>
        </p>
      )
    }

    return (
      <p className="see-all" onClick={() => setShowAll(false)}>
        <span>{i18n.liquidationList.seeLess}</span>
      </p>
    )
  }

  let shortItems = _.clone(items)
  if (!showAll) {
    shortItems = shortItems.slice(0, MAX_NUMBER_SHORT_LIST)
  }

  return (
    <ComponentStyled onClickLiquidation={onClickLiquidation ? true : false}>
      <p className="liquidation-title">{title}</p>

      {shortItems.map((item, index) => {
        const organizationName = _.get(item, 'organizationName', '')
        const amount = _.get(item, 'amount', 9)

        return (
          <div key={index} className="liquidation-wrapper" onClick={() => onClickLiquidation(item)}>
            <p>
              {i18n.liquidationList.liquidationFrom} {organizationName}
            </p>
            <p className="price">{formatMoney(amount)}</p>
            <ArroIcon />
          </div>
        )
      })}

      {renderSectionFooter()}
    </ComponentStyled>
  )
}

LiquidationList.propTypes = {
  title: PropTypes.string,
  onClickLiquidation: PropTypes.func,
  items: PropTypes.array
}

export default LiquidationList
