import styled from 'styled-components'
import colors from '../../../assets/colors'

const MainSideBarStyled = styled.div`
  && {
    min-width: 400px;
    max-width: 400px;
    background-color: ${colors.white};
    display: flex;
    flex-flow: column;

    .sidebar-items {
      overflow-y: scroll;
      -ms-overflow-style: none;
      scrollbar-width: none;
      position: relative;
      height: 100%;
      padding: 15px 45px;

      ::-webkit-scrollbar {
        display: none;
      }
    }

    .terms-wrapper {
      padding: 5px;
      text-align: center;

      .term-item {
        margin-bottom: 0;
        font-size: 12px;

        a {
          text-decoration: underline;
          color: ${colors.black};
        }
      }
    }
  }
`

export default MainSideBarStyled
