import React from 'react'
import SearchChatsStyled from './styled'
import { SearchField } from '../../atoms'
import i18n from '../../../assets/i18n'
import PropTypes from 'prop-types'
import _ from 'lodash'

const SearchChats = ({ organization, userInfo, getHomeSearchQuery, initDirects, initThemes, handleOpenNewTheme }) => {
  const organizationClients = _.map(_.get(organization, 'clients.edges', []), v => v.node)
  const userId = _.get(userInfo, 'id', -1)
  const isClient = _.find(organizationClients, { id: userId }) ? true : false

  const onSearch = searchText => {
    if (searchText.length > 2) {
      getHomeSearchQuery(searchText)
    } else if (searchText === '') {
      initDirects()
      initThemes()
    }
  }

  const _onSearch = (v, onSearch) => {
    onSearch(v)
  }

  const onSearchDebounce = _.debounce(_onSearch, 600)

  return (
    <SearchChatsStyled>
      <div className="search-titles-wrapper">
        <p className="search-title">{i18n.searchChats.title}</p>
        {organization && isClient && (
          <p className="new-theme" onClick={handleOpenNewTheme}>
            {i18n.searchChats.newTheme}
          </p>
        )}
      </div>

      <SearchField
        onClickIcon={onSearch}
        handleOnChange={(e, value) => {
          e.preventDefault()
          onSearchDebounce(value, onSearch)
        }}
        placeholder={i18n.searchChats.search}
      />
    </SearchChatsStyled>
  )
}

SearchChats.propTypes = {
  handleOpenNewTheme: PropTypes.func,
  onSearch: PropTypes.func,
  getHomeSearchQuery: PropTypes.func,
  initDirects: PropTypes.func,
  initThemes: PropTypes.func,
  organization: PropTypes.object,
  userInfo: PropTypes.object
}

export default SearchChats
