import styled from 'styled-components'
import colors from '../../../assets/colors'

const BannerHomeStyled = styled.div`
  && {
    .box-wrapper {
      padding: 30px 15px;
      background-color: ${colors.white};

      .title {
        margin-bottom: 15px;
      }

      .desc {
        margin-bottom: 30px;
      }

      .main-video {
        outline: none;
      }

      button {
        margin-bottom: 30px;
      }

      .logos-wrapper {
        svg {
          margin: 10px;
        }
      }
    }

    @media (min-width: 768px) {
      .box-wrapper {
        padding: 90px 50px;
      }
    }
  }
`

export default BannerHomeStyled
