import React, { Component } from 'react'
import PropTypes from 'prop-types'
import SingleDatePickerStyled from './styled'
import DatePicker, { registerLocale } from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import moment from 'moment'
import es from 'date-fns/locale/es'
import i18n from '../../../assets/i18n'
registerLocale('es', es)

class SingleDatePicker extends Component {
  constructor(props) {
    super(props)
    this.state = {
      startDate: props.value ? moment(props.value, 'YYYY-MM-DD').toDate() : null
    }
  }

  handleChangeStart = date => {
    this.setState({ startDate: date })
    this.props.handleOnChange(date)
  }

  render() {
    const locale = i18n.getLanguage()

    return (
      <SingleDatePickerStyled hasMarginBottom={this.props.hasMarginBottom} hasError={this.props.hasError}>
        <div className="date-wrapper">
          <p>{this.props.label}</p>
          <div className="input-wrapper">
            <DatePicker
              selected={this.state.startDate}
              selectsStart
              minDate={this.props.minDate}
              maxDate={this.props.maxDate}
              startDate={this.state.startDate}
              onChange={this.handleChangeStart}
              onBlur={this.props.handleOnBlur}
              placeholderText={this.props.placeholderText}
              dateFormat="dd/MM/yyyy"
              locale={locale}
              showYearDropdown
              scrollableYearDropdown
              yearDropdownItemNumber={15}
            />
            <img src={require('../../../assets/images/calendar-icon.png')} alt="Calendar" />
          </div>
        </div>
      </SingleDatePickerStyled>
    )
  }
}

SingleDatePicker.defaultProps = {
  placeholderText: 'DD/MM/AAAA',
  hasMarginBottom: true,
  handleOnBlur: () => {},
  minDate: null,
  maxDate: null
}

SingleDatePicker.propTypes = {
  hasMarginBottom: PropTypes.bool,
  label: PropTypes.string,
  styledType: PropTypes.string,
  Icon: PropTypes.element,
  placeholderText: PropTypes.string,
  handleOnChangeStart: PropTypes.func,
  resetDate: PropTypes.bool,
  startDateNoFormat: PropTypes.any,
  handleOnChange: PropTypes.func,
  hasError: PropTypes.bool,
  handleOnBlur: PropTypes.func,
  value: PropTypes.any,
  minDate: PropTypes.bool,
  maxDate: PropTypes.any
}

export default SingleDatePicker
