import React from 'react'
import ThemeListItemStyled from './styled'
import PropTypes from 'prop-types'

const ThemeListItem = ({ name }) => {
  return (
    <ThemeListItemStyled>
      <p>{name}</p>
    </ThemeListItemStyled>
  )
}

ThemeListItem.propTypes = {
  imageUrl: PropTypes.string,
  name: PropTypes.string
}

export default ThemeListItem
