import { connect } from 'react-redux'
import compose from 'recompose/compose'
import View from './view'
import { withRouter } from 'react-router'
import { themesOperations } from '../../../redux/themes'

const mapStateToProps = state => {
  return {
    isFetchingCreateTheme: state.themes.isFetching,
    organization: state.organizations.item
  }
}

const mapDispatchToProps = dispatch => {
  return {
    createTheme: data => dispatch(themesOperations.createTheme(data))
  }
}

const enhance = compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)

export default withRouter(enhance(View))
