import styled from 'styled-components'
import colors from '../../../assets/colors'

const ComponentStyled = styled.div`
  && {
    padding: 15px 0;

    .poll-answer-wrapper {
      padding: 20px 30px;
      margin-bottom: 15px;
      transition: 0.5s ease all;
      cursor: pointer;
      position: relative;
      border-radius: 10px;

      &:hover,
      &.active {
        background-color: ${colors.yellow};
      }

      p {
        position: relative;
        z-index: 1;
        margin-bottom: 0;
      }
    }

    .btn-wrapper,
    .is-voted {
      margin-top: 15px;
      text-align: center;
    }
  }
`

export default ComponentStyled
