import React, { Component } from 'react'
import ComponentStyled from './styled'
import { RegularInput, Button, RegularSelect } from '../../atoms'
import i18n from '../../../assets/i18n'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { countryOptions, getCountryPhoneCodeByIso2 } from '../../../utils'
import { Row, Col } from 'react-bootstrap'

class UpdateOrganizationUsers extends Component {
  constructor(props) {
    super(props)
    this.state = {
      addedError: '',
      prefix: 'ES', // Se hardcodea el código de España
      number: '',
      phones: []
    }
  }

  render() {
    const { handleOnSubmit, isFetchingForm } = this.props
    const { prefix, number, phones, addedError } = this.state
    const formatPrefix = _.toString(getCountryPhoneCodeByIso2(prefix))

    const addParticipant = () => {
      const { prefix, number } = this.state
      const formatPrefix = _.toString(getCountryPhoneCodeByIso2(prefix))
      let { phones } = this.state
      const prefixNumber = `+${formatPrefix}${number}`

      if (_.indexOf(phones, prefixNumber) >= 0) {
        const bounced = _.debounce(() => {
          this.setState({ addedError: '' })
        }, 2000)
        this.setState({ addedError: i18n.updateOrganizationUsers.addedError }, bounced)
      } else {
        phones = _.concat(phones, prefixNumber)
        this.setState({ number: '', phones })
      }
    }

    const onChangePrefix = (e, prefix) => {
      this.setState({ prefix })
    }

    const onChangeNumber = (e, number) => {
      this.setState({ number })
    }

    const onDropParticipant = itemToRemove => {
      let { phones } = this.state
      phones = _.pull(phones, itemToRemove)
      this.setState({ phones })
    }

    const participantAdded = _.map(phones, (item, key) => {
      return (
        <div key={key} className="participant-wrapper">
          <p>{item}</p>
          <p
            className="delete-participant"
            onClick={() => {
              onDropParticipant(item)
            }}
          >
            {i18n.updateOrganizationUsers.delete}
          </p>
        </div>
      )
    })

    return (
      <ComponentStyled>
        <form
          onSubmit={e => {
            e.preventDefault()
            handleOnSubmit(phones)
          }}
        >
          <Row>
            <Col xs={12}>
              <p className="title">{i18n.updateOrganizationUsers.addUser}</p>
              <RegularSelect
                options={countryOptions}
                handleOnChange={onChangePrefix}
                defaultSelectedValue={prefix}
                label={i18n.updateOrganizationUsers.countryLabel}
                name="prefix"
              />
              <RegularInput
                label={i18n.updateOrganizationUsers.enterPhone}
                handleOnChange={onChangeNumber}
                placeholder="000 000 000"
                value={number}
                prepend={formatPrefix ? `+${formatPrefix}` : '+00'}
                name="phone"
                type="number"
              />

              {addedError && <p className="error-msg-input">{addedError}</p>}

              {prefix && number && _.size(number) >= 9 && (
                <p className="add-link">
                  <span onClick={addParticipant}>{i18n.updateOrganizationUsers.add}</span>
                </p>
              )}

              {_.size(phones) > 0 && (
                <>
                  <hr />
                  <div className="numbers-added">
                    <p className="title">{i18n.updateOrganizationUsers.usersAdded}</p>
                    {participantAdded}
                  </div>
                </>
              )}
            </Col>
            {_.size(phones) > 0 && (
              <Col xs={12} className="text-center">
                <Button isFetching={isFetchingForm} type="submit" variant="big" label={i18n.updateOrganizationUsers.save} />
              </Col>
            )}
          </Row>
        </form>
      </ComponentStyled>
    )
  }
}

UpdateOrganizationUsers.propTypes = {
  handleOnSubmit: PropTypes.func,
  isFetchingForm: PropTypes.bool
}

export default UpdateOrganizationUsers
