import React from 'react'
import DashboardLayout from 'components/system/layouts/DashboardLayout'
import i18n from 'assets/i18n'
import { withRouter } from 'react-router-dom'
import { Stats } from 'components/organism'
import * as S from './styled'

const StatsDashboard = () => (
  <DashboardLayout fullWidth title={i18n.statsDashboard.title}>
    <S.Wrapper>
      <Stats />
    </S.Wrapper>
  </DashboardLayout>
)

export default withRouter(StatsDashboard)
