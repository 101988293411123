import * as directsActions from './actions'
import { messagesOperations } from '../messages'
import _ from 'lodash'

export const initList = () => {
  return async dispatch => {
    dispatch(directsActions.updateList([], 0, null))
    return dispatch(fetchList())
  }
}

export const cleanDirect = () => {
  return async dispatch => {
    dispatch(directsActions.updateItem(null))
  }
}

export const fetchList = () => async (dispatch, getState, { api, client, setError }) => {
  const { item: organization } = getState().organizations
  const { userInfo } = getState().me
  try {
    dispatch(directsActions.setFetching(true))
    const userId = _.get(userInfo, 'id')
    const organizationId = _.get(organization, 'id')

    const { list: currentDirects, pageInfo } = getState().directs
    const endCursor = _.get(pageInfo, 'endCursor', null)

    const themesDirectsRes = await client.query({
      query: api.queries.GET_ORGANIZATION_DIRECTS,
      variables: { organizationId, userId, endCursor },
      fetchPolicy: 'network-only'
    })

    const newDirects = _.map(_.get(themesDirectsRes, 'data.directs.edges', []), v => v.node)
    const newPageInfo = _.get(themesDirectsRes, 'data.directs.pageInfo')
    const directsList = [...currentDirects, ...newDirects]
    const total = _.get(themesDirectsRes, 'data.directs.totalCount')
    dispatch(directsActions.updateList(directsList, total, newPageInfo))
    return directsList
  } catch (e) {
    dispatch(setError(e))
    return []
  } finally {
    dispatch(directsActions.setFetching(false))
  }
}

export const loadThemeMessageDirects = (messageId, authorId) => async (dispatch, getState, { api, client, setError }) => {
  try {
    dispatch(directsActions.setFetching(true))
    const messageDirectsRes = await client.query({
      query: api.queries.GET_MESSAGE_DIRECTS,
      variables: { messageId, authorId }
    })

    const direct = _.get(messageDirectsRes, 'data.message.directs.edges[0].node', null)
    if (direct) {
      dispatch(directsActions.updateItem(direct))
      dispatch(messagesOperations.initDirectMessagesList())
    }
  } catch (e) {
    dispatch(setError(e))
  } finally {
    dispatch(directsActions.setFetching(false))
  }
}
