import React, { Component } from 'react'
import { Header, Footer, Advantages, BannerHome } from '../../organism'
import HomeStyled from './styled'

class Home extends Component {
  render() {
    return (
      <HomeStyled>
        <Header />
        <BannerHome />
        <Advantages />
        <Footer />
      </HomeStyled>
    )
  }
}

export default Home
