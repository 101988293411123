import styled, { css } from 'styled-components'
import colors from '../../../assets/colors'

const ComponentStyled = styled.div`
  && {
    .customer-wrapper {
      padding: 10px 0;
      display: flex;
      align-items: center;
      transition: 0.5s ease all;
      justify-content: space-between;
      position: relative;
      margin: 25px 0;

      ${props =>
        props.onClickCustomer === true &&
        css`
          cursor: pointer;
        `}

      p {
        margin-bottom: 0;
        font-size: 16px;
        line-height: 16px;
        color: ${colors.darkGrey8};
      }

      .price {
        color: ${colors.yellow};
      }

      img,
      svg {
        position: relative;
        z-index: 1;
      }
    }

    .customer-title {
      font-size: 14px;
      font-weight: 600;
      line-height: 16px;
      letter-spacing: 1.08025px;
      text-transform: uppercase;
      color: ${colors.black};
    }

    .see-all {
      text-align: center;
      margin-bottom: 0;

      span {
        cursor: pointer;
        font-size: 14px;
        margin-top: 30px;
        line-height: 14px;
        letter-spacing: -0.43px;
        color: ${colors.darkGrey8};
        font-weight: 700;
      }
    }
  }
`

export default ComponentStyled
