import React, { Component } from 'react'
import CreateOrganizationCompletedStyled from './styled'
import { Button } from '../../atoms'
import i18n from '../../../assets/i18n'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'

class CreateOrganizationCompleted extends Component {
  constructor(props) {
    super(props)
    this.state = {
      addedError: '',
      prefix: '',
      number: '',
      phones: []
    }
  }

  render() {
    return (
      <CreateOrganizationCompletedStyled>
        <p className="success">{i18n.createOrganizationCompleted.success}</p>
        <Button type="submit" variant="big" label={i18n.createOrganizationCompleted.createTheme} handleOnClick={this.props.onClickCreateTheme} />
        <p className="go-dashboard">
          {i18n.createOrganizationCompleted.goTo} {''}
          <span onClick={this.props.onClickGoBeenfo}>{i18n.createOrganizationCompleted.myBeenfo}</span>
        </p>
      </CreateOrganizationCompletedStyled>
    )
  }
}

CreateOrganizationCompleted.propTypes = {
  handleOnSubmit: PropTypes.func,
  isFetchingForm: PropTypes.bool,
  onClickCreateTheme: PropTypes.func,
  onClickGoBeenfo: PropTypes.func
}

export default withRouter(CreateOrganizationCompleted)
