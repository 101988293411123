import React, { Component } from 'react'
import CreateThemeStep4Styled from './styled'
import { Button } from '../../atoms'
import i18n from '../../../assets/i18n'
import PropTypes from 'prop-types'

class CreateThemeStep4 extends Component {
  constructor(props) {
    super(props)
    this.state = {
      themeName: '',
      themeDesc: '',
      onlyAnswers: false,
      temporalLimit: false,
      limitDate: ''
    }
  }

  render() {
    return (
      <CreateThemeStep4Styled>
        <p>{i18n.createThemeStep4.title1}</p>
        <p>{i18n.createThemeStep4.title2}</p>
        <div className="button-wrapper">
          <Button type="submit" variant="big" label={i18n.createThemeStep4.finish} handleOnClick={this.props.onFinishButton} />
        </div>
        <p className="new-theme">
          <span onClick={this.props.onClickOtherTheme}>
            {i18n.createThemeStep4.addOther} {i18n.createThemeStep4.theme}
          </span>
        </p>
      </CreateThemeStep4Styled>
    )
  }
}

CreateThemeStep4.propTypes = {
  handleOnSubmit: PropTypes.func,
  isFetchingForm: PropTypes.bool,
  onClickOtherTheme: PropTypes.func,
  onFinishButton: PropTypes.func
}

export default CreateThemeStep4
