import React, { Component } from 'react'
import { CreateThemeStep2Styled } from './styled'
import { Button, Avatar, SearchField } from '../../atoms'
import i18n from '../../../assets/i18n'
import PropTypes from 'prop-types'
import { Row, Col } from 'react-bootstrap'
import _ from 'lodash'
import { ReactComponent as Spinner } from '../../../assets/images/yellow-spinner.svg'

class CreateThemeStep2 extends Component {
  constructor(props) {
    super(props)
    this.state = {
      themesToAdd: props.themes,
      participantsToAdd: [],
      participantsAdded: props?.participantsAdded || []
    }
  }

  componentDidMount() {
    this.loadOrganizationUsers()
  }

  loadOrganizationUsers = async (searchText = '') => {
    let participantsToAdd = await this.props.fetchOrganizationUsers(searchText)
    participantsToAdd = _.differenceWith(participantsToAdd, this.state.participantsAdded, _.isEqual)
    this.setState({ participantsToAdd })
  }

  filterThemes = async (searchText = '') => {
    let themesToAdd = [...this.props.themes]
    if (searchText.length > 0) {
      themesToAdd = themesToAdd.filter(item => {
        return item?.name.toLowerCase().includes(searchText.toLowerCase())
      })
    }

    this.setState({ themesToAdd })
  }

  onSearch = _.debounce((e, searchText) => {
    this.loadOrganizationUsers(searchText)
    this.filterThemes(searchText)
  }, 1000)

  onCheckAll = () => {
    const { isFetchingUsers } = this.props
    const { participantsToAdd, participantsAdded } = this.state
    if (!isFetchingUsers) {
      this.setState({ participantsAdded: _.concat(participantsToAdd, participantsAdded), participantsToAdd: [] })
    }
  }

  onThemeTapped = async itemToAdd => {
    const { participantsAdded, participantsToAdd } = this.state
    const { fetchNonPersistentThemeParticipantsList } = this.props

    const participants = await fetchNonPersistentThemeParticipantsList(itemToAdd?.id)

    const newList = _.uniqBy([...participantsAdded, ...participants], 'id')

    const formatParticipantsToAdd = _.differenceWith(participantsToAdd, newList, _.isEqual)

    this.setState({ participantsAdded: newList, participantsToAdd: formatParticipantsToAdd })
  }

  onClickParticipantToAdd = itemToAdd => {
    const { participantsToAdd } = this.state
    const { isFetchingUsers } = this.props

    if (!isFetchingUsers) {
      _.pull(participantsToAdd, itemToAdd)
      this.setState({ participantsToAdd })
      this.setState(previousState => ({
        participantsAdded: _.uniqBy([...previousState.participantsAdded, itemToAdd], 'id')
      }))
    }
  }

  onClickParticipantRemove = itemToRemove => {
    const { participantsAdded } = this.state
    const { isFetchingUsers } = this.props

    if (!isFetchingUsers) {
      _.pull(participantsAdded, itemToRemove)
      this.setState({ participantsAdded })
      this.setState(previousState => ({
        participantsToAdd: _.uniqBy([...previousState.participantsToAdd, itemToRemove], 'id')
      }))
    }
  }

  render() {
    const { participantsToAdd, participantsAdded, themesToAdd } = this.state
    const { isFetchingUsers, handleOnSubmit } = this.props
    const themesToAddFormat = _.map(themesToAdd, (item, key) => {
      const name = _.get(item, 'name', '')

      return (
        <div key={key} className="theme-list-wrapper" onClick={() => this.onThemeTapped(item)}>
          <p className="theme-name">{name}</p>
        </div>
      )
    })

    const participantsToAddFormat = _.map(participantsToAdd, item => {
      const clients = _.get(this.props.organization, 'clients.edges', '')
      const itemId = _.get(item, '_id', '')
      const directName = _.get(item, 'fullname', '')
      const directImage = _.get(item, 'image._id', '')
      const subInfo = _.get(item, 'phone', '')
      const isAdmin = _.find(clients, client => client?.node?._id === itemId)

      if (isAdmin) return null
      return (
        <div key={itemId} className="participant-list-wrapper" onClick={() => this.onClickParticipantToAdd(item)}>
          <Avatar resourceId={directImage} name={directName} subtitle={subInfo} />
        </div>
      )
    })

    const participantsAddedFormat = _.map(participantsAdded, item => {
      const itemId = _.get(item, '_id', '')
      const directName = _.get(item, 'fullname', '')
      const directImage = _.get(item, 'image._id', '')
      const subInfo = _.get(item, 'phone', '')

      return (
        <div key={itemId} className="participant-list-wrapper" onClick={() => this.onClickParticipantRemove(item)}>
          <Avatar resourceId={directImage} name={directName} subtitle={subInfo} />
        </div>
      )
    })

    return (
      <CreateThemeStep2Styled>
        <form
          onSubmit={e => {
            e.preventDefault()
            handleOnSubmit(participantsAdded)
          }}
        >
          <div className="search-field-wrapper">
            <SearchField placeholder={i18n.createThemeStep2.searchParticipants} handleOnChange={this.onSearch} />
          </div>

          <Row>
            <Col xs={12} sm={6} className="list-wrapper">
              <p className="title-list">{i18n.createThemeStep2.addParticipants}</p>
              {themesToAddFormat}
              {participantsToAddFormat}

              {isFetchingUsers && (
                <div className="spinner-wrapper">
                  <Spinner className="spinner" />
                </div>
              )}
            </Col>
            <Col xs={12} sm={6} className="list-wrapper">
              <p className="title-list">{i18n.createThemeStep2.participantAdded}</p>
              {participantsAddedFormat}
            </Col>
          </Row>
          {_.size(participantsAddedFormat) > 0 && (
            <div className="submit-participants-wrapper">
              <Button type="submit" variant="big" label={i18n.createThemeStep2.next} />
            </div>
          )}
          {_.size(participantsToAddFormat) > 0 && (
            <p className="check-all">
              <span onClick={this.onCheckAll}>{i18n.createThemeStep2.addAll}</span>
            </p>
          )}
        </form>
      </CreateThemeStep2Styled>
    )
  }
}

CreateThemeStep2.propTypes = {
  handleOnSubmit: PropTypes.func,
  fetchOrganizationUsers: PropTypes.func,
  isFetchingUsers: PropTypes.bool,
  themes: PropTypes.array,
  organization: PropTypes.object,
  participantsAdded: PropTypes.arrayOf(PropTypes.object)
}

export default CreateThemeStep2
