import React from 'react'
import PropTypes from 'prop-types'
import StepStyled from './styled'

const Step = ({ active, number, label }) => {
  return (
    <StepStyled active={active}>
      <div className="circle-step">
        <span>{number}</span>
      </div>
      <p>{label}</p>
    </StepStyled>
  )
}

Step.propTypes = {
  active: PropTypes.bool,
  number: PropTypes.string,
  label: PropTypes.string
}

export default Step
