import styled from 'styled-components'
import colors from '../../../assets/colors'

const ComponentStyled = styled.div`
  && {
    .title {
      font-size: 13px;
      line-height: 15px;
      text-transform: uppercase;
      color: ${colors.darkGrey};
      font-weight: 600;
    }

    .add-link {
      text-align: center;
      font-size: 13px;
      line-height: 15px;
      color: ${colors.yellow};

      span {
        cursor: pointer;
      }
    }

    .error-msg-input {
      margin-top: -25px;
    }

    .participant-wrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .delete-participant {
        font-size: 13px;
        line-height: 15px;
        color: ${colors.red};
        cursor: pointer;
      }
    }

    .numbers-added {
      margin-bottom: 50px;
    }
  }
`

export default ComponentStyled
