import { connect } from 'react-redux'
import compose from 'recompose/compose'
import { organizationsOperations } from '../../../redux/organizations'
import View from './view'

const mapStateToProps = state => {
  return {
    isFetching: state.organizations.isFetching
  }
}

const mapDispatchToProps = dispatch => {
  return {
    postOrganizationUsers: data => dispatch(organizationsOperations.postOrganizationUsers(data))
  }
}

const enhance = compose(connect(mapStateToProps, mapDispatchToProps))

export default enhance(View)
