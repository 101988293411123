import styled from 'styled-components'
import colors from '../../../assets/colors'

const ComponentStyled = styled.div`
  && {
    background: transparent;

    .modal-header,
    .modal-body {
      border: 0;
      padding: 0;
      position: relative;
    }

    .close-icon-wrapper {
      position: absolute;
      cursor: pointer;
      padding: 5px;
      border-radius: 50px;
      top: -40px;
      right: -40px;
      background: ${colors.white};
    }

    .image-chat {
      width: 100%;
    }
  }
`

export default ComponentStyled
