import React, { useState } from 'react'
import ComponentStyled from './styled'
import { DirectChatListMoreDetails, UserInfo } from '../../molecules'
import PropTypes from 'prop-types'
import i18n from '../../../assets/i18n'

const SideBarMembers = ({ participants }) => {
  const [userInfo, setUserInfo] = useState(null)

  function changeUserInfo(user) {
    setUserInfo(user)
  }

  return (
    <ComponentStyled>
      {!userInfo ? (
        <>
          <div className="title-member-wrapper">
            <p className="title-member">{i18n.sideBarMembers.members}</p>
          </div>
          <DirectChatListMoreDetails
            showProfile={user => {
              changeUserInfo(user)
            }}
            participants={participants}
          />
        </>
      ) : (
        <>
          <div>
            <span
              className="back"
              onClick={() => {
                changeUserInfo(null)
              }}
            >
              {i18n.sideBarMembers.back}
            </span>
          </div>
          <UserInfo user={userInfo} />
        </>
      )}
    </ComponentStyled>
  )
}

SideBarMembers.propTypes = {
  participants: PropTypes.array,
  onOpenEdit: PropTypes.func
}

export default SideBarMembers
