import firebase from 'firebase/app'
import 'firebase/analytics'

export const setAnalyticsWSReceiveEvent = eventData => {
  const { data, ...props } = eventData
  firebase.analytics().logEvent('ws_receive_event', {
    time: new Date(),
    ...props,
    ...(data || {})
  })
}
