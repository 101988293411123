import React from 'react'
import PropTypes from 'prop-types'
import ComponentStyled from './styled'
import { ReactComponent as ArroIcon } from '../../../assets/images/simple-right-arrow.svg'

const BillingItem = ({ title, description, moreInfo, onClick }) => {
  return (
    <ComponentStyled hasClickEvent={onClick ? true : false} onClick={onClick}>
      <p className="title">{title}</p>
      <p className="desc">{description}</p>
      <p className="more-info">{moreInfo}</p>
      {onClick && <ArroIcon className="arrow" />}
    </ComponentStyled>
  )
}

BillingItem.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  moreInfo: PropTypes.string,
  onClick: PropTypes.func
}

export default BillingItem
