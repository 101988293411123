import React from 'react'
import PropTypes from 'prop-types'
import ImageBgStyled from './styled'

const ImageBg = ({ imageUrl }) => {
  return <ImageBgStyled className="image-bg" style={{ backgroundImage: 'url(' + imageUrl + ')' }}></ImageBgStyled>
}

ImageBg.propTypes = {
  imageUrl: PropTypes.string
}

export default ImageBg
