import React from 'react'
import ComponentStyled from './styled'
import { RegularInput, Button } from '../../atoms'
import i18n from '../../../assets/i18n'
import PropTypes from 'prop-types'
import { Formik } from 'formik'
import { withRouter } from 'react-router'

const RegisterPassword = ({ onSubmit, isFetching }) => {
  return (
    <ComponentStyled>
      <Formik
        initialValues={{
          password: '',
          password2: ''
        }}
        validate={values => {
          let errors = {}
          if (!values.password) {
            errors.password = i18n.registerPassword.requiredField
          }
          if (!values.password2) {
            errors.password2 = i18n.registerPassword.requiredField
          }
          if (values.password && values.password2 && values.password2 !== values.password) {
            errors.password2 = i18n.registerPassword.wrongPass
          }
          return errors
        }}
        onSubmit={data => {
          onSubmit(data)
        }}
      >
        {({ values, errors, touched, handleChange, handleBlur, handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <p className="title">{i18n.registerPassword.title}</p>
            <RegularInput
              label={i18n.registerPassword.newPassLabel}
              placeholder={i18n.registerPassword.newPassPlaceholder}
              type="password"
              handleOnChange={handleChange}
              handleOnBlur={handleBlur}
              name="password"
              value={values.password}
              hasError={errors.password && touched.password && true}
            />
            {errors.password && touched.password && <p className="error-msg-input">{errors.password}</p>}

            <RegularInput
              label={i18n.registerPassword.repeatPassLabel}
              placeholder={i18n.registerPassword.repeatPassPlacaholder}
              type="password"
              handleOnChange={handleChange}
              handleOnBlur={handleBlur}
              name="password2"
              value={values.password2}
              hasError={errors.password2 && touched.password2 && true}
            />
            {errors.password2 && touched.password2 && <p className="error-msg-input">{errors.password2}</p>}

            <Button variant="big" isFetching={isFetching} label={i18n.registerPassword.continue} />
          </form>
        )}
      </Formik>
    </ComponentStyled>
  )
}

RegisterPassword.propTypes = {
  onSubmit: PropTypes.func,
  isFetching: PropTypes.bool
}

export default withRouter(RegisterPassword)
