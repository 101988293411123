import styled from 'styled-components'

const ComponentStyled = styled.div`
  && {
    .modal-header,
    .modal-body {
      border: 0;
      padding: 0;
    }
    .payment-form-wrapper {
      text-align: center;
      > div {
        display: block;
        padding: 0px 40px 40px 40px;
      }
    }
  }
`

export default ComponentStyled
