import styled, { css } from 'styled-components'
import colors from '../../../assets/colors'

const ComponentStyled = styled.div`
  && {
    position: relative;
    padding-right: 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .title {
      margin-bottom: 0;
      font-size: 16px;
      line-height: 16px;
      color: ${colors.darkGrey3};
    }

    .price {
      font-size: 16px;
      line-height: 16px;
      color: ${colors.yellow};
      margin-bottom: 0;
    }

    .arrow {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 0;
    }

    ${props =>
      props.hasClickEvent === true &&
      css`
        cursor: pointer;
      `}
  }
`

export default ComponentStyled
