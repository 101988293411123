import * as customersActions from './actions'
import _ from 'lodash'

export const fetchList = () => async (dispatch, getState, { client, api, setError }) => {
  try {
    dispatch(customersActions.setFetching(true))
    const customersQueryRes = await client.query({ query: api.queries.GET_USER_CUSTOMERS, fetchPolicy: 'network-only' })
    const organizations = _.map(_.get(customersQueryRes, 'data.collectionQueryCustomers.edges', []), v => v.node)

    dispatch(customersActions.updateList(organizations))
    return organizations
  } catch (e) {
    dispatch(setError(e))
    return []
  } finally {
    dispatch(customersActions.setFetching(false))
  }
}

export const selectItem = item => async (dispatch, getState, { client, api, setError }) => {
  dispatch(customersActions.updateItem(item))
}
