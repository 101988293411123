import styled from 'styled-components'
import colors from '../../../assets/colors'

export const Container = styled.div`
  display: flex;
  flex-direction: row;

  .range-date-picker-right-button button {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .range-date-picker-left-button button {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .range-date-picker-separator {
    height: auto;
    background: ${colors.white};
    align-items: center;
    justify-content: center;
    display: flex;
  }
  .react-datepicker__month--selected {
    background-color: ${colors.yellow};
  }
  .react-datepicker__header {
    background-color: ${colors.yellow};
  }
  .react-datepicker__triangle {
    border-bottom-color: ${colors.yellow} !important;
  }
  .react-datepicker__navigation--next {
    border-left-color: ${colors.black};
  }
  .react-datepicker__navigation--previous {
    border-right-color: ${colors.black};
  }
`
