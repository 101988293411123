import React from 'react'
import ComponentStyled from './styled'
import PropTypes from 'prop-types'
import i18n from '../../../assets/i18n'

const PopUpUser = ({ openComercialSidebar, handleOpenUpdateUser, logout, fullName, phone, togglePopup }) => {
  return (
    <ComponentStyled>
      <div className="pop-up">
        <p className="name">{fullName}</p>
        <p className="phone">{phone}</p>
        <hr />
        <ul>
          <li
            onClick={() => {
              handleOpenUpdateUser()
              togglePopup()
            }}
          >
            {i18n.popUpUser.preferences}
          </li>
          <li
            onClick={() => {
              openComercialSidebar()
              togglePopup()
            }}
          >
            {i18n.popUpUser.comercialZone}
          </li>
          <li onClick={logout} className="close-session">
            {i18n.popUpUser.logOut}
          </li>
        </ul>
      </div>
    </ComponentStyled>
  )
}

PopUpUser.propTypes = {
  openComercialSidebar: PropTypes.func,
  handleOpenUpdateUser: PropTypes.func,
  logout: PropTypes.func,
  fullName: PropTypes.string,
  phone: PropTypes.string,
  togglePopup: PropTypes.func
}

export default PopUpUser
