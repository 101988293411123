import { connect } from 'react-redux'
import compose from 'recompose/compose'
import { meOperations } from '../../../redux/me'
import View from './view'
import { withRouter } from 'react-router'

const mapStateToProps = state => {
  return {
    isFetching: state.me.isFetching,
    userInfo: state.me.userInfo
  }
}

const mapDispatchToProps = (dispatch, props) => {
  return {
    disableUser: () => dispatch(meOperations.disableUser(props.history)),
    updateUserInfo: data => dispatch(meOperations.updateUserInfo(data))
  }
}

const enhance = compose(connect(mapStateToProps, mapDispatchToProps))

export default withRouter(enhance(View))
