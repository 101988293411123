import React, { Component } from 'react'
import { Col } from 'react-bootstrap'
import UserCreateOrganizationStyled from './styled'
import { CreateOrganizationForm, PaymentInfo, PaymentMethodForm, AddParticipantsForm, CreateOrganizationCompleted } from '../../molecules'
import { CircleHeader } from '../../atoms'
import PropTypes from 'prop-types'
import { toast } from 'react-toastify'
import { postSubscription } from '../../../api'
import _forEach from 'lodash/forEach'
import _get from 'lodash/get'
import i18n from '../../../assets/i18n'

const STEPS = {
  PAY_INFO: 1,
  ORGANIZATION: 2,
  PAY_METHOD: 3,
  PARTICIPANTS: 4,
  COMPLETED: 5
}

class UserCreateOrganization extends Component {
  constructor(props) {
    super(props)
    this.state = {
      formErrors: '',
      step: props.organizationId && !props.activateOrg ? STEPS.PARTICIPANTS : STEPS.PAY_INFO,
      payMethod: null,
      participants: [],
      isFetching: false,
      organizationCreated: null,
      organizationFormErrors: {}
    }
  }

  onSubmitPaymentInfo = async () => {
    if (this.props.activateOrg) {
      this.setState({ isFetching: true }, async () => {
        try {
          this.setState({ step: STEPS.PAY_METHOD, isFetching: false })
        } catch (e) {
          this.setState({ isFetching: false })
          toast(i18n.generalError, { type: toast.TYPE.ERROR })
        }
      })
    } else {
      this.setState({ step: STEPS.ORGANIZATION })
    }
  }

  onSubmitOrganizationData = async organizationFormData => {
    this.setState({ organizationFormData, isFetching: true }, async () => {
      try {
        this.setState({ organizationFormErrors: {} })
        const organizationCreated = await this.props.postOrganization(organizationFormData)
        if (organizationCreated?.error) {
          let errors = {}
          _forEach(organizationCreated?.error?.response?.data?.violations, error => {
            errors[error?.propertyPath] = i18n.errors[error.code]
          })
          this.setState({ isFetching: false, organizationFormErrors: errors })
        } else {
          this.setState({ step: STEPS.PAY_METHOD, organizationCreated, isFetching: false })
        }
      } catch (e) {
        this.setState({ isFetching: false })
        toast(i18n.paymentMethodForm.success, { type: toast.TYPE.SUCCESS })
      }
    })
  }

  onSubmitOrganizationPaymentMethod = async paymentMethod => {
    const { activateOrg, organizationId } = this.props

    this.setState({ isFetching: true })
    const createdOrganizationId = activateOrg ? organizationId : _get(this.state.organizationCreated, '_id')
    try {
      await postSubscription(createdOrganizationId, paymentMethod)
      this.props.onClickGoBeenfo()
      await this.props.initList()
      toast(i18n.generarlSuccess, { type: toast.TYPE.SUCCESS })
    } catch (e) {
      toast(i18n.generalError, { type: toast.TYPE.ERROR })
    } finally {
      this.setState({ isFetching: false })
    }
  }

  onSubmitParticipants = async usersList => {
    const { organizationId } = this.props
    this.setState({ isFetching: true }, async () => {
      if (usersList.length) {
        const formatOrgId = `/api/organizations/${organizationId}`
        const success = await this.props.postOrganizationUsers(usersList, formatOrgId)
        if (success) {
          this.setState({ step: STEPS.COMPLETED })
        }
      }
      this.setState({ isFetching: false })
    })
  }

  handleCleanErrors = () => {
    this.setState({
      organizationFormErrors: {}
    })
  }

  renderStep() {
    const { step, isFetching } = this.state
    const { activateOrg, organizationId } = this.props
    const createdOrganizationId = activateOrg ? organizationId : _get(this.state.organizationCreated, '_id')
    switch (step) {
      case STEPS.PAY_INFO:
        return <PaymentInfo isFetchingForm={isFetching} handleOnSubmit={this.onSubmitPaymentInfo} />
      case STEPS.ORGANIZATION:
        return (
          <CreateOrganizationForm
            isFetchingForm={isFetching}
            handleOnSubmit={this.onSubmitOrganizationData}
            errors={this.state.organizationFormErrors}
            cleanErrors={this.handleCleanErrors}
          />
        )
      case STEPS.PAY_METHOD:
        return <PaymentMethodForm organizationId={createdOrganizationId} handleOnSubmit={this.onSubmitOrganizationPaymentMethod} />
      case STEPS.PARTICIPANTS:
        return <AddParticipantsForm isFetchingForm={isFetching} handleOnSubmit={this.onSubmitParticipants} />
      case STEPS.COMPLETED:
        return <CreateOrganizationCompleted onClickCreateTheme={this.props.onClickCreateTheme} onClickGoBeenfo={this.props.onClickGoBeenfo} />
      default:
        break
    }
  }

  render() {
    const { formErrors } = this.state
    return (
      <UserCreateOrganizationStyled>
        <div className="login-wrapper">
          <div className="circle-header">
            <CircleHeader />
          </div>
          <Col xs={12} sm={{ span: 6, offset: 3 }}>
            {this.renderStep()}
            <p className="error-msg-form">{formErrors}</p>
          </Col>
        </div>
      </UserCreateOrganizationStyled>
    )
  }
}

UserCreateOrganization.defaultProps = {
  isPaid: false,
  activateOrg: false
}

UserCreateOrganization.propTypes = {
  history: PropTypes.object,
  postOrganizationUsers: PropTypes.func,
  login: PropTypes.func,
  isFetching: PropTypes.bool,
  postOrganization: PropTypes.func,
  onClickGoBeenfo: PropTypes.func,
  onClickCreateTheme: PropTypes.func,
  updateSelectedOrganization: PropTypes.func,
  organizationId: PropTypes.any,
  updateOrganization: PropTypes.func,
  activateOrg: PropTypes.bool,
  initList: PropTypes.func
}

export default UserCreateOrganization
