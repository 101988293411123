import React, { useState } from 'react'
import _get from 'lodash/get'
import OrganizationBarStyled from './styled'
import { useHistory } from 'react-router-dom'
import { PopUpOrganizationBar, Avatar } from '../../atoms'
import routes from 'config/routes'
import PropTypes from 'prop-types'
import { ModalManageUsers, ModalAccept } from '../../modals'
import i18n from '../../../assets/i18n'
import { SUBSCRIPTION_STATUS } from 'config/constants'
import { toast } from 'react-toastify'
import { Dropdown } from 'react-bootstrap'

const CustomToggle = React.forwardRef(({ children, onClick }, ref) => {
  return (
    <span
      ref={ref}
      onClick={e => {
        e.preventDefault()
        onClick(e)
      }}
    >
      {children}
    </span>
  )
})

const OrganizationBar = ({
  onCreateOrganizationTapped,
  onCreateThemeTapped,
  onPreferencesTapped,
  onCreateOrganization,
  isFetchingOrganization,
  organization,
  organizations,
  updateSelectedOrganization,
  onExitOrganization,
  onOpenBilling,
  onToggleModalOrganizationInProgress
}) => {
  const [openModalManageUsers, setOpenModalManageUsers] = useState(false)
  const [openModalAccept, setOpenModalAccept] = useState(false)
  const [openPopup, setOpenPopup] = useState(false)
  const history = useHistory()

  function togglePopup() {
    setOpenPopup(!openPopup)
  }

  const goToOrganization = async (org, isClient, isSubscribed) => {
    if (isSubscribed) {
      await updateSelectedOrganization(org)
      history.push(routes.dashboard)
    } else if (isClient) {
      await onCreateOrganizationTapped(_get(org, '_id'), true)
    } else {
      toast(i18n.organizationBar.pendingPayment, { type: toast.TYPE.ERROR })
    }
  }

  const onOrganizationTapped = async (org, isClient, isSubscribed) => {
    if (_get(org, 'id') === _get(organization, 'id')) {
      return
    }

    switch(org?.subscriptionStatus) {
      case SUBSCRIPTION_STATUS.ACTIVE : goToOrganization(org, isClient, isSubscribed);break;
      case SUBSCRIPTION_STATUS.PENDING : onToggleModalOrganizationInProgress();break;
      default: toast(i18n.organizationBar.pendingPayment, { type: toast.TYPE.ERROR });
    }
  }

  function onToggleManageUsersModal() {
    setOpenModalManageUsers(!openModalManageUsers)
  }

  function toogleCreateOrg() {
    onCreateOrganization()
  }

  function toggleAcceptModal() {
    setOpenModalAccept(!openModalAccept)
  }

  async function exitOrganization() {
    try {
      await onExitOrganization(organization)
      toggleAcceptModal()
    } catch {
      toast(i18n.generalError, { type: toast.TYPE.ERROR })
    }
  }

  const orgName = _get(organization, 'name')
  const orgImgId = _get(organization, 'image._id')

  return (
    <OrganizationBarStyled>
      <Dropdown onToggle={togglePopup} show={openPopup}>
        <Dropdown.Toggle as={CustomToggle}>
          <div className="organization-wrapper">
            <Avatar resourceId={orgImgId} name={orgName} />
            <img className="arrow" src={require('../../../assets/images/black-arrow.png')} alt="Arrow" />
          </div>
        </Dropdown.Toggle>
        <Dropdown.Menu className="menu-popup">
          <PopUpOrganizationBar
            togglePopup={togglePopup}
            onCreateOrganizationTapped={onCreateOrganizationTapped}
            onCreateThemeTapped={onCreateThemeTapped}
            onPreferencesTapped={onPreferencesTapped}
            onOrganizationTapped={onOrganizationTapped}
            onToggleManageUsersModal={onToggleManageUsersModal}
            selectedOrganization={orgName}
            organizations={organizations}
            toogleModal={toogleCreateOrg}
            onClickExit={toggleAcceptModal}
            onOpenBilling={onOpenBilling}
          />
        </Dropdown.Menu>
      </Dropdown>

      <ModalManageUsers show={openModalManageUsers} onHide={onToggleManageUsersModal} />
      <ModalAccept
        show={openModalAccept}
        onHide={toggleAcceptModal}
        onReject={toggleAcceptModal}
        desc={i18n.organizationBar.acceptExit}
        isFetching={isFetchingOrganization}
        onAccept={exitOrganization}
      />
    </OrganizationBarStyled>
  )
}

OrganizationBar.propTypes = {
  isFetchingOrganization: PropTypes.bool,
  onCreateThemeTapped: PropTypes.func,
  userInfo: PropTypes.object,
  organization: PropTypes.object,
  organizations: PropTypes.arrayOf(PropTypes.object),
  isFetching: PropTypes.bool,
  updateSelectedOrganization: PropTypes.func,
  onCreateOrganization: PropTypes.func,
  onPreferencesTapped: PropTypes.func,
  onCreateOrganizationTapped: PropTypes.func,
  onExitOrganization: PropTypes.func,
  onOpenBilling: PropTypes.func,
  onToggleModalOrganizationInProgress: PropTypes.func
}

export default OrganizationBar
