import app from 'firebase/app'
import 'firebase/analytics'
import 'firebase/auth'

const config = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_DATABASE_URL,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID
}

class Firebase {
  constructor() {
    app.initializeApp(config)
  }

  // *** Auth API ***
  signInWithPhoneNumber = (phone, verifier) => app.auth().signInWithPhoneNumber(phone, verifier)
  getIdtoken = () => app.auth().currentUser.getIdToken(true)
  getAuth = () => app.auth()
}

export default Firebase
