import React from 'react'
import ComponentStyled from './styled'
import PropTypes from 'prop-types'
import _ from 'lodash'
import i18n from '../../../assets/i18n'

const SideBarPollInfo = ({ poll }) => {
  const totalVotes = _.get(poll, 'totalVotes', 0)
  const name = _.get(poll, 'name', '')
  const answers = _.get(poll, 'answers.edges', [])

  return (
    <ComponentStyled>
      <div className="title-chat-wrapper">
        <p className="title-chat">{i18n.sideBarPollInfo.stats}</p>
        <div className="total-votes-wrapper">
          <p className="total">{totalVotes}</p>
          <p>{i18n.sideBarPollInfo.votes}</p>
        </div>
        <p>{i18n.sideBarPollInfo.answers}</p>
        <p className="poll-name">{name}</p>
        {_.map(answers, (v, key) => {
          const textAnswer = _.get(v, 'node.textAnswer')
          const votes = !_.isNil(v.node.votes) ? _.get(v, 'node.votes', 0) : 0
          const percent = votes ? (votes * 100) / totalVotes : 0
          const percentFormmated = parseFloat(percent.toFixed(2))

          return (
            <div key={key} className="answer-wrapper">
              <p className="answer-name">{textAnswer}</p>
              <p className="percent">
                <span>{i18n.sideBarPollInfo.percent}</span>
                <span>{percentFormmated}%</span>
              </p>
              <p className="votes">
                <span>{i18n.sideBarPollInfo.votesNumber}</span>
                <span>{votes}</span>
              </p>
            </div>
          )
        })}
      </div>
    </ComponentStyled>
  )
}

SideBarPollInfo.propTypes = {
  poll: PropTypes.object
}

export default SideBarPollInfo
