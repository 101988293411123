import { MESSAGES_TYPES } from '../../config/constants'
import _ from 'lodash'
import moment from 'moment'
import * as api from '../../api'

export const formatMessageData = async data => {
  switch (data.type) {
    case MESSAGES_TYPES.TEXT:
      return formatTextMessageData(data)
    case MESSAGES_TYPES.IMAGE:
      return await formatImageMessageData(data)
    case MESSAGES_TYPES.AUDIO:
      return await formatAudioMessageData(data)
    case MESSAGES_TYPES.FILE:
      return await formatFileMessageData(data)

    default:
      return null
  }
}

const formatTextMessageData = data => {
  const { value, ...rest } = data
  let messageData = { ...rest }
  if (value) {
    messageData.text = value
  }

  return messageData
}

const formatAudioMessageData = async data => {
  const { value, ...rest } = data
  let messageData = { ...rest }
  if (value) {
    const resource = {
      type: 'audio/*',
      uri: `file://${value}`,
      name: `${moment().format('X')}.wav`
    }

    const postResourceRes = await api.postResource(resource)
    const mediaObjectId = _.get(postResourceRes, 'data[@id]')
    messageData.mediaObject = mediaObjectId
  }
  return messageData
}

const formatImageMessageData = async data => {
  const { value, ...rest } = data
  let messageData = { ...rest }
  if (value) {
    const postResourceRes = await api.postResource(value)
    const mediaObjectId = _.get(postResourceRes, 'data[@id]')
    messageData.mediaObject = mediaObjectId
  }

  return messageData
}

const formatFileMessageData = async data => {
  const { value, ...rest } = data
  let messageData = { ...rest }
  if (value) {
    const postResourceRes = await api.postResource(value)
    const mediaObjectId = _.get(postResourceRes, 'data[@id]')
    messageData.mediaObject = mediaObjectId
  }

  return messageData
}
