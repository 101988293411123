import React, { Component } from 'react'
import ComponentStyled from './styled'
import { RegularInput, Button, SingleDatePicker } from '../../atoms'
import i18n from '../../../assets/i18n'
import PropTypes from 'prop-types'
import { Formik } from 'formik'
import _ from 'lodash'
import moment from 'moment'

class EditThemeInfo extends Component {
  render() {
    const { theme } = this.props
    const themeName = _.get(theme, 'name', '')
    const themeDescription = _.get(theme, 'description', '')
    const endDate = _.get(theme, 'endDate', null)
    const readOnly = _.get(theme, 'readOnly')

    return (
      <ComponentStyled>
        <Formik
          initialValues={{
            themeName: themeName,
            themeDesc: themeDescription,
            readOnly: readOnly,
            temporalLimit: endDate ? true : false,
            limitDate: _.isNil(endDate) ? null : moment(endDate)
          }}
          validate={values => {
            let errors = {}
            if (!values.themeName) {
              errors.themeName = i18n.editThemeInfo.requiredField
            }
            return errors
          }}
          onSubmit={data => {
            this.props.handleOnSubmit(data)
          }}
        >
          {({ values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue }) => (
            <form onSubmit={handleSubmit}>
              <RegularInput
                label={i18n.editThemeInfo.themeName}
                placeholder={i18n.editThemeInfo.themeNamePlaceholder}
                handleOnChange={handleChange}
                handleOnBlur={handleBlur}
                name="themeName"
                value={values.themeName}
                hasError={errors.themeName && touched.themeName && true}
              />
              {errors.themeName && touched.themeName && <p className="error-msg-input">{errors.themeName}</p>}

              <RegularInput
                label={i18n.editThemeInfo.themeDesc}
                placeholder={i18n.editThemeInfo.themeDescPlaceholder}
                handleOnChange={handleChange}
                handleOnBlur={handleBlur}
                name="themeDesc"
                value={values.themeDesc}
                hasError={errors.themeDesc && touched.themeDesc && true}
              />

              <div className="check-wrapper">
                <p>{i18n.editThemeInfo.answers}</p>
                <RegularInput
                  type="checkbox"
                  variant="checkbox"
                  handleOnChange={handleChange}
                  handleOnBlur={handleBlur}
                  name="readOnly"
                  checked={values.readOnly}
                  hasError={errors.readOnly && touched.readOnly && true}
                />

                <span>{i18n.editThemeInfo.answersValue}</span>
              </div>
              <div className="check-wrapper">
                <p>{i18n.editThemeInfo.temporalLimit}</p>
                <RegularInput
                  type="checkbox"
                  variant="checkbox"
                  handleOnChange={handleChange}
                  handleOnBlur={handleBlur}
                  name="temporalLimit"
                  checked={values.temporalLimit}
                  hasError={errors.temporalLimit && touched.temporalLimit && true}
                />
                <span>{i18n.editThemeInfo.temporalLimitValue}</span>
              </div>
              {values.temporalLimit && (
                <SingleDatePicker
                  handleOnChange={date => {
                    setFieldValue('limitDate', date)
                  }}
                  minDate={moment().toDate()}
                  name="limitDate"
                  value={values.limitDate}
                  hasError={errors.limitDate && touched.limitDate && true}
                />
              )}
              {errors.limitDate && touched.limitDate && <p className="error-msg-input">{errors.limitDate}</p>}

              <div className="submit-wrapper">
                <Button type="submit" variant="big" label={i18n.editThemeInfo.save} isFetching={this.props.isFetchingForm} />
              </div>
            </form>
          )}
        </Formik>
      </ComponentStyled>
    )
  }
}

EditThemeInfo.propTypes = {
  handleOnSubmit: PropTypes.func,
  isFetchingForm: PropTypes.bool,
  theme: PropTypes.object
}

export default EditThemeInfo
