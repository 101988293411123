import styled from 'styled-components'
import colors from '../../../assets/colors'

const ComponentStyled = styled.div`
  && {
    display: flex;
    padding: 15px 0;

    .avatar-message-wrapper {
      align-self: flex-end;
      margin-right: 20px;
    }

    .message-wrapper {
      max-width: 80%;

      .message-box {
        background-color: ${colors.darkGrey5};
        padding: 15px;
        border-radius: 20px;
        border-bottom-left-radius: 0;

        .message-author {
          font-size: 14px;
          line-height: 14px;
          color: #3c4e58;
          margin-bottom: 10px;
        }

        .message-content {
          font-size: 14px;
          line-height: 19px;
          color: ${colors.darkGrey};
          margin-bottom: 0;
          text-align: right;
          -ms-word-break: break-all;
          word-break: break-all;
          word-break: break-word;

          & a {
            font-size: 14px;
            line-height: 19px;
            color: ${colors.darkGrey};
            margin-bottom: 0;
            text-align: right;
          }
        }

        .message-time {
          color: ${colors.white};
          font-size: 10px;
          line-height: 10px;
          margin-top: 5px;
          margin-bottom: 0;
          text-align: right;
        }
      }

      .btn-wrapper {
        margin-top: 5px;
      }
    }

    .actions-wrapper {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      flex-flow: column;
      margin-left: 10px;

      .reply-btn {
        min-width: 25px;
        max-width: 100%;
        width: 25px; 
        height: 25px;
        display: inline-block
        background-color: ${colors.darkGrey5};
        border: 1px solid ${colors.darkGrey5};
        border-radius: 50px;
        position: relative;
        transition: 0.5s ease all;
        cursor: pointer;

        :hover {
          background-color: transparent;

          svg path {
            fill: ${colors.darkGrey5};
          }
        }

        svg {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          transition: 0.5s ease all;
        }
      }

      .see-more-btn {
        min-width: 200px;
        display: inline-block;
        cursor: pointer;
        p {
          margin-bottom: 0;
          font-weight: 600;
          font-size: 13px;
          line-height: 15px;
          color: ${colors.yellow};

          img,
          svg {
            margin-left: 10px;
          }
        }
        &--isDeleted {
          p {
            color: ${colors.grey2};
          }
        }
      }
    }
  }

  
`

export default ComponentStyled
