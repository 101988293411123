import React from 'react'
import CircleHeaderStyled from './styled'
import { ReactComponent as Logo } from '../../../assets/images/logo.svg'
import { withRouter } from 'react-router'

const CircleHeader = () => {
  return (
    <CircleHeaderStyled>
      <Logo />
    </CircleHeaderStyled>
  )
}

export default withRouter(CircleHeader)
