import React from 'react'
import PropTypes from 'prop-types'
import SearchFieldStyled from './styled'
import { ReactComponent as SearchIcon } from '../../../assets/images/search.svg'

const SearchField = ({ variant, placeholder, type, name, onClickIcon, handleOnChange, handleOnBlur, hasError }) => {
  return (
    <SearchFieldStyled variant={variant} hasError={hasError}>
      <div className="search-wrapper">
        <SearchIcon className="search-icon" onClick={onClickIcon} />
        <input
          placeholder={placeholder}
          type={type}
          name={name}
          onBlur={handleOnBlur ? handleOnBlur : null}
          onChange={handleOnChange ? e => handleOnChange(e, e.target.value, e.target.name) : null}
        />
      </div>
    </SearchFieldStyled>
  )
}

SearchField.defaultProps = {
  type: 'text',
  hasError: false
}

SearchField.propTypes = {
  onClickIcon: PropTypes.func,
  variant: PropTypes.string,
  placeholder: PropTypes.string,
  type: PropTypes.string,
  name: PropTypes.string,
  handleOnChange: PropTypes.func,
  handleOnBlur: PropTypes.func,
  hasError: PropTypes.bool
}

export default SearchField
