import React from 'react'
import PropTypes from 'prop-types'
import * as S from './styled'

const Wrapper = ({ children, ...rest }) => {
  return (
    <S.Container {...rest}>
      <S.Wrapper>{children}</S.Wrapper>
    </S.Container>
  )
}

Wrapper.propTypes = {
  children: PropTypes.node
}

export default Wrapper
