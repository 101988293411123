import * as types from './types'
import { LOG_OUT } from '../auth/types'

const initialState = {
  list: [],
  isFetching: false
}

const reducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case types.PROFITS_UPDATE_LIST:
      return {
        ...state,
        list: action.value
      }

    case types.PROFITS_SET_FETCHING:
      return {
        ...state,
        isFetching: action.value
      }

    case LOG_OUT:
      return initialState

    default:
      return state
  }
}

export default reducer
