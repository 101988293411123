import React, { Component } from 'react'
import ChatWindowStyled from './styled'
import { ChatBarName, FilterAnswersBar, WriteChatBar, AuthorMessage, UserMessage, InfoAnswersBar } from '../../molecules'
import PropTypes from 'prop-types'
import _ from 'lodash'
import moment from 'moment'
import { ReactComponent as Spinner } from '../../../assets/images/yellow-spinner.svg'
import { directsUtils } from '../../../redux/directs'
import i18n from '../../../assets/i18n'
import { toast } from 'react-toastify'

class ChatWindow extends Component {
  constructor(props) {
    super(props)
    this.state = {
      showBroadcastOnly: false
    }
    this.lastMessage = null
  }

  componentDidUpdate() {
    if (_.size(this.props.messages) > 0) {
      this.props.updateDirectReadedDate(this.props.direct)
      setTimeout(this.updateScroll, 200)
    }
  }

  updateScroll = () => {
    const lastMessage = _.last(this.props.messages)
    const obj = document.getElementById('chat-box')

    if (obj && !_.isEqual(lastMessage, this.lastMessage)) {
      obj.scrollTop = obj.scrollHeight
      this.lastMessage = lastMessage
    }
  }

  onSendMessage = message => {
    const messageValue = _.get(message, 'value', '')

    if (messageValue) {
      const { userInfo, direct, postDirectMessage, theme, postThemeMessage } = this.props
      const isAdmin = theme?.isAdmin
      const isAuthor = directsUtils.checkUserIsAuthor(direct, userInfo)

      if (direct) {
        if (isAdmin || isAuthor) {
          const data = {
            ...message,
            broadcast: false,
            directs: [_.get(direct, 'id', null)],
            theme: null
          }
          postDirectMessage(data)
        }
      } else {
        if (isAdmin) {
          const data = {
            ...message,
            theme: _.get(theme, 'id', null),
            broadcast: true
          }
          postThemeMessage(data)
        }
      }
    }
  }

  onChangeBroadcastOnly = showBroadcastOnly => {
    this.setState({ showBroadcastOnly })
  }

  onSeeMoreTapped = (messageId, authorId) => {
    const { directsFetching, loadThemeMessageDirects } = this.props
    if (!directsFetching) {
      loadThemeMessageDirects(messageId, authorId)
    }
  }

  checkIsReadOnlyDirect = () => {
    const { theme, userInfo, direct } = this.props
    const isReadOnly = _.get(theme, 'readOnly', false)
    const isAdmin = theme?.isAdmin
    const isAuthor = directsUtils.checkUserIsAuthor(direct, userInfo)
    const isNotAuthorized = !isAdmin && !isAuthor
    const endDate = _.get(theme, 'endDate')
    const endDateExpired = _.isNil(endDate) ? false : moment(endDate).isBefore(moment(), 'day')

    const data = {
      readOnly: isNotAuthorized || isReadOnly || endDateExpired,
      readOnlyMessage: isNotAuthorized
        ? i18n.chatWindow.isNotAuthorizedDirect
        : isReadOnly
        ? i18n.chatWindow.isReadOnlyDirect
        : endDateExpired
        ? `${i18n.chatWindow.endDateExpired} ${moment(endDate).format('DD/MM/YYYY')}`
        : ''
    }
    return data
  }

  checkIsReadOnlyTheme = () => {
    const { theme } = this.props
    const isNotAdmin = !theme?.isClient
    const endDate = _.get(theme, 'endDate')
    const endDateExpired = _.isNil(endDate) ? false : moment(endDate).isBefore(moment(), 'day')
    const data = {
      readOnly: isNotAdmin || endDateExpired,
      readOnlyMessage: isNotAdmin
        ? i18n.chatWindow.isNotAdminTheme
        : endDateExpired
        ? `${i18n.chatWindow.endDateExpired} ${moment(endDate).format('DD/MM/YYYY')}`
        : ''
    }
    return data
  }

  renderViewMore = () => {
    const { showBroadcastOnly } = this.state
    const { pageInfo, fetchThemeMessagesList, fetchDirectMessagesList, messagesFetching, direct } = this.props

    if (!pageInfo || !pageInfo.hasPreviousPage || messagesFetching) {
      return null
    }

    return (
      <div className="load-more-wrapper">
        <span
          onClick={() => {
            direct ? fetchDirectMessagesList(false) : fetchThemeMessagesList(showBroadcastOnly)
          }}
        >
          {i18n.chatWindow.loadMore}
        </span>
      </div>
    )
  }

  renderChatInput = () => {
    const { direct, theme, handleOpenCreatePollSidebar } = this.props
    const readOnlyDataDirect = this.checkIsReadOnlyDirect()
    const readOnlyDataTheme = this.checkIsReadOnlyTheme()
    const blockMsg = direct ? readOnlyDataDirect.readOnlyMessage : readOnlyDataTheme.readOnlyMessage

    if (theme?.isAdmin || (direct && !readOnlyDataDirect.readOnly) || (theme && !direct && !readOnlyDataTheme.readOnly)) {
      return (
        <div className="bottom-write-chat-wrapper">
          <WriteChatBar
            placeholder={!direct && theme?.isAdmin ? i18n.chatWindow.allParticipantsPlaceholder : null}
            onSendMessage={this.onSendMessage}
            handleOpenCreatePollSidebar={handleOpenCreatePollSidebar}
          />
        </div>
      )
    } else {
      return (
        <div className="block-chat-wrapper">
          <p>{blockMsg}</p>
        </div>
      )
    }
  }

  getPoll = async (poll, userInfo) => {
    const { getPollByMessageId } = this.props
    try {
      await getPollByMessageId(poll, userInfo)
    } catch {
      toast(i18n.generalError, { type: toast.TYPE.ERROR })
    }
  }

  render() {
    const {
      userInfo,
      messages,
      messagesFetching,
      direct,
      themesFetching,
      theme,
      organization,
      onOpenPollInfo,
      handleOpenMembers,
      onOpenRepplyPoll
    } = this.props
    const themeName = _.get(theme, 'name')
    const isAdmin = theme?.isAdmin
    const isClient = organization?.isClient
    const readOnly = _.get(theme, 'readOnly', false)
    const formatMessages = _.map(messages, (item, key) => {
      const mediaObject = _.get(item, 'mediaObject', '')
      const text = _.get(item, 'text', '')
      const imageId = _.get(item, 'author.image._id', '')
      let date = _.get(item, 'created', '')
      date = date ? moment(date).format('DD/MM/YYYY HH:mm') : ''
      const authorId = _.get(item, 'author.id')
      const messageIdIri = _.get(item, 'id')
      const thisUserId = _.get(userInfo, 'id')
      const author = _.get(item, 'author.fullname')
      const seeMore = _.get(item, 'seeMore', false)
      const type = _.get(item, 'type', '')
      const isDeleted = !this.props.participants.find(participant => participant.id === item.author.id)

      if (authorId === thisUserId) {
        return (
          <AuthorMessage
            onOpenPollInfo={() => {
              this.getPoll(item, userInfo)
              onOpenPollInfo()
            }}
            type={type}
            key={key}
            message={text}
            time={date}
            mediaObject={mediaObject}
          />
        )
      } else {
        return (
          <UserMessage
            type={type}
            seeMore={seeMore && !direct && isAdmin && !isDeleted}
            onClickSeeMore={() => {
              this.onSeeMoreTapped(messageIdIri, authorId)
            }}
            onOpenPoll={() => {
              this.getPoll(item, userInfo)
              isAdmin || readOnly ? onOpenPollInfo() : onOpenRepplyPoll()
            }}
            name={!direct ? author : null}
            isDeleted={!isAdmin && isDeleted}
            altPollText={isAdmin || readOnly ? i18n.chatWindow.seePoll : ''}
            avatarResourceId={imageId}
            mediaObject={mediaObject}
            key={key}
            message={text}
            time={date}
          />
        )
      }
    })

    const isDirectAuthor = direct && direct?.author?._id === userInfo?._id
    return (
      <ChatWindowStyled>
        {(direct || theme) && (
          <>
            <div className="top-bar-chat-wrapper">
              <ChatBarName name={`#${themeName}`} label={!isDirectAuthor && direct?.author?.fullname} handleOpenMembers={handleOpenMembers} />
              {!direct && (isAdmin || isClient) && <FilterAnswersBar onChangeBroadcastOnly={this.onChangeBroadcastOnly} edit={theme.editable} />}
              {!direct && isAdmin && <InfoAnswersBar text={i18n.chatWindow.justAdmins} />}
            </div>

            <div className="chat-messages" id="chat-box">
              {this.renderViewMore()}

              {messagesFetching || themesFetching || messagesFetching ? (
                <div className="spinner-wrapper">
                  <Spinner className="spinner" />
                </div>
              ) : (
                formatMessages
              )}
            </div>
            <div>{this.renderChatInput()}</div>
          </>
        )}
      </ChatWindowStyled>
    )
  }
}

ChatWindow.propTypes = {
  participantsFetching: PropTypes.bool,
  handleOpenMembers: PropTypes.func,
  theme: PropTypes.object,
  postThemeMessage: PropTypes.func,
  onOpenPollInfo: PropTypes.func,
  onOpenRepplyPoll: PropTypes.func,
  userInfo: PropTypes.object,
  organization: PropTypes.object,
  messages: PropTypes.array,
  themesFetching: PropTypes.bool,
  messagesFetching: PropTypes.bool,
  postDirectMessage: PropTypes.func,
  direct: PropTypes.object,
  directsFetching: PropTypes.bool,
  fetchDirectMessagesList: PropTypes.func,
  pageInfo: PropTypes.object,
  fetchThemeMessagesList: PropTypes.func,
  loadThemeMessageDirects: PropTypes.func,
  handleOpenCreatePollSidebar: PropTypes.func,
  getPollByMessageId: PropTypes.func,
  updateDirectReadedDate: PropTypes.func,
  participants: PropTypes.array
}

export default ChatWindow
