import * as types from './types'

export const setFetching = value => {
  return {
    type: types.PROFITS_SET_FETCHING,
    value
  }
}

export const updateList = value => {
  return {
    type: types.PROFITS_UPDATE_LIST,
    value
  }
}
