import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import _get from 'lodash/get'
import { Formik } from 'formik'
import { postOrganizationPaymentMethod } from '../../../api'
import i18n from '../../../assets/i18n'
import { useStripe, useElements, CardElement } from '@stripe/react-stripe-js'
import View from './view'

const PaymentForm = ({ organizationId, handleOnSubmit, ...props }) => {
  const [isLoading, setIsLoading] = useState(false)
  const [messages, setMessages] = useState('')
  const [clientSecret, setClientSecret] = useState('')
  const stripe = useStripe()
  const elements = useElements()

  const getClientSecret = async () => {
    const getPaymentMethodUrlRes = await postOrganizationPaymentMethod(organizationId)
    const clientSecret = _get(getPaymentMethodUrlRes, 'data.client_secret')
    setClientSecret(clientSecret)
  }

  useEffect(() => {
    getClientSecret()
  }, [])

  const handleSubmit = async data => {
    const cardElement = elements.getElement(CardElement)
    //const card = elements.getElement(CardNumberElement)
    //const date = elements.getElement(CardExpiryElement)
    //const cvc = elements.getElement(CardCvcElement)

    setIsLoading(true)
    const { setupIntent, error } = await stripe.confirmCardSetup(clientSecret, {
      payment_method: {
        card: cardElement,
        billing_details: {
          name: data.name
        }
      }
    })

    setIsLoading(false)
    if (error) {
      setMessages(error?.message)
    } else {
      handleOnSubmit(setupIntent?.payment_method)
    }
  }
  return (
    <Formik
      initialValues={{
        name: ''
      }}
      validate={values => {
        let errors = {}
        if (!values.name) {
          errors.name = i18n.errors.isRequired
        }
        return errors
      }}
      onSubmit={handleSubmit}
    >
      {({ ...formikProps }) => <View {...props} {...formikProps} messages={messages} isLoading={isLoading} clientSecret={clientSecret} />}
    </Formik>
  )
}

PaymentForm.propTypes = {
  clientSecret: PropTypes.string,
  onSuccess: PropTypes.func,
  organizationId: PropTypes.number.isRequired
}

PaymentForm.defaultProps = {
  clientSecret: null,
  handleOnSubmit: () => null
}

export default PaymentForm
