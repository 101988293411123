export const STATS_SET_FETCHING = 'STATS_SET_FETCHING'
export const STATS_UPDATE_INFO = 'STATS_UPDATE_INFO'
export const STATS_UPDATE_THEMES = 'STATS_UPDATE_THEMES'
export const STATS_UPDATE_NUM_THEMES = 'STATS_UPDATE_NUM_THEMES'
export const STATS_UPDATE_NUM_USERS = 'STATS_UPDATE_NUM_USERS'
export const STATS_UPDATE_SELECTED_THEME = 'STATS_UPDATE_SELECTED_THEME'
export const STATS_UPDATE_SELECTED_THEME_INFO = 'STATS_UPDATE_SELECTED_THEME_INFO'
export const STATS_SET_THEME_ADMINS = 'STATS_SET_THEME_ADMINS'
export const STATS_UPDATE_THEME_INFO = 'STATS_UPDATE_THEME_INFO'
export const STATS_SET_SELECTED_THEME = 'STATS_SET_SELECTED_THEME'
export const STATS_SET_USER_USAGE_RECORDS = 'STATS_SET_USER_USAGE_RECORDS'
export const STATS_SET_NUM_USER_USAGE_RECORDS = 'STATS_SET_NUM_USER_USAGE_RECORDS'
export const STATS_SET_THEME_DATA = 'STATS_SET_THEME_DATA'
export const STATS_SET_GLOBAL_MESSAGE = 'STATS_SET_GLOBAL_MESSAGE'
export const STATS_SET_NUM_GLOBAL_MESSAGE = 'STATS_SET_NUM_GLOBAL_MESSAGE'
export const STATS_SET_ANSWERS_MESSAGE = 'STATS_SET_ANSWERS_MESSAGE'
export const STATS_SET_NUM_ANSWERS_MESSAGE = 'STATS_SET_NUM_ANSWERS_MESSAGE'
export const STATS_SET_FETCHING_THEME = 'STATS_SET_FETCHING_THEME'
export const STATS_SET_DATES = 'STATS_SET_DATES'
