import React, { Component } from 'react'
import CreateThemeStep3Styled from './styled'
import { Button, Avatar, SearchField } from '../../atoms'
import i18n from '../../../assets/i18n'
import { toast } from 'react-toastify'
import { getBlackListUsers } from '../../../utils'
import PropTypes from 'prop-types'
import { Row, Col } from 'react-bootstrap'
import _ from 'lodash'
import { ReactComponent as Spinner } from '../../../assets/images/yellow-spinner.svg'

class CreateThemeStep3 extends Component {
  constructor(props) {
    super(props)
    const imInBlackList = _.findIndex(props.participantsAdded, { id: props.userInfo?.id }) > -1

    this.state = {
      adminsToAdd: [],
      adminsAdded: imInBlackList ? [] : [props.userInfo]
    }
  }

  componentDidMount() {
    this.loadOrganizationUsers()
  }

  loadOrganizationUsers = async (searchText = '') => {
    const { adminsAdded } = this.state
    const adminsToAdd = await this.props.fetchOrganizationUsers(searchText)

    _.map(adminsAdded, admin => {
      if (_.find(adminsAdded, { id: admin.id })) {
        _.remove(adminsToAdd, { id: admin.id })
      }
    })

    this.setState({ adminsToAdd })
  }

  onSearch = _.debounce((e, searchText) => {
    this.loadOrganizationUsers(searchText)
  }, 1000)

  onCheckAll = () => {
    const { isFetchingUsers, participantsAdded } = this.props
    const { adminsToAdd, adminsAdded } = this.state

    if (!isFetchingUsers) {
      const { blackList } = getBlackListUsers(adminsToAdd, participantsAdded)

      if (blackList.length > 0) {
        toast(i18n.alreadyUsersParticipantMessage, { type: toast.TYPE.ERROR })
      } else {
        this.setState({ adminsAdded: _.concat(adminsToAdd, adminsAdded), adminsToAdd: [] })
      }
    }
  }

  onClickAdminToAdd = itemToAdd => {
    const { isFetchingUsers, participantsAdded } = this.props

    if (!isFetchingUsers) {
      const isInBlackList = _.findIndex(participantsAdded, { id: itemToAdd.id })

      if (isInBlackList > -1) {
        toast(i18n.alreadyParticipantMessage, { type: toast.TYPE.ERROR })
      } else {
        const { adminsToAdd } = this.state
        _.pull(adminsToAdd, itemToAdd)
        this.setState({ adminsToAdd })
        this.setState(previousState => ({
          adminsAdded: [...previousState.adminsAdded, itemToAdd]
        }))
      }
    }
  }

  onClickAdminRemove = itemToRemove => {
    const { isFetchingUsers } = this.props
    if (!isFetchingUsers) {
      const { adminsAdded } = this.state
      _.pull(adminsAdded, itemToRemove)
      this.setState({ adminsAdded })
      this.setState(previousState => ({
        adminsToAdd: [...previousState.adminsToAdd, itemToRemove]
      }))
    }
  }

  render() {
    const { adminsToAdd, adminsAdded } = this.state
    const { isFetchingUsers, handleOnSubmit } = this.props

    const adminsToAddedFormat = _.map(adminsToAdd, item => {
      const itemId = _.get(item, '_id', '')
      const directName = _.get(item, 'fullname', '')
      const directImage = _.get(item, 'image._id', '')
      const subInfo = _.get(item, 'phone', '')

      return (
        <div key={itemId} className="admin-list-wrapper" onClick={() => this.onClickAdminToAdd(item)}>
          <Avatar resourceId={directImage} name={directName} subtitle={subInfo} />
        </div>
      )
    })

    const adminsAddedFormat = _.map(adminsAdded, item => {
      const itemId = _.get(item, '_id', '')
      const directName = _.get(item, 'fullname', '')
      const directImage = _.get(item, 'image._id', '')
      const subInfo = _.get(item, 'phone', '')

      return (
        <div key={itemId} className="admin-list-wrapper" onClick={() => this.onClickAdminRemove(item)}>
          <Avatar resourceId={directImage} name={directName} subtitle={subInfo} />
        </div>
      )
    })

    return (
      <CreateThemeStep3Styled>
        <form
          onSubmit={e => {
            e.preventDefault()
            handleOnSubmit(adminsAdded)
          }}
        >
          <div className="search-field-wrapper">
            <SearchField placeholder={i18n.createThemeStep3.searchAdmins} handleOnChange={this.onSearch} />
          </div>
          <Row>
            <Col xs={12} sm={6} className="list-wrapper">
              <p className="title-list">{i18n.createThemeStep3.addAdmins}</p>
              {adminsToAddedFormat}
              {isFetchingUsers && (
                <div className="spinner-wrapper">
                  <Spinner className="spinner" />
                </div>
              )}
            </Col>
            <Col xs={12} sm={6} className="list-wrapper">
              <p className="title-list">{i18n.createThemeStep3.adminAdded}</p>
              {adminsAddedFormat}
            </Col>
          </Row>
          {_.size(adminsAddedFormat) > 0 && (
            <div className="submit-admins-wrapper">
              <Button type="submit" isFetching={this.props.isFetchingForm} variant="big" label={i18n.createThemeStep3.finish} />
            </div>
          )}

          <div className="footer-wrapper">
            <p className="back-wrapper">
              <span
                onClick={() => {
                  this.setState({ adminsToAdd: [], adminsAdded: [] })
                  this.props.onClickBack()
                }}
              >
                {i18n.createThemeStep3.back}
              </span>
            </p>

            {_.size(adminsToAddedFormat) > 0 && (
              <p className="check-all">
                <span onClick={this.onCheckAll}>{i18n.createThemeStep3.addAll}</span>
              </p>
            )}
          </div>
        </form>
      </CreateThemeStep3Styled>
    )
  }
}

CreateThemeStep3.propTypes = {
  onClickBack: PropTypes.func,
  handleOnSubmit: PropTypes.func,
  fetchOrganizationUsers: PropTypes.func,
  isFetchingUsers: PropTypes.bool,
  isFetchingForm: PropTypes.bool,
  userInfo: PropTypes.object,
  participantsAdded: PropTypes.array
}

export default CreateThemeStep3
