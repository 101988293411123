import { getCountries, getCountryCallingCode } from 'react-phone-number-input/input'
import i18n from './assets/i18n'
import _ from 'lodash'

export const UnorderedPrefixOptions = getCountries().map(country => ({
  value: getCountryCallingCode(country),
  label: `${i18n.countries[country]} (+${getCountryCallingCode(country)})`
}))

export const GetAllCountries = getCountries().map(country => ({
  value: country,
  callingCode: getCountryCallingCode(country),
  label: `${i18n.countries[country]}`
}))

export const prefixOptions = _.orderBy(UnorderedPrefixOptions, ['label', 'value'], ['asc', 'desc'])
export const countryOptions = _.orderBy(GetAllCountries, ['label', 'value'], ['asc', 'desc'])

export const formatMoney = amount => {
  return new Intl.NumberFormat('es-es', {
    style: 'currency',
    currency: 'EUR'
  }).format(amount / 100)
}

export const getCountryPhoneCodeByIso2 = iso2 => {
  return getCountryCallingCode(iso2)
}

export const getBlackListUsers = (usersToAdd, blackList) => {
  const formatBlackList = []
  _.map(usersToAdd, participant => {
    const isInBlackList = _.findIndex(blackList, { id: participant.id })
    if (isInBlackList > -1) {
      formatBlackList.push(participant)
    }
  })

  return { blackList: formatBlackList }
}

export const removeAccents = text => {
  const normalizeSearchText = text.normalize('NFD').replace(/[\u0300-\u036f]/g, '')
  return normalizeSearchText
}

export const getFileExtension = filePath => {
  const splitPath = filePath.split('.')
  const extension = splitPath[splitPath.length - 1]
  return extension
}

export const validateIDDoc = value => {
  const regExp = RegExp(/([a-z]|[A-Z]|[0-9])[0-9]{7}([a-z]|[A-Z]|[0-9])/)

  return !regExp.test(value)
}

export const validatePostalCode = value => {
  const regExp = RegExp(/^(?:0[1-9]\d{3}|[1-4]\d{4}|5[0-2]\d{3})$/)

  return !regExp.test(value)
}
