import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { getSignedUrl } from '../../../api'
import { ReactComponent as DownloadIcon } from '../../../assets/images/download_icon.svg'
import { DownloadFile } from './styled.js'
import { getFileExtension } from '../../../utils'
import _ from 'lodash'
import i18n from '../../../assets/i18n'

class FileInChat extends Component {
  constructor(props) {
    super(props)
    this._mounted = true
    this.state = { resourceUri: '#' }

    const mediaObjectId = _.get(props, 'mediaObject._id')

    if (mediaObjectId) {
      getSignedUrl(mediaObjectId)
        .then(({ data }) => {
          this._mounted && this.setState({ resourceUri: data }, () => {})
        })
        .catch(() => {})
    }
  }

  componentWillUnmount() {
    this._mounted = false
  }

  render() {
    const { resourceUri } = this.state
    const { isAudio } = this.props
    const fileName = _.get(this.props, 'mediaObject.fileName')
    const filePath = _.get(this.props, 'mediaObject.filePath')
    const fileExtension = getFileExtension(filePath)

    if (!resourceUri) return null

    return (
      <DownloadFile href={resourceUri} target="_blank" download>
        <DownloadIcon />
        {isAudio ? i18n.fileInChat.audioNote : `${fileName}.${fileExtension}`}
      </DownloadFile>
    )
  }
}

FileInChat.defaultProps = {
  showFullname: true,
  isAudio: false
}

FileInChat.propTypes = {
  mediaObject: PropTypes.object,
  resourceUri: PropTypes.string,
  showFullname: PropTypes.bool,
  variant: PropTypes.string,
  name: PropTypes.string,
  handleOnClick: PropTypes.func,
  subtitle: PropTypes.string,
  isAudio: PropTypes.bool
}

export default FileInChat
