import * as types from './types'
import { LOG_OUT } from '../auth/types'

const initialState = {
  fcmToken: null
}

const reducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case types.NOTIFICATIONS_SET_TOKEN:
      return {
        ...state,
        fcmToken: action.value
      }

    case LOG_OUT:
      return initialState

    default:
      return state
  }
}

export default reducer
