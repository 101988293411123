import countries from 'react-phone-number-input/locale/es.json'

export default {
  generarlSuccess: 'Ha finalizado el proceso con éxito',
  generalError: 'Ha ocurrido un error',
  alreadyUsersAdminMessage: 'Algunos participantes ya son administradores de este tema. Elimínelo antes como administrador.',
  alreadyAdminMessage: 'El participante que está intentando añadir ya es administrador de este tema. Elimínelo antes como administrador.',
  alreadyParticipantMessage: 'El administrador que está intentando añadir ya es participante de este tema. Elimínelo antes como participante.',
  alreadyUsersParticipantMessage: 'Algunos administradores ya son participantes de este tema. Elimínelo antes como participante.',
  pendingInvitationError: 'No se ha podido eliminar la invitación pendiente',

  //FORMS
  errors: {
    isRequired: 'Campo requerido',
    'app.errors.invalid_tax_id': 'NIF inválido'
  },

  //AUTH
  auth: {
    errorLogin: 'Datos incorrectos',
    noOrganization: 'No perteneces a ninguna organización'
  },

  messages: {
    noAdd: 'No se ha podido entregar el mensaje'
  },

  themes: {
    noCreate: 'No se ha podido crear el tema',
    deletedThemeBody: 'El tema {{theme}} ha sido eliminado',
    deletedUserBody: 'Has sido eliminado del tema: {{theme}}',
    added: 'Has sido añadido al tema: {{theme}}'
  },

  organizations: {
    invitation: 'Has sido añadido a la organización',
    deletedBody: 'Has sido eliminado de la organización: {{organization}}',
    subscriptionActiveBody: 'La suscripción de la organización {{organization}} está ahora activa',
    subscriptionExpiredBody: 'La suscripción de la organización {{organization}} ha caducado'
  },

  //Atoms
  popUpOrganizationBar: {
    manageTheme: 'Gestionar temas',
    manageUsers: 'Gestionar usuarios',
    spaces: 'Espacios',
    preferences: 'Preferencias',
    billing: 'Facturación',
    createOrganization: 'Crear organización',
    exit: 'Salir de la organización',
    admin: 'Administrador',
    organizationData: 'Datos de la organización',
    themes: 'Temas'
  },

  popUpWriteMsgAdd: {
    uploadFile: 'Subir archivo',
    createPoll: 'Crear encuesta'
  },

  popUpUser: {
    preferences: 'Preferencias',
    logOut: 'Cerrar Sesión',
    comercialZone: 'Ser Manager'
  },

  popUpTheme: {
    exit: 'Salir del tema',
    edit: 'Editar tema',
    delete: 'Borrar tema'
  },

  fileInChat: {
    audioNote: 'Nota de audio'
  },

  //Molecules
  createOrganizationCompleted: {
    success: 'Tu organización ha sido creada correctamente',
    createTheme: 'Crear un tema',
    goTo: 'Ir a',
    myBeenfo: 'mi BEENFO'
  },

  loginForm: {
    countryLabel: 'País',
    enterPhone: 'introduce tu número de teléfono',
    enterPass: 'Introduce la contraseña',
    forgot: 'He olvidado',
    forgotPass: 'la contraseña',
    enter: 'Entrar',
    requiredField: 'Campo requerido',
    minAge: 'Debe tener 18 años para registrarse'
  },

  organizationBar: {
    acceptExit: '¿Está seguro que desea salir de la organización?',
    pendingPayment: 'La organización esta pendiente de pago. Contacte con el administrador'
  },

  modalManageUsers: {
    back: 'Atrás',
    add: '+ Añadir'
  },

  addParticipantsForm: {
    countryLabel: 'País',
    enterPhone: 'Número de teléfono',
    finish: 'Terminar',
    requiredField: 'Campo requerido',
    addUser: 'Añadir usuarios',
    add: '+ Añadir',
    delete: 'Eliminar',
    usersAdded: 'Usuarios añadidos',
    addedError: 'El número introducido ya se encuentra en la lista'
  },

  organizationUsers: {
    orgUsers: 'Usuarios de la organización',
    remove: 'Eliminar',
    pendingInvitations: 'Invitaciones pendientes',
    searchParticipants: 'Buscar participantes',
    confirmCopy: '¿Borrar la siguiente invitación?',
    confirm: 'Confirmar',
    cancel: 'Cancel'
  },

  userMessage: {
    seeMore: 'Leer más',
    answerPoll: 'Responder encuesta',
    isDeleted: 'Usuario eliminado'
  },

  authorMessage: {
    seePoll: 'Ver encuesta'
  },

  comercialRegisterForm: {
    dniLabel: 'DNI',
    dniPlaceholder: '0000000Z',
    addressLabel: 'Calle, número',
    addressPlaceholder: 'Nombre calle, 0',
    countryLabel: 'País',
    provinceLabel: 'Provincia',
    cityLabel: 'Población',
    accountLabel: 'Número de cuenta',
    accountPlaceholder: 'ES1111 0000 0000 0000 0000',
    selectOption: 'Selecciona una opción',
    save: 'Guardar',
    requiredField: 'Campo requerido',
    invalidIban: 'Invalid IBAN Account'
  },

  comercialBillingItem: {
    registerBillingdata: 'Registra tus datos de facturación'
  },

  resetPassPhone: {
    title: 'Cambiar contraseña',
    countryLabel: 'País',
    enterPhone: 'introduce tu número de teléfono',
    enterCode: 'introduce el código',
    enterCodePlaceholder: '0000',
    send: 'Enviar',
    requiredField: 'Campo obligatorio',
    phoneNotRegistered: 'Teléfono no registrado',
    error: 'Ha ocurrido un error'
  },

  registerFormStep1: {
    title: 'Para empezar, regístrate',
    countryLabel: 'País',
    enterPhone: 'introduce tu número de teléfono',
    enterCode: 'introduce el código',
    enterCodePlaceholder: '0000',
    send: 'Confirmar móvil',
    requiredField: 'Campo obligatorio',
    phoneRegistered: 'Teléfono ya registrado',
    error: 'Ha ocurrido un error'
  },

  codeForm: {
    enterCode: 'introduce el código',
    enterCodePlaceholder: '0000',
    continue: 'Continuar',
    requiredField: 'Campo obligatorio'
  },

  registerPassword: {
    title: 'Introduce una contraseña',
    newPassLabel: 'Nueva contraseña',
    newPassPlaceholder: 'introduce la contraseña',
    repeatPassLabel: 'Repite la contraseña',
    repeatPassPlacaholder: 'Escribe de nuevo la contraseña',
    continue: 'Continuar',
    accept: 'Acepto los',
    accept2: 'Acepto la',
    terms: 'Términos y condiciones',
    requiredField: 'Campo requerido',
    wrongPass: 'Las contraseñas no coinciden',
    privacyCopy:
      'El responsable del tratamiento de sus datos es COCOMU INTERNET SLU y las finalidades de dicho tratamiento serán facilitar el uso de la aplicación y gestionar la relación comercial con los clientes y usuarios de la misma. La base legal es la ejecución de los Términos y Condiciones de la aplicación y de los Términos y Condiciones de Clientes. Sus datos podrán ser accedidos por nuestros prestadores de servicios de IT. Puede ejercitar sus derechos de acceso, rectificación y supresión, entre otros, poniéndose en contacto con nosotros a través del canal detallado en nuestra',
    privacyPolicy: 'Política de privacidad'
  },

  resetNewPassForm: {
    title: 'Introduce una contraseña',
    newPassLabel: 'Nueva contraseña',
    newPassPlaceholder: 'introduce la contraseña',
    repeatPassLabel: 'Repite la contraseña',
    repeatPassPlacaholder: 'Escribe de nuevo la contraseña',
    continue: 'Continuar',
    requiredField: 'Campo requerido',
    wrongPass: 'Las contraseñas no coinciden'
  },

  registerFormStep3: {
    addPhoto: '+ Añadir foto',
    nameLabel: 'Nombre',
    namePlaceholder: 'Nombre',
    surnameLabel: 'Apellidos',
    surnamePlaceholder: 'Apellidos',
    postalCodeLabel: 'Código postal',
    postalCodePlaceholder: 'Escribe tu código postal',
    birthDate: 'Fecha de nacimiento',
    continue: 'Continuar'
  },

  registerCompleted: {
    goToHome: 'Ir a inicio'
  },

  writeChatBar: {
    writeYourMessage: 'Escribe tu mensaje',
    send: 'Enviar',
    cancel: 'Cancelar'
  },

  filterAnswersBar: {
    seeOnlyAnswers: 'Ver sólo mis mensajes',
    editTheme: 'Editar tema'
  },

  updateUserForm: {
    myData: 'Mis datos',
    changePhoto: 'Cambiar foto',
    nameLabel: 'Nombre',
    namePlaceholder: 'Introduce tu nombre',
    surnameLabel: 'Apellidos',
    surnamePlaceholder: 'Introduce tus apellidos',
    countryLabel: 'País',
    countryPlaceholder: 'Selecciona una opción',
    phoneLabel: 'Introduce tu número de teléfono',
    phonePlaceholder: '000 000 000',
    postalCodeLabel: 'Código postal (OPCIONAL)',
    postalCodePlaceholder: 'Escribe tu código postal',
    genreLabel: 'Género',
    genrePlaceholder: 'Selecciona una opción',
    addressLabel: 'Dirección',
    addressPlaceholder: 'Calle y número',
    save: 'Guardar',
    birthDate: 'Fecha de nacimiento',
    updateError: 'Error al actualizar los datos',
    deleteUser: 'Darme de baja',
    confirmDelete: 'Su cuenta será eliminada y no podrá volver a acceder a ella. ¿Desea continuar?'
  },

  updateOrganizationForm: {
    myOrg: 'Mi organización',
    changePhoto: 'Cambiar foto',
    nameLabel: 'Nombre',
    namePlaceholder: 'Introduce el nombre',
    save: 'Guardar',
    updateError: 'Error al actualizar los datos'
  },

  directChatList: {
    title: 'Miembros',
    seeMore: 'Ver más'
  },

  editThemeInfo: {
    newTheme: 'Nuevo tema',
    themeName: 'Nombre del tema',
    themeNamePlaceholder: 'Escribe el nombre del tema',
    themeDesc: 'Descripción',
    themeDescPlaceholder: 'Añade una descripción',
    answers: 'Respuestas',
    answersValue: 'Este grupo no permite respuestas',
    temporalLimit: 'Límite temporal',
    temporalLimitValue: 'Añadir una fecha de fin al grupo',
    save: 'Guardar',
    requiredField: 'Campo requerido'
  },

  editThemeParticipants: {
    searchParticipants: 'Buscar participantes',
    addParticipants: 'Añadir participantes al tema',
    participantAdded: 'participantes añadidos',
    addAll: 'Añadir todos',
    save: 'Guardar'
  },

  editThemeAdmins: {
    searchAdmins: 'Buscar administradores',
    addAdmins: 'Añadir administradores al tema',
    adminAdded: 'administradores añadidos',
    addAll: 'Añadir todos',
    save: 'Guardar'
  },

  createThemeStep1: {
    newTheme: 'Nuevo tema',
    themeName: 'Nombre del tema',
    themeNamePlaceholder: 'Escribe el nombre del tema',
    themeDesc: 'Descripción',
    themeDescPlaceholder: 'Añade una descripción',
    answers: 'Respuestas',
    answersValue: 'Este grupo no permite respuestas',
    temporalLimit: 'Límite temporal',
    temporalLimitValue: 'Añadir una fecha de fin al grupo',
    next: 'Siguiente',
    requiredField: 'Campo requerido'
  },

  createThemeStep2: {
    searchParticipants: 'Buscar participantes',
    addParticipants: 'Añadir participantes al tema',
    participantAdded: 'participantes añadidos',
    addAll: 'Añadir todos',
    next: 'Siguiente'
  },

  createThemeStep3: {
    searchAdmins: 'Buscar administradores',
    addAdmins: 'Añadir administradores al tema',
    adminAdded: 'administradores añadidos',
    addAll: 'Añadir todos',
    finish: 'Terminar',
    back: 'Atrás'
  },

  createThemeStep4: {
    title1: 'Tu tema se ha creado correctamente',
    title2: 'Puedes añadir más miembros o modificarlos desde los ajustes',
    goProfile: 'Ir a mi perfil',
    addOther: 'Añadir otro',
    theme: 'tema',
    finish: 'Terminar'
  },

  updateOrganizationUsers: {
    searchUsers: 'Buscar usuarios',
    addUsers: 'Añadir usuarios a la organización',
    usersAdded: 'usuarios añadidos',
    addAll: 'Añadir todos',
    update: 'Actualizar',
    countryLabel: 'País',
    enterPhone: 'Número de teléfono',
    finish: 'Terminar',
    requiredField: 'Campo requerido',
    addUser: 'Añadir usuarios',
    add: '+ Añadir',
    delete: 'Eliminar',
    addedError: 'El número introducido ya se encuentra en la lista',
    save: 'Guardar'
  },

  userInfo: {
    name: 'Nombre',
    postalCode: 'Código postal',
    birthDate: 'Fecha de nacimiento',
    phone: 'Teléfono'
  },

  paymentInfo: {
    price: 'Precio',
    paymentPrice: '0,49€/mes',
    eachUser: 'Por cada usuario añadido',
    next: 'Siguiente',
    taxPrice: 'Precio +IVA = 0,59'
  },

  pollAnswersList: {
    alreadyAnswered: 'Ya has respondido esta encuesta',
    sendAnswer: 'Enviar respuesta'
  },

  liquidationList: {
    seeAll: 'Ver todos',
    title: 'Liquidaciones',
    liquidationFrom: 'Liquidación de ',
    seeLess: 'Ver menos',
    noItems: 'Aún no tienes liquidaciones'
  },

  customerList: {
    seeAll: 'Ver todos',
    seeLess: 'Ver menos',
    members: 'Miembros',
    noItems: 'Aún no tienes clientes'
  },

  customerInfo: {
    info: 'Información',
    members: 'miembros',
    daysRest: 'Quedan {0} días restantes de cliente comercial'
  },

  liquidationInfo: {
    info: 'Información',
    members: 'miembros',
    daysRest: 'Quedan {0} días restantes de cliente comercial',
    new: 'Pendiente de Pago',
    completed: 'Pagado',
    failed: 'Ha ocurrido un Error'
  },

  createOrganizationForm: {
    requiredField: 'Campo requerido',
    wrongFormat: 'Formato incorrecto',
    accept: 'Acepto los',
    terms: 'Términos y condiciones',
    addPhoto: 'Añadir foto',
    continue: 'Continuar',
    orgName: 'Introduce el nombre de la organización',
    orgNamePlaceholder: 'Nombre',
    businessName: 'Introduce la razón social',
    businessNamePlaceholder: 'Razón social',
    document: 'Introduce el NIF',
    documentPlaceholder: 'NIF',
    address: 'Introduce la dirección',
    addressPlaceholder: 'Dirección',
    postalCode: 'Introduce el código postal',
    postalCodePlaceholder: 'Código postal',
    province: 'Introduce la provincia',
    provincePlaceholder: 'Provincia',
    city: 'Introduce el municipio',
    cityPlaceholder: 'Municipio'
  },

  //Organism
  header: {
    getOn: 'Entra en Beenfo',
    signUp: 'Regístrate',
    goDashboard: 'Ir al dashboard'
  },

  sideBarPollInfo: {
    stats: 'Estadísticas',
    votes: 'Votos',
    answers: 'Respuestas',
    percent: 'Porcentaje de votos',
    votesNumber: 'Número de votos'
  },

  editThemeForm: {
    themeInfo: 'Información del tema',
    participants: 'Participantes',
    admins: 'Administradores'
  },

  sideBarRepplyPoll: {
    poll: 'Encuesta'
  },

  footer: {
    privacyPolicy: 'Políticas de privacidad',
    spanish: 'Español',
    english: 'Inglés'
  },

  advantages: {
    desc1: 'Habla con tu emisor, plantea una preocupación, haz una sugerencia. Tú preguntas, él responde. Él informa, tú estás informado.',
    desc2: 'Todo queda entre tu empresa, comunidad de vecinos o colectivo, y tú. Tus compañeros ven sus publicaciones, pero no tus respuestas.',
    quote: 'El valor de la comunicación es la información'
  },

  bannerHome: {
    title: 'Beenfo',
    desc:
      'Información directa y actualizada en una sola app: sobre tu empresa, tu comunidad de vecinos y tus colectivos de interés, todo sencillo, práctico y en muy pocos clics',
    signUp: 'Regístrate',
    errorVideoTag: 'Tu navegador no soporta este tipo de vídeo'
  },

  userLogin: {
    noAccount: '¿No tienes cuenta?',
    forgotPass: '¿Has olvidado la contraseña?',
    signUp: 'Regístrate'
  },

  comercialUserRegister: {
    haveAccount: '¿Ya tienes cuenta?',
    access: 'Accede'
  },

  userRegister: {
    success: 'Te has registrado correctamente',
    haveAccount: '¿Ya tienes cuenta?',
    access: 'Accede'
  },

  userResetPass: {
    success: 'Contraseña cambiada correctamente',
    haveAccount: '¿Ya tienes cuenta?',
    access: 'Accede',
    errorCode: 'Código incorrecto',
    tooManyAttempts: 'Demasiados intentos. Inténtelo de nuevo más tarde'
  },

  themeList: {
    title: 'Temas',
    exitConfirm: '¿Estás seguro que deseas salir del tema?',
    deleteConfirm: '¿Estás seguro que deseas borrar el tema?'
  },

  mainSideBar: {
    directChatList: 'Directos',
    invitationsTitle: 'Invitaciones pendientes',
    terms: 'Términos y condiciones',
    privacyPolicy: 'Política de privacidad',
    legal: 'Aviso legal'
  },

  noReadMsgList: {
    title: 'Mensajes sin leer'
  },

  searchChats: {
    title: 'Chats',
    search: 'Buscar',
    newTheme: 'Nuevo tema'
  },

  sideBarNewChat: {
    startNewChat: 'Iniciar nuevo chat',
    createTheme: 'Crear nuevo tema',
    startNewDirect: 'Iniciar nuevo directo'
  },

  chatWindow: {
    loadMore: 'Cargar más',
    isNotAuthorizedDirect: 'No estás autorizado a escribir en este directo',
    isReadOnlyDirect: 'El tema es de solo lectura',
    endDateExpired: 'El tema expiró el',
    isNotAdminTheme: 'No eres admin de este tema',
    seePoll: 'Ver encuesta',
    justAdmins: 'Sólo los admin podéis ver los mensajes de los participantes',
    allParticipantsPlaceholder: 'Los mensajes que envíes desde aquí llegarán a todos los participantes'
  },

  sideBarMembers: {
    members: 'Miembros',
    newMember: 'Nuevo miembro',
    edit: 'Editar tema',
    back: 'Atrás'
  },

  sideBarBilling: {
    billing: 'Facturación',
    stats: 'Estadísticas',
    users: 'Usuarios',
    your: 'Por tus',
    subscription: 'Subscripción',
    changePaymentMethod: 'Cambiar método de pago',
    deleteOrganization: 'Cancelar mi suscripción',
    deletedOrganization: 'Suscripción cancelada',
    next_billing: 'Próximas facturas',
    payments: 'Facturas',
    status: 'Estado',
    startDate: 'Fecha de inicio',
    endDate: 'Fecha de fin',
    paymentMethod: 'Método de pago',
    confirmDelete:
      '¿Estás seguro que quieres cancelar tu suscripción? Podrás seguir accediendo a Beenfo hasta el término del periodo ya abonado ({{currentPeriodEnd}})',
    businessName: 'Razón social',
    document: 'NIF',
    address: 'Dirección',
    postalCode: 'Código postal',
    province: 'Provincia',
    city: 'Municipio',
    billingData: 'Datos de facturación'
  },

  sideBarComercial: {
    title: 'Ser Manager',
    benefits: 'Beneficios',
    favLink: 'Enlace Referido',
    customers: 'Clientes',
    lastLiquidations: 'Últimas liquidaciones',
    earnedIncome: 'Tus ingresos obtenidos',
    sendLink: 'Enviar enlace de comercial',
    earnedByCustomers: 'Ingresos por cliente',
    customer: 'Cliente',
    billing: 'Facturación',
    liquidation: 'Liquidación',
    linkCopied: 'Enlace pegado al portapapeles'
  },

  poll: {
    questionLabel: 'Pregunta',
    questionPlaceholder: 'Introduzca una pregunta',
    multipleLabel: 'Permitir respuesta múltiple',
    singleLabel: 'Una respuesta posible',
    answerLabel: 'Respuesta',
    answerPlaceholder: 'Introduzca respuesta',
    answerAddLabel: 'Añadir respuesta',
    answersTitle: 'Respuestas',
    submitLabel: 'Crear encuesta',
    typeOfSelection: 'Tipo de selección de opciones',
    selectAnOption: 'Selecciona una opción',
    newAnswer: 'Nueva respuesta',
    edit: 'Editar',
    save: 'Guardar',
    newQuestion: 'Nueva pregunta',
    nameOfPoll: 'Nombre de la encuesta',
    typeHereNamePoll: 'Escribe aquí el nombre de tu encuesta',
    ok: 'Listo'
  },

  //Page
  dashboard: {
    paymentError: 'Ha ocurrido un error con el pago.',
    paymentMethodUpdated: 'El método de pago se ha actualizado correctamente'
  },
  pageNotFound: {
    error: 'No hemos encontrado la página que estaba buscando',
    backToHome: 'Volver al inicio'
  },

  //Modals
  modalAccept: {
    accept: 'Aceptar',
    back: 'Atrás'
  },

  // Generic
  countries,

  // PAYMENT METHOD FORM
  paymentMethodForm: {
    submit: 'Continuar',
    success: 'Se ha creado la organización con éxito'
  },

  //STATS DASHBOARD
  statsDashboard: {
    title: 'Dashboard',
    viewDataOf: 'Ver datos de',
    showData: 'Ver datos',
    showCharts: 'Ver gráficos',
    numThemes: 'Nº de temas',
    numUsers: 'Nº de usuarios',
    numUsersTotal: 'Nº de usuarios totales',
    day: 'Día',
    numGlobalMessages: 'Nº de comunicados globales',
    numAnswersMessages: 'Nº de respuestas a mensajes',
    themeSelectorLabel: 'Mostrar datos del tema',
    themeAdminsLabel: 'Administrador del tema',
    percentAnswers: '% de respuestas por comunicado',
    print: 'imprimir',
    goToTheme: 'Ir al tema'
  },

  modalOrganizationInProgress: {
    description: 'El proceso de suscripción está en progreso. Vuelve a intentarlo más tarde.',
    updateSuccess: '{{organization}} se ha actualizado correctamente'
  }
}
