import React, { Component } from 'react'
import ComponentStyled from './styled'
import { Button } from '../../atoms'
import _ from 'lodash'
import PropTypes from 'prop-types'
import i18n from '../../../assets/i18n'
import { toast } from 'react-toastify'

class PollAnswersList extends Component {
  constructor(props) {
    super(props)
    this.state = {
      selectedAnswers: []
    }
  }

  onSubmit = async () => {
    const { selectedAnswers } = this.state
    const { poll, onSubmit, toggleSidebar } = this.props
    if (!_.size(selectedAnswers)) {
      return
    }

    const data = {
      pollAnswers: selectedAnswers,
      pollId: _.get(poll, '_id')
    }

    try {
      await onSubmit(data)
      toggleSidebar()
    } catch {
      toast(i18n.generalError, { type: toast.TYPE.ERROR })
    }
  }

  onAnswerTapped = answer => {
    const { poll } = this.props
    const { selectedAnswers } = this.state

    if (poll.isMultiple) {
      if (_.includes(selectedAnswers, answer._id)) {
        const newSelectedAnswers = [...selectedAnswers].filter(v => v !== answer._id)
        this.setState({ selectedAnswers: newSelectedAnswers })
      } else {
        this.setState({ selectedAnswers: [...selectedAnswers, answer._id] })
      }
    } else {
      this.setState({ selectedAnswers: [answer._id] })
    }
  }

  render() {
    const { poll, isFetchingVote } = this.props
    const { selectedAnswers } = this.state
    const answers = _.map(_.get(poll, 'answers.edges', []), v => v.node)
    const userAnswers = _.reduce(
      answers,
      (r, v) => {
        if (_.size(v.users.edges)) {
          r.push(v.id)
        }
        return r
      },
      []
    )

    if (_.size(answers) === 0) return null

    const newList = _.map(answers, (answer, key) => {
      const { selectedAnswers } = this.state
      const selected = _.includes(selectedAnswers, answer._id) || _.includes(userAnswers, answer.id)
      const selectedStyle = selected ? 'active' : ''
      const label = _.get(answer, 'textAnswer', '')
      const readOnly = _.size(this.userAnswers) ? true : false
      const onClick = readOnly ? null : () => this.onAnswerTapped(answer)

      return (
        <div key={key} onClick={onClick} className={`poll-answer-wrapper ${selectedStyle}`}>
          <p>{label}</p>
        </div>
      )
    })

    const readOnly = _.size(userAnswers)
    const disabled = !selectedAnswers.length || readOnly

    return (
      <ComponentStyled>
        {newList}
        {readOnly ? <p className="is-voted">{i18n.pollAnswersList.alreadyAnswered}</p> : null}
        {!disabled ? (
          <div className="btn-wrapper">
            <Button isFetching={isFetchingVote} handleOnClick={this.onSubmit} label={i18n.pollAnswersList.sendAnswer} />
          </div>
        ) : null}
      </ComponentStyled>
    )
  }
}

PollAnswersList.propTypes = {
  poll: PropTypes.object,
  onSubmit: PropTypes.func,
  isFetchingVote: PropTypes.bool
}

export default PollAnswersList
