import React, { Component } from 'react'
import { Row, Col } from 'react-bootstrap'
import ComponentStyled from './styled'
import { RegisterFormStep1, CodeForm, RegisterPassword, RegisterFormStep3, StepCompleted, Steps } from '../../molecules'
import { CircleHeader } from '../../atoms'
import i18n from '../../../assets/i18n'
import { withRouter } from 'react-router'
import PropTypes from 'prop-types'
import moment from 'moment'
import { withFirebase } from '../../Firebase'
import { toast } from 'react-toastify'
import { TOO_MANY_ATTEMTPS_ERROR } from '../../../config/constants'
import _ from 'lodash'
import firebase from 'firebase/app'

const STEPS = {
  PHONE: 1,
  CODE: 2,
  PASSWORD: 3,
  REGISTER: 4,
  COMPLETED: 5
}

class UserRegister extends Component {
  state = {
    step: STEPS.PHONE,
    user: null,
    password: '',
    terms: false,
    privacy: false,
    phone: null,
    code: null,
    country: null,
    registerFormData: null,
    isFetching: false
  }

  onSubmitStep1 = (phone, country, terms) => {
    const recaptchaVerifier = new firebase.auth.RecaptchaVerifier('sign-in-button', {
      size: 'invisible'
    })

    this.setState({ isFetching: true, phone, country, terms }, async () => {
      try {
        const confirmResult = await this.props.firebase.signInWithPhoneNumber(phone, recaptchaVerifier)
        this.setState({ step: STEPS.CODE, confirmResult })
      } catch (e) {
        const message = _.get(e, 'code', i18n.generalError)

        if (message.includes(TOO_MANY_ATTEMTPS_ERROR)) {
          toast(i18n.userResetPass.tooManyAttempts, { type: toast.TYPE.ERROR })
        } else {
          toast(i18n.generalError, { type: toast.TYPE.ERROR })
        }
      } finally {
        this.setState({ isFetching: false })
      }
    })
  }

  onSubmitStep1Code = ({ code }) => {
    const { confirmResult } = this.state
    if (confirmResult && code) {
      this.setState({ isFetching: true, code }, async () => {
        try {
          const { user } = await confirmResult.confirm(code.toString())
          this.setState({ step: STEPS.PASSWORD, user })
        } catch (e) {
          toast(i18n.generalError, { type: toast.TYPE.ERROR })
        } finally {
          this.setState({ isFetching: false })
        }
      })
    }
  }

  onSubmitStep2 = async ({ password }) => {
    this.setState({ step: STEPS.REGISTER, password })
  }

  onSubmitStep3 = async registerFormData => {
    const { user } = this.state
    const tokenFirebase = await user.getIdToken(true)

    this.setState({ isFetching: true, registerFormData }, async () => {
      const { register } = this.props
      const { image, name, surname, cp, birhtdate } = registerFormData
      const { phone, password, country } = this.state
      const referrerCommercialId = localStorage.getItem('commercialId')
      const registerData = {
        referrerCommercialId,
        tokenFirebase,
        image,
        country: country,
        phoneNumber: phone,
        plainPassword: password,
        name,
        surname,
        zipCode: cp.toString(),
        birthDate: moment(birhtdate, 'DD/MM/YYYY').toDate()
      }
      const registerSuccess = await register(registerData)
      if (registerSuccess) {
        this.setState({ step: STEPS.COMPLETED })
      }
      this.setState({ isFetching: false })
    })
  }

  renderStep = () => {
    const { step, isFetching } = this.state

    switch (step) {
      case STEPS.PHONE:
        return <RegisterFormStep1 isFetching={isFetching} onSubmit={this.onSubmitStep1} />
      case STEPS.CODE:
        return <CodeForm isFetching={isFetching} onSubmit={this.onSubmitStep1Code} />
      case STEPS.PASSWORD:
        return <RegisterPassword isFetching={isFetching} onSubmit={this.onSubmitStep2} />
      case STEPS.REGISTER:
        return (
          <div className="step3-wrapper">
            <RegisterFormStep3 isFetching={isFetching} onSubmit={this.onSubmitStep3} />
          </div>
        )
      case STEPS.COMPLETED:
        return <StepCompleted title={i18n.userRegister.success} onFinish={this.props.onFinish} />
      default:
        break
    }
  }

  render() {
    const { step } = this.state
    const { onLoginTapped } = this.props
    return (
      <ComponentStyled>
        <div className="login-wrapper">
          <div className="circle-header">
            <CircleHeader />
            {step !== STEPS.COMPLETED && (
              <div className="steps-header text-center">
                <Steps activeStep1={step === 1 && true} activeStep2={step === 2 && true} activeStep3={step === 4 && true} />
              </div>
            )}
          </div>
          <Row>
            <Col xs={12} sm={{ span: 8, offset: 2 }}>
              <div className="steps-wrapper">{this.renderStep()}</div>
            </Col>
          </Row>
          {step === STEPS.PHONE && (
            <Row>
              <Col xs={12} sm={{ span: 8, offset: 2 }} className="text-center">
                <p className="have-account">
                  {i18n.userRegister.haveAccount}{' '}
                  <span className="pointer" onClick={onLoginTapped}>
                    {i18n.userRegister.access}
                  </span>
                </p>
              </Col>
            </Row>
          )}
        </div>
      </ComponentStyled>
    )
  }
}

UserRegister.propTypes = {
  firebase: PropTypes.object,
  signInWithPhoneNumber: PropTypes.func,
  history: PropTypes.object,
  onFinish: PropTypes.func,
  onLoginTapped: PropTypes.func
}

export default withFirebase(withRouter(UserRegister))
