import React, { Component } from 'react'
import ComponentStyled from './styled'
import { RegularInput, Button } from '../../atoms'
import i18n from '../../../assets/i18n'
import PropTypes from 'prop-types'
import { Formik } from 'formik'

class CodeForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      code: ''
    }
  }
  render() {
    const { onSubmit, showCodeInput, isFetching } = this.props

    return (
      <ComponentStyled>
        <Formik
          initialValues={{
            code: ''
          }}
          validate={values => {
            let errors = {}
            if (showCodeInput && !values.code) {
              errors.code = i18n.codeForm.requiredField
            }
            return errors
          }}
          onSubmit={data => {
            onSubmit(data)
          }}
        >
          {({ values, errors, touched, handleChange, handleBlur, handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <RegularInput
                label={i18n.codeForm.enterCode}
                placeholder={i18n.codeForm.enterCodePlaceholder}
                handleOnChange={handleChange}
                handleOnBlur={handleBlur}
                name="code"
                value={values.code}
                hasError={errors.code && touched.code && true}
              />
              {errors.code && touched.code && <p className="error-msg-input">{errors.code}</p>}
              <Button isFetching={isFetching} variant="big" label={i18n.codeForm.continue} />{' '}
            </form>
          )}
        </Formik>
      </ComponentStyled>
    )
  }
}

CodeForm.propTypes = {
  onSubmit: PropTypes.func,
  showCodeInput: PropTypes.bool,
  isFetching: PropTypes.bool
}

export default CodeForm
