import * as types from './types'
import { LOG_OUT } from '../auth/types'

const initialState = {
  list: [],
  total: 0,
  pageInfo: null,
  item: null,
  isFetching: false
}

const reducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case types.UPDATE_ITEM:
      return {
        ...state,
        item: action.value
      }

    case types.UPDATE_LIST:
      return {
        ...state,
        list: action.list,
        total: action.total,
        pageInfo: action.pageInfo
      }

    case types.SET_FETCHING:
      return {
        ...state,
        isFetching: action.value
      }

    case LOG_OUT:
      return initialState

    default:
      return state
  }
}

export default reducer
