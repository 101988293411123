import styled from 'styled-components'

const ComponentStyled = styled.div`
  && {
    .error-msg-input {
      margin-top: -25px;
    }
  }
`

export default ComponentStyled
