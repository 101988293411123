import _ from 'lodash'
import { authOperations, authUtils } from '../auth'

export const setError = error => (dispatch, getState, { translate, toast }) => {
  // eslint-disable-next-line no-console
  process.env.NODE_ENV === 'development' && console.log('Error: ', error)
  process.env.NODE_ENV === 'development' && console.log('Error response: ', error.response)
  const hydraError = _.get(error, 'response.data.hydra:description')
  process.env.NODE_ENV === 'development' && console.log('Error hydraError: ', hydraError)
  if (authUtils.isUnauthorizedError(error)) {
    dispatch(authOperations.logout())
  } else {
    //const errorLabel = hydraError ? hydraError : 'Ha ocurrido un error'
    //toast(errorLabel, { type: toast.TYPE.ERROR })
  }
}
