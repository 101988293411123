import React from 'react'
import DirectChatListStyled from './styled'
import { Avatar } from '../../atoms'
import _ from 'lodash'
import PropTypes from 'prop-types'
import i18n from '../../../assets/i18n'

const DirectChatList = ({ onClickDirectItem, directsFetching, directsPageInfo, fetchDirects, directs, showArrow, title }) => {
  if (_.size(directs) === 0) return null

  function renderSectionFooter() {
    if (directsPageInfo?.hasNextPage) {
      return (
        <p className="see-more">
          <span onClick={!directsFetching ? fetchDirects : () => {}}>{i18n.directChatList.seeMore}</span>
        </p>
      )
    }

    return null
  }

  const newList = _.map(directs, item => {
    const itemId = _.get(item, '_id', '')
    const directName = _.get(item, 'author.fullname', '')
    const directImage = _.get(item, 'author.image._id', '')
    let themeName = _.get(item, 'theme.name', '')
    themeName = themeName ? `#${themeName}` : ''

    return (
      <div key={itemId} className="direct-user-wrapper" onClick={() => onClickDirectItem(item, 'directs')}>
        <div>
          <Avatar resourceId={directImage} name={directName} subtitle={themeName} />
        </div>
        {showArrow && <img src={require('../../../assets/images/yellow-direct-arrow.png')} alt="Arrow" />}
      </div>
    )
  })

  return (
    <DirectChatListStyled>
      <p className="direct-title-list">{title}</p>
      {newList}
      {renderSectionFooter()}
    </DirectChatListStyled>
  )
}

DirectChatList.defaultProps = {
  showArrow: false
}

DirectChatList.propTypes = {
  onClickDirectItem: PropTypes.func,
  showArrow: PropTypes.bool,
  title: PropTypes.string,
  directs: PropTypes.array,
  fetchDirects: PropTypes.func,
  directsFetching: PropTypes.bool,
  directsPageInfo: PropTypes.object
}

export default DirectChatList
