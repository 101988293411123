import styled, { css } from 'styled-components'
import colors from '../../../assets/colors'

const ComponentStyled = styled.div`
  position: relative;

  .title {
    font-size: 28px;
    line-height: 28px;
    color: ${colors.yellow};
    margin-bottom: 5px;
  }

  .desc {
    font-size: 16px;
    line-height: 16px;
    margin-bottom: 10px;
  }

  .more-info {
    font-size: 14px;
    line-height: 16px;
    color: ${colors.grey2};
  }

  .arrow {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 0;
  }

  ${props =>
    props.hasClickEvent === true &&
    css`
      cursor: pointer;
    `};
`

export default ComponentStyled
