import React from 'react'
import ImageTextStyled from './styled'
import { ImageBg } from '../../atoms'
import PropTypes from 'prop-types'

const ImageText = ({ imageUrl, desc }) => {
  return (
    <ImageTextStyled>
      <ImageBg imageUrl={imageUrl} />
      <p>{desc}</p>
    </ImageTextStyled>
  )
}

ImageText.propTypes = {
  imageUrl: PropTypes.string,
  desc: PropTypes.string
}

export default ImageText
