import React, { forwardRef } from 'react'
import PropTypes from 'prop-types'
import RegularInputStyled from './styled'

const RegularInput = forwardRef(
  (
    {
      hasMarginBottom,
      variant,
      label,
      value,
      autoComplete,
      placeholder,
      type,
      name,
      prepend,
      handleOnChange,
      handleOnBlur,
      checked,
      hasError,
      disabled,
      multiple,
      accept,
      message
    },
    ref
  ) => {
    return (
      <RegularInputStyled variant={variant} hasMarginBottom={hasMarginBottom} hasError={hasError}>
        {label && <p>{label}</p>}
        <div className="input-wrapper">
          {prepend && <span>{prepend}</span>}
          <input
            ref={ref}
            placeholder={placeholder}
            type={type}
            value={value}
            name={name}
            checked={checked}
            autoComplete={autoComplete}
            onBlur={handleOnBlur ? () => handleOnBlur() : null}
            onChange={handleOnChange ? e => handleOnChange(e, e.target.value, e.target.name) : null}
            disabled={disabled}
            multiple={multiple}
            accept={accept}
          />
        </div>
        {message && <span className="input-message">{message}</span>}
      </RegularInputStyled>
    )
  }
)

RegularInput.defaultProps = {
  type: 'text',
  hasError: false,
  autoComplete: 'off',
  hasMarginBottom: true,
  disabled: false,
  multiple: false,
  accept: null,
  checked: false,
  message: null
}

RegularInput.propTypes = {
  checked: PropTypes.bool,
  variant: PropTypes.string,
  hasMarginBottom: PropTypes.bool,
  value: PropTypes.any,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  type: PropTypes.string,
  name: PropTypes.string,
  prepend: PropTypes.string,
  handleOnChange: PropTypes.func,
  handleOnBlur: PropTypes.func,
  hasError: PropTypes.bool,
  autoComplete: PropTypes.string,
  disabled: PropTypes.bool,
  multiple: PropTypes.bool,
  accept: PropTypes.string,
  message: PropTypes.string,
  ref: PropTypes.any
}

export default RegularInput
