import axios from 'axios'
import { BASE_URL } from '../config/api'
import * as WSLogger from './logger'
import _ from 'lodash'

export const STRIPE_KEY = process.env.REACT_APP_STRIPE_API_KEY

export const instance = axios.create({
  baseURL: BASE_URL,
  headers: { 'Content-type': 'application/json' }
})

export function configureAxiosDefaults(header, value) {
  instance.defaults.headers.common[header] = value
}

instance.interceptors.request.use(
  config => {
    WSLogger.logCall(config.method, config.url, config)
    return config
  },
  error => {
    const { method, url } = _.get(error, 'response.config', {})
    WSLogger.logError(method, url, error)
    return Promise.reject(error)
  }
)

instance.interceptors.response.use(
  function(response) {
    WSLogger.logResponse(response.config.method, response.config.url, response)
    return response
  },
  error => {
    const { method, url } = _.get(error, 'response.config', {})
    WSLogger.logError(method, url, error)
    return Promise.reject(error)
  }
)
