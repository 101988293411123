import { put, call, takeEvery } from 'redux-saga/effects'
import * as types from './types'
import * as api from '../../api'
import * as messagesOperations from './operations'
import { setError } from '../error/operations'
import { MESSAGES_TYPES } from '../../config/constants'
import client from '../../config/apollo'
import uuid from 'uuid/v1'
import _ from 'lodash'
import moment from 'moment'

export function* sendMessage(action) {
  const tempId = uuid()
  try {
    // CREATE AND INSERT TEMP MESSAGE
    const tempMessage = {
      ...action.payload.data,
      id: tempId,
      _id: tempId,
      author: action.payload.userInfo,
      created: moment().format(),
      type: MESSAGES_TYPES.TEMP
    }
    yield put(messagesOperations.addListMessage(tempMessage))

    // POST MESSAGE TO API
    const messageRes = yield call(api.postMessage, action.payload.data)
    const messageId = _.get(messageRes, 'data[@id]')

    // GET MESSAGE WITH GRAPHQL FORMAT
    const queryData = { query: api.queries.GET_MESSAGE, variables: { messageId }, fetchPolicy: 'network-only' }
    const messagesQueryRes = yield call(client.query, queryData)

    // REPLACE TEMP MESSAGE
    const message = _.get(messagesQueryRes, 'data.message')
    yield put(messagesOperations.updateListMessage(message, tempId))
  } catch (error) {
    // REMOVE TEMP MESSAGE
    yield put(messagesOperations.removeListMessage(tempId))
    yield put(setError(error))
  }
}

export default function* root() {
  yield takeEvery(types.MESSAGES_POST_SAGA_MESSAGE, sendMessage)
}
