import React from 'react'
import PopUpDirectUserInfoStyled from './styled'
import PropTypes from 'prop-types'

const PopUpDirectUserInfo = ({ showProfile }) => {
  return (
    <PopUpDirectUserInfoStyled>
      <div className="pop-up">
        <ul>
          <li onClick={showProfile}>Ver perfil</li>
        </ul>
      </div>
    </PopUpDirectUserInfoStyled>
  )
}

PopUpDirectUserInfo.propTypes = {
  showProfile: PropTypes.func
}

export default PopUpDirectUserInfo
