import * as organizationsActions from './actions'
import _ from 'lodash'
import { themesOperations, themesActions } from '../themes'
import { directsOperations, directsActions } from '../directs'
import { unreadMessagesOperations, unreadMessagesActions } from '../unread-messages'
import { removeAccents } from '../../utils'
import { organizationsOperations } from '.'
import i18n from '../../assets/i18n'

export const initList = () => {
  return async dispatch => {
    dispatch(organizationsActions.updateList([], 0, null))
    return dispatch(fetchList())
  }
}

export const fetchList = () => async (dispatch, getState, { client, api, setError }) => {
  try {
    dispatch(organizationsActions.setFetching(true))
    const { list: currentOrganizationsList, pageInfo } = getState().organizations
    const endCursor = _.get(pageInfo, 'endCursor', null)
    const organizationsQueryRes = await client.query({ query: api.queries.GET_ORGANIZATIONS, variables: { endCursor }, fetchPolicy: 'network-only' })
    const organizations = _.map(_.get(organizationsQueryRes, 'data.organizations.edges', []), v => v.node)
    const newPageInfo = _.get(organizationsQueryRes, 'data.organizations.pageInfo', { hasNextPage: false, hasPrevPage: false })
    const total = _.get(organizationsQueryRes, 'data.organizations.totalCount', 0)
    const organizationsList = [...currentOrganizationsList, ...organizations]

    dispatch(organizationsActions.updateList(organizationsList, total, newPageInfo))

    if (newPageInfo?.hasNextPage) {
      return dispatch(fetchList())
    } else {
      dispatch(organizationsActions.setFetching(false))
      return organizationsList
    }
  } catch (e) {
    dispatch(organizationsActions.setFetching(false))
    dispatch(setError(e))
    return []
  }
}

export const fetchOrganizationRemainingUsers = () => async (dispatch, getState, { client, api, setError }) => {
  const { item: organization } = getState().organizations
  if (!organization) {
    return []
  }
  try {
    dispatch(organizationsActions.setFetching(true))
    const organizationInvitedUsersRes = await client.query({
      query: api.queries.GET_INVITATIONS_REMAINING,
      variables: { organizationId: organization.id },
      fetchPolicy: 'network-only'
    })
    const invitedUsers = _.map(_.get(organizationInvitedUsersRes, 'data.invitations.edges', []), v => v.node)
    dispatch(organizationsActions.updateInvitationList(invitedUsers))
    return invitedUsers
  } catch (e) {
    dispatch(setError(e))
    return []
  } finally {
    dispatch(organizationsActions.setFetching(false))
  }
}

export const removeInvitation = invitation => async (dispatch, getState, { api, toast }) => {
  try {
    await api.removeInvitation({ invitation })
    dispatch(fetchOrganizationRemainingUsers())
  } catch {
    toast(i18n.pendingInvitationError, { type: toast.TYPE.ERROR })
    return false
  }
}

export const updateSelectedOrganization = organization => dispatch => {
  // SET ORGANIZATION
  dispatch(organizationsActions.updateItem(organization))
  dispatch(themesActions.updateItem(null))
  dispatch(directsActions.updateItem(null))

  // LOAD ORGANIZATION DATA
  dispatch(loadOrganizationData())
}

export const loadOrganizationData = searchText => async (dispatch, getState) => {
  const { item: organization } = getState().organizations
  if (!organization) {
    return false
  }

  // LOAD THEMES
  dispatch(themesOperations.initList(searchText))

  // LOAD DIRECTS
  dispatch(directsOperations.initList(searchText))

  // LOAD PENDING INVITATIONS
  dispatch(organizationsOperations.fetchOrganizationRemainingUsers())

  // LOAD UNREAD MESSAGES
  dispatch(unreadMessagesOperations.initList())
}

export const postOrganization = data => async (dispatch, getState, { client, api, setError }) => {
  const { userInfo } = getState().me

  try {
    let image = null
    if (data.image) {
      // CREATE IMAGE RESOURCE
      const postResourceRes = await api.postResource(data.image)
      image = _.get(postResourceRes, 'data[@id]')
    }

    // CREATE ORGANIZATION
    const name = _.get(data, 'name')
    const businessName = _.get(data, 'businessName')
    const document = _.get(data, 'document')
    const address = _.get(data, 'address')
    const postalCode = _.get(data, 'postalCode')
    const province = _.get(data, 'province')
    const city = _.get(data, 'city')
    const clientId = _.get(userInfo, 'id')

    const organizationData = {
      clients: [clientId],
      image,
      name,
      businessName,
      document,
      address,
      postalCode,
      province,
      city
    }
    const postOrganizationRes = await api.postOrganization(organizationData)
    const organizationId = _.get(postOrganizationRes, 'data[@id]')

    const organizationRes = await client.query({
      query: api.queries.GET_ORGANIZATION,
      variables: { organizationId },
      fetchPolicy: 'network-only'
    })

    const organization = _.get(organizationRes, 'data.organization', {})

    if (organization) {
      return organization
    }

    return false
  } catch (e) {
    dispatch(setError(e))
    return { error: e }
  }
}

export const postOrganizationUsers = (phoneNumbers = [], organizationId) => async (dispatch, getState, { client, api, setError }) => {
  if (!organizationId) {
    const { item: organization } = getState().organizations
    organizationId = _.get(organization, 'id')
  }

  if (_.isNil(organizationId) || !_.size(phoneNumbers)) {
    return false
  }

  try {
    dispatch(organizationsActions.setFetching(true))
    const data = {
      phoneNumber: phoneNumbers,
      organization: organizationId
    }
    await api.postOrganizationUsers(data)

    const organizationRes = await client.query({
      query: api.queries.GET_ORGANIZATION,
      variables: { organizationId },
      fetchPolicy: 'network-only'
    })

    const organization = _.get(organizationRes, 'data.organization', {})
    dispatch(updateSelectedOrganization(organization))
    dispatch(organizationsActions.setFetching(false))
    return true
  } catch (e) {
    dispatch(organizationsActions.setFetching(false))
    dispatch(setError(e))
    return false
  }
}

export const getHomeSearchQuery = (searchText = '') => async (dispatch, getState, { client, api, setError }) => {
  const { item: organization } = getState().organizations
  const { userInfo } = getState().me
  const organizationId = _.get(organization, 'id')
  const userId = _.get(userInfo, 'id')
  if (_.isNil(organizationId) || _.isNil(userId)) {
    return
  }

  try {
    dispatch(organizationsActions.setFetching(true))
    const normalizeSearchText = removeAccents(searchText)

    const homeSearchQueryRes = await client.query({
      query: api.queries.GET_HOME_SEARCH_QUERY,
      variables: { organizationId, userId, searchText: normalizeSearchText },
      fetchPolicy: 'network-only'
    })

    const themesList = _.map(_.get(homeSearchQueryRes, 'data.themes.edges', []), v => v.node)
    dispatch(themesActions.updateList(themesList))

    const directsList = _.map(_.get(homeSearchQueryRes, 'data.directs.edges', []), v => v.node)
    const directsTotal = _.get(homeSearchQueryRes, 'data.directs.totalCount', 0)
    dispatch(directsActions.updateList(directsList, directsTotal))
  } catch (e) {
    dispatch(setError(e))
  } finally {
    dispatch(organizationsActions.setFetching(false))
  }
}

export const updateOrganization = (organizationId, data) => async (dispatch, getState, { api, setError }) => {
  if (!organizationId || !data) {
    return
  }

  try {
    let image = null
    if (data.image) {
      // CREATE IMAGE RESOURCE
      const postResourceRes = await api.postResource(data.image)
      image = _.get(postResourceRes, 'data[@id]')
    }

    // UPDATE ORGANIZATION DATA
    const name = _.get(data, 'name')
    let organizationData = { name }
    if (image) {
      organizationData.image = image
    }

    const updateOrganizationRes = await api.updateOrganization(organizationId, organizationData)

    // RELOAD ORGANIZATION LIST AND SET NEW ORGANIZATION AS SELECTED
    const organizationIri = _.get(updateOrganizationRes, 'data[@id]')
    const organizations = await dispatch(initList())
    let organization = _.find(organizations, { id: organizationIri })

    // TODO: CHECK IF NEW ORGANIZATION COMES IN ORGANIZATIONS
    if (organization) {
      if (image) {
        organizationData.image = image
        organization = _.assign(organization, { orgImg: _.get(organizationData, 'image', '') })
      }
      dispatch(updateSelectedOrganization(organization))
      return true
    } else {
      return false
    }
  } catch (e) {
    dispatch(setError(e))
    return false
  }
}

export const exitOrganization = organization => async (dispatch, getState, { api, setError }) => {
  const organizationIri = _.get(organization, 'id')
  const { userInfo } = getState().me
  const userId = _.get(userInfo, 'id', null)

  if (_.isNil(organizationIri) || _.isNil(userId)) {
    return
  }

  try {
    dispatch(organizationsActions.setFetching(true))

    const data = { organization: organizationIri, userUnsubscribe: userId }
    await api.exitOrganization(data)
    const { item: selectedOrganization } = getState().organizations

    const organizations = await dispatch(initList())

    if (organization.id === selectedOrganization.id) {
      const newSelectedOrg = _.first(organizations)
      if (newSelectedOrg) {
        dispatch(updateSelectedOrganization(newSelectedOrg))
      } else {
        dispatch(resetOrganizationData())
      }
    }
  } catch (e) {
    dispatch(setError(e))
  } finally {
    dispatch(organizationsActions.setFetching(false))
  }
}

export const removeUserFromOrganization = (organizationIri, userIri) => async (dispatch, getState, { api, setError }) => {
  if (_.isNil(organizationIri) || _.isNil(userIri)) {
    return false
  }

  try {
    dispatch(organizationsActions.setFetching(true))
    const data = { organization: organizationIri, userUnsubscribe: userIri }
    await api.exitOrganization(data)

    // RELOAD DIRECTS
    dispatch(directsOperations.initList())

    // RELOAD UNREAD MESSAGES
    dispatch(unreadMessagesOperations.initList())
    return true
  } catch (e) {
    dispatch(setError(e))
    return false
  } finally {
    dispatch(organizationsActions.setFetching(false))
  }
}

export const resetOrganizationData = () => dispatch => {
  // RESET ALL ORGANIZATION DATA
  dispatch(organizationsActions.updateItem(null))
  dispatch(themesActions.updateList([]))
  dispatch(themesActions.updateItem(null))
  dispatch(directsActions.updateList([]))
  dispatch(directsActions.updateItem(null))
  dispatch(unreadMessagesActions.updateList([]))
  dispatch(unreadMessagesActions.updateItem(null))
}

export const fetchOrganizationCurrentBillingData = organization => async (dispatch, getState, { api, setError }) => {
  if (!organization) {
    return
  }
  try {
    dispatch(organizationsActions.setFetching(true))
    const organizationId = _.get(organization, '_id')
    const organizationCurrentBillingDataRes = await api.fetchOrganizationCurrentBillingData(organizationId)
    const billingData = _.get(organizationCurrentBillingDataRes, 'data', null)
    return billingData
  } catch (e) {
    dispatch(setError(e))
    return null
  } finally {
    dispatch(organizationsActions.setFetching(false))
  }
}

export const fetchOrganizationPayments = organization => async (dispatch, getState, { api, setError }) => {
  if (!organization) {
    return
  }
  try {
    dispatch(organizationsActions.setFetching(true))
    const organizationId = _.get(organization, '_id')
    const paymentsRes = await api.fetchOrganizationPayments(organizationId)
    const paymentsData = _.get(paymentsRes, 'data', [])
    return paymentsData
  } catch (e) {
    dispatch(setError(e))
    return null
  } finally {
    dispatch(organizationsActions.setFetching(false))
  }
}

export const fetchOrganizationSubscriptionData = organization => async (dispatch, getState, { api, client, setError }) => {
  if (!organization) {
    return
  }
  try {
    dispatch(organizationsActions.setFetching(true))
    const organizationIri = _.get(organization, 'id')

    const organizationSubscriptionRes = await client.query({
      query: api.queries.GET_ORGANIZATION_SUBSCRIPTION,
      variables: { organizationId: organizationIri },
      fetchPolicy: 'network-only'
    })

    const subscription = _.get(organizationSubscriptionRes, 'data.organization.subscription', {})

    return subscription
  } catch (e) {
    dispatch(setError(e))
    return null
  } finally {
    dispatch(organizationsActions.setFetching(false))
  }
}

export const deleteOrganization = (organization, subscriptionId) => async (dispatch, getState, { api, setError }) => {
  const { item: selectedOrganization } = getState().organizations
  if (!organization) {
    return
  }

  try {
    dispatch(organizationsActions.setFetching(true))
    const organizationId = _.get(organization, '_id')
    await api.deleteOrganization(subscriptionId)
    const organizations = await dispatch(initList())
    if (organizationId === _.get(selectedOrganization, '_id')) {
      if (_.size(organizations)) {
        const newSelectedOrganization = _.first(organizations)
        dispatch(updateSelectedOrganization(newSelectedOrganization))
      } else {
        dispatch(resetOrganizationData())
      }
    }
  } catch (e) {
    dispatch(setError(e))
  } finally {
    dispatch(organizationsActions.setFetching(false))
  }
}

export const checkUserIsInOrganization = username => async (dispatch, getState, { client, api, setError }) => {
  const { item: organization } = getState().organizations

  if (!organization?.isClient || !username) {
    return false
  }

  try {
    dispatch(organizationsActions.setFetching(true))

    const {
      data: { organizationClientsUsers, organizationUsersUsers }
    } = await client.query({
      query: api.queries.CHECK_USER_IS_IN_ORGANIZATION,
      variables: { organizationId: organization.id, username },
      fetchPolicy: 'network-only'
    })

    const userIsInOrganization = organizationClientsUsers?.edges?.length || organizationUsersUsers?.edges?.length
    return userIsInOrganization
  } catch (e) {
    dispatch(setError(e))
    return false
  } finally {
    dispatch(organizationsActions.setFetching(false))
  }
}
