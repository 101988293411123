import React, { useState } from 'react'
import MainSideBarStyled from './styled'
import { DirectChatList, NoReadMsgList, ThemeList, SearchChats, OrganizationBar } from '../../molecules'
import PropTypes from 'prop-types'
import i18n from '../../../assets/i18n'
import _ from 'lodash'
import { ModalAccept, ModalEditTheme } from '../../modals'
import { toast } from 'react-toastify'
import { Link } from 'react-router-dom'
import routes from '../../../config/routes'

const MainSideBar = ({
  onCreateThemeTapped,
  onPreferencesTapped,
  onCreateOrganization,
  onCreateOrganizationTapped,
  onThemeTapped,
  onDirectTapped,
  handleOpenNewTheme,
  onOpenBilling,
  organization,
  onExitTheme,
  themesFetching,
  onDeleteTheme,
  onToggleModalOrganizationInProgress
}) => {
  const [openModalExitTheme, setOpenModalExitTheme] = useState(false)
  const [openModalDeleteTheme, setOpenModalDeleteTheme] = useState(false)
  const [selectTheme, setSelectTheme] = useState({})
  const [openEditModal, setOpenEditModal] = useState(false)

  function toggleExitThemeModal() {
    setOpenModalExitTheme(!openModalExitTheme)
  }

  function toggleDeleteThemeModal() {
    setOpenModalDeleteTheme(!openModalDeleteTheme)
  }

  async function onExitThemeAction() {
    try {
      await onExitTheme(selectTheme)
      toggleExitThemeModal()
    } catch {
      toast(i18n.generalError, { type: toast.TYPE.ERROR })
    }
  }

  async function onDeleteThemeAction() {
    try {
      await onDeleteTheme(selectTheme)
      toggleDeleteThemeModal()
    } catch {
      toast(i18n.generalError, { type: toast.TYPE.ERROR })
    }
  }

  function onItemTapped(item, section) {
    if (section === 'themes') {
      const isAdmin = item?.isAdmin
      const isClient = organization?.isClient
      const direct = _.get(item, 'directs.edges[0].node')

      if (isAdmin || isClient) {
        onThemeTapped(item)
      } else if (direct) {
        onDirectTapped(direct)
      }
    } else if (section === 'directs') {
      onDirectTapped(item)
    } else if (section === 'unreadMessages') {
      onDirectTapped(item?.direct)
    }
  }

  function toggleModalEdit() {
    setOpenEditModal(!openEditModal)
  }

  return (
    <MainSideBarStyled>
      <OrganizationBar
        onCreateOrganizationTapped={onCreateOrganizationTapped}
        onCreateThemeTapped={onCreateThemeTapped}
        onPreferencesTapped={onPreferencesTapped}
        onCreateOrganization={onCreateOrganization}
        onOpenBilling={onOpenBilling}
        onToggleModalOrganizationInProgress={onToggleModalOrganizationInProgress}
      />
      <div className="sidebar-items">
        <SearchChats handleOpenNewTheme={handleOpenNewTheme} />
        <NoReadMsgList onClickUnreadMessage={onItemTapped} />
        <ThemeList
          onClickEdit={toggleModalEdit}
          onClickExitTheme={theme => {
            setSelectTheme(theme)
            toggleExitThemeModal()
          }}
          onClickDeleteTheme={theme => {
            setSelectTheme(theme)
            toggleDeleteThemeModal()
          }}
          onClickThemeItem={onItemTapped}
        />
        <DirectChatList onClickDirectItem={onItemTapped} title={i18n.mainSideBar.directChatList} />
      </div>
      <div className="terms-wrapper">
        <p className="term-item">
          <Link to={routes.terms} target="_blank">
            {i18n.mainSideBar.terms}
          </Link>
          ,{' '}
          <Link to={routes.privacyPolicy} target="_blank">
            {i18n.mainSideBar.privacyPolicy}
          </Link>
          ,{' '}
          <Link to={routes.legalPolicy} target="_blank">
            {i18n.mainSideBar.legal}
          </Link>
        </p>
      </div>

      <ModalEditTheme onSubmitEdit={toggleModalEdit} show={openEditModal} onHide={toggleModalEdit} />

      <ModalAccept
        show={openModalExitTheme}
        onHide={toggleExitThemeModal}
        onReject={toggleExitThemeModal}
        desc={i18n.themeList.exitConfirm}
        onAccept={onExitThemeAction}
        isFetching={themesFetching}
      />

      <ModalAccept
        show={openModalDeleteTheme}
        onHide={toggleDeleteThemeModal}
        onReject={toggleDeleteThemeModal}
        desc={i18n.themeList.deleteConfirm}
        onAccept={onDeleteThemeAction}
        isFetching={themesFetching}
      />
    </MainSideBarStyled>
  )
}

MainSideBar.propTypes = {
  onCreateThemeTapped: PropTypes.func,
  onThemeTapped: PropTypes.func,
  handleOpenNewTheme: PropTypes.func,
  directs: PropTypes.array,
  onDirectTapped: PropTypes.func,
  userInfo: PropTypes.object,
  organization: PropTypes.object,
  onCreateOrganization: PropTypes.func,
  onPreferencesTapped: PropTypes.func,
  onCreateOrganizationTapped: PropTypes.func,
  onOpenBilling: PropTypes.func,
  onExitTheme: PropTypes.func,
  themesFetching: PropTypes.bool,
  onOpenEdit: PropTypes.func,
  onDeleteTheme: PropTypes.func,
  onToggleModalOrganizationInProgress: PropTypes.func
}

export default MainSideBar
