import styled from 'styled-components'
import colors from '../../../assets/colors'

const ComponentStyled = styled.div`
  && {
    margin-top: 70px;

    .title {
      font-size: 14px;
      font-weight: 600;
      line-height: 16px;
      letter-spacing: 1.08px;
      text-transform: uppercase;
      color: ${colors.darkGrey8};
      margin-bottom: 35px;
    }

    .resume-price {
      text-align: center;
      margin-bottom: 40px;

      .price {
        font-size: 28px;
        line-height: 28px;
        color: ${colors.yellow};
        margin-bottom: 5px;
        font-weight: 600;
      }

      .tax {
        font-size: 12px;
        line-height: 12px;
        color: ${colors.yellow};
        margin-bottom: 15px;
        font-weight: 600;
      }

      .info {
        margin-bottom: 0;
        font-size: 16px;
        line-height: 16px;
      }
    }

    .advantages {
      list-style: none;
      padding: 0;
      margin: 0;

      li {
        font-size: 16px;
        line-height: 24px;
        color: ${colors.darkGrey3};
        margin-bottom: 45px;
      }
    }
  }
`

export default ComponentStyled
