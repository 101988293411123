import styled, { keyframes } from 'styled-components'
import colors from '../../../assets/colors'

const appear = keyframes`
  from {
    opacity: 0;
    transform: translateY(-20px);
  }

  to {
    opacity: 1;
    transform: translateY(0px);
  }
`

export const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  width: 100%;
  justify-items: stretch;
  padding: 20px 0px;
`

export const ItemContainer = styled.div`
  padding: 0px 20px;
  display: flex;
  flex-direction: column;
`

export const ItemWrapper = styled.div`
  display: flex;
  justify-content: center;
  border-right: solid 1px ${colors.yellow};
  &:nth-child(4n) {
    border-right: 0px;
  }
  & > * {
    opacity: 0;
    animation: ${appear} 0.5s ${({ itemNum }) => itemNum * 0.1}s forwards;
    @media print {
      animation: none;
      opacity: 1;
    }
  }
`

export const Label = styled.label`
  font-size: 16px;
  font-weight: 300;
  line-height: 18px;
  margin-bottom: 8px;
`

export const Number = styled.span`
  font-size: 50px;
  font-weight: 700;
  line-height: 43px;
`
