import styled, { css } from 'styled-components'
import colors from '../../../assets/colors'

const SingleDatePickerStyled = styled.div`
  .input-wrapper {
    position: relative;

    .react-datepicker-wrapper {
      width: 100%;
    }

    svg,
    img {
      position: absolute;
      top: 50%;
      right: 0;
      transform: translateY(-50%);
    }
  }

  p {
    font-size: 13px;
    line-height: 15px;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: ${colors.darkGrey};
    font-weight: 600;
    margin-bottom: 5px;
  }

  input {
    width: 100%;
    outline: none;
    border: 0;
    border-bottom: 1px solid ${colors.darkGreyAlpha50};
    background-color: transparent;
    padding-bottom: 10px;
    font-size: 17px;
    line-height: 24px;
    letter-spacing: -0.53px;
    color: ${colors.darkGrey};

    ::placeholder {
      color: ${colors.darkGreyAlpha50};
    }
  }

  ${props =>
    props.hasError &&
    css`
      input {
        border-color: ${colors.red};
      }
    `}

  ${props =>
    props.hasMarginBottom === true &&
    css`
      .date-wrapper {
        margin-bottom: 30px;
      }
    `}
`

export default SingleDatePickerStyled
