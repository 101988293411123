import React, { useState, useEffect } from 'react'
import {
  HeaderDashboard,
  ChatWindow,
  SideBarUpdateUser,
  SideBarUpdateOrganization,
  SideBarMembers,
  MainSideBar,
  SideBarRepplyPoll,
  SideBarCreatePoll,
  SideBarBilling,
  SideBarPollInfo,
  SideBarComercial
} from '../../organism'
import DashboardStyled from './styled'
import { ModalCreateTheme, ModalCreateOrganization, ModalOrganizationInProgress, ModalAccept } from '../../modals'
import { withRouter } from 'react-router-dom'
import { toast } from 'react-toastify'
import PropTypes from 'prop-types'
import i18n from '../../../assets/i18n'
import _ from 'lodash'

const Dashboard = ({ history, location, theme, organization }) => {
  const params = new URLSearchParams(location.search)
  const paymentStatus = params.get('payment')
  const isChangingPayment = params.get('isChangingPayment')

  const [openSideBar, setOpenSideBar] = useState(false)
  const [descModalAccept, setDescModalAccept] = useState('')
  const [showUpdateUserSidebar, setShowUpdateUserSidebar] = useState(false)
  const [showUpdateOrganization, setShowUpdateOrganization] = useState(false)
  const [showMembersSidebar, setShowMembersSidebar] = useState(false)
  const [showRepplyPollSidebar, setShowRepplyPollSidebar] = useState(true)
  const [showInfoPollSidebar, setShowInfoPollSidebar] = useState(true)
  const [showCreatePollSidebar, setShowCreatePollSidebar] = useState(false)
  const [openModalCreateTheme, setOpenModalCreateTheme] = useState(false)
  const [openModalAccept, setOpenModalAccept] = useState(false)
  const [openCreateOrganization, setOpenCreateOrganization] = useState(false)
  const [openOrganizationInProgress, setOpenOrganizationInProgress] = useState(false)
  const [showBillingSidebar, setShowBillingSidebar] = useState(false)
  const [showComercialSidebar, setShowComercialSidebar] = useState(false)
  const [organizationId, setOrganizationId] = useState(params.get('organizationId'))
  const [activateOrg, setActivateOrg] = useState(false)

  useEffect(() => {
    if (theme || organization) {
      closeSidebars()
    } else {
      if (paymentStatus) {
        if (paymentStatus === 'KO') {
          toast(i18n.dashboard.paymentError, { type: toast.TYPE.ERROR })
        } else {
          //If user change payment method
          if (isChangingPayment === 'true') {
            setDescModalAccept(i18n.dashboard.paymentMethodUpdated)
            toggleModalAccept()
          } else {
            toggleModalCreateOrganization()
          }
        }
        const pathname = _.get(history, 'location.pathname', '')
        history.replace({ pathname })
      }
    }
  }, [theme, organization])

  function toggleModalCreateTheme() {
    setOpenModalCreateTheme(!openModalCreateTheme)
  }

  function toggleModalAccept() {
    setOpenModalAccept(!openModalAccept)
  }

  function toggleModalCreateOrganization(orgId, activateOrganization) {
    if (openCreateOrganization) {
      setOrganizationId('')
    } else {
      if (orgId && activateOrganization) {
        setActivateOrg(activateOrganization)
        setOrganizationId(orgId)
      }
    }
    setOpenCreateOrganization(!openCreateOrganization)
  }

  function toggleModalOrganizationInProgress() {
    setOpenOrganizationInProgress(!openOrganizationInProgress)
  }

  function toggleSideBar() {
    setOpenSideBar(!openSideBar)
  }

  function openCreateThemeOrganization() {
    setOpenCreateOrganization(false)
    toggleModalCreateTheme()
  }

  function closeSidebars() {
    if (openSideBar) {
      resetSideBar()
      toggleSideBar()
    }
  }

  function resetSideBar() {
    setShowUpdateUserSidebar(false)
    setShowUpdateOrganization(false)
    setShowMembersSidebar(false)
    setShowRepplyPollSidebar(false)
    setShowCreatePollSidebar(false)
    setOpenModalCreateTheme(false)
    setOpenCreateOrganization(false)
    setShowBillingSidebar(false)
    setShowComercialSidebar(false)
    setShowInfoPollSidebar(false)
  }

  function openUpdateUserSidebar() {
    if (!openSideBar) {
      resetSideBar()
      toggleSideBar()
      setShowUpdateUserSidebar(true)
    }
  }

  function openMemberSidebar() {
    if (!openSideBar) {
      resetSideBar()
      toggleSideBar()
      setShowMembersSidebar(true)
    }
  }

  function openCreatePollSidebar() {
    if (!openSideBar) {
      resetSideBar()
      toggleSideBar()
      setShowCreatePollSidebar(true)
    }
  }

  function openUpdateOrganizationSidebar() {
    if (!openSideBar) {
      resetSideBar()
      toggleSideBar()
      setShowUpdateOrganization(true)
    }
  }

  function openBillingSidebar() {
    if (!openSideBar) {
      resetSideBar()
      toggleSideBar()
      setShowBillingSidebar(true)
    }
  }

  function openComercialSidebar() {
    if (!openSideBar) {
      resetSideBar()
      toggleSideBar()
      setShowComercialSidebar(true)
    }
  }

  function openShowRepplyPollSidebar() {
    if (!openSideBar) {
      resetSideBar()
      toggleSideBar()
      setShowRepplyPollSidebar(true)
    }
  }

  function openShowInfoPollSidebar() {
    if (!openSideBar) {
      resetSideBar()
      toggleSideBar()
      setShowInfoPollSidebar(true)
    }
  }

  return (
    <DashboardStyled>
      <div className="header-dashboard-wrapper">
        <HeaderDashboard openComercialSidebar={openComercialSidebar} handleOpenUpdateUser={openUpdateUserSidebar} />
      </div>
      <div className="dashboard-wrapper">
        <MainSideBar
          onCreateOrganizationTapped={toggleModalCreateOrganization}
          onCreateThemeTapped={openCreateThemeOrganization}
          onPreferencesTapped={openUpdateOrganizationSidebar}
          handleOpenNewTheme={toggleModalCreateTheme}
          onCreateOrganization={toggleModalCreateOrganization}
          onToggleModalOrganizationInProgress={toggleModalOrganizationInProgress}
          onOpenBilling={openBillingSidebar}
        />
        <ChatWindow
          handleOpenMembers={openMemberSidebar}
          onOpenRepplyPoll={openShowRepplyPollSidebar}
          onOpenPollInfo={openShowInfoPollSidebar}
          handleOpenCreatePollSidebar={openCreatePollSidebar}
        />
        <div className={`dashboard-sidebar-right  ${openSideBar && 'show'}`}>
          <div className="sidebar-right-wrapper">
            <img className="close-sidebar" onClick={toggleSideBar} src={require('../../../assets/images/close.png')} alt="Arrow" />
            {showUpdateUserSidebar && <SideBarUpdateUser />}
            {showMembersSidebar && <SideBarMembers />}
            {showRepplyPollSidebar && <SideBarRepplyPoll toggleSidebar={toggleSideBar} />}
            {showCreatePollSidebar && <SideBarCreatePoll closeSidebars={closeSidebars} />}
            {showInfoPollSidebar && <SideBarPollInfo />}
            {showUpdateOrganization && <SideBarUpdateOrganization />}
            {showBillingSidebar && <SideBarBilling />}
            {showComercialSidebar && <SideBarComercial />}
          </div>
        </div>
      </div>
      <ModalAccept desc={descModalAccept} show={openModalAccept} onHide={toggleModalAccept} onAccept={toggleModalAccept} />
      <ModalCreateTheme show={openModalCreateTheme} onHide={toggleModalCreateTheme} />
      <ModalCreateOrganization
        organizationId={organizationId}
        activateOrg={activateOrg}
        show={openCreateOrganization}
        onHide={toggleModalCreateOrganization}
        toggleModalCreateOrganization={toggleModalCreateOrganization}
        openCreateThemeOrganization={openCreateThemeOrganization}
      />
      <ModalOrganizationInProgress
        organizationId={organizationId}
        activateOrg={activateOrg}
        show={openOrganizationInProgress}
        onHide={toggleModalOrganizationInProgress}
      />
    </DashboardStyled>
  )
}

Dashboard.propTypes = {
  location: PropTypes.object,
  theme: PropTypes.object,
  organization: PropTypes.object,
  history: PropTypes.object
}

export default withRouter(Dashboard)
