import React from 'react'
import PropTypes from 'prop-types'
import * as S from './styled'

const NumberDataList = ({ data }) => (
  <S.Container>
    {data?.map((item, index) => (
      <S.ItemWrapper itemNum={index} key={item?.id}>
        <S.ItemContainer>
          <S.Label>{item?.label}</S.Label>
          <S.Number>{item?.value || 0}</S.Number>
        </S.ItemContainer>
      </S.ItemWrapper>
    ))}
  </S.Container>
)

NumberDataList.propTypes = {
  data: PropTypes.array
}

NumberDataList.defaultProps = {
  data: []
}

export default NumberDataList
