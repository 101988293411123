import colors from '../../../assets/colors'
import styled from 'styled-components'

export const ComponentStyled = styled.div`
  .poll-header {
    padding: 0 0 25px;
    margin: 0 0 50px;
    border-bottom: 1px solid ${colors.lightGrey};
    font-size: 20px;
  }

  .answer-item {
    display: flex;
    justify-content: space-between;
  }

  .answer-item-title {
    text-transform: uppercase;
    font-size: 13px;
  }

  .poll-name {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    width: 100%;
    cursor: pointer;
  }

  .btn-next-wrapper {
    margin-top: 50px;
  }

  .return-arrow {
    margin-right: 10px;
  }

  .answer-edit-item {
    color: ${colors.yellow};
    cursor: pointer;
    font-size: 14px;
  }
`
