import styled from 'styled-components'

export const Container = styled.div`
  && {
    .modal-header,
    .modal-body {
      border: 0;
      padding: 0;
    }
  }
`

export const Content = styled.div`
  padding: 30px;
  max-width: 407px;
  margin: 0 auto;
`
