import React, { useState, useEffect } from 'react'
import ComponentStyled from './styled'
import { BillingItem, SideBarTitle } from '../../atoms'
import { formatMoney } from '../../../utils'

import { LiquidationInfo, LiquidationList, CustomerList, CustomerInfo, ComercialRegisterForm, ComercialBillingItem } from '../../molecules'
import i18n from '../../../assets/i18n'
import PropTypes from 'prop-types'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { toast } from 'react-toastify'
import _ from 'lodash'
import moment from 'moment'

const STEPS = {
  comercial: 1,
  benefits: 2,
  customer: 3,
  billing: 4,
  liquidation: 5
}

const SideBarComercial = ({
  userInfo,
  customers,
  profits,
  comercialPayments,
  customerSelected,
  updateBillingData,
  getCustomers,
  getProfits,
  getComercialPayments,
  selectComercialPayment,
  selectCustomer,
  comercialPaymentSelected
}) => {
  const [step, setStep] = useState(STEPS.comercial)

  useEffect(() => {
    getCustomers()
    getProfits()
    getComercialPayments()
  }, [])

  const commercialId = _.get(userInfo, 'commercialId', 'not-found')
  const urlToShare = 'http://' + window.location.hostname + '?commercialId=' + commercialId

  function renderComercialZone() {
    const totalProfit = getTotalProfit(profits)
    const today = moment()

    return (
      <>
        <SideBarTitle title={i18n.sideBarComercial.title} />

        <div className="section-wrapper earned-income">
          <p className="section-title">{i18n.sideBarComercial.benefits}</p>
          <BillingItem
            onClick={() => {
              setStep(STEPS.benefits)
            }}
            title={formatMoney(totalProfit)}
            description={`${i18n.sideBarComercial.earnedIncome}`}
            moreInfo={today.format('DD/MM/YYYY')}
          />
        </div>
        <div className="section-wrapper">
          <p className="section-title">{i18n.sideBarComercial.favLink}</p>
          <CopyToClipboard
            text={urlToShare}
            onCopy={() => {
              toast(i18n.sideBarComercial.linkCopied, { type: toast.TYPE.SUCCESS })
            }}
          >
            <div className="send-link">
              <p>{i18n.sideBarComercial.sendLink}</p>
              <img alt="Send" src={require('../../../assets/images/send.png')} />
            </div>
          </CopyToClipboard>
        </div>
        <div className="section-wrapper">
          <LiquidationList
            items={comercialPayments}
            title={i18n.sideBarComercial.lastLiquidations}
            onClickLiquidation={item => {
              selectComercialPayment(item)
              setStep(STEPS.liquidation)
            }}
          />
        </div>
        <div className="section-wrapper">
          <CustomerList
            items={customers}
            onClickCustomer={item => {
              selectCustomer(item)
              setStep(STEPS.customer)
            }}
            title={i18n.sideBarComercial.customers}
          />
        </div>
        <div className="section-wrapper">
          <ComercialBillingItem
            title={i18n.sideBarComercial.billing}
            userInfo={userInfo}
            onClick={() => {
              setStep(STEPS.billing)
            }}
          />
        </div>
      </>
    )
  }

  function getTotalProfit(profits) {
    let total = 0
    profits.map(a => (total = total + a.amount))
    return total
  }

  function renderBenefits() {
    const totalProfit = getTotalProfit(profits)
    const today = moment()

    return (
      <>
        <SideBarTitle
          onClick={() => {
            setStep(STEPS.comercial)
          }}
          title={i18n.sideBarComercial.benefits}
        />
        <div className="section-wrapper earned-income">
          <BillingItem title={formatMoney(totalProfit)} description={`${i18n.sideBarComercial.earnedIncome}`} moreInfo={today.format('DD/MM/YYYY')} />
        </div>
        <div className="section-wrapper">
          <CustomerList withMemberNum={false} items={profits} title={i18n.sideBarComercial.earnedByCustomers} />
        </div>
      </>
    )
  }

  function renderCustomer() {
    return (
      <>
        <SideBarTitle
          onClick={() => {
            setStep(STEPS.comercial)
          }}
          title={i18n.sideBarComercial.customer}
        />
        <div className="section-wrapper">
          <CustomerInfo item={customerSelected} />
        </div>
      </>
    )
  }

  function renderLiquidation() {
    return (
      <>
        <SideBarTitle
          onClick={() => {
            setStep(STEPS.comercial)
          }}
          title={i18n.sideBarComercial.liquidation}
        />
        <div className="section-wrapper">
          <LiquidationInfo item={comercialPaymentSelected}></LiquidationInfo>
        </div>
      </>
    )
  }

  function renderBillingForm() {
    return (
      <>
        <SideBarTitle
          onClick={() => {
            setStep(STEPS.comercial)
          }}
          title={i18n.sideBarComercial.billing}
        />
        <div className="section-wrapper">
          <ComercialRegisterForm initialValues={userInfo.billingData} onSubmit={handleCommercialRegisterForm} />
        </div>
      </>
    )
  }

  function handleCommercialRegisterForm(values) {
    updateBillingData(values)
      .then(() => {
        setStep(STEPS.comercial)
      })
      .catch(e => {})
  }

  function renderSteps() {
    switch (step) {
      case STEPS.comercial:
        return renderComercialZone()
      case STEPS.benefits:
        return renderBenefits()
      case STEPS.customer:
        return renderCustomer()
      case STEPS.billing:
        return renderBillingForm()
      case STEPS.liquidation:
        return renderLiquidation()
      default:
        return null
    }
  }

  return <ComponentStyled>{renderSteps()}</ComponentStyled>
}

SideBarComercial.propTypes = {
  userInfo: PropTypes.object.isRequired,
  customers: PropTypes.array.isRequired,
  profits: PropTypes.array.isRequired,
  comercialPayments: PropTypes.array.isRequired,
  updateBillingData: PropTypes.func.isRequired,
  getCustomers: PropTypes.func.isRequired,
  getProfits: PropTypes.func.isRequired,
  getComercialPayments: PropTypes.func.isRequired,
  customersIsFetching: PropTypes.bool.isRequired,
  customerSelected: PropTypes.object.isRequired,
  comercialPaymentSelected: PropTypes.object.isRequired,
  selectCustomer: PropTypes.func.isRequired,
  selectComercialPayment: PropTypes.func.isRequired
}

export default SideBarComercial
