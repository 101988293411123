import React from 'react'
import PropTypes from 'prop-types'
import i18n from 'assets/i18n'
import * as S from './styled'
import Modal from 'react-bootstrap/Modal'
import { CircleHeader } from '../../atoms'

const ModalOrganizationInProgress = ({ show, onHide }) => (
  <Modal show={show} onHide={onHide} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
    <S.Container>
      <Modal.Body>
        <CircleHeader />
        <S.Content>{i18n.modalOrganizationInProgress.description}</S.Content>
      </Modal.Body>
    </S.Container>
  </Modal>
)

ModalOrganizationInProgress.propTypes = {
  onHide: PropTypes.func,
  show: PropTypes.bool
}

export default ModalOrganizationInProgress
