import React, { useState } from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import BannerHomeStyled from './styled'
import i18n from '../../../assets/i18n'
import { Button } from '../../atoms'
import { ReactComponent as AppleStore } from '../../../assets/images/apple-store.svg'
import { ReactComponent as GooglePlay } from '../../../assets/images/google-play.svg'
import { withRouter } from 'react-router'
import { ModalLogin, ModalRegister, ModalResetPass } from '../../modals'
import { googlePlayUrl, appleStoreUrl } from '../../../config/constants'

const ACTIVEMODAL = {
  login: 1,
  register: 2,
  reset: 3
}

const BannerHome = () => {
  const [activeModal, setActiveModal] = useState(0)

  function toggleModal(activeModal = 0) {
    setActiveModal(activeModal)
  }

  return (
    <BannerHomeStyled>
      <Container>
        <div className="box-wrapper">
          <Row>
            <Col xs={12} sm={5}>
              <h1 className="title">{i18n.bannerHome.title}</h1>
              <p className="desc">{i18n.bannerHome.desc}</p>
              <Button
                handleOnClick={() => {
                  toggleModal(ACTIVEMODAL.register)
                }}
                variant="big"
                label={i18n.bannerHome.signUp}
              />
              <div className="text-center logos-wrapper">
                <a href={appleStoreUrl} target="_blank" rel="noopener noreferrer">
                  <AppleStore />
                </a>
                <a href={googlePlayUrl} target="_blank" rel="noopener noreferrer">
                  <GooglePlay />
                </a>
              </div>
            </Col>
            <Col xs={12} sm={7}>
              <video className="main-video" width="100%" height="100%" controls>
                <source src={require('../../../assets/videos/video-beenfo.mp4')} type="video/mp4" />
                {i18n.bannerHome.errorVideoTag}
              </video>
            </Col>
          </Row>
        </div>
      </Container>
      <ModalLogin
        show={activeModal === ACTIVEMODAL.login}
        onHide={toggleModal}
        onRegisterTapped={() => {
          toggleModal(ACTIVEMODAL.register)
        }}
      />
      <ModalRegister
        show={activeModal === ACTIVEMODAL.register}
        onHide={toggleModal}
        onLoginTapped={() => {
          toggleModal(ACTIVEMODAL.login)
        }}
        onResetTapped={() => {
          toggleModal(ACTIVEMODAL.reset)
        }}
      />
      <ModalResetPass
        show={activeModal === ACTIVEMODAL.reset}
        onHide={toggleModal}
        onLoginTapped={() => {
          toggleModal(ACTIVEMODAL.login)
        }}
      />
    </BannerHomeStyled>
  )
}

export default withRouter(BannerHome)
