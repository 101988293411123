import React, { Component } from 'react'
import ComponentStyled from './styled'
import i18n from '../../../assets/i18n'
import { formatMoney } from '../../../utils'
import PropTypes from 'prop-types'
import _ from 'lodash'
import moment from 'moment'

class LiquidationInfo extends Component {
  render() {
    const comercialPayment = this.props.item
    const organizationName = _.get(comercialPayment, 'organizationName', '')
    const amount = _.get(comercialPayment, 'amount', 0)
    const createdDate = _.get(comercialPayment, 'createdAt', false)
    const status = _.get(comercialPayment, 'status', '')

    return (
      <ComponentStyled>
        <div className="customer-wrapper">
          <p className="name">{organizationName}</p>
          <p className="members">
            {moment(createdDate).format('DD/MM/YYYY')} | {i18n.getString('liquidationInfo.' + status)}
          </p>
          <p className="price">{formatMoney(amount)}</p>
        </div>
      </ComponentStyled>
    )
  }
}

LiquidationInfo.propTypes = {
  item: PropTypes.object
}
export default LiquidationInfo
