import styled from 'styled-components'
import colors from '../../../assets/colors'

const FilterAnswersBarStyled = styled.div`
  && {
    padding: 10px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: ${colors.yellow2};
    padding: 10px 45px;

    .check-wrapper-filter {
      span {
        margin-left: 10px;
        font-size: 16px;
        line-height: 16px;
        color: ${colors.black};
      }
    }
  }
`

export default FilterAnswersBarStyled
