import styled from 'styled-components'
import colors from '../../../assets/colors'

const CreateOrganizationCompletedStyled = styled.div`
  && {
    margin-top: 70px;

    .success {
      text-align: center;
      margin-bottom: 50px;
    }

    .go-dashboard {
      font-size: 13px;
      text-align: center;
      margin-top: 30px;
      margin-bottom: 0;
      letter-spacing: -0.43px;
      color: ${colors.darkGrey2};

      span,
      a {
        cursor: pointer;
        color: ${colors.yellow};
      }
    }
  }
`

export default CreateOrganizationCompletedStyled
