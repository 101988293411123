import React, { Component } from 'react'
import ComponentStyled from './styled'
import { Avatar } from '../../atoms'
import i18n from '../../../assets/i18n'
import { formatMoney } from '../../../utils'
import PropTypes from 'prop-types'
import _ from 'lodash'
import moment from 'moment'

class CustomerInfo extends Component {
  calculateNumDaysRest(finishedDate) {
    let numDaysRest = 0
    if (finishedDate) {
      const finishedDateMoment = moment(finishedDate)
      numDaysRest = finishedDateMoment.diff(moment(), 'days')
      if (numDaysRest < 0) {
        numDaysRest = 0
      }
    }

    return numDaysRest
  }

  render() {
    const customer = this.props.item
    const name = _.get(customer, 'organization.name', '')
    const imageId = _.get(customer, 'organization.image._id', null)
    const enabledMembers = _.get(customer, 'organization.enabledMembers', 0)
    const amount = formatMoney(_.get(customer, 'amount', 0))
    const finishedDate = _.get(customer, 'finishedCommercialDate', false)

    const numDaysRest = this.calculateNumDaysRest(finishedDate)

    return (
      <ComponentStyled>
        <div className="customer-wrapper">
          <div className="avatar-wrapper">
            <Avatar resourceId={imageId} size="big" showFullname={false} name={name} />
          </div>
          <p className="name">{name}</p>
          <p className="members">
            {enabledMembers} {i18n.customerInfo.members}
          </p>
          <p className="price">{amount}</p>
        </div>
        <div className="info-wrapper">
          <p className="title">{i18n.customerInfo.info}</p>
          <p className="info">{i18n.formatString(i18n.customerInfo.daysRest, numDaysRest)}</p>
        </div>
      </ComponentStyled>
    )
  }
}

CustomerInfo.propTypes = {
  item: PropTypes.object
}
export default CustomerInfo
