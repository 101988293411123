import styled from 'styled-components'
import colors from '../../../assets/colors'

const ComponentStyled = styled.div`
  && {
    .title-member-wrapper {
      padding-bottom: 30px;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 15px;

      ::before {
        content: '';
        position: absolute;
        bottom: 0;
        width: 1000px;
        right: 50%;
        transform: translateX(50%);
        height: 1px;
        background-color: ${colors.lightGrey};
      }

      .title-member {
        font-size: 20px;
        line-height: 24px;
        letter-spacing: -0.62px;
        color: ${colors.black};
        margin-bottom: 0;
      }
    }

    .back {
      font-size: 14px;
      line-height: 24px;
      letter-spacing: 1.08px;
      text-transform: uppercase;
      color: ${colors.black};
      margin-bottom: 0;
      cursor: pointer;
    }
  }
`

export default ComponentStyled
