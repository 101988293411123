import { connect } from 'react-redux'
import compose from 'recompose/compose'
import { organizationsOperations } from '../../../redux/organizations'
import View from './view'

const mapStateToProps = state => {
  return {
    isFetching: state.organizations.isFetching,
    list: state.organizations.list
  }
}

const mapDispatchToProps = dispatch => {
  return {
    postOrganization: data => dispatch(organizationsOperations.postOrganization(data)),
    postOrganizationUsers: (data, organizationId) => dispatch(organizationsOperations.postOrganizationUsers(data, organizationId)),
    updateSelectedOrganization: organization => dispatch(organizationsOperations.updateSelectedOrganization(organization)),
    initList: () => dispatch(organizationsOperations.initList())
  }
}

const enhance = compose(connect(mapStateToProps, mapDispatchToProps))

export default enhance(View)
