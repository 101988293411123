import { connect } from 'react-redux'
import compose from 'recompose/compose'
import View from './view'
import { messagesOperations } from '../../../redux/messages'
import { directsOperations } from '../../../redux/directs'
import { themesOperations } from '../../../redux/themes'
import { unreadMessagesOperations } from '../../../redux/unread-messages'

const mapStateToProps = state => {
  return {
    themesFetching: state.themes.isFetching,
    messagesFetching: state.messages.isFetching,
    participantsFetching: state.users.themeParticipantsLoading,
    directsFetching: state.directs.isFetching,
    theme: state.themes.item,
    userInfo: state.me.userInfo,
    messages: state.messages.list,
    participants: state.users.themeParticipantsList,
    organization: state.organizations.item,
    direct: state.directs.item,
    pageInfo: state.messages.pageInfo
  }
}

const mapDispatchToProps = dispatch => {
  return {
    getPollByMessageId: (poll, userInfo) => dispatch(themesOperations.getPollByMessageId(poll, userInfo)),
    postThemeMessage: data => dispatch(messagesOperations.postThemeMessage(data)),
    postDirectMessage: data => dispatch(messagesOperations.postDirectMessage(data)),
    loadThemeMessageDirects: (messageId, authorId) => dispatch(directsOperations.loadThemeMessageDirects(messageId, authorId)),
    fetchThemeMessagesList: showBroadcastOnly => dispatch(messagesOperations.fetchThemeMessagesList(showBroadcastOnly)),
    fetchDirectMessagesList: () => dispatch(messagesOperations.fetchDirectMessagesList()),
    updateDirectReadedDate: direct => dispatch(unreadMessagesOperations.postNotifyDirect(direct))
  }
}

const enhance = compose(connect(mapStateToProps, mapDispatchToProps))

export default enhance(View)
