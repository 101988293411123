import React from 'react'
import ComponentStyled from './styled'
import { RegularInput, Button, RegularSelect } from '../../atoms'
import i18n from '../../../assets/i18n'
import { Formik } from 'formik'
import PropTypes from 'prop-types'
import { countryOptions } from '../../../utils'
import IBAN from 'iban'

const ComercialRegisterForm = ({ initialValues, onSubmit }) => {
  return (
    <ComponentStyled>
      <Formik
        initialValues={initialValues}
        validate={values => {
          let errors = {}
          if (!values.document) {
            errors.document = i18n.comercialRegisterForm.requiredField
          }
          if (!values.address) {
            errors.address = i18n.comercialRegisterForm.requiredField
          }
          if (!values.country) {
            errors.country = i18n.comercialRegisterForm.requiredField
          }
          if (!values.province) {
            errors.province = i18n.comercialRegisterForm.requiredField
          }
          if (!values.city) {
            errors.city = i18n.comercialRegisterForm.requiredField
          }
          if (!values.iban) {
            errors.iban = i18n.comercialRegisterForm.requiredField
          }
          if (!IBAN.isValid(values.iban)) {
            errors.iban = i18n.comercialRegisterForm.invalidIban
          }

          return errors
        }}
        onSubmit={data => {
          onSubmit(data)
        }}
      >
        {({ values, errors, touched, handleChange, handleBlur, handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <RegularInput
              label={i18n.comercialRegisterForm.dniLabel}
              placeholder={i18n.comercialRegisterForm.dniPlaceholder}
              handleOnChange={handleChange}
              handleOnBlur={handleBlur}
              name="document"
              value={values.document}
              hasError={errors.document && true}
            />
            {errors.document && <p className="error-msg-input">{errors.document}</p>}

            <RegularInput
              label={i18n.comercialRegisterForm.addressLabel}
              placeholder={i18n.comercialRegisterForm.addressPlaceholder}
              handleOnChange={handleChange}
              handleOnBlur={handleBlur}
              name="address"
              value={values.address}
              hasError={errors.address && true}
            />
            {errors.address && <p className="error-msg-input">{errors.address}</p>}

            <RegularSelect
              options={countryOptions}
              label={i18n.comercialRegisterForm.countryLabel}
              handleOnChange={handleChange}
              handleOnBlur={handleBlur}
              name="country"
              defaultSelectedValue={initialValues ? initialValues.country : null}
              value={values.country}
              hasError={errors.country && true}
            />
            {errors.country && <p className="error-msg-input">{errors.country}</p>}

            <RegularInput
              label={i18n.comercialRegisterForm.provinceLabel}
              placeholder={i18n.comercialRegisterForm.provinceLabel}
              handleOnChange={handleChange}
              handleOnBlur={handleBlur}
              name="province"
              value={values.province}
              hasError={errors.province && true}
            />
            {errors.province && <p className="error-msg-input">{errors.province}</p>}

            <RegularInput
              label={i18n.comercialRegisterForm.cityLabel}
              placeholder={i18n.comercialRegisterForm.cityLabel}
              handleOnChange={handleChange}
              handleOnBlur={handleBlur}
              name="city"
              value={values.city}
              hasError={errors.city && true}
            />
            {errors.city && <p className="error-msg-input">{errors.city}</p>}

            <RegularInput
              label={i18n.comercialRegisterForm.accountLabel}
              placeholder={i18n.comercialRegisterForm.accountPlaceholder}
              handleOnChange={handleChange}
              handleOnBlur={handleBlur}
              name="iban"
              value={values.iban}
              hasError={errors.iban && true}
            />
            {errors.iban && <p className="error-msg-input">{errors.iban}</p>}

            <Button type="submit" variant="big" label={i18n.comercialRegisterForm.save} />
          </form>
        )}
      </Formik>
    </ComponentStyled>
  )
}

ComercialRegisterForm.defaultProps = {
  onSubmit: () => {},
  initialValues: {}
}

ComercialRegisterForm.propTypes = {
  onSubmit: PropTypes.func,
  initialValues: PropTypes.object
}

export default ComercialRegisterForm
