import * as usersActions from './actions'
import _ from 'lodash'

export const initOrganizationUsersList = searchText => {
  return async dispatch => {
    dispatch(usersActions.updateOrganizationUsersList([], 0, null))
    return dispatch(fetchOrganizationUsersList(searchText))
  }
}

export const fetchOrganizationUsersList = searchText => async (dispatch, getState, { client, api, setError }) => {
  const { item: organization } = getState().organizations
  if (!organization) {
    return
  }

  try {
    dispatch(usersActions.setOrganizationUsersFetching(true))

    const { organizationUsersList: currentUsersList, organizationUsersPageInfo } = getState().users
    const endCursor = _.get(organizationUsersPageInfo, 'endCursor', null)
    const organizationUsersRes = await client.query({
      query: api.queries.GET_ORGANIZATION_USERS,
      variables: { organizationId: organization.id, searchText, endCursor },
      fetchPolicy: 'network-only'
    })

    const newUsersList = _.map(_.get(organizationUsersRes, 'data.organizationUsersUsers.edges', []), v => v.node)
    const newPageInfo = _.get(organizationUsersRes, 'data.organizationUsersUsers.pageInfo', { hasNextPage: false, hasPrevPage: false })
    const total = _.get(organizationUsersRes, 'data.organizationUsersUsers.totalCount', 0)
    const usersList = [...currentUsersList, ...newUsersList]
    dispatch(usersActions.updateOrganizationUsersList(usersList, total, newPageInfo))
    return usersList
  } catch (e) {
    dispatch(setError(e))
    return []
  } finally {
    dispatch(usersActions.setOrganizationUsersFetching(false))
  }
}

export const initThemeParticipantsList = themeId => {
  return async dispatch => {
    dispatch(usersActions.updateThemeParticipantsList([], 0, null))
    return dispatch(fetchThemeParticipantsList(themeId))
  }
}

export const fetchThemeParticipantsList = themeId => async (dispatch, getState, { client, api, setError }) => {
  const { item: theme } = getState().themes
  if (!theme && !themeId) {
    return []
  }

  try {
    dispatch(usersActions.setThemeParticipantsFetching(true))

    const { themeParticipantsList: currentParticipantsList, themeParticipantsPageInfo } = getState().users
    const endCursor = _.get(themeParticipantsPageInfo, 'endCursor', null)
    const themeParticipantsRes = await client.query({
      query: api.queries.GET_THEME_PARTICIPANTS,
      variables: { themeId: themeId || theme.id, endCursor },
      fetchPolicy: 'network-only'
    })

    const newParticipantsList = _.map(_.get(themeParticipantsRes, 'data.themeParticipantsUsers.edges', []), v => v.node)

    const newPageInfo = _.get(themeParticipantsRes, 'data.themeParticipantsUsers.pageInfo', { hasNextPage: false, hasPrevPage: false })
    const total = _.get(themeParticipantsRes, 'data.themeParticipantsUsers.totalCount', 0)
    const usersList = [...currentParticipantsList, ...newParticipantsList]
    dispatch(usersActions.updateThemeParticipantsList(usersList, total, newPageInfo))

    if (newPageInfo?.hasNextPage) {
      dispatch(fetchThemeParticipantsList(themeId))
    } else {
      dispatch(usersActions.setThemeParticipantsFetching(false))
      return usersList
    }
  } catch (e) {
    dispatch(setError(e))
    dispatch(usersActions.setThemeParticipantsFetching(false))
    return []
  }
}

export const initThemeAdminsList = themeId => {
  return async dispatch => {
    dispatch(usersActions.updateThemeAdminsList([], 0, null))
    return dispatch(fetchThemeAdminsList(themeId))
  }
}

export const fetchThemeAdminsList = () => async (dispatch, getState, { client, api, setError }) => {
  const { item: theme } = getState().themes
  if (!theme) {
    return []
  }

  try {
    dispatch(usersActions.setThemeAdminsFetching(true))

    const { themeAdminsList: currentAdminsList, themeAdminsPageInfo } = getState().users
    const endCursor = _.get(themeAdminsPageInfo, 'endCursor', null)
    const themeAdminsRes = await client.query({
      query: api.queries.GET_THEME_ADMINS,
      variables: { themeId: theme?.id, endCursor },
      fetchPolicy: 'network-only'
    })

    const newAdminsList = _.map(_.get(themeAdminsRes, 'data.themeAdminsUsers.edges', []), v => v.node)

    const newPageInfo = _.get(themeAdminsRes, 'data.themeAdminsUsers.pageInfo', { hasNextPage: false, hasPrevPage: false })
    const total = _.get(themeAdminsRes, 'data.themeAdminsUsers.totalCount', 0)
    const usersList = [...currentAdminsList, ...newAdminsList]
    dispatch(usersActions.updateThemeAdminsList(usersList, total, newPageInfo))

    if (newPageInfo?.hasNextPage) {
      return dispatch(fetchThemeAdminsList())
    } else {
      dispatch(usersActions.setThemeAdminsFetching(false))
      return usersList
    }
  } catch (e) {
    dispatch(setError(e))
    dispatch(usersActions.setThemeAdminsFetching(false))
    return []
  }
}

export const fetchNonPersistentThemeParticipantsList = (themeId, pageInfo, currentList = []) => async (dispatch, getState, { client, api, setError }) => {
  if (!themeId) {
    return []
  }

  try {
    dispatch(usersActions.setThemeParticipantsFetching(true))

    const endCursor = _.get(pageInfo, 'endCursor', null)
    const themeParticipantsRes = await client.query({
      query: api.queries.GET_THEME_PARTICIPANTS,
      variables: { themeId: themeId, endCursor },
      fetchPolicy: 'network-only'
    })

    const newList = _.map(_.get(themeParticipantsRes, 'data.themeParticipantsUsers.edges', []), v => v.node)
    const newPageInfo = _.get(themeParticipantsRes, 'data.themeParticipantsUsers.pageInfo', { hasNextPage: false, hasPrevPage: false })

    const usersList = [...currentList, ...newList]

    if (newPageInfo?.hasNextPage) {
      return dispatch(fetchNonPersistentThemeParticipantsList(themeId, newPageInfo, usersList))
    } else {
      dispatch(usersActions.setThemeParticipantsFetching(false))
      return usersList
    }
  } catch (e) {
    dispatch(setError(e))
    dispatch(usersActions.setThemeParticipantsFetching(false))
    return []
  }
}
