import React from 'react'
import NoReadMsgItemStyled from './styled'
import PropTypes from 'prop-types'

const NoReadMsgItem = ({ title, subtitle, hour, totalMessages }) => {
  return (
    <NoReadMsgItemStyled>
      <div>
        <p className="title-no-read">{title}</p>
        <p className="msg-no-read">{subtitle}</p>
      </div>
      <div className="right-wrapper">
        <p className="hour-no-read">{hour}</p>
        <div className="total-no-read">
          <span>{totalMessages}</span>
        </div>
      </div>
    </NoReadMsgItemStyled>
  )
}

NoReadMsgItem.propTypes = {
  imageUrl: PropTypes.string,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  hour: PropTypes.string,
  totalMessages: PropTypes.number
}

export default NoReadMsgItem
