import styled from 'styled-components'

const ComponentStyled = styled.div`
  && {
    .modal-header,
    .modal-body {
      border: 0;
      padding: 0;
    }

    .info-wrapper {
      padding: 30px;
      text-align: center;
      display: flex;
      gap: 30px;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .back {
        margin-top: 30px;
        font-size: 14px;
        font-weight: 600;
        line-height: 16px;
        letter-spacing: 1.08px;
        text-transform: uppercase;

        span {
          cursor: pointer;
        }
      }
    }
  }
`

export default ComponentStyled
