import * as types from './types'

export const setFetching = value => ({
  type: types.SET_FETCHING,
  value
})

export const updateList = (list, total = 0, pageInfo) => ({
  type: types.UPDATE_LIST,
  list,
  total,
  pageInfo
})

export const updateItem = value => ({
  type: types.UPDATE_ITEM,
  value
})
