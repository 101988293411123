import styled from 'styled-components'
import { RegularSelect, Button } from 'components/atoms'
import { Panel as PanelComp } from 'components/atoms'
import colors from '../../../assets/colors'

export const SectionHeader = styled.div`
  display: flex;
  justify-content: center;
  & > * {
    margin: 0px 8px;
  }
  @media print {
    display: none;
  }
`

export const SectionSubheader = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 32px;
  & > * {
    margin-right: 16px;
  }
  @media print {
    margin-top: 50px;
    margin-bottom: 0px;
  }
`

export const Header = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  margin-bottom: 40px;
  @media print {
    margin-bottom: 0px;
  }

  .stats-header-label {
    font-size: 16px;
    line-height: 24px;
    color: ${colors.black};
    margin: 0 16px 0 0;
  }

  @media (max-width: 576px) {
    flex-direction: column;
    .stats-header-label {
      margin: 0 0 16px 0;
    }
  }
`

export const Container = styled.div`
  width: ${({ printView }) => (printView ? '1300px' : '100%')};
  @page {
    size: landscape;
  }
  & .show {
    display: block;
  }
  & .hide {
    display: none;
  }
  @media print {
    width: 1300px;
    & .show {
      display: block;
    }
  }
`

export const Panel = styled(PanelComp)`
  @media print {
    margin: 0px;
    padding-bottom: 0px;
  }
`

export const ChartList = styled.div`
  display: grid;
  grid-template-columns: calc(50% - 30px) calc(50% - 30px);
  grid-row-gap: 0px;
  grid-column-gap: 74px;
  justify-items: stretch;
  padding: 75px 0px;
  @media print {
    padding-top: 0px;
    grid-row-gap: 30px;
    & .hide {
      display: block;
    }
  }
`

export const DataList = styled.div`
  padding: 75px 0px;
  border-bottom: solid 1px ${colors.grey};
  @media print {
    padding: 10px 0px;
  }
`

export const DataContainer = styled.div``

export const LabelAdministrator = styled.span``

export const Administrator = styled.span`
  color: ${colors.yellow};
`

export const Selector = styled(RegularSelect)`
  && {
    & .select-wrapper {
      margin-bottom: 0px;
    }
    @media print {
      & img {
        display: none;
      }
    }
  }
`
export const ButtonGoToTheme = styled(Button)`
  && {
    @media print {
      display: none;
    }
  }
`
