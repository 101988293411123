import React from 'react'
import PropTypes from 'prop-types'
import ComponentStyled from './styled'
import { ReactComponent as DownloadIcon } from '../../../assets/images/download_icon.svg'

const BillingRowItem = ({ title, description, moreInfo, onClick, onClickDownload }) => {
  return (
    <ComponentStyled hasClickEvent={onClick ? true : false} onClick={onClick}>
      <div className="info-wrapper">
        <p className="title">{title}</p>
        <p className="desc">{description}</p>
        <p className="more-info">{moreInfo}</p>
      </div>
      <DownloadIcon className="download-icon" onClick={onClickDownload} />
    </ComponentStyled>
  )
}

BillingRowItem.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  moreInfo: PropTypes.string,
  onClick: PropTypes.func,
  billingUrl: PropTypes.string,
  onClickDownload: PropTypes.func
}

export default BillingRowItem
